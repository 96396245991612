import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private readonly afs: AngularFirestore) { }


  log(data: LOG) {
    const id = this.afs.createId();
    // console.log("LOG", data)
    this.afs.collection('log').doc(id).set(data)
  }
}



interface LOG {
  registered_by?: string
  name: string;
  module: string;
  method: string;
  type: string;
  date: number;
  uid: string;
  user: {}
  erro: {};
}