import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CommonService, CustomizationfileService } from 'src/app/services/services';
import { AssigmentService } from 'src/app/services/v2/permission/assigment.service';

@Component({
  selector: 'app-claim-trophy',
  templateUrl: './claim-trophy.component.html',
  styleUrls: ['./claim-trophy.component.scss'],
})
export class ClaimTrophyComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  isChecked = false


  public query: string = '';
  // public query: string = '';
  public list: any[] = [];
  @Input() details: any;
  awardObj: { type: string; medals: any; trophy: number; participarte?: undefined; } | { type: string; participarte: any; trophy: number; medals?: undefined; };

  constructor(
    private readonly afs: AngularFirestore,
    public commonService: CommonService,
    private assigmentService: AssigmentService,
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    public customizationfileService: CustomizationfileService
  ) {
    this.registerForm = this.formBuilder.group({
      name: ["", Validators.required],
      identification: ["", [Validators.required, Validators.minLength(4)]],
      email: ["", [Validators.required, Validators.email]],
      date: [Date.now()],
      uid: [this.customizationfileService.getUid()],
    });
  }

  ngOnInit() {
    // console.log("details", this.details.item)
    this.registerForm.patchValue({
      name: `${this.details.profile.name} ${this.details.profile.surnames}`,
      identification: `${this.details.profile.identificationNumber}`,
      email: `${this.details.profile.email}`
    });

    this.awardObj = this.commonService.
      typeDivision(this.details.code, this.details.myRating.participarte.length)
  }

  setUser(user) {
    // console.log('user', user)
    let uid = user._id ? user._id : user.uid
    this.isChecked = false;
    this.registerForm.patchValue({
      name: user.name,
      identification: user.identification,
      email: user.email,
      uid: uid
    });
  }

  clearSearch() {
    this.query = '';
    this.list = [];
  }

  async onSearchChange() {
    if (`${this.query}`.trim().length === 0) { return; }

    try {
      const find = await this.assigmentService.findUseByEmail(this.query);
      this.list = find;
      // console.log({ find });
    } catch (error) {
      // console.log('Error on find user by email', { error });
    }
  }


  async destroy(item: any) { }

  ngOnDestroy() { }


  get f() {
    return this.registerForm.controls;
  }

  closeModal(update) {
    this.modalCtrl.dismiss({
      update
    });
  }

  async onSubmit() {
    try {
      this.submitted = true;

      // stop here if form is invalid
      // console.log(this.registerForm.invalid);
      if (this.registerForm.invalid) {
        this.commonService.presentAlertTranslation("formulario incompleto")
        return;
      }

      this.details.item.component = null


      await this.commonService.presentLoading();
      const data = this.registerForm.value;


      let keyRequest = this.details.myRating.key
      let keydb = this.customizationfileService.getKeyDb()
      let request = Object.assign(data, this.details,
        {
          award: this.awardObj,
          type: this.details.item.id,
          keyRequest,
          keydb,
          status: 3
        })

      // console.log('keyRequest', keyRequest)
      // console.log('request', request)

      await this.afs.collection('claim-trophy').doc(keyRequest).set(request)

      this.commonService.dismissLoading()
      this.commonService.presentAlertTranslation("solicitud exitosa")
      this.closeModal(1)
    } catch (error) {
      this.commonService.dismissLoading()
      this.commonService.presentAlertTranslation("error")
    }


  }



}
