import { Component, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { AddShippingPriceComponent } from '../add-shipping-price/add-shipping-price.component';
import { ShippingPriceListComponent } from '../shipping-price-list/shipping-price-list.component';

@Component({
  selector: 'app-popover-trophy',
  templateUrl: './popover-trophy.component.html',
  styleUrls: ['./popover-trophy.component.scss'],
})
export class PopoverTrophyComponent implements OnInit {

  optionList = [
    {
      name: "add shipping price",
      component: AddShippingPriceComponent
    },
    {
      name: "shipping Price List",
      component: ShippingPriceListComponent
    }
  ]


  constructor(public modalController: ModalController, public popoverController: PopoverController) { }

  ngOnInit() { }



  async openModal(component) {
    this.popoverController.dismiss()
    const modal = await this.modalController.create({
      component: component,
      cssClass: 'my-custom-class',
      componentProps: { details: "" }
    });

    modal.onDidDismiss()
      .then(async (dataReturned) => {
        console.log("dataReturned", dataReturned)

      });
    return await modal.present();
  }

}
