import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/services';

@Component({
  selector: 'app-email-verified',
  templateUrl: './email-verified.component.html',
  styleUrls: ['./email-verified.component.scss'],
})
export class EmailVerifiedComponent implements OnInit {
  @Input() email: any;
  constructor(public auth: AuthenticationService, private router: Router,) { }

  ngOnInit() { }


  async setEmailVerified() {
    await this.auth.setEmailVerified().catch(err => {
      console.log("err", err)
    })
    this.router.navigate(["/members/user/profile"]);
  }
}
