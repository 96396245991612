import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-see-request',
  templateUrl: './see-request.component.html',
  styleUrls: ['./see-request.component.scss'],
})
export class SeeRequestComponent implements OnInit {
  @Input() details: any;
  resultList = []
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {
    Object.keys(this.details).forEach(x => {
      this.resultList.push({
        name: this.details[x], key: x
      })
    })

    console.log(this.details)


  }

  getInfo(res, key) {
    // console.log("res", res)
    if (!res || key === "myRating" || key === "profile" || key === "award" || key === "item") { return null }
    return JSON.stringify(res)
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
