import { Component } from "@angular/core";
import { ModalController, NavParams, LoadingController } from "@ionic/angular";
import { CustomizationfileService } from "../../services/v1/customizationfile/customizationfile.service";

@Component({
  selector: "app-information",
  templateUrl: "./information.component.html",
  styleUrls: ["./information.component.scss"]
})
export class InformationComponent {
  public details: any = [];
  show_music = false

  constructor(private modalCtrl: ModalController,
    private params: NavParams,
    public loadingController: LoadingController,
    public _cf: CustomizationfileService) {
    this.details = this.params.get("details");
    this.showMusic()
  }

  getHora(i, s) {
    let suma = i * 3 + s;
    return this.convertMinsToHrsMins(suma);
  }

  showMusic() {
    if (this.details) {
      const value = this.details.user.participants
      const uid = this._cf.getUid()
      for (let index = 0; index < value.length; index++) {
        const element = value[index].uid
        if (uid === element) {
          this.show_music = true
        }
      }
    }
  }

  convertMinsToHrsMins(mins) {
    if (!mins) return;
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}:${m}`;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
