import { Component, Input, OnInit } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { AngularFireDatabase } from "angularfire2/database";
import {
  CommonService,
  CustomizationfileService,
  ManageUsersService,
  RatingsService,
} from "src/app/services/services";
import { MediaFilesService } from "src/app/services/v2/media-files.service";
import { ScrollEventService } from "src/app/services/v2/scroll-event.service";
import { SeeVideoComponent } from "../../../components/see-video/see-video.component";

@Component({
  selector: "app-main-choreography-admin",
  templateUrl: "./main-choreography-admin.component.html",
  styleUrls: ["./main-choreography-admin.component.scss"],
})
export class MainChoreographyAdminComponent implements OnInit {
  @Input() data: any;
  @Input() keyDivision: string;
  @Input() round: string;
  @Input() code: string;
  @Input() dataGroups: any;
  @Input() participarte: string;
  @Input() keydb: string;
  name: any;
  files: any;
  listAudio = [];

  constructor(
    public ratingsService: RatingsService,
    public manageUsersService: ManageUsersService,
    public alertController: AlertController,
    public scrollEventService: ScrollEventService,
    public db: AngularFireDatabase,
    public commonService: CommonService,
    public mediaFilesService: MediaFilesService,
    public modalController: ModalController,
    public customizationfileService: CustomizationfileService
  ) { }

  async ngOnInit() {
    this.name = this.dataGroups
      ? this.dataGroups.nameGroups
      : this.participarte;

    this.getAudio(this.data);
    this.createStates();
  }

  async getAudio(data) {
    console.log("audio", data.audio)
    if (!data.audio) {
      return;
    }

    this.listAudio = Object.keys(data.audio).map((x) => {
      return Object.assign(data.audio[x], { uid: x })
    })

    console.log("listAudio", this.listAudio)

  }

  async createStates() {
    if (this.data) {
      console.log("ya tiene el paso 4 creado");
      return;
    }

    console.log("create step 3", {
      keydb: this.keydb,
      round: this.round,
      code: this.code,
      keyDivision: this.keyDivision,
      uid: this.customizationfileService.getUid(),
    });

    await this.commonService.presentLoading();
    await this.manageUsersService
      .mainChoreography({
        keydb: this.keydb,
        round: this.round,
        code: this.code,
        keyDivision: this.keyDivision,
        uid: this.customizationfileService.getUid(),
      })
      .then(() => {
        this.commonService.dismissLoading();
        this.scrollEventService.updateAdminEvent.next(true);
      })
      .catch((err) => {
        this.commonService.dismissLoading();
        this.commonService.presentAlert(JSON.stringify(err));
      });
  }

  async presentModal(data, video) {
    console.log("data", data);
    const modal = await this.modalController.create({
      component: SeeVideoComponent,
      cssClass: "my-custom-modal-css",
      componentProps: {
        value: {
          name: this.name,
          nameVideo: `${this.code}-${this.round}-${this.name}-test-videos-${this.keyDivision}`,
          url: video ? data.video : data.videoReview,
          code: this.code,
          key: this.keyDivision,
        },
      },
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        if (dataReturned.data.status === 1) {

        }
      }
    });

    return await modal.present();
  }


  async viewVideo(url) {
    this.mediaFilesService.playVideo(url)
  }

  async uploadFile($event) {
    try {
      await this.commonService.presentLoading();
      console.log("$event", $event);
      this.files = $event.target.files;
      const videoBase64 = await this.mediaFilesService.getBase64(this.files[0]);
      const videoUrl = await this.mediaFilesService.uploadFile(videoBase64);

      await this.db
        .object(`/competition/${this.keydb}/mainChoreography/${this.code}/${this.round}/${this.keyDivision}`)
        .update({
          video: videoUrl,
          comment: "video uploaded by admin",
          step: 3,
          admin: this.customizationfileService.getprofile(),
        });

      await this.db
        .object(`/competition/${this.keydb}/division/${this.keyDivision}/states`)
        .update({ step3: 3, mainStep: 3 });
      this.scrollEventService.updateAdminEvent.next(true);
      this.commonService.dismissLoading();
    } catch (err) {
      this.commonService.dismissLoading();
      this.commonService.presentAlert(JSON.stringify(err));
    }
  }

  async enableWebUpload(value) {
    const alert = await this.alertController.create({
      header: "¿habilitar carga web?",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Ok",
          handler: async () => {
            await this.db
              .object(
                `/competition/${this.keydb}/mainChoreography/${this.code}/${this.round}/${this.keyDivision}`
              )
              .update({
                upload_web: value,
              });

            this.commonService.presentAlert("successfully enabled");
          },
        },
      ],
    });

    await alert.present();
  }

  changeStatus(status) {
    switch (status) {
      case 0:
        this.presentAlertPrompt({ status, title: "" });
        break;
      case 1:
        this.presentAlertPrompt({ status, title: "pending" });
        break;
      case 2:
        this.presentAlertPrompt({ status, title: "reject" });
        break;
      case 3:
        this.presentAlertPrompt({ status, title: "accepted" });
        // this.status({ status, comment: "accepted" });
        break;
    }
  }

  async presentAlertPrompt({ status, title }) {
    const alert = await this.alertController.create({
      header: title,
      inputs: [
        {
          name: "value",
          type: "textarea",
          placeholder: "ingresar comentario",
        },
      ],
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "Ok",
          handler: (data) => {
            if (status === 3) {
              const c = data.value.length > 0 ? data.value : "accepted";
              this.status({ status, comment: c });
            } else if (data.value.length > 0) {
              this.status({ status, comment: data.value });
            } else {
              this.commonService.presentAlert("debes agregar un mensaje");
            }
          },
        },
      ],
    });

    await alert.present();
  }

  async status({ status, comment }) {
    console.log({ status, comment });
    await this.commonService.presentLoading();
    await this.db
      .object(
        `/competition/${this.keydb}/mainChoreography/${this.code}/${this.round}/${this.keyDivision}`
      )
      .update({
        comment: comment,
        step: status,
      });

    console.log(
      `/competition/${this.keydb}/division/${this.keyDivision}/states`
    );
    await this.db
      .object(`/competition/${this.keydb}/division/${this.keyDivision}/states`)
      .update({ step3: status, mainStep: status });

    this.commonService.dismissLoading();
    this.scrollEventService.updateAdminEvent.next(true);
    // this.commonService.reloadApp();
  }



  async changeMultimedia($event, item, isVideo, oldMultimedia) {
    await this.commonService.presentLoading()
    console.warn("$event", $event)
    this.files = $event.target.files;
    const videoBase64 = await this.mediaFilesService.getBase64(this.files[0]);
    const UrlMultimedia = await this.mediaFilesService.uploadFile(videoBase64);

    console.log("videoUrl", UrlMultimedia)
    console.warn("item", item)
    console.log(`/competition/${this.keydb}/mainChoreography/${this.code}/${this.round}/${this.keyDivision}/audio/${item.uid}`)


    let _isVideo = isVideo ? 'urlVideo' : 'urlaudio';

    console.log("_isVideo", _isVideo)
    console.log("{ [_isVideo]: UrlMultimedia }", { [_isVideo]: UrlMultimedia })


    await this.db
      .list(`/competition/${this.keydb}/feedbackChange/${this.code}/${this.round}/${this.keyDivision}/audio/${item.uid}`)
      .push({
        type: "main-choreography-admin-module",
        [_isVideo]: UrlMultimedia,
        oldMultimedia: oldMultimedia,
        date: Date.now(),
        uidAdmin: this.customizationfileService.getUid()
      });

    await this.db
      .object(`/competition/${this.keydb}/mainChoreography/${this.code}/${this.round}/${this.keyDivision}/audio/${item.uid}`)
      .update({ [_isVideo]: UrlMultimedia });




    this.commonService.dismissLoading()
    this.commonService.presentAlert("updated video")
    window.location.reload()
  }




  uploadFeeback() {

    // Audio

    // Audio

  }

  showComment(text) {
    this.commonService.presentAlert(text);
  }
}
