import { Injectable } from "@angular/core";
import { AngularFirestore, DocumentReference } from "@angular/fire/firestore";
import { Observable, from, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class LikesService {
  constructor(public readonly afs: AngularFirestore) {}

  public like(holeId: string, userId: string): Observable<DocumentReference> {
    // https://emilyxiong.medium.com/how-to-write-a-firebase-cloud-function-to-implement-a-counter-a6acd9452826
    return from(
      this.afs.firestore
        .collection("chapters-likes")
        .doc(holeId)
        .collection("likes")
        .add({ uuid: userId })
    );
  }

  public alreadyLiked(holeId: string, userId: string) {
    return from(
      this.afs.firestore
        .collection("chapters-likes")
        .doc(holeId)
        .collection("likes")
        .where("uuid", "==", userId)
        .get()
        .then((querySnapshot) => {
          return querySnapshot.docs.length;
        })
    );
  }

  public unlike(holeId: string, userId: string): Observable<void> {
    return from(
      this.afs.firestore
        .collection("chapters-likes")
        .doc(holeId)
        .collection("likes")
        .where("uuid", "==", userId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.map((doc) => {
            return doc.ref.delete();
          });
        })
    );
  }

  private likesCollection(holeId: string) {
    return this.afs
      .collection("chapters-likes")
      .doc(holeId)
      .collection("likes");
  }

  public getLikesCount(holeId: string): Observable<number> {
    return from(this.likesCollection(holeId).get()).pipe(
      map((querySnapshot) => {
        return querySnapshot.size;
      }),
      catchError((_) => of(0))
    );
  }
}
