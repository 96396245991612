import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { AngularFireDatabase } from "angularfire2/database";
import { CommonService } from "src/app/services/services";

@Component({
  selector: "app-main-video",
  templateUrl: "./main-video.component.html",
  styleUrls: ["./main-video.component.scss"],
})
export class MainVideoComponent implements OnInit {
  round: any;
  keydb: any;
  data: any;
  name: any;
  screenResult: boolean;
  loading: boolean;
  day: any;
  constructor(
    public db: AngularFireDatabase,
    public modalController: ModalController,
    private navParams: NavParams,
    public commonService: CommonService
  ) {
    this.round = this.navParams.get("round");
    this.keydb = this.navParams.get("keydb");
    this.day = this.navParams.get("day");

    console.log("this.keydb", this.keydb);
    console.log("this.round", this.round);
  }

  ngOnInit() {
    console.log(`/livingRoom/${this.keydb}/${this.day}/${this.round}/livingRoomMain/0`)
    this.db
      .object(`/livingRoom/${this.keydb}/${this.day}/${this.round}/livingRoomMain/0`)
      .valueChanges()
      .subscribe((res: any) => {
        this.data = {};
        this.name = "";
        if (res === null) {
          this.screenResult = false;
          this.commonService.presentToast("No user to rate");
          return;
        }
        this.data = res;
        this.name = res.user;
        this.screenResult = true;
        this.loading = true;
        console.log("livingRoomMain", res);
      });
  }

  dismiss(status, message?) {
    this.modalController.dismiss({
      dismissed: true,
      status,
      message,
    });
  }
}
