import { Pipe, PipeTransform } from '@angular/core';
import { ManageUsersService } from "../../services/v1/manage_users/manage-users.service";

@Pipe({
  name: 'validateDivision'
})
export class ValidateDivisionPipe implements PipeTransform {


  constructor(private _mus: ManageUsersService) { }

  async transform(value: any, division: any): Promise<any> {
    if (!value && !division) return
    // this._mus.validateDivision(value, division)
    //   .then(result => console.log)
    //   .catch(err => console.log)
    return null;
  }


  getVerifyFullPass() {

  }

  getLevel() { }

  getcheckAge() { }

}
