import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CommonService, CustomizationfileService, StorageService } from "src/app/services/services";
import { LoadingService } from "src/app/services/v2/loading.service";

@Component({
  selector: "app-competitor-verification",
  templateUrl: "./competitor-verification.component.html",
  styleUrls: ["./competitor-verification.component.css"],
})
export class CompetitorVerificationComponent implements OnInit {

  @Input() text: string = "Si no eres competidor haz click aquí";
  @Input() goToNext: string;
  @Input() public isParticipant: boolean;
  @Input() key_db: string;


  constructor(
    private loadingSrv: LoadingService,
    public commonService: CommonService,
    public _cf: CustomizationfileService,
    private storageSrv: StorageService,
    private router: Router,
  ) {
    this.key_db = this._cf.getKeyDb();
    // this.loadData()
  }

  ngOnInit() {
    /** Si no esta definido la ruta siguiente */
    if(!this.goToNext){
      this.goToNext = `/members/tabs-event/tabs/home/${this.key_db}`;
    }
  }

  loadData(){
    this.isParticipant = this._cf.getIsParticipant();
    // console.log({isParticipant: this.isParticipant});
  }

  async verefication() {
    try {
      await this.loadingSrv.presentLoading();
      console.log("click");
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingSrv.dismissLoading();
      this.commonService.presentAlert("verificar si eres competidor");
    }
  }

  async setTypeRegularAndRedirect(){
    console.log("setTypeRegularAndRedirect");
    this.storageSrv.storageSet('isParticipant', 'false');
    window.localStorage.setItem('isParticipant', 'false');
    console.log('this.goToNext: ', this.goToNext);
    return this.router.navigate([this.goToNext]);
  }

  async setUserType(type: boolean){
    try {
      await this.loadingSrv.presentLoading();
      console.log("click", type);

      const typeParsed = type ? 'true' : 'false';
      console.log('typeParsed: ', typeParsed);

      this.storageSrv.storageSet('isParticipant', typeParsed);
      window.localStorage.setItem('isParticipant', typeParsed);
      this.loadData();
      return;
      
    } catch (err) {
      console.log('Erorr on CompetitorVerificationComponent.setUserType: ', err);
      return;
    }finally{
      this.loadingSrv.dismissLoading();
    }
  }
}
