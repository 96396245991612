import { Injectable, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from "firebase/app";
import { map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { CustomizationfileService } from '../customizationfile/customizationfile.service';

import { Item } from "../../../model/item";
import { Post } from "../../../model/post";


@Injectable({
  providedIn: 'root'
})
export class SocialService implements OnInit {



  public usuario: any = {};

  private postCollection: AngularFirestoreCollection<Post>;
  public posts$: Observable<Post[]>;
  // private postDoc: AngularFirestoreDocument<Post>;

  private commentCollection: AngularFirestoreCollection<Post>;
  public comment$: Observable<Post[]>;
  private commentDoc: AngularFirestoreDocument<Post>;
  public commentItems: Observable<any[]>;



  private userDoc: AngularFirestoreCollection<Item>;
  public user: Observable<Item[]>;
  public _likes = true


  constructor(private afs: AngularFirestore,
    public afAuth: AngularFireAuth, public _cf: CustomizationfileService) {

  }

  async ngOnInit() {
    await this.profile()
    await this.post()
  }

  profile() {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        let profile = this._cf.getprofile()
        let name = `${profile.name} ${profile.surnames}`
        this.usuario.nombre = name;
        this.usuario.avatar = this._cf.getavatar();
        this.usuario.uid = this._cf.getUid();
      }

    });
  }



  /**
   * Post
   */


  post() {
    this.postCollection = this.afs.collection<Post>('post', ref => ref.orderBy("date", 'desc').limit(20));
    this.posts$ = this.postCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Post;
        const key = a.payload.doc.id;
        return { key, ...data };
      }))
    );
  }

  getPosts() {
    return this.posts$;
  }

  addPost(item: Post) {
    this.postCollection.add(item);
  }

  getPostsCollection() {
    return this.postCollection;
  }

  /**
  * 
  * likes de publicaciones
  */


  public likesPost(key): void {
    this._likes = false
    const documentReference = this.postCollection.doc(key);

    // running the transaction
    firebase.firestore().runTransaction(t => {

      // returning the transaction function
      return t.get(documentReference.ref)
        .then(doc => {

          // read the current "value" field of the document
          const newValue = doc.data().likes + 1;

          // increase it by 1 atomically
          t.update(documentReference.ref, {
            likes: newValue
          });

        });
    }).then(res => this._likes = true, err => console.error(err));
  }

  /**
   * Comentarios de publicacion
   */

  getComment(key) {
    this.commentCollection = this.afs.collection<Post>('comment', ref => ref.where('key', '==', key).orderBy("date", 'desc').limit(20));
    this.comment$ = this.commentCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as Post;
        const key = a.payload.doc.id;
        return { key, ...data };
      }))
    );
  }

  addComment(item) {
    this.commentCollection.add(item);

  }

  getCommentCollection() {
    return this.commentCollection;
  }

}
