import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { CommonService, CustomizationfileService } from 'src/app/services/services';

@Component({
  selector: 'app-see-coupon',
  templateUrl: './see-coupon.component.html',
  styleUrls: ['./see-coupon.component.scss'],
})
export class SeeCouponComponent implements OnInit {
  @Input() details: any;
  showinfo: any
  constructor(
    public db: AngularFireDatabase,
    public commonService: CommonService,
    private modalCtrl: ModalController,
    public customizationfileService: CustomizationfileService,) { }

  ngOnInit() {
    console.log("this.details", this.details)
    this.showinfo = Object.keys(this.details).map(x => {
      return {
        value: this.details[x], key: x
      }
    })
    console.log("showinfo", this.showinfo)
  }

  async enabledCoupon() {
    console.log("enabledCoupon")
    let res = await this.commonService.presentAlertConfirm({ message: "enabled Coupon" })
    if (!res) {
      return
    }
    await this.db.list('coupon').update(this.details.key,
      {
        enabled: true,
        reactivated_coupon: true,
        reactivation_date: Date.now(),
        reactivation_user: this.customizationfileService.getUid()
      })
    this.commonService.presentAlertTranslation("cupón activado con éxito")
    this.closeModal()
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
