import { Component, OnInit } from "@angular/core";
import {
  InAppBrowser,
  InAppBrowserOptions,
} from "@ionic-native/in-app-browser/ngx";
import { Router } from "@angular/router";
import { AuthenticationService, CommonService } from "../../../../services/services";

@Component({
  selector: "app-secondary-banner",
  templateUrl: "./secondary-banner.component.html",
  styleUrls: ["./secondary-banner.component.scss"],
})
export class SecondaryBannerComponent implements OnInit {
  articles: any[] = [];
  authenticationState: boolean;
  userProfiles: any[];

  constructor(
    public authentication: AuthenticationService,
    private iab: InAppBrowser,
    private router: Router,
    public commonService: CommonService
  ) {

    this.authentication.authenticationState.subscribe((data) => { this.authenticationState = data; });

    this.authentication.authProfiles.subscribe((res) => {
      this.userProfiles = res;
      this.articles = [
        {
          title: "CILIK TV,  DANCE CHANNEL",
          subtitle: "Record",
          image: "/assets/img/logo-white.svg",
          url: "/tv/browse",
          type: 1,
          auth: false,
          public: true
        },
        // {
        //   title: "Register your dance academy",
        //   subtitle: "Record",
        //   image: "/assets/img/logo-white.svg",
        //   url: "/members/school-record",
        //   type: 1,
        //   auth: true,
        //   public: true,
        // },
        // {
        //   title: "Convocatoria de bailarines",
        //   subtitle: "para trabajar en Dubai.",
        //   image: "/assets/img/logo-white.svg",
        //   url: "/survey/call-form",
        //   type: 1,
        //   auth: false,
        //   public: true,
        // }, 
        // {
        //   title: "Academy",
        //   subtitle: "Record",
        //   image: "/assets/img/logo-white.svg",
        //   url: "/academy/app/tabs/dashboard",
        //   auth: true,
        //   type: 1,
        //   public: true,
        //   // public: this.commonService.isRoles(['academy'], this.userProfiles),
        // }, 
        // {
        //   title: "inventory",
        //   subtitle: "Record",
        //   image: "/assets/img/logo-white.svg",
        //   url: "/academy/inventory/app/articles",
        //   type: 1,
        //   auth: true,
        //   public: this.commonService.isRoles(['inventory'], this.userProfiles),
        // }
      ];
    });

  }

  ngOnInit() {

  }

  openUrl(url, type, auth) {
    console.log(url, type, auth);
    switch (type) {
      case 1:
        let urlFinal = url
        if (auth) {
          urlFinal = this.authenticationState ? url : '/auth/login'
        }
        this.router.navigate([urlFinal]);
        break;

      case 2:
        const options: InAppBrowserOptions = {
          zoom: "no",
          hidenavigationbuttons: "no",
          toolbar: "yes",
          hideurlbar: "yes",
          location: "no",
        };
        const browser = this.iab.create(url, "_blank", options);
        browser.on('loadstop').subscribe(event => {
          browser.insertCSS({ code: "toolbar{display: none;" });
        });
        break;

      default:
        break;
    }
  }
}
