import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { IonicModule } from "@ionic/angular";

import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { ComponentsModule } from "../components/components.module";
import { PipesModule } from "../pipes/pipes.module";

import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";

import { LazyLoadImageModule } from "ng-lazyload-image"; // <-- include ScrollHooks
import { IonicSelectableModule } from "ionic-selectable";
import { GridComponent } from "./grid/grid.component";
import { CompetitorVerificationComponent } from "./competitor-verification/competitor-verification.component";

@NgModule({
  imports: [
    IonicModule,
    RouterModule,
    TranslateModule,
    CommonModule,
    FormsModule,
    ComponentsModule,
    PipesModule,
    IonicSelectableModule,
    LazyLoadImageModule,
  ],
  exports: [
    PipesModule,
    ComponentsModule,
    TranslateModule,
    IonicSelectableModule,
    RouterModule,
    LazyLoadImageModule,
    GridComponent,
    CompetitorVerificationComponent,
  ],
  declarations: [GridComponent, CompetitorVerificationComponent],
  entryComponents: [],
  providers: [InAppBrowser, SocialSharing],
})
export class SharedModule {}
