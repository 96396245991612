import { Component, Input, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: "app-serial-information",
  templateUrl: "./serial-information.component.html",
  styleUrls: ["./serial-information.component.scss"],
})
export class SerialInformationComponent implements OnInit {
  @Input() watch_id: any;
  data: any;
  constructor(public readonly afs: AngularFirestore) {}

  ngOnInit() {
    console.log("watch_id", this.watch_id);
    this.afs
      .collection("series")
      .doc(this.watch_id)
      .valueChanges()
      .subscribe((res) => {
        this.data = res;
        console.log(res);
      });
  }
}
