import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usersSearch'
})
export class UsersSearchPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return
    return value.map((task, index, array) => {
      let profile = task.profile
      let identification = this.removeSllSpecialCharacters(profile.identificationNumber)
      return {
        avatar: task.avatar,
        full_pass: task.full_pass,
        bio: profile.bio,
        birthdate: profile.birthdate,
        celular: profile.celular,
        cityOfBirth: profile.cityOfBirth,
        countryOfBirth: profile.countryOfBirth,
        countryOfResidence: profile.countryOfResidence,
        email: profile.email,
        facebook: profile.facebook ? profile.facebook : false,
        gender: profile.gender,
        idType: profile.idType,
        identificationNumber: identification.replace(/\s/g, ''),
        instagram: profile.instagram ? profile.instagram : false,
        name: profile.name.toLowerCase(),
        phone: profile.phone,
        school: profile.school ? profile.school : false,
        stageName: profile.stageName ? profile.stageName : false,
        stateOfBirth: profile.stateOfBirth,
        stateOfResidence: profile.stateOfResidence,
        surnames: profile.surnames.toLowerCase(),
        tShirtSize: profile.tShirtSize ? profile.tShirtSize : false,
        uid: task.key
      }

    })
  }


  removeSllSpecialCharacters(stringToReplace) {
    if (!stringToReplace) return 'N/A'
    return stringToReplace.replace(/[^\w\s]/gi, '')
  }

}
