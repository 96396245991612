import { Component } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { ModalController, NavParams } from "@ionic/angular";
import { AngularFireDatabase } from "angularfire2/database";
import { MediaFilesService } from "src/app/services/v2/media-files.service";
import {
  RatingsService,
  LanguageService,
  CommonService,
  CustomizationfileService,
} from "../../../../services/services";
import { EditErrorResultComponent } from "../../../../pages/members/results/finalResults/edit-error-result/edit-error-result.component";
import { EditResultsComponent } from "../../../../pages/members/results/finalResults/edit-results/edit-results.component";
@Component({
  selector: "app-final-results-modal",
  templateUrl: "./final-results-modal.component.html",
  styleUrls: ["./final-results-modal.component.scss"],
})
export class FinalResultsModalComponent {
  public details: any = [];
  private itemsCollection: AngularFirestoreCollection<any>;
  isUser = false;
  keyDivision: any;
  name: any;
  round: any;
  code: any;
  keydb: any;
  divisionOld: any;
  ratingsOld: any;
  profile: any;
  files: any;
  constructor(
    public mediaFilesService: MediaFilesService,
    public customizationfileService: CustomizationfileService,
    public db: AngularFireDatabase,
    private modalCtrl: ModalController,
    public _language: LanguageService,
    private params: NavParams,
    public _rs: RatingsService,
    public _cs: CommonService,
    private afs: AngularFirestore

  ) {
    this.details = this.params.get("details");
    console.log('this.details', this.details)
    this.name = this.params.get("name");
    this.round = this.params.get("round");
    this.code = this.params.get("code");
    this.keydb = this.params.get("keydb");
    this.keyDivision = this.params.get("keyDivision");
    this.profile = this.customizationfileService.getprofile()

    this.itemsCollection = afs.collection<any>('ratings').doc(this.keydb).collection('list');
    this.validateUid();
  }

  expandItem(index2): void {
    for (let index = 0; index < this.details.itemsJunior.length; index++) {
      if (index === index2) {
        this.details.itemsJunior[index].expanded = !this.details.itemsJunior[
          index
        ].expanded;
      } else {
        this.details.itemsJunior[index].expanded = false;
      }
    }
  }

  validateUid() {
    const userUid = this.customizationfileService.getUid();
    this.details.participarte.forEach((uid) => {
      if (uid === userUid) {
        this.isUser = true;
      }
    });
  }



  async editResult(division) {
    const id = this.afs.createId();
    console.log("division", division)
    this.itemsCollection.doc(id).set({
      public: false,
      new_score: [],
      code: this.code,
      round: this.round,
      competitor: this._cs.removeCommas(this.details.name),
      date_modified: Date.now(),
      admin_name: `${this.profile.name} ${this.profile.surnames}`,
      admin_uid: this.profile.uid,
      jubge_name: division.name,
      jubge_uid: division.uid,
      type: division.type,
      previou_scores: division.ratings
    });

    const modal = await this.modalCtrl.create({
      component: EditResultsComponent,
      swipeToClose: false,
      backdropDismiss: false,
      componentProps: {
        details: division
      },
    });


    modal.onDidDismiss().then(async (dataReturned) => {
      if (!dataReturned.data.update) {
        this.itemsCollection.doc(id).delete()
        return
      }


      let newRatings = dataReturned.data.details
      let uidJubge = dataReturned.data.details.uid

      await this.db
        .object(`/rate/${this.keydb}/${this.code}/${this.round}/${this.keyDivision}/items/${uidJubge}`)
        .update(newRatings.ratings)
      await this.itemsCollection.doc(id).update({
        new_score: newRatings.ratings,
        public: true,
        ratingJustification: newRatings.ratingJustification
      })
      this._cs.presentAlert("modificado con éxito")
      this.closeModal(true)

    });

    return await modal.present();


  }


  async editResultErro(errorDivison) {
    const id = this.afs.createId();
    let objectError = errorDivison[0]
    console.log("objectError", objectError)
    this.itemsCollection.doc(id).set({
      public: false,
      new_score: {},
      code: this.code,
      round: this.round,
      competitor: this._cs.removeCommas(this.details.name),
      date_modified: Date.now(),
      admin_name: `${this.profile.name} ${this.profile.surnames}`,
      admin_uid: this.profile.uid,
      jubge_name: objectError.name,
      jubge_uid: objectError.uid,
      type: objectError.type,
      previou_scores: {
        milderrors: objectError.milderrors,
        seriousErrors: objectError.seriousErrors
      }
    });

    const modal = await this.modalCtrl.create({
      component: EditErrorResultComponent,
      swipeToClose: false,
      backdropDismiss: false,
      componentProps: {
        details: objectError
      },
    });

    modal.onDidDismiss().then(async (dataReturned) => {
      if (!dataReturned.data.update) {
        this.itemsCollection.doc(id).delete()
        return
      }



      let newRatings = dataReturned.data.details

      let uidJubge = dataReturned.data.details.uid
      console.log("newRatings", newRatings)
      console.log("uidJubge", uidJubge)

      delete newRatings.name
      delete newRatings.type
      newRatings.milderrorsList = newRatings.milderrors.reduce((prev, cur) => {
        return prev + cur.value;
      }, 0);
      newRatings.seriousErrorsList = newRatings.seriousErrors.reduce((prev, cur) => {
        return prev + cur.value;
      }, 0);


      console.log(1, `/rate/${this.keydb}/${this.code}/${this.round}/${this.keyDivision}/erros/${uidJubge}`)
      console.log(2, newRatings)

      await this.db
        .object(`/rate/${this.keydb}/${this.code}/${this.round}/${this.keyDivision}/erros/${uidJubge}`)
        .update(newRatings)

      await this.itemsCollection.doc(id).update({
        previou_scores: {
          milderrors: newRatings.milderrors,
          seriousErrors: newRatings.seriousErrors
        },
        public: true,
        ratingJustification: newRatings.ratingJustification
      })
      this._cs.presentAlert("successfully modified")
      this.closeModal(true)

    });

    return await modal.present();




  }


  videoFeedback(url) {
    this.mediaFilesService.playVideo(url)
  }


  async changeMultimedia($event, item, isVideo, oldMultimedia) {

    try {

      await this._cs.presentLoading()
      console.warn("$event", $event)
      this.files = $event.target.files;
      const videoBase64 = await this.mediaFilesService.getBase64(this.files[0]);
      const UrlMultimedia = await this.mediaFilesService.uploadFile(videoBase64);

      console.log("videoUrl", UrlMultimedia)
      console.warn("item", item)

      console.log(`/competition/${this.keydb}/mainChoreography/${this.code}/${this.round}/${this.keyDivision}/audio/${item.uid}`)


      let _isVideo = isVideo ? 'urlVideo' : 'urlaudio';

      console.log("_isVideo", _isVideo)
      console.log("{ [_isVideo]: UrlMultimedia }", { [_isVideo]: UrlMultimedia })

      await this.db
        .list(`/competition/${this.keydb}/feedbackChange/${this.code}/${this.round}/${this.keyDivision}/audio/${item.uid}`)
        .push({
          type: "final-result-module",
          [_isVideo]: UrlMultimedia,
          oldMultimedia: oldMultimedia ? oldMultimedia : null,
          date: Date.now(),
          uidAdmin: this.customizationfileService.getUid()
        });

      await this.db
        .object(`/competition/${this.keydb}/mainChoreography/${this.code}/${this.round}/${this.keyDivision}/audio/${item.uid}`)
        .update({ [_isVideo]: UrlMultimedia });


      this._cs.presentAlert("updated video")
      this.closeModal(true)
    } catch (error) {
      this._cs.presentAlert(JSON.stringify(error))

    } finally {
      this._cs.dismissLoading()

    }

  }





  expandHeight(num) {
    if (num) {
      return num * 150;
    }
  }

  closeModal(update) {
    this.modalCtrl.dismiss({
      update
    });
  }
}
