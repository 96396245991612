import { Component, Input, OnInit } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { AngularFireDatabase } from "angularfire2/database";
import { CommonService } from "src/app/services/services";
import { ScrollEventService } from "src/app/services/v2/scroll-event.service";
import { SeeVideoComponent } from "../../../components/see-video/see-video.component";

@Component({
  selector: "app-practice-video-list",
  templateUrl: "./practice-video-list.component.html",
  styleUrls: ["./practice-video-list.component.scss"],
})
export class PracticeVideoListComponent implements OnInit {
  @Input() data: any;
  @Input() keyDivision: string;
  @Input() round: string;
  @Input() code: string;
  @Input() dataGroups: any;
  @Input() participarte: string;
  @Input() keydb: string;
  name: any;

  constructor(
    public alertController: AlertController,
    public scrollEventService: ScrollEventService,
    public db: AngularFireDatabase,
    public commonService: CommonService,
    public modalController: ModalController
  ) {}

  ngOnInit() {
    this.data.reverse();
    this.name = this.dataGroups
      ? this.dataGroups.nameGroups
      : this.participarte;
  }

  async skipStep() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "omitir el paso 1",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Ok",
          handler: async () => {
            await this.commonService.presentLoading();
            await this.db
              .object(
                `/competition/${this.keydb}/division/${this.keyDivision}/states`
              )
              .update({ step0: 3 });
            this.scrollEventService.updateAdminEvent.next(true);
            this.commonService.dismissLoading();
          },
        },
      ],
    });

    await alert.present();
  }

  async presentModal(data) {
    console.log("data", data);
    const modal = await this.modalController.create({
      component: SeeVideoComponent,
      cssClass: "my-custom-modal-css",
      componentProps: {
        value: {
          name: this.name,
          nameVideo: `${this.code}-${this.round}-${this.name}-practice${data.key}`,
          url: data.urlVideo,
          code: this.code,
          key: data.key,
        },
      },
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        if (dataReturned.data.status === 1) {
        }
      }
    });

    return await modal.present();
  }
}
