import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { ModalController } from "@ionic/angular";
import { AngularFireDatabase } from "angularfire2/database";
import { Observable, from, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { RecordComponent } from "src/app/components/record/record.component";
import {
  CommonService,
  CustomizationfileService,
  ManageUsersService,
} from "src/app/services/services";

@Component({
  selector: "app-record-event-welcome",
  templateUrl: "./record-event-welcome.component.html",
  styleUrls: ["./record-event-welcome.component.scss"],
})
export class RecordEventWelcomeComponent implements OnInit {

  fullpass: any;
  divisions: any;

  public keyDb: string;
  public uid: string;
  public divisions$!: Observable<any[]>;

  constructor(
    private manageUsersServ: ManageUsersService,
    public commonService: CommonService,
    private modalCtrl: ModalController,
    public customizationfileService: CustomizationfileService,
    public db: AngularFireDatabase
  ) {
    this.keyDb = this.customizationfileService.getKeyDb();
    this.uid = this.customizationfileService.getUid();
    console.log("this.keyDb", this.keyDb);
    console.log("this.uid", this.uid);
  }

  ngOnInit() {
    this.loadData();
    // this.getPassCompetitor(this.keyDb, this.uid);
    // this.getDivisions(this.keyDb, this.uid);
    // this.getDivisions2(this.keyDb, "50FgIU36AHbavXYvRaYXzCCeOY92");
  }

  loadData(){
    if (!this.keyDb && !this.uid) {
      this.divisions$ = of([]);
    }else {
      this.divisions$ = from(
        this.manageUsersServ.getDivision(this.keyDb, this.uid)
      ).pipe(
        /** Extraer Propiedad de la respuesta */
        map((res: any) => res.division),
        /** Parsear valores */
        map((divisions: any) => {
          return divisions.map((division: any) => {
            const { categories } = division;
            const {participarte} = categories[0];

            const someFakeMail = participarte.map((item) => `${item.email}`.trim().toLowerCase())
            /** find into string chart "yopmail" */
            .filter((item: string) => item.includes('yopmail'));
            division.hasFake = someFakeMail.length > 0;

            categories[0].participarte = participarte.map((item: any) => ({
                ...item, 
                hasFake: someFakeMail.includes(`${item.email}`.trim().toLowerCase())
              })
            );
            division.categories = categories;

            return division;
          });
        }),
        catchError((err) => {
          console.log('Error on RecordEventWelcomeComponent', err);
          return of([]);
        }),
        // tap((data) => console.log('data', data))
      );
    }
  }

  /**
   * 
   * @param keyDb 
   * @param uid 
   */
  getDivisions(keyDb, uid) {
    console.log("getDivisions", keyDb, uid);
    this.manageUsersServ.getDivision(keyDb, uid)
      .then((res: any) => {
        this.divisions = res.division;
        console.log("this.divisions 2", res.division);

      });
  }

  /**
   * 
   * @param keyDb 
   * @param uid 
   */
  // async getPassCompetitor(keyDb, uid) {
  //   this.db
  //     .object(`competition/${keyDb}/panticipants/${uid}`)
  //     .snapshotChanges()
  //     .subscribe((action) => {
  //       this.fullpass = action.payload.exists()
  //         ? action.payload.val()
  //         : action.payload.exists();
  //       console.log("this.fullpass ", this.fullpass);
  //     });
  // }

  /**
   * 
   * @param keyDb 
   * @param uid 
   */
  // async getDivisions2(keyDb, uid) {
  //   console.log(1, `competition/${keyDb}/division/`);
  //   console.log(2, uid);
  //   const itemsRef = this.db.list(`competition/${keyDb}/division/`, (ref) =>
  //     ref.orderByChild("uid").startAt(uid).endAt(uid)
  //   );

  //   // Obtenemos todos los cursos
  //   itemsRef
  //     .snapshotChanges()
  //     .pipe(
  //       map((changes) =>
  //         changes.map((c) => ({
  //           key: c.payload.key,
  //           ...(c.payload.val() as any),
  //         }))
  //       )
  //     )
  //     .subscribe((divisions) => {
  //       this.divisions = divisions;
  //       console.log("this.divisions 1", divisions);
  //     });
  // }


  async presentModal(data: any) {
    const modal = await this.modalCtrl.create({
      component: RecordComponent,
      // cssClass: "my-custom-modal-css",
      componentProps: {
        details: { ...data, type: 22, event_id: this.keyDb },
      },
      swipeToClose: true,
    })
    return await modal.present();
  }
}
