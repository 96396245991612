import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Onesignalv2Service } from "src/app/services/v2/onesignalv2.service";

@Component({
  selector: "app-view-notification-admin",
  templateUrl: "./view-notification-admin.component.html",
  styleUrls: ["./view-notification-admin.component.scss"],
})
export class ViewNotificationAdminComponent implements OnInit {
  @Input() notificationId: any;
  notification: any;
  constructor(
    private modalCtrl: ModalController,
    public onesignalv2Service: Onesignalv2Service
  ) {}

  ngOnInit() {
    console.log("notificationId", this.notificationId);
    this.getNotification();
  }

  async getNotification() {
    const res: any = await this.onesignalv2Service.onesignalV1({
      route: "viewNotification",
      data: { notificationId: this.notificationId },
    });
    this.notification = res.results;
    console.log("getNotification", res);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
