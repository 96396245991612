import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as tus from 'tus-js-client';
const url_root: any = environment.urlrootFunctions;
const prod = environment.production;
const vimeoEnv = environment.vimeo;

@Injectable({
  providedIn: 'root'
})
export class VimeoService {


  public megabiteSize = 1000000;
  public chunkSize = this.megabiteSize * 128;       // 128mb
  public retryDelays = [0, 1000, 3000, 5000];
  public parallelUploads = 1;
  public progress = new BehaviorSubject<number>(0);


  constructor(
    private http: HttpClient
  ) { }


  /**
   * Crear video
   * @param file        Archivo
   * @param file.name   Nombre del archivo
   * @param file.size   Tamaño del archivo
   * 
   * @returns 
   * - upload_link
   * - 
   */
  createVideo(file: File): Observable<any> {
    const body = {
      name: file.name,
      upload: {
        approach: 'tus',
        size: file.size
      }
    };

    /** Crear cabecera necesaria */
    const header: HttpHeaders = new HttpHeaders()
      .set('Authorization', `bearer ${vimeoEnv.access_token}`)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/vnd.vimeo.*+json;version=3.4');

    return this.http.post(vimeoEnv.api, body, {
      headers: header,
      observe: 'response'
    });
  }

  uploadVideoToVimeo(file: any): tus.Upload{
    const upload = new tus.Upload(
      file.video,
      {
        uploadUrl: file.upload_link,
        endpoint: file.upload_link,
        retryDelays: this.retryDelays,
        chunkSize: this.chunkSize,
        onError: (err) => { console.log('Failed: ' + file.video.name + err); },
        onProgress: (bytesUploaded, bytesTotal) => {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          console.log('file: ', bytesUploaded, bytesTotal, percentage + '%');
          this.progress.next( Number(percentage) );
        },
        onSuccess: () => {
          console.log('Download ' + file.video.name + ' from ' + upload.url);
          console.log({upload});
          console.log('Videos uploaded successfully');
        }
      }
    );

    return upload;
  }


  uploadVideo({ file, name, description }) {

    return new Promise(async (resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', name);
      formData.append('description', description);

      this.http.post<any>(`${url_root}/v2/vimeo/upload`, formData)
        .subscribe(
          (res) => resolve(res),
          (err) => reject(err)
        );
    })


  }

}
