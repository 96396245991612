import { Component, Input, OnInit } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentReference,
} from "@angular/fire/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { from, Observable, of } from "rxjs";
import { CustomizationfileService } from "src/app/services/services";
import * as firebase from "firebase";
import { map, catchError } from "rxjs/operators";

@Component({
  selector: "app-comments",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.scss"],
})
export class CommentsComponent implements OnInit {
  @Input() idVideo: any;
  private commentsCollection: AngularFirestoreCollection<any>;
  comments: Observable<any[]>;
  public commentForm: FormGroup;
  constructor(
    public readonly afs: AngularFirestore,
    public fb: FormBuilder,
    public customizationfileService: CustomizationfileService
  ) {
    this.commentForm = fb.group({
      comment: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(300),
        ]),
      ],
    });
  }

  ngOnInit() {
    console.log("this.idVideo", this.idVideo);
    this.commentsCollection = this.afs
      .collection<any>("chapters-comment")
      .doc(this.idVideo)
      .collection("list", (ref) => ref.orderBy("timestamp", "desc").where("public", "==", true).limit(20));
    this.comments = this.commentsCollection.valueChanges();
  }

  addComment() {
    const value = this.commentForm.value;
    console.log("value", value);

    const { uid, name, surnames } = this.customizationfileService.getprofile();
    const avatar = this.customizationfileService.getavatar();
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();

    this.commentsCollection.add({
      timestamp: timestamp,
      comment: value.comment,
      avatar,
      uid,
      public: false,
      name: `${name} ${surnames}`,
    });
    this.commentForm.reset();
  }

  trackByFn(index, item) {
    return index; // or item.id
  }
}
