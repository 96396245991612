import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CommonService, CustomizationfileService, LanguageService } from 'src/app/services/services';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit {
  public surveyForm: FormGroup;
  public surveyError: string;

  survey = [
    {
      id: 0,
      question: "¿How did you know about this event?",
      type: "select",
      required: true,
      list: [
        { id: 0, option: "instagram" },
        { id: 1, option: "facebook" },
        { id: 2, option: "whatsapp" },
        { id: 3, option: "email" },
        { id: 4, option: "Friends, acquaintances" },
        { id: 5, option: "Other" },
      ]
    }, {
      id: 1,
      question: "¿Estás participando en el WLDC por primera vez?",
      type: "select",
      required: true,
      list: [
        { id: 0, option: "si" },
        { id: 1, option: "no" },
      ]
    }, {
      id: 2,
      question: "How was your experience in this online version of WLDC 2021?",
      type: "select",
      required: true,
      list: [
        { id: 0, option: "excellent" },
        { id: 1, option: "good" },
        { id: 2, option: "Regular" },
        { id: 3, option: "bad" },
        { id: 4, option: "very bad" }
      ]
    }, {
      id: 3,
      required: true,
      question: "Once the pandemic is over, do you agree that the WLDC should continue to be held virtually?",
      type: "select",
      list: [
        { id: 0, option: "Agree" },
        { id: 1, option: "Neither agree nor disagree" },
        { id: 2, option: "disagree" },
      ]
    }, {
      id: 4,
      question: "Level of ease of registering for the event via Internet or application",
      description: "Please rate the following aspects of the event using a scale from 1 to 6, where 1 is 'Poor' and 6 is 'Excellent'.",
      type: "range",
      required: true,
      min: 0,
      max: 6
    }, {
      id: 5,
      question: "How did you find the general functioning of the Settle App?",
      type: "range",
      required: true,
      min: 0,
      max: 6
    }, {
      id: 6,
      question: "How did you like the attention received from customer service through WhatsApp?",
      description: "Please rate the following aspects of the event using a scale from 1 to 6, where 1 is 'Poor' and 6 is 'Excellent'.",
      type: "range",
      required: true,
      min: 0,
      max: 6
    }, {
      id: 7,
      question: "How did you find the information received through the different information channels of the competition (social networks, website, notifications, live meetings, chat)?",
      description: "Please rate the following aspects of the event using a scale from 1 to 6, where 1 is 'Poor' and 6 is 'Excellent'.",
      required: true,
      type: "range",
      min: 0,
      max: 6
    }, {
      id: 8,
      question: "¿What is your perception of judges?",
      description: "Please rate the following aspects of the event using a scale from 1 to 6, where 1 is 'Poor' and 6 is 'Excellent'.",
      type: "range",
      required: true,
      min: 0,
      max: 6
    }, {
      id: 9,
      question: "General event",
      description: "Please rate the following aspects of the event using a scale from 1 to 6, where 1 is 'Poor' and 6 is 'Excellent'.",
      type: "range",
      required: true,
      min: 0,
      max: 6
    }, {
      id: 10,
      question: "¿Would you recommend this event to others?",
      type: "select",
      required: true,
      list: [
        { id: 0, option: "si" },
        { id: 1, option: "no" },
      ]
    }, {
      id: 11,
      question: "What did you like most about this virtual version of the WLDC?",
      type: "input",
      required: false,
    }, {
      id: 12,
      question: "¿In what areas do you think we should improve?",
      type: "input",
      required: false,
    }

  ]

  submitted: boolean;

  constructor(
    public languageService: LanguageService,
    public commonService: CommonService,
    public customizationfileService: CustomizationfileService,
    private modalCtrl: ModalController,
    private afs: AngularFirestore) {

  }

  ngOnInit() {
    let group = {}
    this.survey.map(res => {
      return Object.assign(res, { key: this.getformControlName(res.question) })
    }).forEach(elem => {
      //  group[this.getformControlName(elem.question)] = new FormControl('');
      group[elem.key] = elem.required ?
        new FormControl('', Validators.required)
        : new FormControl('');
    });
    this.surveyForm = new FormGroup(group);
  }

  get f() {
    return this.surveyForm.controls;
  }

  async onSubmit() {
    this.submitted = true;
    console.log(1, this.surveyForm.value);
    console.log(2, this.surveyForm);
    console.log(2, this.surveyForm.invalid);

    // stop here if form is invalid
    if (this.surveyForm.invalid) {
      return;
    }

    const uid = this.customizationfileService.getUid()
    let data = Object.assign(this.surveyForm.value, { uid })
    await this.afs.collection('survey')
      .doc(this.customizationfileService.getKeyDb())
      .collection('list')
      .doc(uid)
      .set(data)
    this.closeModal()
    this.commonService.presentAlert(this.languageService.getTranslation("Thank you for your feedback"),
      this.languageService.getTranslation("We are glad that you share with us your ideas"))

  }




  getformControlName(name) {
    if (!name) { return }
    return name.replace(/[^a-zA-Z ]/g, "").replace(/\s/g, '-').toLowerCase()
  }


  closeModal() {
    this.modalCtrl.dismiss();
  }
}
