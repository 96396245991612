import { Component, Input, OnInit } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { AngularFireDatabase } from "angularfire2/database";
import {
  CommonService,
  CustomizationfileService,
} from "src/app/services/services";
import { MediaFilesService } from "src/app/services/v2/media-files.service";
import { ScrollEventService } from "src/app/services/v2/scroll-event.service";
import { SeeVideoComponent } from "../../../components/see-video/see-video.component";

@Component({
  selector: "app-test-videos-admin",
  templateUrl: "./test-videos-admin.component.html",
  styleUrls: ["./test-videos-admin.component.scss"],
})
export class TestVideosAdminComponent implements OnInit {
  @Input() data: any;
  @Input() keyDivision: string;
  @Input() round: string;
  @Input() code: string;
  @Input() dataGroups: any;
  @Input() participarte: string;
  @Input() keydb: string;
  name: any;
  files: any;

  constructor(
    public customizationfileService: CustomizationfileService,
    public mediaFilesService: MediaFilesService,
    public scrollEventService: ScrollEventService,
    public alertController: AlertController,
    public db: AngularFireDatabase,
    public commonService: CommonService,
    public modalController: ModalController
  ) {}

  ngOnInit() {
    this.name = this.dataGroups
      ? this.dataGroups.nameGroups
      : this.participarte;
  }

  async skipStep() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "omitir el paso 2",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Ok",
          handler: async () => {
            await this.commonService.presentLoading();
            await this.db
              .object(
                `/competition/${this.keydb}/division/${this.keyDivision}/states`
              )
              .update({ step1: 3 });

            this.scrollEventService.updateAdminEvent.next(true);
            this.commonService.dismissLoading();
          },
        },
      ],
    });

    await alert.present();
  }

  async uploadFile($event) {
    try {
      await this.commonService.presentLoading();
      console.log("$event", $event);
      this.files = $event.target.files;
      const videoBase64 = await this.mediaFilesService.getBase64(this.files[0]);
      const videoUrl = await this.mediaFilesService.uploadFile(videoBase64);

      await this.db
        .object(
          `/competition/${this.keydb}/testVideos/${this.code}/${this.round}/${this.keyDivision}`
        )
        .update({
          video: videoUrl,
          comment: "video uploaded by admin",
          step: 3,
        });

      await this.db
        .object(
          `/competition/${this.keydb}/division/${this.keyDivision}/states`
        )
        .update({ step1: 3 });
      this.scrollEventService.updateAdminEvent.next(true);
      this.commonService.dismissLoading();
    } catch (err) {
      this.commonService.dismissLoading();
      this.commonService.presentAlert(JSON.stringify(err));
    }
  }

  async presentModal(data) {
    console.log("data", data);
    const modal = await this.modalController.create({
      component: SeeVideoComponent,
      cssClass: "my-custom-modal-css",
      componentProps: {
        value: {
          name: this.name,
          nameVideo: `${this.code}-${this.round}-${this.name}-test-videos-${this.keyDivision}`,
          url: data.video,
          code: this.code,
          key: this.keyDivision,
        },
      },
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        if (dataReturned.data.status === 1) {
          // setTimeout(() => {
          //   this.getListVideo();
          // }, 1000);
        }
      }
    });

    return await modal.present();
  }

  changeStatus(status) {
    switch (status) {
      case 1:
        this.presentAlertPrompt({ status, title: "pending" });
        break;
      case 2:
        this.presentAlertPrompt({ status, title: "reject" });
        break;
      case 3:
        this.presentAlertPrompt({ status, title: "accepted" });
        // this.status({ status, comment: "accepted" });
        break;
    }
  }

  async presentAlertPrompt({ status, title }) {
    const alert = await this.alertController.create({
      header: title,
      inputs: [
        {
          name: "value",
          type: "textarea",
          placeholder: "ingresar comentario",
        },
      ],
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "Ok",
          handler: (data) => {
            if (status === 3) {
              const c = data.value.length > 0 ? data.value : "accepted";
              this.status({ status, comment: c });
            } else if (data.value.length > 0) {
              this.status({ status, comment: data.value });
            } else {
              this.commonService.presentAlert("debes agregar un mensaje");
            }
          },
        },
      ],
    });

    await alert.present();
  }

  async status({ status, comment }) {
    console.log({ status, comment });
    await this.commonService.presentLoading();

    const url = `/competition/${this.keydb}/testVideos/${this.code}/${this.round}/${this.keyDivision}`;
    await this.db.object(url).update({
      comment: comment,
      admin: this.customizationfileService.getprofile(),
      step: status,
    });

    await this.db
      .object(`/competition/${this.keydb}/division/${this.keyDivision}/states`)
      .update({ step1: status });

    this.scrollEventService.updateAdminEvent.next(true);
    this.commonService.dismissLoading();
  }

  showComment(text) {
    this.commonService.presentAlert(text);
  }
}
