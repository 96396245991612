import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CartService, CommonService, CustomizationfileService, DataService } from 'src/app/services/services';


@Component({
  selector: 'app-send-trophy',
  templateUrl: './send-trophy.component.html',
  styleUrls: ['./send-trophy.component.scss'],
})
export class SendTrophyComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  @Input() details: any;
  country: { id: string; sortname: string; name: string; phonecode: string; }[];
  price: void;
  shippingPrice: any;
  awards: any
  awardObj
  medals: unknown;
  trophy: unknown;
  totalPay: any
  shippingPriceFixed: any;
  cartState: any;
  _trophyCopy: any;
  get f() {
    return this.registerForm.controls;
  }
  countrySelect


  constructor(
    private router: Router,
    public dataService: DataService,
    private readonly afs: AngularFirestore,
    public commonService: CommonService,
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    public cartService: CartService,
    public customizationfileService: CustomizationfileService) {
    this.getCountry()
    this.registerForm = this.formBuilder.group(
      {
        address: ["", Validators.required],
        address2: ["", Validators.required],
        city: ["", Validators.required],
        state: ["", Validators.required],
        postalCode: ["", Validators.required],
        add_trophy_copy: [false, Validators.required],
      }
    );

    this.cartService.cartState.subscribe((data) => {
      this.cartState = data;
    });
  }

  async ngOnInit() {
    this.details.item.component = null
    console.log("details", this.details.item)
    console.log("details", this.details)
    console.log("code", this.details.code)

    this.awardObj = this.commonService
      .typeDivision(this.details.code, this.details.myRating.participarte.length)

    console.log("this.awardObj", this.awardObj)

    this.medals = await this.getpriceOfPrizes('medals')
    this.trophy = await this.getpriceOfPrizes('trophy')

    console.log("medals", this.trophy)
    console.log("trophy", this.trophy)

    this.countrySelect = this.country
      .find((word: any) => word.name.toLowerCase() === this.details.myRating.representedCountry);

    this.shippingPrice = await this.getShippingPriceList(this.countrySelect.sortname)
    if (!this.shippingPrice) { return }

    this.shippingPriceFixed = this.shippingPrice
    this.totalPay = this.shippingPrice.shipping_value
    console.log("this.shippingPrice", this.shippingPrice)
    console.log("this.totalPay", this.totalPay)
  }

  async countryChange($event) {
    console.log($event.value)
    this.shippingPrice = await this.getShippingPriceList($event.value.sortname)
    this.shippingPriceFixed = this.shippingPrice
    this.totalPay = this.shippingPrice.shipping_value
    console.log("this.shippingPrice", this.shippingPrice)
    console.log("this.totalPay", this.totalPay)
  }


  trophyCopy(event) {
    this._trophyCopy = event.detail.checked
    if (event.detail.checked) {
      this.totalPay = this.totalPay + this.trophy
    } else {
      this.totalPay = this.shippingPriceFixed.shipping_value
    }

  }




  async getShippingPriceList(code) {
    await this.commonService.presentLoading()
    return new Promise((resolve, reject) => {
      this.afs.collection('shipping-price-list', ref => ref.where('sortname', '==', code))
        .valueChanges()
        .subscribe(res => {
          if (res.length === 0) {
            this.commonService.dismissLoading()
            this.commonService.presentAlertTranslation("envíos no habilitados")
            resolve(false)
          }
          this.commonService.dismissLoading()
          resolve(res[0])
        })
    })
  }



  async getpriceOfPrizes(value) {
    return new Promise((resolve) => {
      this.afs.collection('priceOfPrizes').doc(value)
        .valueChanges()
        .subscribe((res: any) => {
          if (!res) {
            resolve(false)
          }
          resolve(res.price)
        })
    })
  }


  getCountry() {
    this.country = this.dataService.country.sort();
  }

  getCountryText(port: any, portIndex: number, ports: any[]) {
    if (portIndex === 0 || port.id !== ports[portIndex - 1].id) {
      return port.name;
    }
    return null;
  }


  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    console.log(this.registerForm.invalid);
    if (this.registerForm.invalid || !this.shippingPrice) {
      this.commonService.presentAlertTranslation("formulario incompleto")
      return;
    }

    await this.commonService.presentLoading();
    const data = this.registerForm.value;
    this.details.item.component = null

    console.log("data", data)
    console.log("this.totalPay", this.totalPay)
    console.log("this.awardObj", this.awardObj)
    console.log("this.details", this.details)

    let uid = this.customizationfileService.getUid()
    const id = this.afs.createId();

    let res = Object.assign(data,
      {
        keyRequest: this.details.key_division,
        keydb: this.customizationfileService.getKeyDb(),
        price: this.totalPay,
        type: 4,
        status: 3,
        uid: uid,
        shippingPrice: this.shippingPrice,
        countrySelect: this.countrySelect,
        award: this.awardObj,
        details: this.details
      })

    console.warn('res', res)
    console.warn('uid', uid)
    let cart = await this.afs
      .collection("cart")
      .doc(uid)
      .collection("product")
      .doc(id)
      .set(res)
      .catch(err => {
        console.log("err", err)
        this.commonService.presentAlertTranslation("ocurrió un error")
      })

    console.log('cart', cart)

    this.submitted = false
    this.commonService.dismissLoading()
    this.goToCart()
  }


  goToCart() {
    this.closeModal(1)
    this.router.navigate(['/members/cart']);
  }



  closeModal(update) {
    this.modalCtrl.dismiss({ update });
  }


}
