import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ViewFileComponent } from 'src/app/components/view-file/view-file.component';
import { CommonService, StorageService, LanguageService } from 'src/app/services/services';
import { Html2canvasService } from 'src/app/services/v2/html2canvas.service';
import { SocialSharedService } from 'src/app/services/v2/social-shared.service';

@Component({
  selector: 'app-certificates-of-participation',
  templateUrl: './certificates-of-participation.component.html',
  styleUrls: ['./certificates-of-participation.component.scss'],
})
export class CertificatesOfParticipationComponent implements OnInit {

  @Input() myRating: any;
  @Input() avatar: any;
  @Input() code: any;
  @Input() round: any;
  @Input() profile: any;
  @Input() yourImageDataURLBase64: any;


  nameDivision: any
  eventName: any
  name: string;

  list2 = [
    {
      name: "Participation certificate",
      id: 0,
      file: "pdf",
      type: "participation-certificate",
      typeDoc: 1
    }, {
      name: "Diploma of Participation",
      id: 1,
      file: "img",
      type: "participation-certificate-letter",
      typeDiploma: "simple",
      typeDoc: "letter"
    }, {
      name: "share post",
      id: 2,
      file: "img",
      type: "participation-certificate-post",
      typeDiploma: "simple",
      typeDoc: "post"
    }, {
      name: "share storie",
      id: 3,
      file: "img",
      type: "participation-certificate-storie",
      typeDiploma: "simple",
      typeDoc: "storie"
    },
  ]

  constructor(
    public alertController: AlertController,
    public commonService: CommonService,
    public storageService: StorageService,
    public modalController: ModalController,
    public socialSharedService: SocialSharedService,
    public languageService: LanguageService,
  ) { }

  ngOnInit() {
    // console.log("myRating", this.myRating)
    this.getTranslation(this.code)
    this.eventName = this.storageService.localStorageGetItem("eventName")
    this.name = `${this.profile.name} ${this.profile.surnames}`
  }

  getTranslation(code: any) {
    this.nameDivision = this.languageService.getTranslation(code)
    // console.log("getTranslation", this.nameDivision)
  }


  async viewFile(item: any) {
    const details = Object.assign({}, {
      file: item.file,
      type: item.type,
      typeDiploma: "ranking",                          // Tipo de diploma    
      typeDoc: item.typeDoc,                          // Tipo de documento
      month: this.commonService.getDateOnlyMonthName(Date.now()),                                      // Mes de emisión del certificado
      year: this.commonService.getDateOnlyYearName(Date.now()),                                       // Año de emisión del certificado
      participantName: this.name,                     // Nombre del participante
      codeDivision: this.code,                        // Código de la división
      nameDivision: this.nameDivision,                // Nombre de la división
      participantSchool: this.myRating.schoolThatRepresents,                        // Escuela del participante
      participantGroup: null,                         // Grupo del participante
      participantCountry: this.myRating.representedCountry,                       // País del participante
      userImage: null,                                // Imagen del usuario a utilizar
      round: this.round,
      eventName: this.eventName,
      nroRanking: this.myRating.position
    });

    const modal = await this.modalController.create({
      component: ViewFileComponent,
      cssClass: 'my-custom-class',
      componentProps: { details }
    });
    return await modal.present();
  }


}
