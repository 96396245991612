import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { PlayVideoComponent } from 'src/app/components/play-video/play-video.component';
import { CommonService } from 'src/app/services/services';
import { MainVideoComponent } from '../main-video/main-video.component';

@Component({
  selector: 'app-multimedia-list-of-judges',
  templateUrl: './multimedia-list-of-judges.component.html',
  styleUrls: ['./multimedia-list-of-judges.component.scss'],
})
export class MultimediaListOfJudgesComponent implements OnInit {
  round: any;
  keydb: any;
  day: any;
  data: any;
  details: any;

  constructor(public db: AngularFireDatabase,
    public modalController: ModalController,
    private navParams: NavParams,
    public commonService: CommonService) { }

  ngOnInit() {
    this.details = this.navParams.get("details");
    console.log("this.details", this.details);
  }

  async onlineVideo(video) {
    const modal = await this.modalController.create({
      component: PlayVideoComponent,
      cssClass: "my-custom-modal-css",
      componentProps: {
        video,
        showHeader: true
      },
    });
    return await modal.present();
  }

  dismiss(status, message?) {
    this.modalController.dismiss({
      dismissed: true,
      status,
      message,
    });
  }

}
