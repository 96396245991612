import { Component, Input, OnInit } from "@angular/core";
import { AngularFireDatabase } from "angularfire2/database";
import { map } from "rxjs/operators";
import { CommonService, StorageService } from "src/app/services/services";

@Component({
  selector: "app-calendar-zoom",
  templateUrl: "./calendar-zoom.component.html",
  styleUrls: ["./calendar-zoom.component.scss"],
})
export class CalendarZoomComponent implements OnInit {
  @Input() round: string;
  @Input() code: string;
  @Input() key_division: string;
  @Input() keydb: string;
  @Input() day: string;
  @Input() showCalendar: any;
  segment = "my_division";
  updateProgress = false;

  calendarRoom: any;
  division: any = [];
  calendarRoomCopy: any;

  constructor(
    public storageService: StorageService,
    public db: AngularFireDatabase,
    public commonService: CommonService
  ) { }

  async ngOnInit() {
    await this.getData()
  }

  async getData() {
    try {
      console.log(`/livingRoom/${this.keydb}/${this.day}/${this.round}/calendarRoom`)
      this.db
        .list(`/livingRoom/${this.keydb}/${this.day}/${this.round}/calendarRoom`)
        .valueChanges()
        .subscribe((res: any) => {
          if (!res) { return; }
          this.updateProgress = true;
          setTimeout(() => {
            this.updateCalendar(res);
          }, 3000);
        });
    } catch (err) {
      this.updateProgress = false;
      this.calendarRoom = [];
      console.log("err", err);
    }
  }

  updateCalendar(res) {
    try {
      this.calendarRoom = res;
      this.calendarRoomCopy = res;

      // console.log("res", res);
      // console.log("this.code", this.code);

      let result = res.filter((res) => {
        // console.log("res.code ", res.code);
        return res.code == this.code;
      });

      //  console.log("result", result);
      if (result.length === 0) {
        this.division = [];
        return;
      }
      this.division.division_list = result[0].division_list.map((res) => {
        // console.log("res", res);
        return Object.assign(res, {
          mydivision: res.keyDivision === this.key_division ? true : false,
        });
      });

      this.updateProgress = false;
    } catch (err) {
      this.updateProgress = false;
      this.calendarRoom = [];
      this.division.division_list = [];
      console.log(err);
    }
  }

  search(evt) {
    console.log(" this.calendarRoom", this.calendarRoom);
    const searchTerm = evt.toLowerCase();
    if (searchTerm.length >= 3) {
      this.calendarRoom = this.calendarRoomCopy
        .filter((res) => !res.break)
        .map((res) => {
          console.log("res", res);
          res.division_list = res.division_list.filter((x) => {
            return (
              x.user.toLowerCase().indexOf(searchTerm) > -1 ||
              x.school.toLowerCase().indexOf(searchTerm) > -1
            );
          });

          return res;
        })
        .filter((res) => res.division_list.length > 0);
    } else {
      this.calendarRoom = this.calendarRoomCopy;
    }
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  show1(index2, array) {
    for (let index = 0; index < this[array].length; index++) {
      if (index === index2) {
        this[array][index].show = !this[array][index].show;
      } else {
        this[array][index].show = false;
      }
    }
  }
}
