import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFirestore } from "@angular/fire/firestore";
import { BehaviorSubject } from "rxjs";
import { CustomizationfileService } from "../customizationfile/customizationfile.service";
@Injectable({
  providedIn: "root",
})
export class ViewStatusService {
  public _playOff = new BehaviorSubject(false);
  public _finalResults = new BehaviorSubject(false);
  public _calendar = new BehaviorSubject(false);
  public _liveVideo = new BehaviorSubject(false);
  public _semifinal = new BehaviorSubject(false);
  public accreditations = new BehaviorSubject(false);
  public streaming_media = new BehaviorSubject(false);
  public local_notification = new BehaviorSubject(false);
  public location = new BehaviorSubject(false);
  public button_from_the_app = new BehaviorSubject(false);
  public check_in = new BehaviorSubject(false);
  public test = new BehaviorSubject(false);
  public goToEvent = new BehaviorSubject(false);
  public ticketpass = new BehaviorSubject(false);
  public complaints = new BehaviorSubject(false);
  public zoom = new BehaviorSubject(false);
  public settleTV = new BehaviorSubject(false);
  public chatSupport = new BehaviorSubject(false);
  public survey = new BehaviorSubject(false);

  public eventMethodPipe = new BehaviorSubject([]);

  public calendarName = new BehaviorSubject([]);





  constructor(
    private afs: AngularFirestore,
    public db: AngularFireDatabase,
    public _cf: CustomizationfileService
  ) {
    this.getStatusRatings();
  }


  /**
   * 
   * @param _key 
   * @returns 
   */
  getCalendarTitle(_key?: any) {
    const key = _key ? _key : this._cf.getKeyDb();
    if (!key) { return }
    this.db.object(`/eventMethod/${key}/section_list/calendar_title`)
      .snapshotChanges()
      .subscribe((action) => {
        const data: any = action.payload.val();
        console.log(1, action.payload.exists())
        if (!action.payload.exists()) { return }
        const filteData = data.filter((x: any) => x.enabled == true)
          .map((x: any, index: any) => { return { ...x, day: `day${index + 1}` } });
        this.calendarName.next(filteData);
      });
  }

  /**
   * 
   * @param _key 
   * @returns 
   */
  eventMethod(_key?: any) {
    const key = _key ? _key : this._cf.getKeyDb();
    if (!key) { return }
    this.db.object(`/eventMethod/${key}/section_list/division-details`)
      .snapshotChanges()
      .subscribe((action) => {
        const data: any = action.payload.val();
        console.log(1, action.payload.exists())
        if (!action.payload.exists()) { return }
        // console.log(2, "eventMethod", data)
        this.eventMethodPipe.next(data);
      });
  }


  /**
   * 
   * @param keyDb 
   * @returns 
   */
  getStatusRatings(_key?: any) {
    const key = _key ? _key : this._cf.getKeyDb();
    if (!key) { return }
    this.eventMethod();
    this.getCalendarTitle();
    this.db.object(`_competitionListCreated/${key}/statusRatings`)
      .snapshotChanges().subscribe((action) => {
        const data: any = action.payload.val();
        if (!action.payload.exists()) { return }
        data.forEach((x: any) => {
          if (x.id == "qualifiers") {
            // this.notification("_playOff", x.enabled);
            this._playOff.next(x.enabled);
          }
          if (x.id == "final_results") {
            this._finalResults.next(x.enabled);
            // this.notification("_finalResults", x.enabled);
          }
          if (x.id == "calendar") {
            this._calendar.next(x.enabled);
            // this.notification("_calendar", x.enabled);
          }
          if (x.id == "youtube") {
            this._liveVideo.next(x.enabled);
            // this.notification("_liveVideo", x.enabled);
          }
          if (x.id == "semifinal") {
            this._semifinal.next(x.enabled);
            // this.notification("_semifinal", x.enabled);
          }
          if (x.id == "streaming_media") {
            this.streaming_media.next(x);
            // this.notification("streaming_media", x);
          }
          if (x.id == "accreditations") {
            this.accreditations.next(x.enabled);
            // this.notification("accreditations", x.enabled);
          }
          if (x.id == "l// ocal_notification") {
            this.local_notification.next(x.enabled);
            // this.notification("l// ocal_notification", x.enabled);
          }
          if (x.id == "location") {
            this.location.next(x.enabled);
            // this.notification("location", x.enabled);
          }
          if (x.id == "app_acreditacion") {
            this.button_from_the_app.next(x.enabled);
          }
          if (x.id == "check_in") {
            this.check_in.next(x.enabled);
          }

          if (x.id == "test") {
            this.test.next(x.enabled);
          }

          if (x.id == "gotoevent") {
            this.goToEvent.next(x.enabled);
          }

          if (x.id == "ticketpass") {
            this.ticketpass.next(x.enabled);
          }

          if (x.id == "complaints") {
            this.complaints.next(x.enabled);
          }

          if (x.id == "zoom") {
            this.zoom.next(x.enabled);
          }

          if (x.id == "settleTv") {
            this.settleTV.next(x.enabled);
          }

          if (x.id == "chatSupport") {
            this.chatSupport.next(x.enabled);
          }
          if (x.id == "survey") {
            this.survey.next(x.enabled);
          }
        });

      });
  }


  /**
   * 
   * @param param0 
   * @returns 
   */
  getStatusView({ keyDb, type }) {
    let key = keyDb ? keyDb : this._cf.getPROVISIONAKEY()
    return new Promise((resolve, reject) => {
      if (!key) { reject(false) }
      this.db.object(`_competitionListCreated/${key}/statusRatings`)
        .valueChanges()
        .subscribe((res: any) => {
          if (!res) { reject(false) }
          console.log(res)
          // const result = res.filter(word => word.id === type);
          // resolve(result[0].enabled)
        })
    })
  }


  /**
   * 
   * @param param0 
   * @returns 
   */
  getEventInfo({ keyDb }) {
    return new Promise((resolve, reject) => {
      this.afs
        .collection("events")
        .doc(keyDb)
        .valueChanges()
        .subscribe((data: any) => {
          if (!data) {
            reject({})
            return
          }
          resolve(data)
        });
    })
  }

  unsubscribeAll() {

  }

  notification(data, enabled) {
    if (enabled) {
      // console.log(data, enabled)
    }
  }

}
