import { Component, OnInit } from "@angular/core";
import { AlertController, ModalController, NavParams } from "@ionic/angular";
import {
  CommonService,
  CustomizationfileService,
} from "src/app/services/services";
import { MediaFilesService } from "src/app/services/v2/media-files.service";
import { RecordRtcService2 } from "src/app/services/v2/record-rtc.service";

@Component({
  selector: "app-record-audio",
  templateUrl: "./record-audio.component.html",
  styleUrls: ["./record-audio.component.scss"],
})
export class RecordAudioComponent implements OnInit {
  divisionData: any;
  urlBase: unknown;
  base64: unknown;
  constructor(
    public mediaFilesService: MediaFilesService,
    public _recordRTC: RecordRtcService2,
    public customizationfileService: CustomizationfileService,
    public commonService: CommonService,
    public alertController: AlertController,
    public modalController: ModalController,
    private navParams: NavParams
  ) {
    this.divisionData = this.navParams.get("divisionData");
    console.log(this.divisionData);
  }

  ngOnInit() { }

  async startVoiceRecord() {
    const url = await this._recordRTC.toggleRecord({ audio: true }, {
      type: "audio",
      mimeType: "audio/webm",
    });
    console.log("url", url);
    if (url) {
      this.base64 = await this.mediaFilesService.getBase64(url);
    }
  }

  async saveAudio() {
    this.commonService.presentLoading();
    this.urlBase = await this.mediaFilesService.uploadFile(this.base64);
    this.commonService.dismissLoading();
    this.dismiss(1, this.urlBase);
  }

  dismiss(status, message?) {
    this.modalController.dismiss({
      dismissed: true,
      status,
      message,
    });
  }
}
