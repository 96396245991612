import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import {
  ActionSheetController,
  IonContent,
  ModalController,
} from "@ionic/angular";
import { AngularFireDatabase } from "angularfire2/database";
import {
  CommonService,
  CustomizationfileService,
  LanguageService,
  ManageUsersService,
} from "src/app/services/services";
import { MediaFilesService } from "src/app/services/v2/media-files.service";
import { Globalization } from "@ionic-native/globalization/ngx";
import { ScrollEventService } from "src/app/services/v2/scroll-event.service";
import { SeeVideoComponent } from "src/app/admin/components/see-video/see-video.component";
import { Camera } from "@ionic-native/camera/ngx";


@Component({
  selector: "app-custom-videos",
  templateUrl: "./custom-videos.component.html",
  styleUrls: ["./custom-videos.component.scss"],
})
export class CustomVideosComponent implements OnInit {
  @Input() states: any;
  @Input() keyDb: string;
  @Input() keyDivision: string;
  @Input() dataDivision: any;
  @Input() round: any;
  multimediaContent = [];
  url_root: string;
  dataVideo: any;
  type = ""
  @ViewChild(IonContent, { read: IonContent }) myContent: IonContent;
  @ViewChild("theTarget") targetx: any;


  @ViewChild('fileUploadInputVideo') fileUploadInputVideo: ElementRef<HTMLInputElement>;
  @ViewChild('fileUploadInputImgs') fileUploadInputImgs: ElementRef<HTMLInputElement>;

  constructor(
    private camera: Camera,
    public scrollEventService: ScrollEventService,
    public languageService: LanguageService,
    private globalization: Globalization,
    public mediaFilesService: MediaFilesService,
    public db: AngularFireDatabase,
    public modalController: ModalController,
    public commonService: CommonService,
    public customizationfileService: CustomizationfileService,
    public manageUsersService: ManageUsersService,
    private actionSheetController: ActionSheetController
  ) { }

  async ngOnInit() {
    let language: any = "en";
    if (this.commonService.isCordova()) {
      language = await this.globalization.getPreferredLanguage();
    } else {
      language = this.languageService.navigatorLanguage();
    }

    this.url_root = `competition/${this.keyDb}/multimediaContent/${this.dataDivision.category_detail.id}/${this.round}/${this.keyDivision}`;

    this.db
      .object(this.url_root)
      .valueChanges()
      .subscribe(async (res: any) => {
        if (res !== null) {
          this.multimediaContent = res;
          this.validateStep2(res);
        } else {
          console.log(" language.value", language.value);
          const res = language.value
            ? language.value.substring(0, 2)
            : language.substring(0, 2);
          console.log("createStates language", res);
          await this.commonService.presentLoading();
          await this.createStates({ language: res });
          this.commonService.dismissLoading();
        }
      });
  }

  async validateStep2(multimediaContent) {
    // console.log("multimediaContent", multimediaContent);

    const result = multimediaContent.map((res) => {
      res.step = res.state.step;
      return res;
    });

    const found = result.find((elem) => elem.step === 0 || elem.step === 1 || elem.step === 2);
    const validVideo = result.filter(word => word.step === 3);


    console.log("dataDivision", this.dataDivision)
    console.log("result", result);
    console.log("found", found);
    console.warn("validVideo", validVideo.length)
    console.log("states", this.states);

    // no hay video pendiente por revisar entonces lo pasamos directamente al paso 4
    // if (found === undefined && this.states.step2 !== 3) {
    if (validVideo.length >= 5 && this.states.step2 !== 3) {

      await this.db
        .object(`competition/${this.keyDb}/division/${this.keyDivision}/states/`)
        .update({
          uid_user: this.customizationfileService.getUid(),
          last_update: Date.now(),
          step2: 3,
        });
      this.commonService.dismissLoading();
    }
  }

  showMultimedia(index2) {
    for (let index = 0; index < this.multimediaContent.length; index++) {
      if (index === index2) {
        this.multimediaContent[index].show = !this.multimediaContent[index]
          .show;

        console.log("index");
        this.scrollEventService.scrollEvent.next(index);
      } else {
        this.multimediaContent[index].showComment = false;
        this.multimediaContent[index].show = false;
      }
    }
  }

  showCommentshow(index) {
    this.multimediaContent[index].showComment = !this.multimediaContent[index]
      .showComment;
    this.scrollEventService.scrollEvent.next(2);
  }

  async createStates({ language }) {
    console.log({
      language,
      keydb: this.keyDb,
      round: this.round,
      code: this.dataDivision.category_detail.id,
      keyDivision: this.keyDivision,
    });
    await this.manageUsersService
      .getCustomVideos({
        language,
        keydb: this.keyDb,
        round: this.round,
        code: this.dataDivision.category_detail.id,
        keyDivision: this.keyDivision,
      })
      .then((data: any) => {
        console.log("getCustomVideos", data);
      }, (err) => {
        this.commonService.presentAlert(JSON.stringify(err));
      });
  }

  async selectMediaVideo(data, index) {
    this.dataVideo = Object.assign({ index: index }, data);
    const actionSheet = await this.actionSheetController.create({
      header: this.languageService.getTranslation(
        "Este video es de 60 segundos Max"
      ),
      buttons: [
        {
          text: this.languageService.getTranslation("record video"),
          handler: () => {
            this.recordVideo();
          },
        },
        {
          text: this.languageService.getTranslation("load video"),
          handler: async () => {
            this.commonService.isCordova() ? this.selectVideo() : this.fileUploadInputVideo.nativeElement.click()
          },
        },
        {
          text: this.languageService.getTranslation("cancelar"),
          role: "cancel",
        },
      ],
    });
    await actionSheet.present();
  }




  async selectMediaImg(data, index, event?) {
    try {
      if (this.commonService.isCordova()) {
        this.dataVideo = Object.assign({ index: index }, data);
        await this.commonService.presentLoading();
        const captureVideoUrl: any = await this.mediaFilesService.pickImages({
          message: "CILIK",
          outputType: 0,
          quality: 100,
          maximumImagesCount: 5,
          allow_video: false,
          disable_popover: true,
          enabledCopyFileToLocalDir: true,
        });
        console.log("captureVideoUrl");
        let listPromise = [];
        captureVideoUrl.forEach((element) => {
          console.log("selectMediaImg");
          listPromise.push(
            this.upload({
              captureVideoUrl: element,
              contentType: "image/png",
              newName: this.keyDivision,
              codeDivision: this.dataDivision.category_detail.id,
            })
          );
        });

        const urlVideo = await Promise.all(listPromise);
        console.log(urlVideo);
        await this.updateStatusIndex({ urlVideo, status: 3 });
        await this.updateMainStatusIndex();
        this.commonService.presentAlertTranslation("cargado con éxito");
        this.commonService.dismissLoading();
      } else {
        const file = event.target.files;
        console.log("this.dataVideo", this.dataVideo)
        console.log("file", file)
      }


    } catch (err) {
      this.commonService.dismissLoading();
      this.commonService.presentAlert(JSON.stringify(err));
    }
  }

  async selectMediaImgWebUpload(event) {
    await this.commonService.presentLoading()
    let files = event.target.files;
    console.log("this.dataVideo", this.dataVideo)
    console.log("files", files)

    let listBase64 = []
    Object.keys(files).forEach(x => {
      listBase64.push(this.mediaFilesService.getBase64(files[x]))
    });
    const listBase64Result = await Promise.all(listBase64);
    console.log("listBase64Result", listBase64Result)


    let listPromiseUrl = []
    listBase64Result.forEach(base64 => {
      listPromiseUrl.push(this.mediaFilesService.uploadFile(base64))
    });

    const listUrlResult = await Promise.all(listPromiseUrl);
    console.log('listUrlResult', listUrlResult);
    await this.updateStatusIndex({ urlVideo: listUrlResult, status: 3 });
    await this.updateMainStatusIndex();
    this.commonService.presentAlertTranslation("cargado con éxito");
    this.commonService.dismissLoading();



    this.commonService.dismissLoading()
  }


  selectMediaImgWeb(data, index) {
    this.dataVideo = Object.assign({ index: index }, data);
    this.fileUploadInputImgs.nativeElement.click()
  }

  async selectVideo(event?) {
    try {
      if (this.commonService.isCordova()) {
        await this.commonService.presentLoading();
        const captureVideoUrl = await this.mediaFilesService.selectMedia({
          mediaType: this.camera.MediaType.VIDEO,
        });
        console.log("selectMedia", captureVideoUrl);
        const urlVideo = await this.upload({
          captureVideoUrl,
          contentType: "video/mp4",
          newName: this.keyDivision,
          codeDivision: this.dataDivision.category_detail.id,
        });
        await this.updateStatusIndex({ urlVideo, status: 3 });
        await this.updateMainStatusIndex();
        this.commonService.presentAlertTranslation("cargado con éxito");
        this.commonService.dismissLoading();
      } else {
        await this.commonService.presentLoading();
        const file = event.target.files[0];
        const eventUrl = await this.mediaFilesService.getBase64(file)
        const urlVideo = await this.mediaFilesService.uploadFile(eventUrl)
        await this.updateStatusIndex({ urlVideo, status: 3 });
        await this.updateMainStatusIndex();
        this.commonService.presentAlertTranslation("cargado con éxito");
        this.commonService.dismissLoading();
      }

    } catch (err) {
      this.updateStatusIndex({ urlVideo: false, status: 2 });
      this.commonService.dismissLoading();
      this.commonService.presentAlert(JSON.stringify(err));
    }
  }

  async recordVideo() {
    console.log("start recordVideo");

    if (this.commonService.isCordova()) {
      await this.commonService.presentLoading();
      this.type = "movil"
      const res = await this.mediaFilesService
        .recordVideo({ limit: 1, duration: 60 })
        .catch((err) => {
          this.commonService.dismissLoading();
          this.updateStatusIndex({ urlVideo: false, status: 2 });
          this.commonService.presentAlert(JSON.stringify(err));
        });

      const urlVideo = await this.upload({
        captureVideoUrl: res,
        contentType: "video/mp4",
        newName: this.keyDivision,
        codeDivision: this.dataDivision.category_detail.id,
      });

      console.log("urlVideo", urlVideo);
      await this.updateStatusIndex({ urlVideo, status: 3 });
      await this.updateMainStatusIndex();

    } else {
      this.type = "web"
      const urlVideo = await this.mediaFilesService.presentModalRecordVideo()
      console.log("urlVideo", urlVideo);
      await this.updateStatusIndex({ urlVideo, status: 3 });
      await this.updateMainStatusIndex();

    }

    this.commonService.presentAlertTranslation("cargado con éxito");
    this.commonService.dismissLoading();


  }

  async updateStatusIndex({ urlVideo, status }) {
    await this.db
      .object(`${this.url_root}/${this.dataVideo.index}/state`)
      .update({
        type: this.type,
        uid_user: this.customizationfileService.getUid(),
        last_update: Date.now(),
        comments: this.languageService.getTranslation("pending"),
        video: urlVideo,
        step: status,
      });
  }

  async updateMainStatusIndex() {
    await this.db
      .object(`competition/${this.keyDb}/division/${this.keyDivision}/states/`)
      .update({
        uid_user: this.customizationfileService.getUid(),
        last_update: Date.now(),
        step2: 1,
      });
  }

  async upload({ captureVideoUrl, contentType, newName, codeDivision }) {
    return new Promise(async (resolve, reject) => {
      console.log("upload");
      return await this.mediaFilesService
        .upload({
          captureVideoUrl,
          contentType,
          newName,
          codeDivision,
        })
        .then(async (urlVideo) => {
          resolve(urlVideo);
        })
        .catch((err) => {
          reject(err);
          console.log("upload", err);
          this.commonService.dismissLoading();
          this.commonService.presentAlert(JSON.stringify(err));
        });
    });
  }

  openVideo(url) {
    const urlCheck = Array.isArray(url);
    if (!urlCheck) {
      this.mediaFilesService.playVideo(url);
    } else {
      console.log("urlCheck", urlCheck, url);
      this.presentModalPhotoOrVideo(url);
    }
  }

  async presentModalPhotoOrVideo(url) {
    const modal = await this.modalController.create({
      component: SeeVideoComponent,
      cssClass: "my-custom-modal-css",
      componentProps: {
        value: {
          name: `my photo`,
          nameVideo: `my photo`,
          url: url,
          code: `my photo`,
          key: null,
        },
      },
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        if (dataReturned.data.status === 1) {
        }
      }
    });

    return await modal.present();
  }

  showComment(text) {
    this.commonService.presentAlert(text);
  }

  trackByFn(index, item) {
    return index;
  }
}
