import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RemoveCacheService {

  constructor(private http: HttpClient) { }


  /**
   * Borra el caché de la aplicación  
   */
  clearCache() {
    // var dominio = window.location.hostname;
    // console.log(dominio);
    var dominioCompleto = window.location.hostname;
    var partesDominio = dominioCompleto.split('.');
    var dominio = partesDominio[partesDominio.length - 2] + '.' + partesDominio[partesDominio.length - 1];
    const headers = new HttpHeaders({
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
      'Expires': '0'
    });

    this.http.get(dominio, { headers }).subscribe(response => {
      console.log('Caché borrado con éxito');
    }, error => {
      console.error('Error al borrar el caché:', error);
    });
  }


  /**
   * Borra el caché de la aplicación
   */
  removeChache() {


    // Borrar el caché de la aplicación Ionic
    if ('caches' in window) {
      caches.keys().then(function (cacheNames) {
        cacheNames.forEach(function (cacheName) {
          caches.delete(cacheName);
        });
      });
    }

    // Desactivar el Service Worker de Angular (opcional)
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        registrations.forEach(function (registration) {
          registration.unregister();
        });
      });
    }

    // Borrar el caché del navegador
    window.location.reload();

  }

}

