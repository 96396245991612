import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertController } from "@ionic/angular";
import {
  CommonService,
  CustomizationfileService,
  DataService,
  ManageUsersService,
} from "src/app/services/services";
import { Onesignalv2Service } from "src/app/services/v2/onesignalv2.service";
const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';


@Component({
  selector: "app-send-push-notification",
  templateUrl: "./send-push-notification.component.html",
  styleUrls: ["./send-push-notification.component.scss"],
})
export class SendPushNotificationComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;

  listType = [
    { name: "Subscribed Users", type: "Subscribed Users", disabled: false },
    { name: "only this competition", type: "competition", disabled: false },
    { name: "Active Users", type: "Active Users", disabled: false },
    { name: "Inactive Users", type: "Inactive Users", disabled: false },
    { name: "division", type: "division", disabled: false },
    { name: "country", type: "country", disabled: false },
    { name: "winners", type: "winners", disabled: false },
    { name: "continent", type: "continent", disabled: true },
    { name: "age", type: "age", disabled: true },
    { name: "result", type: "result", disabled: true },
    { name: "complaints", type: "complaints", disabled: true },
  ];
  listDivision: any;
  codeDivision: any;
  country: { id: string; sortname: string; name: string; phonecode: string }[];

  constructor(
    public _data: DataService,
    private formBuilder: FormBuilder,
    public customizationfileService: CustomizationfileService,
    public commonService: CommonService,
    public onesignalv2Service: Onesignalv2Service,
    public manageUsersService: ManageUsersService,
    public alertController: AlertController
  ) {
    this.registerForm = this.formBuilder.group({
      subtitle_es: ["", Validators.required],
      subtitle_en: ["", Validators.required],
      headings_es: ["", Validators.required],
      headings_en: ["", Validators.required],
      contents_es: ["", Validators.required],
      contents_en: ["", Validators.required],
      url: ["", Validators.pattern(reg)],
      type: ["", Validators.required],

      division: [""],
      country: [""],
    });
  }

  async ngOnInit() {
    const res: any = await this.manageUsersService.global({
      url: "division/getAllDivisionAdmin",
      data: {
        keydb: this.customizationfileService.getPROVISIONAKEY(),
        round: 1,
      },
    });

    console.log("res", res)
    this.listDivision = res.results;

    this.getCountry();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  selectDivision(event) {
    this.registerForm.patchValue({
      division: event.value.code,
    });
    console.log("codeDivision", this.codeDivision);
  }

  selectCountry(event) {
    this.registerForm.patchValue({
      country: event.value.sortname,
    });
  }

  async onSubmit() {
    try {
      this.submitted = true;

      // stop here if form is invalid
      if (this.registerForm.invalid) {
        return;
      }

      console.log("this.registerForm.value", this.registerForm.value);

      await this.commonService.presentLoading();
      const value = this.registerForm.value;
      let notification: any = {
        data: { url: value.url ? value.url : false, img: "", type: 1 },
        contents: { en: value.contents_en, es: value.contents_es },
        headings: { en: value.headings_en, es: value.headings_es },
        subtitle: { en: value.subtitle_en, es: value.subtitle_es },
      };

      console.log("tokenWinners", notification)
      console.log("this.registerForm.value.type", this.registerForm.value.type)

      switch (this.registerForm.value.type) {
        case "Subscribed Users":
          notification.included_segments = ["Subscribed Users"];
          break;
        case "competition":
          const tokenByCompetition: any = await this.getTokenByCompetition();
          notification.include_player_ids = tokenByCompetition.results.list;
          break;

        case "Active Users":
          notification.included_segments = ["Active Users"];
          break;

        case "Inactive Users":
          notification.included_segments = ["Inactive Users"];
          break;

        case "division":
          const tokenByDivision: any = await this.getTokenByDivision(
            value.division
          );
          notification.include_player_ids = tokenByDivision.results.list;
          break;

        case "winners":
          const tokenWinners: any = await this.getWinnersPushToken();
          notification.include_player_ids = tokenWinners.results.list;
          break;

        case "country":
          notification.filters = [
            { field: "country", relation: "=", value: value.country },
          ];
          break;

        default:
          this.commonService.presentAlert("not enabled")
          break;
      }

      console.log("notification", notification);


      this.commonService.dismissLoading();

      let include_player_ids = notification.include_player_ids ? notification.include_player_ids.length : ""

      const alert = await this.alertController.create({
        message: `Enviar notificación a ${include_player_ids}`,
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Confirm Cancel: blah');

            }
          }, {
            text: 'Ok',
            handler: async () => {
              await this.commonService.presentLoading();
              const res: any = await this.onesignalv2Service.onesignalV1({
                route: "createNotification",
                data: { notification: notification },
              });
              this.commonService.dismissLoading();
              this.commonService.presentAlert(`recipients: ${res.results.recipients}`);
            }
          }
        ]
      });

      await alert.present();




    } catch (err) {
      console.log("err", err);
      this.commonService.dismissLoading();
      this.commonService.presentAlert(JSON.stringify(err.error.message));
    }
  }


  getTokenByCompetition() {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.onesignalv2Service.onesignalV2({
          url: "notification/getTokenByCompetition",
          data: {
            keydb: this.customizationfileService.getPROVISIONAKEY(),
          },
        });
        resolve(result);
      } catch (err) {
        reject(err);
        this.commonService.presentAlert(JSON.stringify(err));
      }
    });
  }

  async getTokenByDivision(code) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.onesignalv2Service.onesignalV2({
          url: "notification/getTokenByDivision",
          data: {
            keydb: this.customizationfileService.getPROVISIONAKEY(),
            code: code,
          },
        });
        resolve(result);
      } catch (err) {
        reject(err);
        this.commonService.presentAlert(JSON.stringify(err));
      }
    });
  }

  async getWinnersPushToken() {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.onesignalv2Service.onesignalV2({
          url: "notification/winnersPushToken",
          data: {
            keydb: this.customizationfileService.getPROVISIONAKEY()
          },
        });
        console.log("result", result)
        resolve(result);
      } catch (err) {
        reject(err);
        this.commonService.presentAlert(JSON.stringify(err));
      }
    });
  }

  getCountry() {
    this.country = this._data.country.sort();
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }
}
