import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController } from '@ionic/angular';
import { CustomizationfileService } from 'src/app/services/services';
import { PayJudgeComponent } from '../pay-judge/pay-judge.component';

@Component({
  selector: 'app-list-jubge',
  templateUrl: './list-jubge.component.html',
  styleUrls: ['./list-jubge.component.scss'],
})
export class ListJubgeComponent implements OnInit {
  listJubge: unknown;

  constructor(
    public modalController: ModalController,
    public afs: AngularFirestore,
    public customizationfileService: CustomizationfileService) { }

  async ngOnInit() {
    this.listJubge = await this.getpaymentRequest()
    console.log("res", this.listJubge)
  }

  getpaymentRequest() {
    return new Promise(async (resolve, reject) => {
      console.log("this.customizationfileService.getKeyDb()", this.customizationfileService.getKeyDb())
      this.afs.collection('payment-request', ref =>
        ref.where('keydb', '==', this.customizationfileService.getKeyDb())
          .where('type', '==', 'payment request')
          .where('status', '==', 'pending for approval')
          .orderBy('date', 'desc'))
        .valueChanges({ idField: 'key' })
        .subscribe(res => {
          if (!res) {
            resolve([])
          }
          resolve(res)
        })
    })
  }

  async openModalPay(item) {
    const modal = await this.modalController.create({
      component: PayJudgeComponent,
      cssClass: "my-custom-modal-css",
      swipeToClose: false,
      backdropDismiss: false,
      componentProps: {
        details: item
      },
    });

    // modal.onDidDismiss()
    // .then(async (dataReturned) => {
    //   // if (!dataReturned.data.update) {
    //   //   return
    //   // }
    // })


    return await modal.present();
  }

}
