import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(private readonly sanitizer: DomSanitizer) { }

  public transform(url: string): SafeResourceUrl {
    if (!url) { return }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
