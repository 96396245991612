import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-modal-admin-chat",
  templateUrl: "./modal-admin-chat.component.html",
  styleUrls: ["./modal-admin-chat.component.scss"],
})
export class ModalAdminChatComponent implements OnInit {
  @Input() infoChat;
  constructor(public modalController: ModalController) {}

  ngOnInit() {
    console.log(this.infoChat);
  }

  closeButtonChat($event) {
    this.modalController.dismiss();
  }
}
