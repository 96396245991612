import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ModalController, NavParams } from "@ionic/angular";
import { CommonService } from "src/app/services/services";

@Component({
  selector: "app-see-complaints",
  templateUrl: "./see-complaints.component.html",
  styleUrls: ["./see-complaints.component.scss"],
})
export class SeeComplaintsComponent implements OnInit {
  details: any;
  constructor(
    private params: NavParams,
    private modalCtrl: ModalController,
    public commonService: CommonService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.details = this.params.get("data");
    console.log("this.details", this.details);
  }

  openURL(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getName(data) {
    if (!data) {
      return;
    }
    return this.commonService.getName2(data);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
