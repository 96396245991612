import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "../common/common.service";
@Injectable({
  providedIn: "root"
})
export class NotificationService {
  constructor(private http: HttpClient, public _cs: CommonService) { }

  sendNotificationOfAccreditation(data: any) {
    return new Promise(async (resolve, reject) => {

      this.http.post(`${this._cs.functions}/sendNotificationOfAccreditation`, JSON.stringify(data)).subscribe(
        res => {
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  sendCategoryAccreditationCategories(data: any) {
    if (data) {
      return new Promise(async (resolve, reject) => {
        this.http.post(`${this._cs.functions}/sendCategoryAccreditationCategories`, JSON.stringify(data)).subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
      });
    }
  }
}
