import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ModalController, NavParams, LoadingController, AlertController, ToastController, ActionSheetController } from "@ionic/angular";
import { AngularFireDatabase } from "angularfire2/database";
import {
  AuthenticationService,
  CommonService,
  CustomizationfileService,
  DataService,
  DivisionService,
  ManageUsersService,
  UploadFileService,
  ViewStatusService,
} from "src/app/services/services";
import * as moment from "moment";
import { ModalFormUpdateEmailComponent } from "../modal-form-update-email/modal-form-update-email.component";

@Component({
  selector: "record",
  templateUrl: "./record.component.html",
  styleUrls: ["./record.component.scss"],
})
export class RecordComponent implements OnInit {

  public key_db: string;
  details: any;
  sound: any;
  files: any;
  hourPassedValid = false;
  searchTerm: any = "";
  search: boolean;
  spinner: boolean;
  users: any[];
  gender_number = 3;
  param = "identification";
  purchaseDetails: any;
  showWhoRegisteredTheCategory: string;
  changeMusic: string;
  changeCompetitor: string;
  nameGroups = "";
  country: any
  country_select: any
  getCountryText: any
  schoolRecord: any[];

  school_select: any
  getschoolText: any
  changeCountry: any;
  changeAcanemy: any;

  constructor(
    public toastController: ToastController,
    public alertController: AlertController,
    public _division: DivisionService,
    public _data: DataService,
    public database: AngularFireDatabase,
    public _uploadFile: UploadFileService,
    public manageUsersService: ManageUsersService,
    public domSanitizer: DomSanitizer,
    public customizationfileService: CustomizationfileService,
    public commonService: CommonService,
    private modalCtrl: ModalController,
    private viewStatusServ: ViewStatusService,
    private params: NavParams,
    private actionSheetCtrl: ActionSheetController,
    private authSrv: AuthenticationService,
  ) {
    this.key_db = this.customizationfileService.getKeyDb();
  }

  ngOnInit() {
    const data = this.params.get("details");
    this.nameGroups = data.categories[0].dataGroups.nameGroups
    console.log("data", data);
    console.log("data.categories", this.nameGroups);

    if (data.categories) {
      console.log("welcome");
      this.details = data;
    } else {
      console.log("record");
      this.details = data.division;
    }
    const start = moment(this.details.transaction_date);
    const hourPassed = moment().diff(start, "hours");
    this.hourPassedValid = hourPassed > 23 ? false : true;
    // console.log("this.hourPassedValid", this.hourPassedValid);
    this.getStatusDivisionEvent();

    this.getCountry()

    this.getSchoolRecord()
  }

  /**
   *
   */
  getStatusDivisionEvent() {
    // console.log("getStatusDivisionEvent");
    this.viewStatusServ.eventMethodPipe.subscribe((data) => {
      // console.log("RecordComponent", data);
      if (!data) { return }
      this.purchaseDetails = data[0].enabled;
      this.showWhoRegisteredTheCategory = data[1].enabled;
      this.changeMusic = data[2].enabled;
      this.changeCompetitor = data[3].enabled
      this.changeCountry = data[4].enabled;
      this.changeAcanemy = data[5].enabled;

    });
  }

  uploadFile(event) {
    this.files = event.target.files;
    this.sound = this.domSanitizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(this.files[0])
    );
  }

  /**
   *
   */
  async updateMusic() {
    try {
      await this.commonService.presentLoading();
      await this._uploadFile
        .uploadFile(this.files)
        .then(
          async (res) => {

            /// @dev update music
            const musicRef = this.database.object(
              `/competition/${this.details.event_id}/division/${this.details.key}/categories/0/music`
            );
            console.log("1", `/competition/${this.details.event_id}/division/${this.details.key}/categories/0/music`);
            await musicRef.set(res);


            /// @dev update changeofmusic
            const changeofmusicRef = this.database.object(`/competition/${this.details.event_id}/division/${this.details.key}/changeofmusic`);
            await changeofmusicRef.set(false);


            /// @dev update userMusicChange
            // const userMusicChange = this.database.object(
            //   `/competition/${this.details.event_id}/division/${this.details.key}/userMusicChange`
            // );
            // const uid = this.customizationfileService.getUid();
            // const profile = this.customizationfileService.getProfile();
            // await userMusicChange.set({
            //   uid: uid,
            //   name: `${profile.name} ${profile.surnames}`,
            //   date: moment().format("YYYY-MM-DD HH:mm:ss"),
            // });

            await this.updateOrder();

            // this.commonService.presentAlert("successful");
            this.presentToast('Música registrado con éxito');
            this.closeModal();
          },
          (err) => {
            this.commonService.presentAlert(JSON.stringify(err));
          }
        ).catch((err) => {
          this.commonService.presentAlert(JSON.stringify(err));
        });

      this.commonService.dismissLoading();
      this.sound = null;
      this.files = null;
      this._uploadFile.isUploading = false;
      this._uploadFile.isUploaded = false;
    } catch (error) {
      console.log("error", error);
      this.commonService.presentAlert(JSON.stringify(error));
      return
    } finally {
      this.commonService
    }


  }


  /**
   *
   * @returns
   */
  async updateUsers() {
    await this.commonService.presentLoading();

    const minimal_dancers = this.details.categories[0].category_detail.minimal_dancers;
    const participarte = this.details.categories[0].participarte.length;

    console.log("participarte", participarte);
    console.log("minimal_dancers", minimal_dancers);


    console.log(`/competition/${this.details.event_id}/division/${this.details.key}/categories/0/participarte`);
    const participarteRef = this.database.object(
      `/competition/${this.details.event_id}/division/${this.details.key}/categories/0/participarte`
    );
    // await participarteRef.set(this.details.categories[0].participarte);

    if (minimal_dancers === participarte) {
      await participarteRef.set(this.details.categories[0].participarte);
    } else if (minimal_dancers === 3 && participarte >= 3) {
      await participarteRef.set(this.details.categories[0].participarte);
    } else {
      this.commonService.dismissLoading();
      this.commonService.presentAlert("El número de participantes no coincide con el mínimo requerido");
      return;
    }

    const changeofmusicRef = this.database.object(
      `/competition/${this.details.event_id}/division/${this.details.key}/enableAddser`
    );
    await changeofmusicRef.set(false);
    this.closeModal();
    this.commonService.dismissLoading();
    this.commonService.presentAlert("Usuario registrado con éxito");
  }

  /**
   *
   * @returns
   */
  async searchParticipant() {
    this.users = [];
    if (this.searchTerm.length < 3) {
      this.spinner = false;
      return;
    }
    this.spinner = true;
    this.search = true;
    // const q = this.searchTerm.toLowerCase();

    let q = "";
    if (this.param == "name") {
      q = this.searchTerm.toLowerCase();
    }

    if (this.param == "identification") {
      q = this.searchTerm.toLowerCase().trim();
    }

    if (this.param == "email") {
      q = this.searchTerm.toLowerCase().trim();
    }

    await this.manageUsersService
      .validateDivision({
        division_select: {
          id: this.details.categories[0].category_detail.id,
          availablePlaces: this.details.categories[0].category_detail
            .availablePlaces,
          competidor_max: this.details.categories[0].category_detail
            .competidor_max,
          competidor_min: this.details.categories[0].category_detail
            .competidor_min,
          descripcion: this.details.categories[0].category_detail.descripcion,
          edad_max: this.details.categories[0].category_detail.edad_max,
          edad_min: this.details.categories[0].category_detail.edad_min,
          event_id: this.details.event_id,
          finalHour: "2020-12-20T23:59:41.334+04:00",
          id_categoria: this.details.categories[0].category_detail.id_categoria,
          id_sub_categoria: this.details.categories[0].category_detail
            .id_sub_categoria,
          id_tipo_sub_categoria: this.details.categories[0].category_detail
            .id_tipo_sub_categoria,
          startTime: "2020-12-19T00:00:41.332+04:00",
          sub_categoria: this.details.categories[0].category_detail
            .sub_categoria,
          durationMusic: {
            min: 89,
            max: 121,
          },
          improvisation: this.details.categories[0].category_detail
            .improvisation,
          isAGroup: this.details.categories[0].category_detail.isAGroup,
          gender: 3,
          gender_number: 3,
          type: this.details.categories[0].category_detail.type,
          minimal_dancers: 2,
        },
        key_db: this.details.event_id,
        block: {
          categoria: "AMATEUR COUPLES",
          estatus: "1",
          id_categoria: this.details.categories[0].category_detail.id_categoria,
        },
        param: this.param,
        query: q,
      })
      .then((users_data: any) => {
        console.log("users_data", users_data);
        this.users = users_data.users_validate;
        this.search = false;
      })
      .catch((err) => {
        this.commonService.presentAlert(JSON.stringify(err));
        console.log("err", err);
        this.users = [];
        this.search = false;
        this.spinner = false;
      });
  }


  /**
   *
   * @param user
   */
  async addUser(user) {
    this.details.categories[0].participarte.push(user);
    this.users = [];
    await this.commonService.presentAlert("Agregado correctamente");
  }

  async showOpts(idx: number, user: any){
    const optionsList: any[] = [
      // {
      //   text: 'Remover',
      //   role: 'remove',
      //   icon: 'close-circle-outline'
      // }
    ];

    if(user.hasFake){
      optionsList.push({
        text: 'Actualizar',
        icon: 'pencil',
        role: 'updateEmail'
      },)
    }

    optionsList.push({
      text: 'Cancel',
      icon: 'close',
      role: 'cancel'
    });

    const actionSheet = await this.actionSheetCtrl.create({
      // header: 'Opciones',
      cssClass: 'my-custom-class',
      buttons: optionsList,
    });
    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    // console.log('onDidDismiss resolved with role', role);
    if(role == 'cancel') return;

    switch (role) {
      case 'remove': return this.remove(idx);
      case 'updateEmail': return this.updateEmail(user, idx);
      default: break;
    }
  }


  /**
   *
   * @param index
   */
  async remove(index: number) {
    const ask = await this.alertController.create({
      message: '¿Estás seguro de que quieres eliminar este usuario?',
      buttons: [
        { text: 'No', role: 'cancel'},
        { text: 'Sí', role: 'next'}
      ],
    });

    await ask.present();
    const { role } = await ask.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
    if(role == 'cancel') return;

    /** TODO: pendiente por habilitar */
    this.details.categories[0].participarte = this.details.categories[0].participarte
      .slice(0, index)
      .concat(this.details.categories[0].participarte.slice(index + 1, this.details.categories[0].participarte.length));
  }

  async updateEmail(user: any, idx: number) {
    console.log('updateEmail', user);
    const modal = await this.modalCtrl.create({
      component: ModalFormUpdateEmailComponent,
      componentProps: {
        email: user.email,
        uid: user.uid
      },
      swipeToClose: true,
      presentingElement: await this.modalCtrl.getTop(),
    });

    await modal.present();

    const { data, role } = await modal.onDidDismiss();
    if(role == 'cancel') return;
    console.log({data, role});

    try {
      await this.commonService.presentLoading();
      await this.authSrv.updateEventUserEmail(this.key_db, user.uid, data.email);
      await this.commonService.presentAlert('Email actualizado con éxito');
      this.details.categories[0].participarte[idx].email = data.email;
      return;
      
    } catch (err) {
      console.log('Error on RecordComponent.updateEmail', err);
      return;
    }finally{
      this.commonService.dismissLoading();
    }
  }



  /**
   * @dev  pais
   */
  getCountry() {
    this.country = this._data.country.sort();
  }

  /**
   * @dev save country
   */
  async saveCountry() {
    const alert = await this.alertController.create({
      message: '¿Estás seguro de que quieres guardar este país?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Ok',
          handler: async () => {
            if (this.country_select == undefined) {
              return this.presentToast('Seleccione un país');
            }

            await this.commonService.presentLoading();
            const urlCountry = `/competition/${this.details.event_id}/division/${this.details.key}/country`;
            await this.database.object(urlCountry).set(this.country_select);
            await this.updateOrder();
            this.presentToast('país registrado con éxito');
            this.closeModal();
            await this.commonService.dismissLoading();
          },
        },
      ],
    });

    await alert.present();
  }


  /**
   * @dev get school record
   */
  getSchoolRecord() {
    this._division.getSchoolRecord().subscribe((data) => {
      this.schoolRecord = data.sort();
    });
  }


  /**
   * @dev save school record
   */
  async saveAcademy() {
    const alert = await this.alertController.create({
      message: '¿Estás seguro de que quieres guardar esta academia?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Ok',
          handler: async () => {
            if (this.school_select == undefined || this.school_select == null) {
              return this.presentToast('Seleccione una academia');
            }

            await this.commonService.presentLoading();
            console.log('Confirm Okay', this.school_select);
            const urlAcademy = `/competition/${this.details.event_id}/division/${this.details.key}/school`;
            const academy = {
              key: this.school_select.key,
              name: this.school_select.name_institution,
            }
            console.log('academy', academy);
            console.log('urlAcademy', urlAcademy);
            await this.database.object(urlAcademy).set(academy);
            await this.updateOrder();
            this.presentToast('Academia registrado con éxito');
            await this.commonService.dismissLoading();
            this.closeModal();

          },
        },
      ],
    });

    await alert.present();
  }



  /**
   *
   * @param msg
   */
  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
    });
    toast.present();
  }


  async updateOrder() {
    const userMusicChange = this.database.object(
      `/competition/${this.details.event_id}/division/${this.details.key}/userMusicChange`
    );
    const uid = this.customizationfileService.getUid();
    const profile = this.customizationfileService.getProfile();
    await userMusicChange.set({
      uid: uid,
      name: `${profile.name} ${profile.surnames}`,
      date: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
  }


  /**
   *
   */
  closeModal() {
    this.modalCtrl.dismiss();
  }
}
