import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController } from '@ionic/angular';
import { CommonService, CustomizationfileService } from 'src/app/services/services';
import { WalletService } from 'src/app/services/v2/wallet.service';
import { SeePaidDivisionComponent } from '../see-paid-division/see-paid-division.component';

@Component({
  selector: 'app-list-of-qualified-divisions',
  templateUrl: './list-of-qualified-divisions.component.html',
  styleUrls: ['./list-of-qualified-divisions.component.scss'],
})
export class ListOfQualifiedDivisionsComponent implements OnInit {
  listResult: any
  divisionPaid: any
  constructor(
    public modalController: ModalController,
    public commonService: CommonService,
    public walletService: WalletService,
    public afs: AngularFirestore,
    public customizationfileService: CustomizationfileService) { }

  async ngOnInit() {
    const result: any = await this.getlogDivisionsPaid()
    this.divisionPaid = result.filterDivision
    console.log("result", result)
  }

  getlogDivisionsPaid() {
    return new Promise(async (resolve, reject) => {
      this.afs.collection('judgesWallet-DivisionsPaid', ref =>
        ref.where('keydb', '==', this.customizationfileService.getKeyDb())
          .where('jubgeUid', '==', this.customizationfileService.getUid())
          .orderBy('date', 'desc'))
        .valueChanges()
        .subscribe(res => {
          if (!res) {
            resolve({
              log: [],
              filterDivision: []
            })
          }
          resolve({
            log: res,
            filterDivision: res.map((x: any) => {
              return x.code
            })
          })
        })
    })
  }

  presentModal(code) {
    this.modalController
      .create({
        cssClass: "my-custom-modal-css",
        component: SeePaidDivisionComponent,
        componentProps: {code},
      })
      .then((modal) => {
        modal.present();
      });
  }



}
