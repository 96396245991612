import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { AlertController, ModalController, NavParams } from "@ionic/angular";
import { AngularFireDatabase } from "angularfire2/database";
import {
  CommonService,
  CustomizationfileService,
  RatingsService,
} from "src/app/services/services";
import { saveAs } from "file-saver";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { Onesignalv2Service } from "src/app/services/v2/onesignalv2.service";

@Component({
  selector: "app-modal-complaints",
  templateUrl: "./modal-complaints.component.html",
  styleUrls: ["./modal-complaints.component.scss"],
})
export class ModalComplaintsComponent implements OnInit {
  details: any;
  mainChoreographyVideo: any;
  resolution = {
    resolution: "",
    status: "",
  };

  listJubges: any;
  constructor(
    public ratingsService: RatingsService,
    public alertController: AlertController,
    public db: AngularFireDatabase,
    private params: NavParams,
    private modalCtrl: ModalController,
    public commonService: CommonService,
    public sanitizer: DomSanitizer,
    private afs: AngularFirestore,
    private router: Router,
    public onesignalv2Service: Onesignalv2Service,
    public customizationfileService: CustomizationfileService
  ) {}

  ngOnInit() {
    this.details = this.params.get("data");
    console.log("this.details", this.details);
    this.getMainVideo();
    this.getJubges();
  }

  getMainVideo() {
    this.db
      .object(
        `/competition/${this.customizationfileService.getPROVISIONAKEY()}/mainChoreography/${
          this.details.code
        }/${this.details.round}/${this.details.keyDivision}`
      )
      .valueChanges()
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.mainChoreographyVideo = res.video;
      });
  }

  getJubges() {
    this.db
      .object(
        `/competition/${this.customizationfileService.getPROVISIONAKEY()}/juez/${
          this.details.code
        }/${this.details.round}/${this.details.keyDivision}`
      )
      .valueChanges()
      .subscribe(async (res: any) => {
        if (!res) {
          return;
        }
        const getData = async () => {
          return Promise.all(
            Object.keys(res).map(async (x) => {
              console.log("uid", x);
              const name = await this.ratingsService.getProfileJudges(x);
              return {
                uid: x,
                name,
                IQualify: res[x].IQualify,
              };
            })
          );
        };

        this.listJubges = await getData();
      });
  }

  async changeJudgeStatus(data) {
    console.log("data", data);
    console.log("status", status);

    const url = `/competition/${this.customizationfileService.getPROVISIONAKEY()}/juez/${
      this.details.code
    }/${this.details.round}/${this.details.keyDivision}/${data.uid}`;

    console.log("url", url);

    const alert = await this.alertController.create({
      header: `enable judge: ${data.name}`,
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "SI",
          handler: async () => {
            await this.commonService.presentLoading();
            this.db
              .object(
                `/competition/${this.customizationfileService.getPROVISIONAKEY()}/juez/${
                  this.details.code
                }/${this.details.round}/${this.details.keyDivision}/${data.uid}`
              )
              .update({
                IQualify: !data.IQualify,
              });
            this.commonService.dismissLoading();
          },
        },
      ],
    });

    await alert.present();
  }

  async sendResolution() {
    if (!this.resolution.resolution || !this.resolution.status) {
      return;
    }
    const alert = await this.alertController.create({
      header: "Confirm?",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Ok",
          handler: async () => {
            console.log("Confirm Okay");
            console.log("resolution", this.resolution);

            // casos abiertos - 0
            // casos aceptado - 1
            // casos casos rechazsdos - 2
            // casos aceptados - 3
            await this.commonService.presentLoading();
            await this.afs
              .collection("complaints")
              .doc(this.details.division.keydb)
              .collection("list")
              .doc(this.details.docId)
              .update({
                resolution: this.resolution.resolution,
                status: Number(this.resolution.status),
              });

            // this.details.from.uid
            // const res: any = await this.onesignalv2Service.onesignalV1({
            //   route: "createNotification",
            //   data: {
            //     notification: {
            //       include_player_ids: [],
            //       data: { foo: "bar" },
            //       contents: { en: "English Message" },
            //     },
            //   },
            // });

            this.commonService.dismissLoading();
            this.closeModal();
          },
        },
      ],
    });

    await alert.present();
  }

  async download() {
    await this.commonService.presentLoading();
    await this.commonService
      .downloadFile({ url: this.mainChoreographyVideo })
      .subscribe((response) => {
        this.getDownload({
          response,
          MIME_types: "video/mp4",
          ext: "mp4",
        });
        this.commonService.dismissLoading();
      }),
      (error) => console.log("Error downloading the file"),
      () => console.info("File downloaded successfully");
    this.commonService.dismissLoading();
  }

  getDownload({ response, MIME_types, ext }) {
    let blob: any = new Blob([response], {
      type: MIME_types,
    });
    return saveAs.saveAs(blob, `${Date.now()}.${ext}`);
  }

  openURL(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getName(data) {
    if (!data) {
      return;
    }
    return this.commonService.getName2(data);
  }

  goRouter() {
    this.router.navigate([
      "/admin/result/result-division",
      this.details.division.code,
    ]);
    this.closeModal();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
