import { Component, Input, OnInit } from "@angular/core";
import {
  ActionSheetController,
  AlertController,
  ModalController,
} from "@ionic/angular";
import { AngularFireDatabase } from "angularfire2/database";
import {
  CommonService,
  CustomizationfileService,
  LanguageService,
  ManageUsersService,
} from "src/app/services/services";
import { MediaFilesService } from "src/app/services/v2/media-files.service";
import * as moment from "moment";
import { ScrollEventService } from "src/app/services/v2/scroll-event.service";
import { Camera, CameraOptions } from "@ionic-native/camera/ngx";
import { SeeVideoComponent } from "src/app/admin/components/see-video/see-video.component";
import { SettingDivisionService } from "src/app/services/v2/setting-division.service";

@Component({
  selector: "app-main-choreography",
  templateUrl: "./main-choreography.component.html",
  styleUrls: ["./main-choreography.component.scss"],
})
export class MainChoreographyComponent implements OnInit {
  @Input() states: string;
  @Input() keyDb: string;
  @Input() keyDivision: string;
  @Input() dataDivision: any;
  @Input() round: any;
  data: any;
  url_root = "";
  startRecord: any;
  endRecord: any;
  typeVideos: any;
  timeExtra = 59;
  duration: string;
  hournow = Date.now();
  files: any;
  videoUploadFromWeb: boolean;
  URL_URI: unknown;
  diff: moment.Duration;
  seconds: any;
  urlVideo: unknown;
  durationDivision: any;
  constructor(
    public settingDivisionService: SettingDivisionService,
    public modalController: ModalController,
    private camera: Camera,
    public languageService: LanguageService,
    public scrollEventService: ScrollEventService,
    public manageUsersService: ManageUsersService,
    public alertController: AlertController,
    public mediaFilesService: MediaFilesService,
    public commonService: CommonService,
    public customizationfileService: CustomizationfileService,
    public db: AngularFireDatabase,
    public actionSheetController: ActionSheetController
  ) {
    this.settingDivisionService.videoUploadFromWeb.subscribe((res) => {
      this.videoUploadFromWeb = res
      console.log('settingDivisionService', res)
    })


  }

  ngOnInit() {

    this.url_root = `competition/${this.keyDb}/mainChoreography/${this.dataDivision.category_detail.id}/${this.round}/${this.keyDivision}`;
    console.log("this.url_root", this.url_root);

    const durationDivision = this.dataDivision.category_detail.durationMusic.max ?
      this.dataDivision.category_detail.durationMusic.max + this.timeExtra : 200

    this.duration = this.commonService.secondsToString(durationDivision);
    this.db
      .object(this.url_root)
      .valueChanges()
      .subscribe((res: any) => {
        console.log("res", res);
        if (res) {
          this.data = res;
          this.data.show = false;
          if (this.data.step !== 3) {
            this.recordWithAnuncio();
          }
          console.log(this.data.urlaudio);
        } else {
          this.createStates();
        }





      });

    setInterval(() => {
      this.hournow = Date.now();
    }, 1000);
  }

  showCommentshow() {
    this.data.show = !this.data.show;
    this.scrollEventService.scrollEvent.next(2);
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: this.languageService.getTranslation("request to record again"),
      buttons: [
        {
          text: this.languageService.getTranslation("load video"),
          handler: () => {
            this.requestVideo();
          },
        },
        {
          text: this.languageService.getTranslation("comentario"),
          role: "destructive",
          handler: () => {
            this.requestToComment();
          },
        },
        {
          text: this.languageService.getTranslation("cancelar"),
          icon: "close",
          role: "cancel",
          handler: () => {
            console.log("Cancel clicked");
          },
        },
      ],
    });
    await actionSheet.present();
  }

  async requestVideo() {
    try {
      await this.commonService.presentLoading();
      const captureVideoUrl = await this.mediaFilesService.selectMedia({
        mediaType: this.camera.MediaType.VIDEO,
      });
      console.log("selectMedia", captureVideoUrl);
      const urlVideo = await this.upload({
        captureVideoUrl,
        contentType: "video/mp4",
        newName: this.keyDivision,
        codeDivision: this.dataDivision.category_detail.id,
      });

      await this.updateDivision({
        step: 1,
        uid_user: this.customizationfileService.getUid(),
        date: Date.now(),
        comment: this.languageService.getTranslation("video in review"),
        videoReview: urlVideo,
      });

      await this.db
        .object(
          `competition/${this.keyDb}/division/${this.keyDivision}/states/`
        )
        .update({
          uid_user: this.customizationfileService.getUid(),
          last_update: Date.now(),
          step3: 1,
        });

      this.commonService.dismissLoading();
      this.commonService.presentAlert(
        this.languageService.getTranslation("solicitud enviada")
      );

      this.commonService.dismissLoading();
    } catch (err) {
      this.commonService.dismissLoading();
      this.commonService.presentAlert(JSON.stringify(err));
    }
  }

  async requestToComment() {
    const alert = await this.alertController.create({
      header: this.languageService.getTranslation("request to record again"),
      inputs: [
        {
          name: "value",
          id: "value",
          type: "textarea",
          placeholder: this.languageService.getTranslation(
            "request to record again"
          ),
        },
      ],
      buttons: [
        {
          text: this.languageService.getTranslation("cancelar"),
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "Ok",
          handler: async (data) => {
            console.log("Confirm Ok");
            console.log("data", data.value);
            if (!data.value) {
              return;
            }
            await this.updateDivision({
              step: 1,
              messageReview: data.value,
            });
            this.commonService.presentAlert(
              this.languageService.getTranslation("request sent")
            );
          },
        },
      ],
    });

    await alert.present();
  }

  async recordWithAnuncio() {
    const alert = await this.alertController.create({
      header: this.languageService.getTranslation("Anunció"),
      subHeader: this.languageService.getTranslation(
        "Recuerda en caso de falla técnica no"
      ),
      message: this.languageService.getTranslation(
        "Click en"
      ),
      buttons: [
        {
          text: this.languageService.getTranslation("VER VIDEO"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
            const url =
              "https://firebasestorage.googleapis.com/v0/b/wldcprueba.appspot.com/o/multimedia%2F1GlmVLakGirK61Ry8Gyw%2F1076999-1608741363231.MOV?alt=media&token=40573b45-eba1-41f7-99cc-7d75c86947be";
            this.mediaFilesService.playVideo(url);
          },
        },
        {
          text: "OK",
          handler: () => { },
        },
      ],
    });

    await alert.present();
  }

  async recordWithCamareAlert1() {
    const alert = await this.alertController.create({
      header: this.languageService.getTranslation(
        "Finalmente vas a mostrarnos tu coreografía"
      ),
      subHeader: this.languageService.getTranslation(
        "Después de darle al"
      ),
      message: "",
      buttons: [
        {
          text: this.languageService.getTranslation("cancelar"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: this.languageService.getTranslation("SIGUIENTE"),
          handler: () => {
            this.recordWithCamareAlert2();
          },
        },
      ],
    });

    await alert.present();
  }

  async recordWithCamareAlert2() {
    const alert = await this.alertController.create({
      header: this.languageService.getTranslation(
        "Es importante que sepas que después de darle click al botón ESTOY LISTO"
      ),
      message: `${this.languageService.getTranslation(
        "comenzara a contar el cronometro y solo tendrás únicamente"
      )}  ${this.duration} ${this.languageService.getTranslation(
        "minutos para terminar el proceso de grabar tu coreografía darle click al botón USAR VIDEO"
      )} `,
      buttons: [
        {
          text: this.languageService.getTranslation("cancelar"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: this.languageService.getTranslation("SIGUIENTE"),
          handler: () => {
            this.recordWithCamareAlert3();
          },
        },
      ],
    });

    await alert.present();
  }

  async recordWithCamareAlert3() {
    const alert = await this.alertController.create({
      header: this.languageService.getTranslation(
        "Estas seguro que estas listo para grabar tu coreografía"
      ),
      message: `${this.languageService.getTranslation(
        "Recuerda que solo tienes "
      )} ${this.duration}  ${this.languageService.getTranslation(
        "minutos para terminar el proceso y darle click a USAR VIDEO"
      )}`,
      buttons: [
        {
          text: this.languageService.getTranslation("cancelar"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: this.languageService.getTranslation("ESTOY LISTO"),
          handler: () => {
            this.recordWithCamare();
          },
        },
      ],
    });

    await alert.present();
  }

  async recordWithCamare() {
    let status = true;
    let type = "";
    try {

      if (this.commonService.isCordova()) {
        type = "movil"
        await this.commonService.presentLoading();
        this.startRecord = Date.now();
        this.durationDivision =
          this.dataDivision.category_detail.durationMusic.max + this.timeExtra;
        await this.updateDivision({
          startRecord: this.startRecord,
          step: 1,
          uid_user: this.customizationfileService.getUid(),
          last_update: Date.now(),
        });

        // crear video
        this.URL_URI = await this.mediaFilesService.recordVideo({
          limit: 1,
          duration: this.durationDivision,
        });
        this.endRecord = this.mediaFilesService.endRecord;
        console.log("this.endRecord", this.endRecord);

        await this.updateDivision({
          uid_user: this.customizationfileService.getUid(),
          last_update: Date.now(),
          endRecord: this.endRecord,
          step: 1,
        });


        this.diff = moment.duration(
          moment(this.endRecord).diff(moment(this.startRecord))
        );


        this.seconds = this.diff.asSeconds();

        console.log("diff", this.seconds);
        console.log("recordWithCamare", this.URL_URI);

        // colocar estatus aprobado
        this.urlVideo = await this.upload({
          captureVideoUrl: this.URL_URI,
          contentType: "video/mp4",
          newName: this.keyDivision,
          codeDivision: this.dataDivision.category_detail.id,
        });


      } else {
        type = "web"
        this.startRecord = Date.now();
        this.durationDivision =
          this.dataDivision.category_detail.durationMusic.max + this.timeExtra;
        await this.updateDivision({
          startRecord: this.startRecord,
          step: 1,
          type,
          uid_user: this.customizationfileService.getUid(),
          last_update: Date.now(),
        });

        this.urlVideo = await this.mediaFilesService.presentModalRecordVideo()
        this.diff = moment.duration(
          moment(this.endRecord).diff(moment(this.startRecord))
        );
        this.seconds = this.diff.asSeconds();
      }





      await this.updateDivision({
        video: this.urlVideo,
        status,
        uid_user: this.customizationfileService.getUid(),
        last_update: Date.now(),
        step: 3,
        type,
        seconds: this.seconds,
        durationDivision: this.durationDivision,
        comments: "accepted",
      });

      await this.db
        .object(`competition/${this.keyDb}/division/${this.keyDivision}/states/`)
        .update({
          uid_user: this.customizationfileService.getUid(),
          last_update: Date.now(),
          step3: 3,
          mainStep: 3,
        });

      this.commonService.dismissLoading();
      if (this.seconds >= this.durationDivision) {
        //colocar estatus rechazado disqualified
        const text = `${this.languageService.getTranslation(
          "prueba"
        )} ${this.languageService.getTranslation(
          "disqualified"
        )}, ${this.languageService.getTranslation(
          "Exceeded limited  time allowed to record"
        )} ${this.commonService.secondsToString(
          this.durationDivision
        )},${this.languageService.getTranslation(
          "seconds consumed"
        )}  ${this.commonService.secondsToString(this.seconds)}`;
        status = false;

        await this.updateDivision({
          endRecord: this.endRecord,
          step: 2,
          type,
          uid_user: this.customizationfileService.getUid(),
          last_update: Date.now(),
          comment: text,
          comments: text,
          seconds: this.seconds,
          durationDivision: this.durationDivision,
        });

        this.commonService.presentAlert(text);
      } else {
        this.commonService.presentAlert(
          this.languageService.getTranslation("successfully uploaded")
        );
      }
    } catch (err) {
      status = false;
      await this.updateDivision({
        status,
        err: err,
        step: 2,
        type,
        comment: `${this.languageService.getTranslation(
          "disqualified"
        )} ${this.languageService.getTranslation(
          "usted solo tenía una oportunidad para grabar"
        )}`,
      });
      this.commonService.dismissLoading();
      this.commonService.presentAlert(
        `${this.languageService.getTranslation(
          "disqualified"
        )} ${this.languageService.getTranslation(
          "usted solo tenía una oportunidad para grabar"
        )}`
      );
    }
  }

  async upload({ captureVideoUrl, contentType, newName, codeDivision }) {
    return new Promise(async (resolve, reject) => {
      console.log("upload");
      const url = await this.mediaFilesService
        .upload({
          captureVideoUrl,
          contentType,
          newName,
          codeDivision,
        })
        .then(async (urlVideo) => {
          resolve(urlVideo);
        })
        .catch((err) => {
          console.log("upload", err);
          this.commonService.dismissLoading();
          this.commonService.presentAlert(JSON.stringify(err));
          reject(err);
        });
    });
  }

  async updateDivision(data) {
    await this.db.object(this.url_root).update(data);
  }

  async createStates() {
    await this.commonService.presentLoading();
    await this.manageUsersService
      .mainChoreography({
        keydb: this.keyDb,
        round: this.round,
        code: this.dataDivision.category_detail.id,
        keyDivision: this.keyDivision,
        uid: this.customizationfileService.getUid(),
      })
      .then((data) => {
        this.commonService.dismissLoading();
      })
      .catch((err) => {
        this.commonService.dismissLoading();
        this.commonService.presentAlert(JSON.stringify(err));
      });
  }

  async uploadFile($event) {
    try {
      await this.commonService.presentLoading();
      console.log("$event", $event);
      this.files = $event.target.files;
      const videoBase64 = await this.mediaFilesService.getBase64(this.files[0]);
      const videoUrl = await this.mediaFilesService.uploadFile(videoBase64);

      console.log("videoUrl", videoUrl);
      console.log("url_root", this.url_root);
      console.log(
        "url",
        `/competition/${this.keyDb}/division/${this.keyDivision}/states`
      );

      await this.db.object(this.url_root).update({
        video: videoUrl,
        comment: "video uploaded from the web version",
        step: 3,
        upload_web: false,
        admin: this.customizationfileService.getprofile(),
      });

      await this.db
        .object(
          `/competition/${this.keyDb}/division/${this.keyDivision}/states`
        )
        .update({ step3: 3, mainStep: 3 });
      this.commonService.dismissLoading();
    } catch (err) {
      this.commonService.dismissLoading();
      this.commonService.presentAlert(JSON.stringify(err));
    }
  }

  openVideo(url) {
    if (this.commonService.isCordova()) {
      this.mediaFilesService.playVideo(url);
    } else {
      this.presentModal(url);
    }
  }

  async presentModal(video) {
    const code = this.dataDivision.category_detail.id;

    const modal = await this.modalController.create({
      component: SeeVideoComponent,
      cssClass: "my-custom-modal-css",
      componentProps: {
        value: {
          name: `VIDEO`,
          nameVideo: `${code}-${this.round}--${this.keyDivision}`,
          url: video,
          code: code,
          key: this.keyDivision,
        },
      },
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        if (dataReturned.data.status === 1) {
          // setTimeout(() => {
          //   this.getListVideo();
          // }, 1000);
        }
      }
    });

    return await modal.present();
  }

  showComment(text) {
    this.commonService.presentAlert(text);
  }
}
