import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";

// Pipes
import { NgforPipe } from "../pipes/Ngfor/ngfor.pipe";
import { SlugifyPipe } from "../pipes/slugify/slugify.pipe";
import { KeysPipe } from "../pipes/keys/keys.pipe";
import { MildSeriousErrorPipe } from "../pages/members/results/pipes/mild-serious-error.pipe";
import { OrderResultsPipe } from "../pages/members/results/pipes/order-results.pipe";
import { TotalQualificationPipe } from "../pages/members/results/pipes/total-qualification.pipe";
import { SortDatePipe } from "../pipes/sortDate/sort-date.pipe";
import { SortCalendarPipe } from "../pipes/sort-calendar/sort-calendar.pipe";
import { SortLocalNotificationPipe } from "../pipes/sort-local-notification/sort-local-notification.pipe";
import { UsersSearchPipe } from "../pipes/users_search/users-search.pipe";
import { FileSizeFormatPipe } from "../pipes/fileSize/file-size.pipe";
import { CartSortPipe } from "./cart/cart-sort.pipe";
import { ValidateDivisionPipe } from "./validateDivision/validate-division.pipe";
import { SafeUrlPipe } from "./safeUrl/safe-url.pipe";
import { SafeHtmlPipe } from "./safeHtml/safe-html.pipe";
import { HideEmailPipe } from "./hideEmail/hide-email.pipe";
import { GetNameJubgePipe } from './getNameJubge/get-name-jubge.pipe';
import { JudgeUploadedVideoPipe } from './judgeUploadedVideo/judge-uploaded-video.pipe';
import { JudgeUploadedAudioPipe } from './judgeUploadedAudio/judge-uploaded-audio.pipe';
import { GetDivisionInformationPipe } from './getDivisionInformation/get-division-information.pipe';
import { GetUserInformationPipe } from './getUserInformation/get-user-information.pipe';
import { VimeoSafeUrlPipe } from './vimeoSafeUrl/vimeo-safe-url.pipe';
import { FullPassVerifierPipe } from './full-pass-verifier.pipe';

@NgModule({
  declarations: [
    NgforPipe,
    SlugifyPipe,
    KeysPipe,
    MildSeriousErrorPipe,
    OrderResultsPipe,
    TotalQualificationPipe,
    SortDatePipe,
    SortCalendarPipe,
    SortLocalNotificationPipe,
    UsersSearchPipe,
    FileSizeFormatPipe,
    CartSortPipe,
    ValidateDivisionPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    HideEmailPipe,
    GetNameJubgePipe,
    JudgeUploadedVideoPipe,
    JudgeUploadedAudioPipe,
    GetDivisionInformationPipe,
    GetUserInformationPipe,
    VimeoSafeUrlPipe,
    FullPassVerifierPipe,
  ],
  exports: [
    NgforPipe,
    KeysPipe,
    MildSeriousErrorPipe,
    OrderResultsPipe,
    TotalQualificationPipe,
    SortDatePipe,
    SortCalendarPipe,
    SortLocalNotificationPipe,
    UsersSearchPipe,
    FileSizeFormatPipe,
    CartSortPipe,
    SlugifyPipe,
    ValidateDivisionPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    HideEmailPipe,
    GetNameJubgePipe,
    JudgeUploadedVideoPipe,
    JudgeUploadedAudioPipe,
    GetDivisionInformationPipe,
    VimeoSafeUrlPipe
  ],
  imports: [
    IonicModule,
    RouterModule,
    TranslateModule,
    CommonModule,
    FormsModule,
  ],
})
export class PipesModule { }
