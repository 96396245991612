import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { map, debounceTime } from "rxjs/operators";
import { Vibration } from "@ionic-native/vibration/ngx";
import {
  ToastController,
  AlertController,
  Platform,
  LoadingController,
} from "@ionic/angular";
import { CustomizationfileService } from "../customizationfile/customizationfile.service";
import { StorageService } from "../storage/storage.service";
import { CommonService } from "../common/common.service";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";

// ZOOM NATIVO
import { Zoom } from "@ionic-native/zoom/ngx";

// ZOOM WEB
// https://medium.com/lacolaco-blog/angular-dynamic-importing-large-libraries-8ec079603d0
import { normalizeCommonJSImport } from "../../../helpers/normalizeCommonJSImport";
import { environment } from "src/environments/environment";
import { ManageUsersService } from "../manage_users/manage-users.service";

@Injectable({
  providedIn: "root",
})
export class ConferencingService {
  // meeting: any
  groups: any[];
  meetingNumber = "91636894229";
  meetingPassword = "mRn3Jc";
  displayName = "SETTLE";
  participants: any;
  conference_streaming = false;
  connect: boolean;

  connect_a_backstage = new BehaviorSubject({});
  connect_a_stage = new BehaviorSubject({});
  lastAdded: any;

  zooo_options = {
    custom_meeting_id: "SETTLE WORK",
    no_share: false,
    no_audio: false,
    no_video: false,
    no_driving_mode: true,
    no_invite: true,
    no_meeting_end_message: true,
    no_dial_in_via_phone: true,
    no_dial_out_to_phone: true,
    no_disconnect_audio: true,
    no_meeting_error_message: false,
    no_unmute_confirm_dialog: false,
    no_webinar_register_dialog: false,
    no_titlebar: true,
    no_bottom_toolbar: false,
    no_button_video: true,
    no_button_audio: false,
    no_button_share: false,
    no_button_participants: true,
    no_button_more: true,
    no_text_password: true,
    no_text_meeting_id: true,
    no_button_leave: true,
  };
  day: any;
  stage: any;

  constructor(
    public db: AngularFireDatabase,
    public _cf: CustomizationfileService,
    public _cs: CommonService,
    public manageUsersService: ManageUsersService,
    public platform: Platform,
    public alertController: AlertController,
    private zoomService: Zoom,
    public loadingController: LoadingController,
    private router: Router,
    public storage: StorageService
  ) { }

  joinMeetingNative({ meetingNumber, meetingPassword, displayName }) {
    console.log("Going to join meeting");
    console.log({
      meetingNumber,
      meetingPassword,
      displayName,
    });
    // Prepare meeting option
    const options = {
      custom_meeting_id: "SETTLE WORK",
      no_share: false,
      no_audio: false,
      no_video: false,
      no_driving_mode: true,
      no_invite: true,
      no_meeting_end_message: true,
      no_dial_in_via_phone: false,
      no_dial_out_to_phone: false,
      no_disconnect_audio: true,
      no_meeting_error_message: true,
      no_unmute_confirm_dialog: true,
      no_webinar_register_dialog: false,
      no_titlebar: false,
      no_bottom_toolbar: false,
      no_button_video: false,
      no_button_audio: false,
      no_button_share: false,
      no_button_participants: false,
      no_button_more: false,
      no_text_password: true,
      no_text_meeting_id: false,
      no_button_leave: false,
    };
    // Call join meeting method.

    return this.zoomService.joinMeeting(
      meetingNumber,
      meetingPassword,
      displayName,
      options
    );
  }

  async joinMeetingWeb({ meetingNumber, meetingPassword, displayName }) {
    try {
      console.log(1, { meetingNumber, meetingPassword, displayName });
      console.log("load importZoomSDK");
      const importZoomSDK = normalizeCommonJSImport(import("@zoomus/websdk"));

      console.log("importZoomSDK", importZoomSDK);

      const zoom = await importZoomSDK;
      console.log("ZoomMtg", zoom);

      document.getElementById("zmmtg-root").style.display = "block";

      const signature: any = await this.manageUsersService.globalOld({
        url: "zoom/signature",
        data: { meetingNumber, role: 0 },
      });

      console.log("signature", signature);
      console.log("meetingNumber", meetingNumber);
      const profile = this._cf.getProfile().email;
      console.log("profile", profile);

      zoom.ZoomMtg.setZoomJSLib(
        "https://source.zoom.us/1.9.1/lib",
        "/av"
      );


      zoom.ZoomMtg.preLoadWasm();
      zoom.ZoomMtg.prepareJssdk();


      document.getElementById("zmmtg-root").style.display = "block";

      zoom.ZoomMtg.init({
        leaveUrl: `https://settlework.com/members/tabs-video-online/tabs/lives`,
        isSupportAV: true,
        meetingInfo: [
          'topic',
          'host',
        ],
        success: (success) => {
          console.log("init", success);
          console.log("json", {
            signature: signature.results.signature,
            meetingNumber: meetingNumber,
            userName: displayName,
            apiKey: environment.zoom.JWT_API_KEY,
            userEmail: this._cf.getProfile().email,
            passWord: meetingPassword
          })
          zoom.ZoomMtg.join({
            signature: signature.results.signature,
            meetingNumber: meetingNumber,
            userName: displayName,
            apiKey: environment.zoom.JWT_API_KEY,
            userEmail: this._cf.getProfile().email,
            passWord: meetingPassword,
            success: (success) => {
              console.log(success);
            },
            error: (error) => {
              console.log(error);
            },
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
    } catch (err) {
      console.log(err);
    }

    // zoom.ZoomMtg.init({
    //   debug: true, // optional
    //   leaveUrl: "https://settlework.com/members/tabs-video-online/tabs/lives", // required
    //   isSupportAV: true,
    //   meetingInfo: [],
    //   success: (success) => {
    //     console.log(success, {
    //       signature: signature.results.signature,
    //       meetingNumber: meetingNumber,
    //       passWord: meetingPassword,
    //       userName: displayName,
    //       apiKey: environment.zoom.SDK_KEY_SDK,
    //       userEmail: this._cf.getProfile().email,
    //     });
    //     zoom.ZoomMtg.join({
    //       signature: signature.results.signature,
    //       meetingNumber: meetingNumber,
    //       passWord: meetingPassword,
    //       userName: displayName,
    //       apiKey: environment.zoom.SDK_KEY_SDK,
    //       userEmail: this._cf.getProfile().email,

    //       success: (success) => {
    //         console.log(success);
    //       },
    //       error: (error) => {
    //         console.log(error);
    //         this._cs.presentAlert(error.result);
    //         // this.router.navigate(["/members/dashboard"]);
    //         location.reload();
    //       },
    //     });
    //   },
    //   error: (error) => {
    //     console.log(error);
    //     this._cs.presentAlert(error.result);
    //     this.router.navigate(["/members/dashboard"]);
    //     location.reload();
    //   },
    // });
  }

  // async joinMeetingold(method) {
  //   let meeting: any;

  //   if (method == "stage") {
  //     meeting = await this.getDataKeyStraming("stage");
  //   } else if (method == "backstage") {
  //     meeting = await this.getDataKeyStraming("backstage");
  //   } else if (method == "test") {
  //     meeting = await this.getDataKeyStraming("test");
  //   } else if (method == "audience") {
  //     meeting = await this.getDataKeyStraming("audience");
  //   } else {
  //     this._cs.presentAlert("not enabled");
  //     return;
  //   }

  //   const loading = await this.loadingController.create({
  //     spinner: "bubbles",
  //     message: `connected to ${method}`,
  //     backdropDismiss: false,
  //   });
  //   await loading.present();

  //   this.meetingNumber = meeting.meetingNumber;
  //   this.meetingPassword = meeting.meetingPassword;

  //   console.log("method", method);
  //   console.log("meeting", meeting.meeting);
  //   console.log("this.meetingNumber", this.meetingNumber);
  //   console.log("this.meetingPassword", this.meetingPassword);
  //   console.log("this.displayName", this.displayName);

  //   await loading.dismiss();
  //   console.log("joinMeeting", method);
  //   console.log("this._cs.isCordova()", this._cs.isCordova());
  //   if (this._cs.isCordova()) {
  //     this.zoomService
  //       // .joinMeeting(
  //       //   this.meetingNumber,
  //       //   this.meetingPassword,
  //       //   this.displayName,
  //       //   this.zooo_options
  //       // )
  //       .joinMeeting("93551557127", "qXFzm5", "TEST IPAD", this.zooo_options)
  //       .then((success) => {
  //         this._cs.presentToast(success);
  //       })
  //       .catch((error) => {
  //         console.log("error", error);
  //         this._cs.presentToast(error);
  //       });

  //     return;
  //   } else {
  //     console.log("load importZoomSDK");
  //     const importZoomSDK = normalizeCommonJSImport(import("@zoomus/websdk"));

  //     console.log("importZoomSDK", importZoomSDK);

  //     const zoom = await importZoomSDK;
  //     console.log("ZoomMtg", zoom);

  //     zoom.ZoomMtg.setZoomJSLib(
  //       "https://dmogdx0jrul3u.cloudfront.net/1.7.9/lib",
  //       "/av"
  //     );
  //     // console.log("checkSystemRequirements");
  //     // console.log(JSON.stringify(zoom.ZoomMtg.checkSystemRequirements()));
  //     // zoom.preLoadWasm();
  //     // zoom.prepareJssdk();

  //     document.getElementById("zmmtg-root").style.display = "block";

  //     zoom.ZoomMtg.init({
  //       debug: true, // optional
  //       leaveUrl: "https://settlework.com/members/tabs-video-online/tabs/lives", // required
  //       isSupportAV: true,
  //       meetingInfo: [],
  //       success: (success) => {
  //         console.log(success);
  //         zoom.ZoomMtg.join({
  //           signature: meeting.meeting.signature,
  //           meetingNumber: this.meetingNumber,
  //           userName: this.displayName,
  //           apiKey: meeting.meeting.API_KEY,
  //           userEmail: meeting.meeting.email,
  //           passWord: meeting.meeting.password,
  //           success: (success) => {
  //             console.log(success);
  //           },
  //           error: (error) => {
  //             console.log(error);
  //             this._cs.presentAlert(error.result);
  //             this.router.navigate(["/members/dashboard"]);
  //             location.reload();
  //           },
  //         });
  //       },
  //       error: (error) => {
  //         console.log(error);
  //         this._cs.presentAlert(error.result);
  //         this.router.navigate(["/members/dashboard"]);
  //         location.reload();
  //       },
  //     });
  //   }
  // }
}
