import { Component, Input, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { ModalController } from "@ionic/angular";
import { CommonService } from "src/app/services/services";

@Component({
  selector: "app-option",
  templateUrl: "./option.component.html",
  styleUrls: ["./option.component.scss"],
})
export class OptionComponent implements OnInit {
  statusChat = [
    {
      name: "pendiente",
      status: 0,
    },
    {
      name: "urgente",
      status: 1,
    },
    {
      name: "rechazado",
      status: 2,
    },
    {
      name: "cerrar",
      status: 3,
    },
  ];
  @Input() infoChat: any;

  constructor(
    private modalCtrl: ModalController,
    private readonly afs: AngularFirestore,
    public commonService: CommonService
  ) {}

  ngOnInit() {
    console.log("infoChat", this.infoChat);
  }

  closeChat() {}

  async setStatus(event) {
    try {
      console.log("status", event.detail.value);
      await this.afs
        .collection("chat-messages")
        .doc(this.infoChat.uidChat)
        .update({ status: event.detail.value });
    } catch (error) {
      console.log(error);
      this.commonService.presentAlert("ERROR");
    }
  }

  async setDepartamento(event) {
    try {
      console.log("status", event.detail.value);
      await this.afs
        .collection("chat-messages")
        .doc(this.infoChat.uidChat)
        .update({ department: event.detail.value });
    } catch (error) {
      console.log(error);
      this.commonService.presentAlert("ERROR");
    }
  }

  async setCloseChat(event) {
    try {
      console.log("status", event);
      await this.afs
        .collection("chat-messages")
        .doc(this.infoChat.uidChat)
        .update({ close_chat: event });
      this.dismiss();
    } catch (error) {
      console.log(error);
      this.commonService.presentAlert("ERROR");
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
