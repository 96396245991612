import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/services/v1/common/common.service';
import { MediaFilesService } from 'src/app/services/v2/media-files.service';

@Component({
  selector: 'app-input-single-file',
  templateUrl: './input-single-file.component.html',
  styleUrls: ['./input-single-file.component.scss'],
})
export class InputSingleFileComponent implements OnInit {

  @ViewChild('fileUploadInputImage') fileUploadInputImage: ElementRef<HTMLInputElement>;


  @Input() title = 'file';
  @Input() avatar = '';
  @Input() fileName = '';
  @Input() upload = false;
  @Input() folder = 'multimedia';
  @Input() ext = 'pdf';
  @Input() accept = 'application/pdf';
  @Input() size = 5242880;  // 1MB = 1048576 - Default 5 MB bytes
  @Output() upAvatar = new Subject<string>();

  constructor(
    private commonService: CommonService,
    private actionSheetController: ActionSheetController,
    private mediaFileService: MediaFilesService,
  ) { }

  ngOnInit() {
    if (!this.avatar) { this.avatar = ''; }
    // console.log({ avatar: this.avatar });
  }


  /**
   * Selecionar origen de la imagen
   */
  async take() {
    // const device = (this.commonService.isCordova() || this.commonService.isCordovaIOS()) ? true : false;
    const device = false;
    let actionSheetOpts = [];

    if (device) {
      actionSheetOpts = [].concat([
        {
          text: 'Camera',
          icon: 'camera-outline',
          role: 'native',
          handler: () => { this.selectImageFromCamera(); }
        },
        {
          text: 'Galery',
          icon: 'images-outline',
          role: 'native',
          handler: () => { this.selectImageFromDevice(); }
        },
      ]);
    } else {
      actionSheetOpts = [].concat([
        {
          text: 'Document',
          icon: 'folder-outline',
          role: 'web',
          handler: () => { this.fileUploadInputImage.nativeElement.click(); }
        },
      ]);
    }

    actionSheetOpts.push({
      text: 'Cancelar',
      icon: 'close',
      role: 'cancel',
    });

    const actionSheet = await this.actionSheetController.create({
      header: 'Desde',
      cssClass: 'my-custom-class',
      buttons: actionSheetOpts,
    });

    await actionSheet.present();
  }


  /**
   * Tomar desde la camara
   */
  async selectImageFromCamera() { }


  /**
   * Seleccionar desde el dispositivo
   */
  async selectImageFromDevice() { }


  /**
   * Seleccionar desde el navegador
   * @param e
   */
  async selectImageFromBrowser(e: Event) {
    const files: FileList = this.fileUploadInputImage.nativeElement.files;
    console.log({ files });

    /** Si no hay archivo seleccionado */
    if (files.length === 0) { return; }

    const file = files[0];

    /** Validar si coincide el tamaño */
    if(file.size > this.size){
      await this.commonService.presentAlert('El archivo excede el tamaña máximo permitido');
      this.fileUploadInputImage.nativeElement.value = null;
      return;
    }

    try {
      await this.commonService.presentLoading()
      const toBase64 = await this.mediaFileService.getBase64(file);
      this.avatar = toBase64 as string;
      this.fileName = file.name;

      /** Si posee identificador de documento cargamos archivo al servidor reactivamente*/
      if (this.upload) { await this.uploadReactive(); }

      this.fileUploadInputImage.nativeElement.value = null;
      this.upAvatar.next(this.avatar);
    } catch (err) {
      console.log('Error on InputSingleImage@selectImageFromBrowser', err);
    } finally {
      this.commonService.dismissLoading();
    }
  }


  /**
   * Cargar avatar de forma reactiva
   */
  async uploadReactive() {
    const avatarUrl = await this.mediaFileService.uploadFileWithCustomFoler(this.avatar, this.folder, this.ext);
    this.avatar = `${avatarUrl}`;
  }


  /**
   * Remover Avatar
   */
  async remove() {

    /** Si posee identificador de documento eliminar reactivamente*/
    if (this.upload) { await this.removeReactive(); }
    this.avatar = '';

    this.upAvatar.next(this.avatar);
  }


  /**
   * Remover avatar de forma reactiva
   */
  async removeReactive() {
    await this.mediaFileService.removeFileByUrl(this.avatar);
    console.log('eliminar reactivo');
  }


  /**
   * Expandir imagen
   */
  show() { }

}
