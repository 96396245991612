import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/services/services';
import { MultimediaContentService } from 'src/app/services/v2/multimedia-content.service';

import * as JSZip from "jszip";
import * as JSZipUtils from "src/assets/script/jszip-utils.js";
import { saveAs } from 'file-saver';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-event-multimedia-files',
  templateUrl: './event-multimedia-files.component.html',
  styleUrls: ['./event-multimedia-files.component.scss'],
})
export class EventMultimediaFilesComponent implements OnInit, AfterViewInit {

  @ViewChild('btnStart', {read: ElementRef}) btnStart: ElementRef<HTMLButtonElement>;
  @ViewChild('btnDownload', {read: ElementRef}) btnDownload: ElementRef<HTMLButtonElement>;

  @Input() keydb: string = null;

  public dataListFormat = { data: null, list: [], percent: 0, files: 0, videos: 0, images: 0, down: false };
  public dataList = {
    practiceVideoList : Object.assign({}, this.dataListFormat, {
      label: 'practice videos',
      url: 'practiceVideo',
    }),
    testVideoList : Object.assign({}, this.dataListFormat, {
      label: 'test videos',
      url: 'testVideo',
    }),
    multimediaContentList : Object.assign({}, this.dataListFormat, {
      label: 'multimedia content',
      url: 'multimediaContent',
    }),
    mainChoreographyList : Object.assign({}, this.dataListFormat, {
      label: 'main choreogrephys',
      url: 'mainChoreography',
    }),
  };

  public listKeys = Object.keys(this.dataList);

  public processing = false;

  public fileDownloadedCounter = new BehaviorSubject<number>(0);

  constructor(
    private commonService: CommonService,
    public multimediaContentService: MultimediaContentService,
  ) { }

  ngOnInit() {
    console.log({keydb: this.keydb});
  }
  
  ngAfterViewInit(){
    /** Si no se recibe el keydb del evento */
    if(!this.keydb){ this.btnStart.nativeElement.disabled = true; }
    this.btnDownload.nativeElement.disabled = true;
  }


  /**
   * Cargar listado de direcciones de los archivos a descargar
   */
  async loadData(){

    this.btnStart.nativeElement.disabled = true;
    this.processing = true;    

    const toPromise = [];

    for (const key of this.listKeys) {
      toPromise.push( this.getList(key) );
    }

    try {
      await Promise.all(toPromise);

      const filesCount = this.listKeys.map((key) => { return this.dataList[key]['files']; });
      const totalFiles = filesCount.reduce((old, next) => old + next);

      if( totalFiles > 0 ){ this.btnDownload.nativeElement.disabled = false; }

    } catch (err) {
      this.btnStart.nativeElement.disabled = false;
      console.log('Error on EventMultimediaContentFiles@loadData', err);
    }finally{ 
      this.processing = false; 
    }
  }


  /**
   * Obtener listado de archivos de cada categoria
   * @param key Identificador del evento
   * @returns 
   */
  async getList(key: string){
    const toUp = this.dataList[key];

    const data = await this.multimediaContentService.getDataList(this.keydb, toUp.url);

    const categories = Object.keys(data);

    /** No tiene categorias asignadas */
    if(categories.length === 0){ return; }


    const toList = [];

    for (const [categoryKey, categoryData ] of Object.entries(data)) {
      
      for (const [roundKey, roundContent] of Object.entries(categoryData)) {

        for (const row of roundContent as any) {
          toList.push( row );
          if(row.type === 'image') { toUp['images']++; }
          if(row.type === 'video') { toUp['videos']++; }

        }

      }

    }

    toUp['data'] = data;
    toUp['list'] = toList;
    toUp['files'] = toList.length;

    /** Actualizar objeto principal */
    this.dataList[key] = toUp;
  }


  /**
   * Descargar contenido de las categorias de un evento
   * @returns 
   */
  async downloadSelected(){

    /** Validar que seleccione al menos una opción */
    const selected = this.listKeys
      .filter((key) => this.dataList[key]['down'] );

    if(selected.length === 0){ 
      this.commonService.presentAlert('Must to select a category');
      return; 
    }

    /** Calcular cantidad de archivos a descargar */
    const totalFiles = this.listKeys
      .filter((key) => this.dataList[key]['down'] )
      .map((key) => this.dataList[key]['files'] )
      .reduce((old, next) => old + next);

    if(totalFiles === 0){ 
      this.commonService.presentAlert('Must to select a category');
      return; 
    }

    
    /** Confirmar acción de descargar */
    const ask = await this.commonService.presentAlertConfirm({message: `Download <strong>${totalFiles}</strong> files?`});
    if(!ask){ return; }
    
    /** Mostrar spin de procesando */
    this.processing = true;

    /** deshabilitar boton de descarga */
    this.btnDownload.nativeElement.disabled = true;

    const toDown = this.listKeys
    .filter((key) => this.dataList[key]['down'] );

    /** Repetir por cada categoria seleccionada para descargar */
    for (const key of toDown) {

      /** Crear archivo zip */
      const zip = new JSZip();
      let zipName = '';

      const typeName = this.dataList[key]['url'];

      /** Por cada uno de los archivos encontrados dentro de esa categoria */
      const toAwait = [];
      for (const row of this.dataList[key]['list']) {

        /** Definir nombre del archivo a comprimir */
        const fileName = [typeName, row['fileName'],].join('_');

        /** Definir nombre del archivo zip */
        let getName = fileName.split('.').shift();
        zipName = [getName, 'zip'].join('.');

        /** Almacenar promesas */
        toAwait.push( this.getBinaryContent(Object.assign({}, row, {fileName, zipName})) );
        
        // /** Obtener archivo via stream */
        // JSZipUtils.getBinaryContent(row['url'],{
        //   callback: async (err, data) => {

        //     /** Si ocurre algun error */
        //     if (err) { throw err; }

        //     /** Añadir archivo descargado al zip */
        //     zip.file(fileName, data, { binary: true });
        //   }
        // });

      }

      const result = await Promise.all(toAwait);

      for (const record of result) {
        /** Añadir archivo descargado al zip */
        zip.file(record['fileName'], record['binary'], { binary: true });
      }

      /** Generar archivo zip */
      const content = await zip.generateAsync({ type: "blob" });

      /** Guardar zip */
      saveAs(content, key);

    }

    /** Ocultar spin de procesandos */
    this.processing = false;

    /** habilitar boton para descargar */
    this.btnDownload.nativeElement.disabled = false;
  }


  async getBinaryContent(record: any){
    return new Promise((resolve, reject) => {
      /** Obtener archivo via stream */
      JSZipUtils.getBinaryContent(record['url'], {
        callback: async (err, data) => {

          /** Si ocurre algun error */
          if (err) { return reject(err); }


          /** Incrementar contador */
          let old = Number(this.fileDownloadedCounter.getValue());
          this.fileDownloadedCounter.next(old++);

          /** retornar contenido */
          return resolve(Object.assign({},record, {binary: data}));
        }
      });
    });
  }

  get downloadCounter(){
    return Number(this.fileDownloadedCounter.getValue());
  }

}
