import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { CommonService } from "src/app/services/services";
import { saveAs } from "file-saver";

@Component({
  selector: "app-see-video",
  templateUrl: "./see-video.component.html",
  styleUrls: ["./see-video.component.scss"],
})
export class SeeVideoComponent implements OnInit {
  data: any;
  isPhoto: any;
  constructor(
    public commonService: CommonService,
    public modalController: ModalController,
    private navParams: NavParams
  ) {
    this.data = this.navParams.get("value");
    console.log("this.data.data", this.data);
    this.isPhoto = Array.isArray(this.data.url);
    console.log("this.data.url", this.data.url);
    console.log("this.isPhoto", this.isPhoto);
  }

  ngOnInit() {}

  dismiss(status, message?) {
    this.modalController.dismiss({
      dismissed: true,
      status,
      message,
    });
  }
  onPlayerReady($event) {}

  async download() {
    await this.commonService.presentLoading();
    if (!this.isPhoto) {
      await this.commonService
        .downloadFile({ url: this.data.url })
        .subscribe((response) => {
          this.getDownload({
            response,
            MIME_types: "video/mp4",
            ext: "mp4",
          });
          this.commonService.dismissLoading();
        }),
        (error) => console.log("Error downloading the file"),
        () => console.info("File downloaded successfully");
    } else {
      this.data.url.map(async (img) => {
        console.log("img", img);

        await this.commonService
          .downloadFile({ url: img })
          .subscribe((response) => {
            this.getDownload({
              response,
              MIME_types: "image/png",
              ext: "png",
            });

            this.commonService.dismissLoading();
          }),
          (error) => console.log("Error downloading the file"),
          () => console.info("File downloaded successfully");
      });
    }
  }

  getDownload({ response, MIME_types, ext }) {
    let blob: any = new Blob([response], {
      type: MIME_types,
    });
    return saveAs.saveAs(blob, `${this.data.nameVideo}-${Date.now()}.${ext}`);
  }
}
