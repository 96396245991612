import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { CommonService } from '../common/common.service';
import { CustomizationfileService } from '../customizationfile/customizationfile.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationPushService {
  public uid: any;
  public categoryPush = [];
  public url = environment.urlrootFunctions;
  constructor(
    public db: AngularFireDatabase,
    private http: HttpClient,
    public _cs: CommonService,
    public _cf: CustomizationfileService
  ) {
  }

  getCategory() {
    return new Promise(async resolve => {
      if (this._cf.getUid()) {
        const url = `competition/${this._cs.key}/participate_category`;
        const itemsRef = this.db.list(url, ref =>
          ref
            .orderByChild('uid')
            .startAt(this._cf.getUid())
            .endAt(this._cf.getUid())
        );

        // Obtenemos todos los cursos
        itemsRef
          .snapshotChanges()
          .pipe(
            map(changes =>
              changes.map(c => ({ key: c.payload.key, ...c.payload.val() as any }))
            )
          )
          .subscribe(category => {
            if (category) {
              this.registeTopic(category).then(async (data: any) => {
                const result = await this.removeDuplicates(data);
                resolve(result);
              });
            }
          });
      } else {
        resolve({});
      }
    });
  }



  allCategory(): Observable<any[]> {
    return this.db.list(`categoriesenabled/${this._cf.getKeyDb()}`).snapshotChanges()
      .pipe(
        map(res => {
          return res.map(element => {
            const user = element.payload.toJSON();
            user['$key'] = element.key;
            return user as any;
          });
        }));
  }

  removeDuplicates(data) {
    if (data) {
      const result = data.filter(
        (thing, index, self) =>
          index ===
          self.findIndex(t => t.name === thing.name && t.code === thing.code)
      );
      return result;
    }
  }

  getToken() {
    return localStorage.getItem('tokenPush');
  }

  registeTopic(category) {
    return new Promise(async resolve => {
      if (category) {
        const categoryPush = [];
        await category.forEach(x => {
          x.categories.forEach(category => {
            const result = {
              name: category.category_detail.sub_categoria,
              code: category.category_detail.id_sub_categoria,
              isChecked: true
            };
            categoryPush.push(result);
          });
        });
        resolve(categoryPush);
      }
    });
  }

}
