import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, AlertController, ModalController } from '@ionic/angular';
import { CommonService, StorageService, LanguageService } from 'src/app/services/services';
import { Html2canvasService } from 'src/app/services/v2/html2canvas.service';
import { ImageCropperComponent } from 'src/app/components/image-cropper/image-cropper.component';
import { MediaFilesService } from 'src/app/services/v2/media-files.service';
import { SocialSharedService } from 'src/app/services/v2/social-shared.service';
import { SocialSharingService } from 'src/app/services/v2/social-sharing.service';

@Component({
  selector: 'app-view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.scss'],
})
export class ViewFileComponent implements OnInit {
  viewpost: boolean;
  viewStorie: boolean;
  @Input() details: any;
  text = "Our primary goal is to redefine the way we do competitions in the Future. Our second goal is to make it easier for anyone to join competitions and get a feel of being a competitor."
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  selectedFileBLOB: any;
  selectedFileDownload: any;
  toBase64Donwload: any;
  getDownloadPath: any;
  yourImageDataURLBase64: unknown;
  isCordova: boolean;
  @ViewChild('fileUploadInput') fileUploadInput: ElementRef<HTMLInputElement>;

  constructor(
    public mediaFilesService: MediaFilesService,
    public actionSheetController: ActionSheetController,
    private modalCtrl: ModalController,
    public commonService: CommonService,
    public html2canvas: Html2canvasService,
    public storageService: StorageService,
    public socialSharingService: SocialSharingService,
    public socialSharedService: SocialSharedService,
    public languageService: LanguageService,
    public alertController: AlertController) {
    this.isCordova = this.commonService.isCordova();
    let imgBase64 = this.storageService.localStorageGetItem("yourImageDataURLBase64")
    this.yourImageDataURLBase64 = imgBase64 ? imgBase64 : null;
  }

  async ngOnInit() {
    this.presentAlertDoYouWantToAddYourPhoto()
    this.getDownloadPath = await this.socialSharedService.getDownloadPath()

  }

  getfile() {
    switch (this.details.file) {
      case 'pdf': this.getCertificate(); break;
      default: this.getDiploma(); break;
    }
  }

  async presentAlertDoYouWantToAddYourPhoto() {
    const alert = await this.alertController.create({
      message: this.languageService.getTranslation("do you want to add your photo"),
      buttons: [
        {
          text: this.languageService.getTranslation("NO"),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
            this.getfile()
          }
        }, {
          text: this.languageService.getTranslation("YES"),
          handler: () => {
            let imagen = this.isCordova ? this.loadImageFromDeviceCordova() : this.fileUploadInput.nativeElement.click();
          }
        }
      ]
    });

    await alert.present();


  }

  deleteImage() {
    this.yourImageDataURLBase64 = null;
    this.storageService.localStorageRemoveItem("yourImageDataURLBase64")
  }


  async loadImageFromDevice(event?) {
    try {

      // await this.closeModal()
      const modal = await this.modalCtrl.create({
        component: ImageCropperComponent,
        swipeToClose: false,
        backdropDismiss: false,
        componentProps: {
          imageChangedEvent: event,
        },
      });

      modal.onDidDismiss().then(async (dataReturned) => {
        if (dataReturned !== null) {
          if (dataReturned.data.status === 1) {
            this.yourImageDataURLBase64 = dataReturned.data.message
            this.details.userImage = this.yourImageDataURLBase64;
            this.getfile()
            return
          }
        }
      });

      return await modal.present();
    } catch (err) {
      console.log("error", err)
      this.commonService.presentAlertTranslation("ha ocurrido un error desconocido")
    }

  };


  async loadImageFromDeviceCordova() {
    try {
      const captureImagenUrl: any = await this.mediaFilesService.pickImages({
        message: "CILIK",
        outputType: 0,
        quality: 100,
        maximumImagesCount: 1,
        allow_video: false,
        disable_popover: true,
        enabledCopyFileToLocalDir: false
      })
      let pathToImage = captureImagenUrl[0]

      console.warn("pathToImage", pathToImage);

      const cropImagen: any = await this.mediaFilesService
        .cropImagen({ pathToImage, quality: 75, targetWidth: -1, targetHeight: -1, base64: true })
      console.warn("cropImagen", cropImagen);

      this.yourImageDataURLBase64 = await this.mediaFilesService.showCroppedImage(cropImagen.split('?')[0])
      // this.storageService.localStorageSetItem("yourImageDataURLBase64", this.yourImageDataURLBase64)
      this.details.userImage = this.yourImageDataURLBase64;
      this.getfile()
    } catch (err) {
      console.log("error", err)
      this.commonService.presentAlertTranslation("ha ocurrido un error desconocido")
    }

  }




  /**
   * Obtener certificado PDF
   */
  async getCertificate() {
    const route = 'pdf/generateCertificate';
    console.log("url", route);
    try {
      await this.commonService.presentLoading()
      const streamFile = await this.socialSharedService.getFile({ route, data: this.details })
        .catch(err => {
          console.log(err)
          this.closeModal()
          this.commonService.presentAlertTranslation("ha ocurrido un error desconocido")
        })
      console.log("res", streamFile);
      this.selectedFileDownload = streamFile;
      await this.downloadPDf();
      this.commonService.dismissLoading()
      this.closeModal();

    } catch (err) {
      console.log('err', err)
    }
  }


  /**
   * Descargar documento PDF
   * @returns
   */
  async downloadPDf() {
    const fileName = [this.details.codeDivision, this.details.participantName, this.details.type].join("-");
    return await this.socialSharedService
      .downloadBlodWeb({
        response: this.selectedFileDownload,
        MIME_types: "application/pdf",
        ext: "pdf",
        name: `${fileName}.pdf`
      })
  }


  /**
   * Obtener diploma JPEG
   */
  async getDiploma() {
    this.commonService.presentLoading();
    const route = 'pdf/generateDiploma';
    console.log("url", route);
    try {
      let streamFile = await this.socialSharedService.getFile({ route, data: this.details })
        .catch(err => {
          console.log(err)
          this.closeModal()
          this.commonService.presentAlertTranslation("ha ocurrido un error desconocido")
        })
      /* Convertir buffer a blob */
      streamFile = await this.socialSharedService.bufferToBlob(streamFile, 'image/jpeg');
      this.selectedFileDownload = streamFile;
      /* Convertir blob a base64 */
      const toBase64 = await this.socialSharedService.blobTob64(streamFile);
      this.toBase64Donwload = toBase64
      // this.canvas.nativeElement.src = toBase64;
      // this.canvas.nativeElement.id = this.details.type;
      this.commonService.dismissLoading();
    } catch (err) {
      console.log('err', err)
      this.commonService.dismissLoading();
      this.closeModal();
    }
  }


  /**
   * Descargar imagen JPEG
   * @returns
   */
  async downloadImg() {
    console.log("downloadImg()")
    try {
      await this.commonService.presentLoading();
      console.log("download");
      const fileName = [this.details.codeDivision, this.details.participantName, this.details.type].join('-');
      const MIME_types = 'image/jpeg';
      const ext = "jpeg";

      /* Si es Cordova */
      if (this.commonService.isCordova()) {
        const result = await this.socialSharedService
          .saveCanvasImage(this.mediaFilesService.validBase64(this.toBase64Donwload))
        console.log("result", result)
        this.commonService.dismissLoading();
        this.commonService.presentAlertTranslation("guardado en galería");
        return;
      }

      this.commonService.dismissLoading();
      return await this.socialSharedService.downloadBlodWeb({
        MIME_types,
        ext,
        name: fileName,
        response: this.selectedFileDownload,
      });
    } catch (err) {
      console.log('err', err)
      this.commonService.presentAlertTranslation("ha ocurrido un error desconocido")
      this.commonService.dismissLoading();
    }

  }


  /**
   * Compartir contenido
   * TODO: Validar comportamiento
   * @returns
   */
  async shared() {
    try {
      console.log("shared")
      if (!this.commonService.isCordova()) {
        return
      }

      const result: any = await this.mediaFilesService.savePicture(this.toBase64Donwload);
      let file = result.nativeURL

      const sharedResult = await this.socialSharingService
        .ShareGeneric({ message: this.text, subject: "settle work", file })
      console.log("sharedResult", sharedResult)

    } catch (err) {
      console.log('err', err)
      this.commonService.presentAlertTranslation("ha ocurrido un error desconocido")
      this.commonService.dismissLoading();
    }




  }




  async closeModal() {
    await this.modalCtrl.dismiss();
  }

}


function ViewQuery(arg0: string) {
  throw new Error('Function not implemented.');
}

