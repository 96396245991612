import { Component, Input, OnInit } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { Observable } from "rxjs";
import * as firebase from "firebase";

@Component({
  selector: "app-view",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"],
})
export class ViewComponent implements OnInit {
  private countDoc: AngularFirestoreDocument<any>;
  count: Observable<any>;
  @Input() idVideo: any;
  @Input() watch_id: any;
  constructor(public afs: AngularFirestore) { }

  ngOnInit() {
    console.log("idVideo", this.idVideo);
    console.log("watch_id", this.watch_id);
    this.getCount();

    setTimeout(() => {
      this.displayCounter();
    }, 60000);
  }

  displayCounter() {
    console.log("displayCounter");
    console.log("this.watch_id", this.watch_id);
    let pinDocRef = firebase
      .firestore()
      .collection("chapters-counter")
      .doc(this.idVideo);

    return firebase
      .firestore()
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(pinDocRef).then((pinDoc) => {
          if (!pinDoc.exists) {
            firebase
              .firestore()
              .collection("chapters-counter")
              .doc(this.idVideo)
              .set({ count: 1 });
            throw "Document does not exist!";
          }

          let newPinScore = pinDoc.data() ? pinDoc.data().count + 1 : 1;
          transaction.update(pinDocRef, { count: newPinScore });
        });
      })
      .then(() => {
        console.log("Transaction successfully committed!");
      })
      .catch((err) => {
        console.log("Transaction failed: ", err);
      });
  }

  getCount() {
    this.countDoc = this.afs.doc<any>(`/chapters-counter/${this.idVideo}`);
    this.count = this.countDoc.valueChanges();
  }
}
