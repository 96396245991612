import { HttpClientModule } from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { registerLocaleData } from "@angular/common";

import { BrowserModule } from "@angular/platform-browser";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage";
import { Vibration } from "@ionic-native/vibration/ngx";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { FormsModule } from "@angular/forms";

import { HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";



import {
  AngularFireDatabaseModule,
} from "@angular/fire/database";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { SharedModule } from "./shared/shared.module";
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Camera } from "@ionic-native/camera/ngx";
import { AppRate } from "@ionic-native/app-rate/ngx";
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { Base64 } from "@ionic-native/base64/ngx";
import { Crop } from "@ionic-native/crop/ngx";
import { FileTransfer } from "@ionic-native/file-transfer/ngx";
import { Base64ToGallery } from "@ionic-native/base64-to-gallery/ngx";
import { Globalization } from "@ionic-native/globalization/ngx";
import { Firebase } from "@ionic-native/firebase/ngx";
import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { Facebook } from "@ionic-native/facebook/ngx";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { NativeGeocoder } from "@ionic-native/native-geocoder/ngx";
import { CalendarModule } from "ion2-calendar";
import { Zoom } from "@ionic-native/zoom/ngx";
import { Network } from "@ionic-native/network/ngx";
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { IonicSelectableModule } from "ionic-selectable";

import {
  LazyLoadImageModule,
  LAZYLOAD_IMAGE_HOOKS,
  ScrollHooks,
} from "ng-lazyload-image";
import { ImagePicker } from "@ionic-native/image-picker/ngx";
import { MediaCapture } from "@ionic-native/media-capture/ngx";
import { Media } from "@ionic-native/media/ngx";
import { StreamingMedia } from "@ionic-native/streaming-media/ngx";
import { PhotoViewer } from "@ionic-native/photo-viewer/ngx";
import { File } from "@ionic-native/file/ngx";
import { InAppPurchase2 } from "@ionic-native/in-app-purchase-2/ngx";
import { VgCoreModule } from "@videogular/ngx-videogular/core";
import { VgControlsModule } from "@videogular/ngx-videogular/controls";
import { VgOverlayPlayModule } from "@videogular/ngx-videogular/overlay-play";
import { VgBufferingModule } from "@videogular/ngx-videogular/buffering";

// importar locales
// https://medium.com/dailyjs/dynamic-locales-in-angular-dd9a527ebe1f
import localeCO from "@angular/common/locales/es-CO";
import localeEnUS from "@angular/common/locales/en-US-POSIX";
import { MediaFilesService } from "./services/v2/media-files.service";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { RouteReuseStrategy } from "@angular/router";
import { TranslationService } from "./services/v2/translation-service.service";
import { AgmCoreModule } from "@agm/core";
import { customAnimation } from "./_animations/custom.animation";
import { ClipboardModule } from 'ngx-clipboard';


registerLocaleData(localeCO, "es");
registerLocaleData(localeEnUS, "en");

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    FormsModule,
    ClipboardModule,
    HttpClientModule,
    CalendarModule,
    AngularFireStorageModule,
    IonicSelectableModule,
    LazyLoadImageModule,
    IonicModule.forRoot({ navAnimation: customAnimation }),
    IonicStorageModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    AngularFirestoreModule.enablePersistence(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationService,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({ apiKey: environment.googleMapsAPIKey }),
  ],
  providers: [
    InAppBrowser,
    SplashScreen,
    StatusBar,
    Camera,
    BarcodeScanner,
    Firebase,
    AppRate,
    OneSignal,
    Base64,
    Crop,
    FileTransfer,
    GooglePlus,
    Facebook,
    Base64ToGallery,
    Globalization,
    Geolocation,
    NativeGeocoder,
    Zoom,
    Network,
    ImagePicker,
    MediaCapture,
    Media,
    StreamingMedia,
    PhotoViewer,
    Vibration,
    File,
    MediaFilesService,
    InAppPurchase2,
    SocialSharing,
    DocumentViewer,
    AndroidPermissions,
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: "es" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
