import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { CustomizationfileService } from '../customizationfile/customizationfile.service';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public cartState = new BehaviorSubject([]);

  constructor(
    public db: AngularFireDatabase,
    private afs: AngularFirestore,
    public _cf: CustomizationfileService) {
    this.getCart();
  }


  getCart() {
    const uid = this._cf.getUid();
    if (uid) {
      return this.afs.collection('cart')
        .doc(this._cf.getUid())
        .collection('product')
        .snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          }))
        )
        .subscribe((data: any) => {
          if (data.length == 0) {
            this.cartState.next([]);
          } else {
            this.cartState.next(data);
          }
        });
    }
  }

  getCoupon({ coupon }) {
    return new Promise((resolve, reject) => {
      this.db.list(`coupon`, (ref) =>
        ref
          .orderByChild("code_coupon")
          .startAt(coupon)
          .endAt(coupon)
      )
        .snapshotChanges()
        .pipe(map(items => {             // <== new way of chaining
          return items.map(a => {
            const data = a.payload.val();
            const key = a.payload.key;
            return Object.assign(data, { key: key });           // or {key, ...data} in case data is Obj
          });
        })).subscribe((res: any) => {
          const result = res[0];
          console.warn('res', res)
          console.warn('result', result)
          console.warn('res.length', res.length)
          console.warn('result.enabled', result.enabled)
          if (res.length == 0 || !result.enabled) {
            reject({})
          }
          resolve(result)
        })
    })
  }

  delete(id) {
    return this.afs
      .collection('cart')
      .doc(this._cf.getUid())
      .collection('product')
      .doc(id)
      .delete();
  }

}
