import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomizationfileService } from '../services';
const url_root: any = environment.urlrootFunctions;

@Injectable({
  providedIn: 'root'
})
export class JudgesService {

  constructor(private http: HttpClient, public customizationfileService: CustomizationfileService,) { }

  judgesReport({ route, data }) {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${url_root}/v2/${route}`).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  resultReport({ route, data }) {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${url_root}/v2/${route}`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  downloadReport({ route, data }) {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${url_root}/v2/${route}`, data, {
        responseType: 'arraybuffer'
      })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  /**
  * Method is use to download file.
  * @param data - Array Buffer data
  * @param type - type of the document.
  */
  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });
    let fileURL = window.URL.createObjectURL(blob);
    // let pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //   alert('Please disable your Pop-up blocker and try again.');
    // }

    var linkToFile = document.createElement('a');
    linkToFile.download = `report-${Date.now()}.xlsx`;
    linkToFile.href = fileURL;
    linkToFile.click();

  }
}
