import { Component, Input, OnInit } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { AngularFireDatabase } from "angularfire2/database";
import {
  CommonService,
  CustomizationfileService,
} from "src/app/services/services";
import { MediaFilesService } from "src/app/services/v2/media-files.service";
import { ScrollEventService } from "src/app/services/v2/scroll-event.service";
import { SeeVideoComponent } from "../../../components/see-video/see-video.component";

@Component({
  selector: "app-multimedia-content",
  templateUrl: "./multimedia-content.component.html",
  styleUrls: ["./multimedia-content.component.scss"],
})
export class MultimediaContentComponent implements OnInit {
  @Input() data: any;
  @Input() keyDivision: string;
  @Input() round: string;
  @Input() code: string;
  @Input() dataGroups: any;
  @Input() participarte: string;
  @Input() keydb: string;
  name: any;
  files: any;

  constructor(
    public customizationfileService: CustomizationfileService,
    public mediaFilesService: MediaFilesService,
    public scrollEventService: ScrollEventService,
    public alertController: AlertController,
    public db: AngularFireDatabase,
    public commonService: CommonService,
    public modalController: ModalController
  ) {}

  ngOnInit() {
    console.log("data", this.data);
    this.name = this.dataGroups
      ? this.dataGroups.nameGroups
      : this.participarte;
  }

  async presentAlertCheckboxChangeStatus() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "cambiar estado",
      inputs: [
        {
          name: "value",
          type: "radio",
          label: "accept",
          value: 3,
          checked: true,
        },
        {
          name: "value",
          type: "radio",
          label: "pending",
          value: 1,
        },
        {
          name: "value",
          type: "radio",
          label: "reject",
          value: 2,
        },
        {
          name: "value",
          type: "radio",
          label: "restart",
          value: 0,
        },
      ],
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "Ok",
          handler: async (value) => {
            await this.commonService.presentLoading();
            await this.data.map(async (x, index) => {
              if (x.isChecked) {
                console.log("isChecked", x.isChecked);
                console.log("index", index);
                console.log("value", value);
                const url = `/competition/${this.keydb}/multimediaContent/${this.code}/${this.round}/${this.keyDivision}/${index}/state`;
                await this.db.object(url).update({
                  comment: "admin",
                  comments: "admin",
                  admin: this.customizationfileService.getprofile(),
                  step: value,
                });
              }
            });
            this.commonService.dismissLoading();
            this.scrollEventService.updateAdminEvent.next(true);
          },
        },
      ],
    });

    await alert.present();
  }

  selected($event) {
    console.log("$event", $event.detail.checked);
    this.data = this.data.map((x) => {
      return Object.assign(x, { isChecked: $event.detail.checked });
    });
  }

  async skipStep() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "saltar el paso 3",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Ok",
          handler: async () => {
            await this.commonService.presentLoading();
            await this.db
              .object(
                `/competition/${this.keydb}/division/${this.keyDivision}/states`
              )
              .update({ step2: 3 });
            this.scrollEventService.updateAdminEvent.next(true);
            this.commonService.dismissLoading();
          },
        },
      ],
    });

    await alert.present();
  }

  async uploadFile(event) {
    try {
      await this.commonService.presentLoading();
      this.files = event.target.files;
      if (Object.keys(this.files).length < 4) {
        this.commonService.dismissLoading();
        this.commonService.presentAlert("min 5 photo");
        return;
      }

      let promiseBase64List = [];
      console.log("this.files", this.files);

      Object.keys(this.files).map(async (index) => {
        promiseBase64List.push(
          this.mediaFilesService.getBase64(this.files[index])
        );
      });

      const resultBase64 = await Promise.all(promiseBase64List);
      console.log("resultBase64", resultBase64);

      let listPromise = [];
      resultBase64.forEach((img) => {
        listPromise.push(this.mediaFilesService.uploadFile(img));
      });

      const resultUrlImg = await Promise.all(listPromise);
      console.log("result img ", resultUrlImg);

      const url = `/competition/${this.keydb}/multimediaContent/${this.code}/${this.round}/${this.keyDivision}/0/state`;
      await this.db.object(url).update({
        video: resultUrlImg,
      });

      await this.status({
        status: 3,
        comment: "photos uploaded by admin",
        index: 0,
      });

      this.scrollEventService.updateAdminEvent.next(true);
      this.commonService.dismissLoading();
      // console.log("result", resultBase64);
    } catch (err) {
      this.commonService.dismissLoading();
      this.commonService.presentAlert(JSON.stringify(err));
    }
  }

  async uploadFileVideo($event, index) {
    try {
      await this.commonService.presentLoading();
      console.log("$event", $event);
      this.files = $event.target.files;
      const videoBase64 = await this.mediaFilesService.getBase64(this.files[0]);
      const videoUrl = await this.mediaFilesService.uploadFile(videoBase64);
      const url = `/competition/${this.keydb}/multimediaContent/${this.code}/${this.round}/${this.keyDivision}/${index}/state`;
      console.log("url", url);
      await this.db.object(url).update({
        video: videoUrl,
        comment: "video uploaded by admin",
        comments: "video uploaded by admin",
        admin: this.customizationfileService.getprofile(),
        step: 3,
      });

      this.commonService.dismissLoading();
      this.scrollEventService.updateAdminEvent.next(true);
    } catch (err) {
      this.commonService.dismissLoading();
      this.commonService.presentAlert(JSON.stringify(err));
    }
  }

  async upload(imageURI) {
    return new Promise(async (resolve, reject) => {
      console.log("upload");
      return await this.mediaFilesService
        .uploadFile(imageURI)
        .then(async (urlVideo) => {
          resolve(urlVideo);
        })
        .catch((err) => {
          reject(err);
          console.log("upload", err);
          this.commonService.dismissLoading();
          this.commonService.presentAlert(JSON.stringify(err));
        });
    });
  }

  async presentModal(data) {
    const modal = await this.modalController.create({
      component: SeeVideoComponent,
      cssClass: "my-custom-modal-css",
      componentProps: {
        value: {
          name: data.question,
          nameVideo: `${this.code}-${this.round}-${this.name}-test-videos-${this.keyDivision}`,
          url: data.state.video,
          code: this.code,
          key: this.keyDivision,
        },
      },
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        if (dataReturned.data.status === 1) {
        }
      }
    });

    return await modal.present();
  }

  changeStatus(status, index) {
    switch (status) {
      case 1:
        this.presentAlertPrompt({ status, title: "pending", index });
        break;
      case 2:
        this.presentAlertPrompt({ status, title: "reject", index });
        break;
      case 3:
        this.presentAlertPrompt({ status, title: "accepted", index });
        break;
      case 0:
        this.presentAlertPrompt({ status, title: "restart", index });
        break;
    }
  }

  async presentAlertPrompt({ status, title, index }) {
    const alert = await this.alertController.create({
      header: title,
      inputs: [
        {
          name: "value",
          type: "textarea",
          placeholder: "ingresar comentario",
        },
      ],
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "Ok",
          handler: (data) => {
            if (status === 3) {
              const c = data.value.length > 0 ? data.value : "accepted";
              this.status({ status, comment: c, index });
            } else if (data.value.length > 0) {
              this.status({ status, comment: data.value, index });
            } else {
              this.commonService.presentAlert("debes agregar un mensaje");
            }
          },
        },
      ],
    });

    await alert.present();
  }

  async status({ status, comment, index }) {
    console.log({ status, comment, index });
    await this.commonService.presentLoading();
    const url = `/competition/${this.keydb}/multimediaContent/${this.code}/${this.round}/${this.keyDivision}/${index}/state`;
    console.log("url", url);
    await this.db
      .object(url)
      .update({
        comment: comment,
        comments: comment,
        step: status,
        admin: this.customizationfileService.getprofile(),
      })
      .then(() => {
        console.log("update");
        this.scrollEventService.updateAdminEvent.next(true);
        this.commonService.dismissLoading();
      });
  }

  showMultimedia(index2, array) {
    for (let index = 0; index < this[array].length; index++) {
      if (index === index2) {
        this[array][index].show = !this[array][index].show;
      } else {
        this[array][index].showComment = false;
        this[array][index].show = false;
      }
    }
  }
}
