import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";

@Component({
  selector: "app-result-view",
  templateUrl: "./result-view.component.html",
  styleUrls: ["./result-view.component.scss"],
})
export class ResultViewComponent implements OnInit {
  data: any;
  name: any;
  constructor(
    public modalController: ModalController,
    private navParams: NavParams
  ) {
    this.data = this.navParams.get("details");
    this.name = this.navParams.get("name");
    console.log("details", this.data);
  }

  ngOnInit() {}

  dismiss(status, message?) {
    this.modalController.dismiss({
      dismissed: true,
      status,
      message,
    });
  }
}
