import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'vimeoSafeUrl'
})
export class VimeoSafeUrlPipe implements PipeTransform {

  constructor(private readonly sanitizer: DomSanitizer) { }

  public transform(url: string): SafeResourceUrl {
    if (!url) { return }
    console.log("url", url)
    console.log("parseVimeo", this.parseVimeo(url))
    const urlFinal: any = this.parseVimeo(url) !== null ? this.parseVimeo(`${url}?autoplay=1&loop=1&muted=1&title=false`) : false
    if (urlFinal) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(urlFinal);
    }
    return false
  }

  parseVimeo(url) {
    const re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
    const matches = re.exec(url);
    return matches && "https://player.vimeo.com/video/" + matches[1];
  }

}
