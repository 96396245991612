import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Pipe({
  name: 'getUserInformation'
})
export class GetUserInformationPipe implements PipeTransform {

  constructor(public db: AngularFireDatabase) { }
  async transform(uid: any) {
    const name = await this.getUser(uid)
    return name;
  }

  getUser(uid) {
    return new Promise((resolve) => {
      this.db
        .object(`/users/${uid}`)
        .valueChanges()
        .subscribe((x: any) => {
          if (x) {
            resolve(x);
          } else {
            resolve("N/A");
          }
        });
    });
  }

}
