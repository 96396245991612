import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PipesModule } from "../pipes/pipes.module";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { VgCoreModule } from "@videogular/ngx-videogular/core";
import { VgBufferingModule } from "@videogular/ngx-videogular/buffering";
import { VgControlsModule } from "@videogular/ngx-videogular/controls";
import { VgOverlayPlayModule } from "@videogular/ngx-videogular/overlay-play";
import { IonicSelectableModule } from "ionic-selectable";
import { ImageCropperModule } from 'ngx-image-cropper';

// Component
import { SidebarMenuComponent } from "../shared/sidebar-menu/sidebar-menu.component";
import { ExpandableComponent } from "../components/expandable/expandable.component";
import { ScheduleComponent } from "../components/qr-modal/schedule/schedule.component";
import { ParticipantsComponent } from "../components/qr-modal/participants/participants.component";
import { SkeletonComponent } from "../shared/skeleton/skeleton.component";
import { InformationComponent } from "../components/information/information.component";
import { NotificationViewComponent } from "../components/notification-view/notification-view.component";
import { MapWelcomeComponent } from "../components/map-welcome/map-welcome.component";
import { AllEventsComponent } from "./all-events/all-events.component";
import { RecordComponent } from "../components/record/record.component";
import { UserInfoVideoComponent } from "../components/user-info-video/user-info-video.component";

// Component - WELCOME
import { MainSlidesComponent } from "../pages/members/welcome/main-slides/main-slides.component";
import { MainEventsComponent } from "../pages/members/welcome/main-events/main-events.component";
import { FilterByContinentsComponent } from "../pages/members/welcome/filter-by-continents/filter-by-continents.component";
import { SecondaryBannerComponent } from "../pages/members/welcome/secondary-banner/secondary-banner.component";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { UsersComponent2 } from "../pages/members/purchases/division-purchase/users/users.component";
import { RecordEventWelcomeComponent } from "../pages/members/welcome-event/record-event-welcome/record-event-welcome.component";
import { CustomVideosComponent } from "../pages/members/tabs-video-online/pages/home/components/custom-videos/custom-videos.component";
import { TestVideosComponent } from "../pages/members/tabs-video-online/pages/home/components/test-videos/test-videos.component";
import { MainChoreographyComponent } from "../pages/members/tabs-video-online/pages/home/components/main-choreography/main-choreography.component";
import { RateErrorsComponent } from "../admin/judge/components/rate-errors/rate-errors.component";
import { RateItemsComponent } from "../admin/judge/components/rate-items/rate-items.component";
import { FinalResultsModalComponent } from "../admin/result/components/final-results-modal/final-results-modal.component";
import { PracticeMyChoreographyComponent } from "../pages/members/tabs-video-online/pages/home/components/practice-my-choreography/practice-my-choreography.component";
import { MainChoreographyAdminComponent } from "../admin/videos-division/components/main-choreography-admin/main-choreography-admin.component";
import { MultimediaContentComponent } from "../admin/videos-division/components/multimedia-content/multimedia-content.component";
import { PracticeVideoListComponent } from "../admin/videos-division/components/practice-video-list/practice-video-list.component";
import { TestVideosAdminComponent } from "../admin/videos-division/components/test-videos-admin/test-videos-admin.component";
import { SeeVideoComponent } from "../admin/components/see-video/see-video.component";

import { JudgesQualificationsComponent } from "../admin/judge/components/judges-qualifications/judges-qualifications.component";
import { OpenACaseComponent } from "../pages/members/tabs-video-online/pages/complaints/components/open-acase/open-acase.component";
import { RejectedCasesComponent } from "../pages/members/tabs-video-online/pages/complaints/components/rejected-cases/rejected-cases.component";
import { ResolvedCasesComponent } from "../pages/members/tabs-video-online/pages/complaints/components/resolved-cases/resolved-cases.component";
import { OpenCaseAModalComponent } from "../pages/members/tabs-video-online/pages/complaints/components/open-case-amodal/open-case-amodal.component";
import { SeeComplaintsComponent } from "../pages/members/tabs-video-online/pages/complaints/components/see-complaints/see-complaints.component";
import { ListResultDivisionComponent } from "../admin/result/list-result-division/list-result-division.component";
import { RecordAudioComponent } from "./record-audio/record-audio.component";
import { ModalComplaintsComponent } from "../admin/complaints/components/modal-complaints/modal-complaints.component";
import { ResultViewComponent } from "../admin/result/components/result-view/result-view.component";
import { AddSerieComponent } from "../admin/admin-settle-tv/components/add-serie/add-serie.component";
import { AddChaptersComponent } from "../admin/admin-settle-tv/components/add-chapters/add-chapters.component";
import { UpdateChaptersComponent } from "../admin/admin-settle-tv/components/update-chapters/update-chapters.component";
import { PlayVideoComponent } from "./play-video/play-video.component";
import { CommentsComponent } from "../pages/settle-tv/watch/components/comments/comments.component";
import { LikesComponent } from "../pages/settle-tv/watch/components/likes/likes.component";
import { SerialInformationComponent } from "../pages/settle-tv/watch/components/serial-information/serial-information.component";
import { ViewComponent } from "../pages/settle-tv/watch/components/view/view.component";
import { ChatComponent } from "../admin/admin-chat/components/chat/chat.component";
import { InfoChatComponent } from "../admin/admin-chat/components/info-chat/info-chat.component";
import { ListChatComponent } from "../admin/admin-chat/components/list-chat/list-chat.component";
import { SendMessageComponent } from "../admin/admin-chat/components/send-message/send-message.component";
import { OptionComponent } from "../admin/admin-chat/components/option/option.component";
import { ModalAdminChatComponent } from "../admin/admin-chat/components/modal-admin-chat/modal-admin-chat.component";
import { SharedSettleTvComponent } from "../pages/settle-tv/watch/components/shared-settle-tv/shared-settle-tv.component";
import { SendPushNotificationComponent } from "../admin/admin-notifications/components/send-push-notification/send-push-notification.component";
import { ListAdminNotificationComponent } from "../admin/admin-notifications/components/list-admin-notification/list-admin-notification.component";
import { ViewNotificationAdminComponent } from "../admin/admin-notifications/components/view-notification-admin/view-notification-admin.component";
import { DragDropComponent } from "../admin/organize-event/components/drag-drop/drag-drop.component";
import { ConnectZoomComponent } from "../pages/members/tabs-video-online/pages/lives/components/connect-zoom/connect-zoom.component";
import { CalendarZoomComponent } from "../pages/members/tabs-video-online/pages/lives/components/calendar-zoom/calendar-zoom.component";
import { RateItemsOnlineComponent } from "../admin/judge/components/rate-items-online/rate-items-online.component";
import { RateErrosOnlineComponent } from "../admin/judge/components/rate-erros-online/rate-erros-online.component";
import { ResultViewOnlineComponent } from "../admin/result/components/result-view-online/result-view-online.component";
import { MainVideoComponent } from "../admin/organize-event/components/main-video/main-video.component";
import { BackstageOnlineComponent } from "../admin/organize-event/components/backstage-online/backstage-online.component";
import { EditResultsComponent } from "../pages/members/results/finalResults/edit-results/edit-results.component";
import { ResultChangeListComponent } from "../admin/result/components/result-change-list/result-change-list.component";
import { EditErrorResultComponent } from "../pages/members/results/finalResults/edit-error-result/edit-error-result.component";
import { OpenCasesComponent } from "../pages/members/tabs-video-online/pages/complaints/components/open-cases/open-cases.component";


import { ViewFileComponent } from "./view-file/view-file.component";
import { EmailVerifiedComponent } from "./email-verified/email-verified.component";
import { SurveyComponent } from "./survey/survey.component";
import { MultimediaListOfJudgesComponent } from "../admin/organize-event/components/multimedia-list-of-judges/multimedia-list-of-judges.component";
import { ResultPopoverComponent } from "../admin/result/components/result-popover/result-popover.component";
import { GlobalReportOfJudgesResultComponent } from "../admin/result/components/global-report-of-judges-result/global-report-of-judges-result.component";
import { ViewMultimediaResultComponent } from "../admin/result/components/view-multimedia-result/view-multimedia-result.component";
import { RecordVideoComponent } from "./record-video/record-video.component";
import { CertificatesOfParticipationComponent } from "../pages/members/tabs-video-online/pages/social-shared/components/certificates-of-participation/certificates-of-participation.component";
import { PositionCertificatesComponent } from "../pages/members/tabs-video-online/pages/social-shared/components/position-certificates/position-certificates.component";
import { TrophyClaimComponent } from "../pages/members/tabs-video-online/pages/social-shared/components/trophy-claim/trophy-claim.component";
import { WinnersReportComponent } from "../admin/result/components/winners-report/winners-report.component";
import { BalanceComponent } from "../pages/members/tabs-event/pages/wallet/components/balance/balance.component";
import { ListOfQualifiedDivisionsComponent } from "../pages/members/tabs-event/pages/wallet/components/list-of-qualified-divisions/list-of-qualified-divisions.component";
import { SeePaidDivisionComponent } from "../pages/members/tabs-event/pages/wallet/components/see-paid-division/see-paid-division.component";
import { RequestComponent } from "./request/request.component";
import { AddPaymentMethodComponent } from "./add-payment-method/add-payment-method.component";
import { MovementsWalletComponent } from "../pages/members/tabs-event/pages/wallet/components/movements-wallet/movements-wallet.component";
import { IframeVimeoComponent } from "./iframe-vimeo/iframe-vimeo.component";
import { ImageCropperComponent } from "./image-cropper/image-cropper.component";
import { CreateLanguageComponent } from "../admin/translate/components/create-language/create-language.component";
import { ListLanguageComponent } from "../admin/translate/components/list-language/list-language.component";
import { ListJubgeComponent } from "../admin/admin-wallet/components/list-jubge/list-jubge.component";
import { PayJudgeComponent } from "../admin/admin-wallet/components/pay-judge/pay-judge.component";
import { ClaimTrophyComponent } from "../pages/members/tabs-video-online/pages/social-shared/components/trophy-claim/components/claim-trophy/claim-trophy.component";
import { SendTrophyComponent } from "../pages/members/tabs-video-online/pages/social-shared/components/trophy-claim/components/send-trophy/send-trophy.component";
import { SurveyListComponent } from "../admin/admin-survey/components/survey-list/survey-list.component";
import { BarChartComponent } from "../admin/admin-survey/components/bar-chart/bar-chart.component";
import { AddShippingPriceComponent } from "../admin/admin-trophy/components/add-shipping-price/add-shipping-price.component";
import { SeeRequestComponent } from "../admin/admin-trophy/components/see-request/see-request.component";
import { TrophyRequestListComponent } from "../admin/admin-trophy/components/trophy-request-list/trophy-request-list.component";
import { PopoverTrophyComponent } from "../admin/admin-trophy/components/popover-trophy/popover-trophy.component";
import { ShippingPriceListComponent } from "../admin/admin-trophy/components/shipping-price-list/shipping-price-list.component";
import { AddCouponComponent } from "../admin/admin-coupon/components/add-coupon/add-coupon.component";
import { CouponListComponent } from "../admin/admin-coupon/components/coupon-list/coupon-list.component";
import { SeeCouponComponent } from "../admin/admin-coupon/components/see-coupon/see-coupon.component";
import { MoveDivisionComponent } from "../admin/videos-division/components/move-division/move-division.component";
import { InputSingleVimeoVideoComponent } from "./input-single-vimeo-video/input-single-vimeo-video.component";
import { EventMultimediaFilesComponent } from "./event-multimedia-files/event-multimedia-files.component";
import { InputSingleImageComponent } from "./input-single-image/input-single-image.component";
import { InputSingleFileComponent } from "./input-single-file/input-single-file.component";
import { ProfileFormComponent } from "./profile-form/profile-form.component";
import { AvatarComponent } from "../pages/members/user/avatar/avatar.component";
import { HeaderComponent } from "../shared/header/header.component";
import { FooterComponent } from "../shared/footer/footer.component";
import { HeaderBackComponent } from "../shared/header-back/header-back.component";
import { ResultsViewComponent } from "../pages/members/tabs-video-online/pages/results/components/results-view/results-view.component";
import { ModalFormUpdateEmailComponent } from "./modal-form-update-email/modal-form-update-email.component";
import { AdminFormUpdateUserEmailComponent } from "./admin-form-update-user-email/admin-form-update-user-email.component";

@NgModule({
  entryComponents: [
    MoveDivisionComponent,
    ScheduleComponent,
    ParticipantsComponent,
    InformationComponent,
    NotificationViewComponent,
    AllEventsComponent,
    RecordComponent,
    UserInfoVideoComponent,
    UsersComponent2,
    RecordEventWelcomeComponent,
    CustomVideosComponent,
    MainChoreographyComponent,
    RateErrorsComponent,
    RateItemsComponent,
    FinalResultsModalComponent,
    PracticeMyChoreographyComponent,
    MainChoreographyAdminComponent,
    MultimediaContentComponent,
    PracticeVideoListComponent,
    TestVideosAdminComponent,
    SeeVideoComponent,
    JudgesQualificationsComponent,
    OpenACaseComponent,
    OpenCasesComponent,
    RejectedCasesComponent,
    ResolvedCasesComponent,
    OpenCaseAModalComponent,
    SeeComplaintsComponent,
    ListResultDivisionComponent,
    RecordAudioComponent,
    ModalComplaintsComponent,
    ResultViewComponent,
    AddSerieComponent,
    AddChaptersComponent,
    UpdateChaptersComponent,
    PlayVideoComponent,
    CommentsComponent,
    SerialInformationComponent,
    LikesComponent,
    ViewComponent,
    ChatComponent,
    InfoChatComponent,
    ListChatComponent,
    SendMessageComponent,
    OptionComponent,
    ModalAdminChatComponent,
    SharedSettleTvComponent,
    SendPushNotificationComponent,
    ListAdminNotificationComponent,
    ViewNotificationAdminComponent,
    DragDropComponent,
    CalendarZoomComponent,
    ConnectZoomComponent,
    RateItemsOnlineComponent,
    RateErrosOnlineComponent,
    ResultViewOnlineComponent,
    MainVideoComponent,
    BackstageOnlineComponent,
    EditResultsComponent,
    ResultChangeListComponent,
    EditErrorResultComponent,
    ResultsViewComponent,
    ViewFileComponent,
    EmailVerifiedComponent,
    SurveyComponent,
    MultimediaListOfJudgesComponent,
    ResultPopoverComponent,
    GlobalReportOfJudgesResultComponent,
    RecordVideoComponent,
    ViewMultimediaResultComponent,
    CertificatesOfParticipationComponent,
    PositionCertificatesComponent,
    TrophyClaimComponent,
    WinnersReportComponent,
    BalanceComponent,
    ListOfQualifiedDivisionsComponent,
    SeePaidDivisionComponent,
    RequestComponent,
    AddPaymentMethodComponent,
    MovementsWalletComponent,
    IframeVimeoComponent,
    ImageCropperComponent,
    CreateLanguageComponent,
    ListLanguageComponent,
    ListJubgeComponent,
    PayJudgeComponent,
    ClaimTrophyComponent,
    SendTrophyComponent,
    SurveyListComponent,
    BarChartComponent,
    AddShippingPriceComponent,
    SeeRequestComponent,
    TrophyRequestListComponent,
    PopoverTrophyComponent,
    ShippingPriceListComponent,
    SeeCouponComponent,
    CouponListComponent,
    AddCouponComponent,
    InputSingleVimeoVideoComponent,
    EventMultimediaFilesComponent,
    InputSingleImageComponent,
    InputSingleFileComponent,
    ProfileFormComponent,
    AvatarComponent,
    ModalFormUpdateEmailComponent,
    AdminFormUpdateUserEmailComponent,
  ],
  declarations: [
    MoveDivisionComponent,
    ScheduleComponent,
    ParticipantsComponent,
    SkeletonComponent,
    InformationComponent,
    NotificationViewComponent,
    MainSlidesComponent,
    MainEventsComponent,
    FilterByContinentsComponent,
    SecondaryBannerComponent,
    MapWelcomeComponent,
    SidebarMenuComponent,
    HeaderComponent,
    HeaderBackComponent,
    FooterComponent,
    ExpandableComponent,
    AllEventsComponent,
    RecordComponent,
    UserInfoVideoComponent,
    UsersComponent2,
    RecordEventWelcomeComponent,
    CustomVideosComponent,
    TestVideosComponent,
    MainChoreographyComponent,
    RateErrorsComponent,
    RateItemsComponent,
    FinalResultsModalComponent,
    PracticeMyChoreographyComponent,
    MainChoreographyAdminComponent,
    MultimediaContentComponent,
    PracticeVideoListComponent,
    TestVideosAdminComponent,
    SeeVideoComponent,
    JudgesQualificationsComponent,
    OpenACaseComponent,
    OpenCasesComponent,
    RejectedCasesComponent,
    ResolvedCasesComponent,
    OpenCaseAModalComponent,
    SeeComplaintsComponent,
    ListResultDivisionComponent,
    RecordAudioComponent,
    ModalComplaintsComponent,
    ResultViewComponent,
    AddSerieComponent,
    AddChaptersComponent,
    UpdateChaptersComponent,
    PlayVideoComponent,
    SerialInformationComponent,
    CommentsComponent,
    LikesComponent,
    ViewComponent,
    ChatComponent,
    InfoChatComponent,
    ListChatComponent,
    SendMessageComponent,
    OptionComponent,
    ModalAdminChatComponent,
    SharedSettleTvComponent,
    SendPushNotificationComponent,
    ListAdminNotificationComponent,
    ViewNotificationAdminComponent,
    DragDropComponent,
    CalendarZoomComponent,
    ConnectZoomComponent,
    RateItemsOnlineComponent,
    RateErrosOnlineComponent,
    ResultViewOnlineComponent,
    MainVideoComponent,
    BackstageOnlineComponent,
    EditResultsComponent,
    ResultChangeListComponent,
    EditErrorResultComponent,
    ResultsViewComponent,
    ViewFileComponent,
    EmailVerifiedComponent,
    SurveyComponent,
    MultimediaListOfJudgesComponent,
    ResultPopoverComponent,
    GlobalReportOfJudgesResultComponent,
    RecordVideoComponent,
    ViewMultimediaResultComponent,
    CertificatesOfParticipationComponent,
    PositionCertificatesComponent,
    TrophyClaimComponent,
    WinnersReportComponent,
    BalanceComponent,
    ListOfQualifiedDivisionsComponent,
    SeePaidDivisionComponent,
    RequestComponent,
    AddPaymentMethodComponent,
    MovementsWalletComponent,
    IframeVimeoComponent,
    ImageCropperComponent,
    CreateLanguageComponent,
    ListLanguageComponent,
    ListJubgeComponent,
    PayJudgeComponent,
    ClaimTrophyComponent,
    SendTrophyComponent,
    SurveyListComponent,
    BarChartComponent,
    AddShippingPriceComponent,
    SeeRequestComponent,
    TrophyRequestListComponent,
    PopoverTrophyComponent,
    ShippingPriceListComponent,
    SeeCouponComponent,
    CouponListComponent,
    AddCouponComponent,
    InputSingleVimeoVideoComponent,
    EventMultimediaFilesComponent,
    InputSingleImageComponent,
    InputSingleFileComponent,
    ProfileFormComponent,
    AvatarComponent,
    ModalFormUpdateEmailComponent,
    AdminFormUpdateUserEmailComponent,
  ],
  exports: [
    MoveDivisionComponent,
    SidebarMenuComponent,
    HeaderComponent,
    HeaderBackComponent,
    FooterComponent,
    ExpandableComponent,
    ScheduleComponent,
    ParticipantsComponent,
    SkeletonComponent,
    NotificationViewComponent,
    MainSlidesComponent,
    MainEventsComponent,
    FilterByContinentsComponent,
    SecondaryBannerComponent,
    MapWelcomeComponent,
    AllEventsComponent,
    RecordComponent,
    UserInfoVideoComponent,
    UsersComponent2,
    RecordEventWelcomeComponent,
    CustomVideosComponent,
    TestVideosComponent,
    MainChoreographyComponent,
    RateErrorsComponent,
    RateItemsComponent,
    FinalResultsModalComponent,
    PracticeMyChoreographyComponent,
    MainChoreographyAdminComponent,
    MultimediaContentComponent,
    PracticeVideoListComponent,
    TestVideosAdminComponent,
    SeeVideoComponent,
    JudgesQualificationsComponent,
    OpenACaseComponent,
    OpenCasesComponent,
    RejectedCasesComponent,
    ResolvedCasesComponent,
    OpenCaseAModalComponent,
    SeeComplaintsComponent,
    ListResultDivisionComponent,
    RecordAudioComponent,
    ModalComplaintsComponent,
    ResultViewComponent,
    AddSerieComponent,
    AddChaptersComponent,
    UpdateChaptersComponent,
    PlayVideoComponent,
    SerialInformationComponent,
    CommentsComponent,
    LikesComponent,
    ViewComponent,
    ChatComponent,
    InfoChatComponent,
    ListChatComponent,
    SendMessageComponent,
    OptionComponent,
    ModalAdminChatComponent,
    SharedSettleTvComponent,
    SendPushNotificationComponent,
    ListAdminNotificationComponent,
    ViewNotificationAdminComponent,
    DragDropComponent,
    CalendarZoomComponent,
    ConnectZoomComponent,
    RateItemsOnlineComponent,
    RateErrosOnlineComponent,
    ResultViewOnlineComponent,
    MainVideoComponent,
    BackstageOnlineComponent,
    EditResultsComponent,
    ResultChangeListComponent,
    EditErrorResultComponent,
    ResultsViewComponent,
    ViewFileComponent,
    EmailVerifiedComponent,
    SurveyComponent,
    MultimediaListOfJudgesComponent,
    ResultPopoverComponent,
    GlobalReportOfJudgesResultComponent,
    RecordVideoComponent,
    ViewMultimediaResultComponent,
    CertificatesOfParticipationComponent,
    PositionCertificatesComponent,
    TrophyClaimComponent,
    WinnersReportComponent,
    BalanceComponent,
    ListOfQualifiedDivisionsComponent,
    SeePaidDivisionComponent,
    RequestComponent,
    AddPaymentMethodComponent,
    MovementsWalletComponent,
    IframeVimeoComponent,
    ImageCropperComponent,
    CreateLanguageComponent,
    ListLanguageComponent,
    ListJubgeComponent,
    PayJudgeComponent,
    ClaimTrophyComponent,
    SendTrophyComponent,
    SurveyListComponent,
    BarChartComponent,
    AddShippingPriceComponent,
    SeeRequestComponent,
    TrophyRequestListComponent,
    PopoverTrophyComponent,
    ShippingPriceListComponent,
    SeeCouponComponent,
    CouponListComponent,
    AddCouponComponent,
    InputSingleVimeoVideoComponent,
    EventMultimediaFilesComponent,
    InputSingleImageComponent,
    InputSingleFileComponent,
    ProfileFormComponent,
    AvatarComponent,
    ModalFormUpdateEmailComponent,
    AdminFormUpdateUserEmailComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    IonicModule,
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    RouterModule,
    PipesModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    DragDropModule,
    IonicSelectableModule,
    ImageCropperModule
  ],
})
export class ComponentsModule { }
