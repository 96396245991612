import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public alertController: AlertController) { }


  async presentAlert(header = '', text = '', message = '') {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      animated: true,
      cssClass: "alertLogCss",
      header,
      subHeader: text,
      message,
      buttons: ["OK"],
    });
    await alert.present();
  }

  async presentAlertTranslation(text: string) {
    let mens = this.getTranslation(text)
    const alert = await this.alertController.create({
      backdropDismiss: false,
      animated: true,
      cssClass: "alertLogCss",
      header: "ZOE",
      message: mens ? mens : null,
      buttons: ["OK"],
    });

    await alert.present();
  }

  async presentAlertConfirm(message) {
    return new Promise(async (resolve, reject) => {
      let mess = this.getTranslation(message)
      const alert = await this.alertController.create({
        message: mess,
        buttons: [
          {
            text: this.getTranslation("NO"),
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              resolve(false)
            },
          },
          {
            text: this.getTranslation("SI"),
            handler: () => {
              resolve(true)
            },
          },
        ],
      });

      return await alert.present();
    })
  }


  /**
   * Alerta con campos inputs
   * @param params
   * @param params.inputs         Arreglo de valores
   * @param params.message        Mensaje a mostrar
   * @returns
   */
  async presentAlertWithInput(params: any){
    const { inputs, message } = params;

    const alert = await this.alertController.create({
      message: this.getTranslation(message),
      inputs,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'Ok',
          role: 'store',
        }
      ]
    });


    await alert.present();

    const { data, role } = await alert.onWillDismiss();
    return {data, role};
  }

  getTranslation(text) {
    return text
  }
}
