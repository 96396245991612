import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';
import { CustomizationfileService } from "../customizationfile/customizationfile.service";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DivisionService {
  keyDb: any
  keyInfo: any
  informForTheRecord: any
  utc: any
  constructor(
    public db: AngularFireDatabase,
    public _cf: CustomizationfileService,
    private http: HttpClient) {
    this.keyDb = this._cf.getKeyDb()
    this.keyInfo = this._cf.getKeyInfo()
    // this.getInfoCompetency()
  }

  getCountry() {
    return this.http.get("/assets/country-state-city/country.json");
  }


  getBlock({ keydb }) {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${environment.urlrootFunctions}/v2/division/block?keydb=${keydb}`)
        .subscribe((res: any) => {
          if (!res) { reject() }
          resolve(res.results)
        })
    })
  }

  getSchoolRecord() {
    return this.db.list('schoolRecord', ref => ref.orderByChild('public').equalTo(true))
      .snapshotChanges()
      .pipe(map(changes => changes.map(c => ({ key: c.payload.key, ...c.payload.val() as any }))));
  }


  // async getInfoCompetency() {
  //   this.utc = this._cf.getUTC()
  //   const itemRef = this.db.object(`_competitionListCreated/${this.keyInfo}`);
  //   await itemRef.snapshotChanges().subscribe(action => {
  //     this.informForTheRecord = action.payload.val()
  //   });
  // }


  // getBlock() {
  //   return this.db.list(`competition/${this.keyDb}/bloque`).valueChanges();
  // }

  // searchCategory(id_categoria: any) {
  //   if (!id_categoria) return

  //   const itemsRef = this.db.list(`competition/${this.keyDb}/categories_list`, ref =>
  //     ref.orderByChild("id_categoria").startAt(id_categoria).endAt(id_categoria));

  //   // Obtenemos todos los cursos
  //   return itemsRef
  //     .snapshotChanges()
  //     .pipe(
  //       map(changes =>
  //         changes.map(c => ({ key: c.payload.key, ...c.payload.val() as any }))
  //       )
  //     )
  // }

  // async selectSubcategory(category) {
  //   let namecategory = category.sub_categoria.toLowerCase()
  //   let result = namecategory.indexOf('improvisation')
  //   let tango_salon = namecategory.indexOf('couple tango salon')
  //   let tango_salon_grup = namecategory.indexOf('team tango salon')
  //   let dataGroups = false;
  //   let allowRegistration = false;
  //   let isAGroup = false;
  //   let improvisation = false
  //   let tango_salon_gruop = false

  //   if (result != -1) {
  //     improvisation = true;
  //   } else if (tango_salon != -1) {
  //     improvisation = true;
  //   } else if (tango_salon_grup != -1) {
  //     tango_salon_gruop = true
  //   } else {
  //     improvisation = false;
  //   }


  //   let price = 0
  //   await this.getPriceDivision(category.id_tipo_sub_categoria)
  //     .then(data => {
  //       price = data.stage.cost;
  //     })

  //   return {
  //     price: price ? price : false,
  //     config: {
  //       dataGroups: dataGroups,
  //       allowRegistration: allowRegistration,
  //       isAGroup: category.id_tipo_sub_categoria ? category.id_tipo_sub_categoria == "Grupo" : false,
  //       improvisation: improvisation,
  //       tango_salon_gruop: tango_salon_gruop,
  //     },
  //     category_detail: category
  //   }

  // }

  // async getPriceDivision(type) {
  //   if (!type) return
  //   if (this.informForTheRecord.category_free) return false


  //   let price: any
  //   await this
  //     .getCurrentCategoryPrice(type)
  //     .then((data: any) => {
  //       price = data
  //     }).catch(err => {
  //       price = err
  //     });

  //   return price
  // }

  // getCurrentCategoryPrice(type) {
  //   return new Promise((resolve, reject) => {
  //     const url_root = 'https://us-central1-wldc-app.cloudfunctions.net'
  //     this.http.post(`${url_root}/getCurrentCategoryPrice`, JSON.stringify({
  //       utc: this.utc,
  //       key: this.keyInfo,
  //       type: type
  //     })).subscribe(
  //       res => { resolve(res); },
  //       err => { reject(err); }
  //     );
  //   });
  // }


  // getCurrentPriceOfCompetitorPass() {
  //   console.log("getCurrentPriceOfCompetitorPass()")
  //   console.log({
  //     utc: this.utc,
  //     key: this.keyInfo
  //   })
  //   return new Promise((resolve, reject) => {
  //     const url_root = 'https://us-central1-wldc-app.cloudfunctions.net'
  //     this.http.post(`${url_root}/getCurrentPriceOfCompetitorPass`, JSON.stringify({
  //       utc: this.utc,
  //       key: this.keyInfo
  //     })).subscribe(
  //       res => { resolve(res); },
  //       err => { reject(err); }
  //     );
  //   });
  // }


  // async getPriceCompetitorPass() {
  //   if (this.informForTheRecord.category_free) return false
  //   let price: any
  //   await this
  //     .getCurrentPriceOfCompetitorPass()
  //     .then((data: any) => {
  //       price = data
  //     }).catch(err => {
  //       price = err
  //     });

  //   return price
  // }





  // removeDups(data) {
  //   let dataf = data.filter(
  //     (thing, index, self) =>
  //       index ===
  //       self.findIndex(
  //         t =>
  //           t.categoria === thing.categoria &&
  //           t.id_categoria === thing.id_categoria
  //       )
  //   );

  //   console.log("dataf", dataf)

  //   return dataf.sort(function (a, b) {
  //     if (a.categoria > b.categoria) {
  //       return 1;
  //     }
  //     if (a.categoria < b.categoria) {
  //       return -1;
  //     }
  //     // a must be equal to b
  //     return 0;
  //   });
  // }

}
