import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Pipe({
  name: 'fullPassVerifier'
})
export class FullPassVerifierPipe implements PipeTransform {


  constructor(public db: AngularFireDatabase,) { }


  /**
   * 
   * @param KeyDb 
   * @param uid 
   * @returns 
   */
  async transform(KeyDb: any, uid: any): Promise<any> {

    const fullPass = await this.getFullPass(KeyDb, uid);

    return fullPass;
  }


  /**
   * 
   * @param KeyDb 
   * @param uid 
   * @returns 
   */
  getFullPass(KeyDb, uid) {
    return new Promise((resolve, reject) => {
      this.db
        .object(`competition/${KeyDb}/panticipants/${uid}`)
        .snapshotChanges()
        .subscribe((action) => {
          resolve(action.payload.exists());
        });
    });

  }

}
