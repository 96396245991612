import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import {
  CommonService,
  CustomizationfileService,
} from "src/app/services/services";

@Component({
  selector: "app-judges-qualifications",
  templateUrl: "./judges-qualifications.component.html",
  styleUrls: ["./judges-qualifications.component.scss"],
})
export class JudgesQualificationsComponent implements OnInit {
  data: any;
  mode: any;

  constructor(
    public customizationfileService: CustomizationfileService,
    public commonService: CommonService,
    public modalController: ModalController,
    private navParams: NavParams
  ) {
    this.data = this.navParams.get("value");
    this.mode = this.navParams.get("mode");
    console.log("this.data", this.data);
  }

  ngOnInit() {}

  dismiss(status, message?) {
    this.modalController.dismiss({
      dismissed: true,
      status,
      message,
    });
  }
}
