// https://stackoverflow.com/questions/53239092/angular-7-drag-and-drop-dynamically-create-drop-zones
import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDragStart,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";

import { Component, OnInit } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { AngularFireDatabase } from "angularfire2/database";
import { map } from "rxjs/operators";
import {
  CommonService,
  CustomizationfileService,
  ManageUsersService,
} from "src/app/services/services";
import { FirestoreService } from "src/app/services/v2/firestore.service";
import { SeeVideoComponent } from "../../../components/see-video/see-video.component";
import { ResultViewOnlineComponent } from "../../../result/components/result-view-online/result-view-online.component";
import { BackstageOnlineComponent } from "../backstage-online/backstage-online.component";
import { MainVideoComponent } from "../main-video/main-video.component";
import { MultimediaListOfJudgesComponent } from "../multimedia-list-of-judges/multimedia-list-of-judges.component";

@Component({
  selector: "app-drag-drop",
  templateUrl: "./drag-drop.component.html",
  styleUrls: ["./drag-drop.component.scss"],
})
export class DragDropComponent implements OnInit {
  listDivision: any = [];
  meeting: any = [];
  livingRoomMain = [];
  calendarRoom: any = [];
  takeList = [];
  settingConfig = false;
  setting = {
    startTimeFixed: null,
    startTime: null,
    checkInMin: 0,
    durationOfTheEvent: {},
    round: 1,
    day: "day1",
    min: 0,
    updatedLastTime: null,
    beforeParticipation: 0,
    meeting: {
      meetingNumber: "",
      meetingPassword: "",
    },
    codeDivision: {
      code: "",
    },
    connectionTime: {
      time_min: 0,
      time_max: 0,
    },
  };

  keydb: any;
  update: boolean;
  listDivisionCopy = []
  constructor(
    public modalController: ModalController,
    public db: AngularFireDatabase,
    public commonService: CommonService,
    public manageUsersService: ManageUsersService,
    public customizationfileService: CustomizationfileService,
    public alertController: AlertController,
    public firestoreService: FirestoreService
  ) { }

  ngOnInit() {
    this.keydb = this.customizationfileService.getPROVISIONAKEY();
    this.selectDay();
  }


  async selectDay() {
    const alert = await this.alertController.create({
      header: "select day",
      inputs: [
        {
          name: "round",
          type: "radio",
          label: "day 1",
          value: "day1",
        },
        {
          name: "round",
          type: "radio",
          label: "day 2",
          value: "day2",
        },
      ],
      buttons: [
        {
          text: "Ok",
          handler: (data) => {
            if (!data) {
              this.selectDay();
              return;
            }
            this.setting.day = data;

            this.selectRound();
            console.log("Confirm Ok", data);
          },
        },
      ],
    });

    await alert.present();
  }

  async selectRound() {
    const alert = await this.alertController.create({
      header: "select round",
      inputs: [
        {
          name: "round",
          type: "radio",
          label: "round 1",
          value: 1,
        },
        // {
        //   name: "round",
        //   type: "radio",
        //   label: "round 2",
        //   value: 2,
        // },
      ],
      buttons: [
        {
          text: "Ok",
          handler: async (data) => {
            if (!data) {
              this.selectRound();
              return;
            }

            this.setting.round = data;
            console.log("Confirm Ok", this.setting);

            // await this.updateList();

            this.setDatalivingRoom();
            this.getAllDivision();
            this.filterListDivisionAndCalendar();
          },
        },
      ],
    });

    await alert.present();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    this.updateList();
    this.setting.updatedLastTime = this.commonService.setDateLocale(Date.now())
  }

  async getAllDivision() {
    try {
      this.update = true;
      const res: any = await this.manageUsersService.global({
        url: "organizeEvent/getDivisions",
        data: {
          keydb: this.keydb,
          round: this.setting.round,
        },
      });
      this.update = false;
      this.listDivision = res.results;

      this.filterListDivisionAndCalendar();
    } catch (err) {
      this.update = false;
      this.commonService.presentAlert(JSON.stringify(err));
    }
  }


  search(evt) {
    const searchTerm = evt.toLowerCase();
    if (searchTerm.length > 1) {
      console.log("searchTerm", searchTerm);
      this.listDivision = this.listDivision.filter((item) => {
        // tslint:disable-next-line: max-line-length
        console.log("user", item);
        return (
          item.code.toLowerCase().indexOf(searchTerm) > -1
        );
      });
    } else {
      this.listDivision = this.listDivisionCopy;
      this.filterListDivisionAndCalendar();
    }
  }

  async updateList() {
    try {
      await this.commonService.presentLoading();
      console.log("loader", this.commonService.loader);
      await this.updatelivingRoom({
        route: "setting",
        array: this.setting,
      });

      await this.updatelivingRoom({
        route: "calendarRoom",
        array: this.mapCalendarRoom(this.calendarRoom),
      });

      await this.updatelivingRoom({
        route: "livingRoomMain",
        array: this.livingRoomMain,
      });

      await this.updatelivingRoom({
        route: "meeting",
        array: this.meeting,
      });

      await this.updatelivingRoom({
        route: "takeList",
        array: this.takeList,
      });

      this.setDatalivingRoom();
      this.changeStatus();
      this.configDay();
    } catch (err) {
      console.log("err", err);
      this.commonService.dismissLoading();
      this.commonService.presentAlert("Error");
    }
  }

  configDay() {
    console.warn("configDay", this.calendarRoom);
    this.calendarRoom.map(async (x) => {
      console.warn("listDivisions", x.code);
      await this.updateDivision({
        code: x.code,
        division: {
          day: this.setting.day,
        },
      });
    });
  }

  async updateDivision({ code, division }) {
    if (code === "break") {
      return;
    }
    console.log("code", code);
    console.log("division", division);
    console.log("division", {
      code,
      division,
      keydb: this.keydb,
    });
    return await this.firestoreService.updateDivisionCode({
      code,
      division,
      keydb: this.keydb,
    });
  }

  async setDatalivingRoom() {
    console.log("setDatalivingRoom()");
    this.update = true;
    const res: any = await this.getLivingRoom();

    this.setting = res.hasOwnProperty("setting") ? res.setting : this.setting;

    this.calendarRoom = res.hasOwnProperty("calendarRoom")
      ? this.mapCalendarRoom(res.calendarRoom)
      : [];

    this.livingRoomMain = res.hasOwnProperty("livingRoomMain")
      ? res.livingRoomMain
      : [];

    this.meeting = res.hasOwnProperty("meeting") ? res.meeting : [];

    this.takeList = res.hasOwnProperty("takeList") ? res.takeList : [];

    this.filterListDivisionAndCalendar();
    this.changeStatus();
    this.update = false;
  }

  async getLivingRoom() {
    return new Promise(async (resolve, reject) => {
      this.db
        .object(
          `livingRoom/${this.keydb}/${this.setting.day}/${this.setting.round}`
        )
        .valueChanges()
        .subscribe((res: any) => {
          if (!res) {
            resolve([]);
          }
          resolve(res);
        });
    });
  }

  async updatelivingRoom({ route, array }) {
    if (!array) {
      return;
    }
    return new Promise(async (resolve, reject) => {
      await this.db
        .object(
          `livingRoom/${this.keydb}/${this.setting.day}/${this.setting.round}/${route}`
        )
        .set(array)
        .then(() => {
          resolve({});
        });
    });
  }

  async removeCompetitor(item, nameArray, index) {
    console.log('removeCompetitor', item)
    const alert = await this.alertController.create({
      header: `remove ${item.user}`,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'SI',
          handler: async () => {
            console.log(nameArray, this[nameArray])
            console.log("this[nameArray][index]", this[nameArray][index])
            console.log("index", index)
            this[nameArray] = this[nameArray].filter((ele, i) => {
              return index != i;
            });
            console.log("this[nameArray]", this[nameArray])
            await this.updateList();
          }
        }
      ]
    });

    await alert.present();


  }

  filterListDivisionAndCalendar() {
    console.log("filterListDivisionAndCalendar");
    this.listDivision = this.listDivision
      .map((elem) => {
        return Object.assign(elem, {
          break: false,
          show_division: this.calendarRoom.find((x) => x.code === elem.code)
            ? true
            : false,
        });
      })
      .filter((division) => !division.show_division);
    this.commonService.dismissLoading();
  }

  mapCalendarRoom(calendarRoom) {
    let minCount = 0;

    console.log("calendarRoom", calendarRoom);
    return (
      calendarRoom
        // evita mostrar divisiones vacias
        .filter((division) => division.division_list)
        // asignar la hour de las diviones
        .map((division: any) => {
          let dresul;
          if (!division.break) {
            dresul = division.division_list
              // remove duplicate
              .filter(
                (thing, index, self) =>
                  index === self.findIndex((t) => t.key === thing.key)
              )
              // asignar hora
              .map((x) => {
                minCount = minCount + Number(this.setting.min);

                // console.warn(1, this.commonService.addMinutes(
                //   minCount,
                //   this.setting.startTime
                // ))

                return Object.assign(x, {
                  hour: this.commonService.addMinutes(
                    minCount,
                    this.setting.startTime
                  ),
                });
              });
            // evita enviar duplicados
            // .filter((division) => !division.organize_event);
          } else {
            minCount = minCount + Number(division.break_minutes);

            // console.warn(2, this.commonService.addMinutes(
            //   minCount,
            //   this.setting.startTime
            // ))
            return Object.assign(division, {
              division_list: true,
              hour: this.commonService.addMinutes(
                minCount,
                this.setting.startTime
              ),
            });
          }

          return Object.assign(division, {
            division_list: dresul,
          });
        })
        // muestra la hora inicial y final de la division
        .filter((division) => division.division_list)
        // mustra start time  and end time de la division
        .map((division) => {
          return Object.assign(division, {
            start_time: !division.break
              ? this.gethour(division.division_list, 0)
              : false,
            end_hour: !division.break
              ? this.gethour(
                division.division_list,
                division.division_list.length - 1
              )
              : false,
          });
        })
    );
  }

  gethour(time, l) {
    return time.length > 0 ? time[l].hour : false;
  }

  async enabledLiveZoomAlertConfirm(item) {
    console.log("item", item);
    const alert = await this.alertController.create({
      header: "enabled Live Zoom",
      buttons: [
        {
          text: "NO",
          role: "cancel",
          cssClass: "secondary",
          handler: async (blah) => {
            console.log("Confirm Cancel: blah");
            await this.db
              .object(
                `/competition/${this.keydb}/mainChoreography/${item.code}/${this.setting.round}/${item.keyDivision}`
              )
              .update({
                zoomlive: false,
              });
          },
        },
        {
          text: "SI",
          handler: async () => {
            await this.db
              .object(
                `/competition/${this.keydb}/mainChoreography/${item.code}/${this.setting.round}/${item.keyDivision}`
              )
              .update({
                zoomlive: true,
              });
          },
        },
      ],
    });

    await alert.present();
  }

  async setMinBreakCalendarAlertPrompt() {
    const alert = await this.alertController.create({
      cssClass: "my-custom-class",
      header: "break",
      inputs: [
        {
          name: "description",
          placeholder: "description",
          value: "break",
          type: "text",
        },
        {
          name: "break_minutes",
          placeholder: "minutes",
          type: "number",
          min: 3,
        },
      ],
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "Ok",
          handler: (data) => {
            if (!data.break_minutes || !data.description) {
              return;
            }
            this.calendarRoom.push({
              break: true,
              code: "break",
              break_minutes: data.break_minutes,
              description: data.description,
              division_list: true,
            });

            this.updateList();
          },
        },
      ],
    });

    await alert.present();
  }

  async setStartTimeCalendarAlertPrompt() {
    const alert = await this.alertController.create({
      header: "start Time?",
      inputs: [
        {
          name: "startTime",
          value: this.setting.startTime,
          type: "datetime-local",
        },
      ],
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "Ok",
          handler: (data) => {
            if (!data.startTime) {
              return;
            }
            console.log("Confirm Ok", data.startTime);
            let dateLocale = this.commonService.setDateLocale(data.startTime);
            this.setting.startTime = dateLocale
            this.setting.startTimeFixed = dateLocale
            console.log(dateLocale)
            this.updateList();
          },
        },
      ],
    });

    await alert.present();
  }

  async connectUserCalendarAlertPrompt() {
    console.log("this.setting.meeting", this.setting);
    const alert = await this.alertController.create({
      header: "connection time",
      inputs: [
        {
          name: "time_min",
          placeholder: "time min",
          value: this.setting.connectionTime
            ? this.setting.connectionTime.time_min
            : "",
          type: "number",
        },
        {
          name: "time_max",
          placeholder: "time max",
          value: this.setting.connectionTime
            ? this.setting.connectionTime.time_max
            : "",
          type: "number",
        },
      ],
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "Ok",
          handler: (data) => {
            if (!data.time_max && !data.time_min) {
              return;
            }
            console.log("Confirm Ok", data);

            this.setting.connectionTime = data;
            this.updateList();
          },
        },
      ],
    });

    await alert.present();
  }

  async setZoomAccessCalendarAlertPrompt() {
    console.log("this.setting.meeting", this.setting);
    const value = this.setting.meeting;

    const alert = await this.alertController.create({
      header: "zoom access",
      inputs: [
        {
          name: "meetingNumber",
          placeholder: "Meeting Number",
          value: this.setting.meeting ? value.meetingNumber : "",
          type: "text",
        },
        {
          name: "meetingPassword",
          value: this.setting.meeting ? value.meetingPassword : "",
          placeholder: "Meeting Password",
          type: "text",
        },
      ],
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "Ok",
          handler: (data) => {
            if (!data.meetingPassword || !data.meetingNumber) {
              return;
            }
            this.setting.meeting = data;
            console.log("Confirm Ok", data);
            this.updateList();
          },
        },
      ],
    });

    await alert.present();
  }

  async setCheckInCalendarAlertPrompt() {
    console.log("this.setting.meeting", this.setting.checkInMin);
    const value: any = this.setting.checkInMin;
    const alert = await this.alertController.create({
      header: "check In Min",
      inputs: [
        {
          name: "checkInMin",
          placeholder: "Number",
          value: this.setting.checkInMin ? value : "",
          type: "number",
        },
      ],
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "Ok",
          handler: (data) => {
            if (!data.checkInMin) {
              return;
            }
            this.setting.checkInMin = data.checkInMin;
            console.log("Confirm Ok", data);
            this.updateList();
          },
        },
      ],
    });

    await alert.present();
  }


  show1(index2, array) {
    console.log(array, this[array]);
    console.log("index2", index2);
    for (let index = 0; index < this[array].length; index++) {
      if (index === index2) {
        this[array][index].show = !this[array][index].show;
      } else {
        this[array][index].show = false;
      }
    }
  }

  async setMainStage(item, x, y) {
    console.log("item", item);
    const alert = await this.alertController.create({
      message: `enviar ${item.user}`,
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "SI",
          handler: async () => {
            console.log('startTime', this.setting.startTime)
            console.log('min', this.setting.min)
            this.setting.startTime = this.commonService.addMinutes(this.setting.min, this.setting.startTime)
            console.log('startTime', this.setting.startTime)
            this.livingRoomMain.push(item);
            this.calendarRoom[x].division_list = this.calendarRoom[
              x
            ].division_list
              .slice(0, y)
              .concat(
                this.calendarRoom[x].division_list.slice(
                  y + 1,
                  this.calendarRoom[x].division_list.length
                )
              );
            await this.updateList();
          },
        },
      ],
    });

    await alert.present();
  }

  removeObjectCalendar(x) {
    this.calendarRoom = this.calendarRoom
      .slice(0, x)
      .concat(this.calendarRoom.slice(x + 1, this.calendarRoom.length));
    this.getAllDivision();
    this.updateList();
  }

  async changeStatus() {
    this.takeList.map(async (division) => {
      await this.db
        .object(
          `/competition/${this.keydb}/mainChoreography/${division.code}/${this.setting.round}/${division.key}`
        )
        .update({
          organize_event: true,
          zoomlive: false
        });
    });
  }

  async onlineResults() {
    const modal = await this.modalController.create({
      component: ResultViewOnlineComponent,
      cssClass: "my-custom-modal-css",
      componentProps: {
        round: this.setting.round,
        day: this.setting.day,
        keydb: this.keydb,
      },
    });
    return await modal.present();
  }

  async backstageOnline() {
    const modal = await this.modalController.create({
      component: BackstageOnlineComponent,
      cssClass: "my-custom-modal-css",
      componentProps: {
        round: this.setting.round,
        day: this.setting.day,
        keydb: this.keydb,
      },
    });
    return await modal.present();
  }

  async multimediaListOfJudges(item) {
    console.log("item", item)
    console.log("audio", item.audio)

    if (item.audio === undefined) {
      this.commonService.presentAlert("no multimedia")
      return
    }


    const multimediaList = Object.keys(item.audio).map(x => {
      return Object.assign(item.audio[x], { uid: x })
    });


    console.log("multimediaList", multimediaList)

    const modal = await this.modalController.create({
      component: MultimediaListOfJudgesComponent,
      cssClass: "my-custom-modal-css",
      componentProps: {
        details: {
          code: item.code,
          user: item.user,
          multimediaList: multimediaList,
          round: this.setting.round,
          day: this.setting.day,
          keydb: this.keydb,
        }

      },
    });
    return await modal.present();
  }

  async onlineVideo() {
    const modal = await this.modalController.create({
      component: MainVideoComponent,
      cssClass: "my-custom-modal-css",
      componentProps: {
        round: this.setting.round,
        day: this.setting.day,
        keydb: this.keydb,
      },
    });
    return await modal.present();
  }

  async viewVideo(item) {
    console.log("item", item)
    const modal = await this.modalController.create({
      component: SeeVideoComponent,
      cssClass: "my-custom-modal-css",
      componentProps: {
        value: {
          name: item.user,
          nameVideo: `${item.code}--${item.user}-test-videos-${item.keyDivision}`,
          url: item.video,
          code: item.code,
          key: item.keyDivision,
        },
      },
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        if (dataReturned.data.status === 1) {
          // setTimeout(() => {
          //   this.getListVideo();
          // }, 1000);
        }
      }
    });

    return await modal.present();
  }

  setSettingConfig() {
    this.settingConfig = !this.settingConfig;
  }

  trackByFn(index, item) {
    return index; // or item.id
  }
}
