import { Component, Input, OnInit } from "@angular/core";
import { CommonService } from "src/app/services/services";
import { SocialSharingService } from "src/app/services/v2/social-sharing.service";

@Component({
  selector: "app-shared-settle-tv",
  templateUrl: "./shared-settle-tv.component.html",
  styleUrls: ["./shared-settle-tv.component.scss"],
})
export class SharedSettleTvComponent implements OnInit {
  @Input() currentItem: any;
  options: any;
  constructor(
    public socialSharingService: SocialSharingService,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    console.log("currentItem", this.currentItem);
    this.options = {
      message: this.currentItem.title, // not supported on some apps (Facebook, Instagram)
      subject: "SETTLE WORK", // fi. for email
      files: ["", ""], // an array of filenames either locally or remotely
      url: `${this.commonService.urlRootShared}settle-tv/watch/${this.currentItem.idVideo}`,
      chooserTitle: "Pick an app",
      appPackageName: this.commonService.appPackageName,
      iPadCoordinates: "0,0,0,0",
    };

    console.log("this.options", this.options)
  }

  async ShareLink() {
    try {
      const result = await this.socialSharingService
        .ShareGeneric({ message: this.options.message, subject: null, file: this.options.url });
      console.log("result", result);
      this.commonService.presentToast("compartido con éxito");
    } catch (error) {
      console.log("error", error);
      this.commonService.presentToast("error");
    }
  }

  async ShareWhatsapp() {
    try {
      const result = await this.socialSharingService.ShareWhatsapp({
        message: this.currentItem.title,
        imgurl: null,
        url: this.options.url,
      });
      console.log("result", result);
      this.commonService.presentToast("compartido con éxito");
    } catch (error) {
      console.log("error", error);
      this.commonService.presentToast("error");
    }
  }

  async ShareFacebook() {
    try {
      const result = await this.socialSharingService
        .ShareFacebook({ message: this.currentItem.title, imgurl: null, url: this.options.url, pasteMessageHint: this.currentItem.title });
      console.log("result", result);
      this.commonService.presentToast("compartido con éxito");
    } catch (error) {
      console.log("error", error);
      this.commonService.presentToast("error");
    }
  }

  async SendEmail() {
    await this.socialSharingService.SendEmail(this.options);
    this.commonService.presentToast("compartido con éxito");
  }

  async SendTwitter() {
    await this.socialSharingService.SendTwitter({ message: this.currentItem.title, imgurl: null, url: this.options.url });
    this.commonService.presentToast("compartido con éxito");
  }

  async SendInstagram() {
    let text = this.currentItem.title;
    let imgurl = this.currentItem.placeholder;
    let link = this.commonService.urlRootShared;
    await this.socialSharingService.SendInstagram({ message: text, imgurl });
    this.commonService.presentToast("compartido con éxito");
  }
}
