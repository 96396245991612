import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Platform, ToastController, AlertController } from "@ionic/angular";

import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { Globalization } from "@ionic-native/globalization/ngx";
import { environment } from "../environments/environment";

import {
  OneSignalPushService,
  AuthenticationService,
  CommonService,
  LanguageService,
  WelcolmeService,
  LocationService,
  ViewStatusService,
  CustomizationfileService,
  CartService,
  PresenceService,
} from "./services/services";

import { timer } from "rxjs/internal/observable/timer";
import { Zoom } from "@ionic-native/zoom/ngx";

import {
  IAPProduct,
} from "@ionic-native/in-app-purchase-2/ngx";
import { AppRateService } from "./services/v2/app-rate.service";
import { CheckForUpdateService } from "./services/v2/check-for-update.service";
import { RemoveCacheService } from "./services/remove-cache.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  dark = false;
  authenticationState = false;
  showSplash = true;
  SDK_KEY = environment.zoom.SDK_KEY_SDK;
  SDK_SECRET = environment.zoom.SDK_SECRET_SDK;
  language = "en-US";
  product: IAPProduct;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private toastCtrl: ToastController,
    public toastController: ToastController,
    public alertController: AlertController,
    public _auth: AuthenticationService,
    public _language: LanguageService,
    public _oneSignal: OneSignalPushService,
    public _cs: CommonService,
    public _welcome: WelcolmeService,
    public statusService: ViewStatusService,
    public _cf: CustomizationfileService,
    private globalization: Globalization,
    public _cart: CartService,
    private zoomService: Zoom,
    public _presence: PresenceService,
    public _location: LocationService,
    public appRateService: AppRateService,
    public checkForUpdateService: CheckForUpdateService,
    private removeCacheServ: RemoveCacheService,
  ) {
    this.initializeApp();
    this.isAuthenticated();
  }

  ngOnInit() {
    this._language.switchLanguage("es");
    if (!environment.production) {
      this.removeCacheServ.clearCache();
    }
  }


  initializeApp() {
    this.platform.ready().then(async () => {
      console.log("Platform ready");
      this.getPreferredLanguage();
      this.checkForUpdateService.checkForUpdate()
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this._cart.getCart();
      this._oneSignal.configuracionInicial();
      this._oneSignal.getUserIdOneSignal();
      this._oneSignal.getIdsOneSignal();
      this._oneSignal.cargarMensajes();
      this._location.getGeolocation();
      this.statusService.getStatusRatings();
      if (this._cs.isCordova()) {
        this.appRateService.showRatePrompt(false)
        this.zoomService
          .initialize(this.SDK_KEY, this.SDK_SECRET)
          .then((success) => {
            console.log("zoomService success", success);
          })
          .catch((error) => {
            console.log("zoomService error", error);
          });
        this.setLanguage();
      }

      timer(100).subscribe(() => {
        this.showSplash = false;
      });
    });
  }

  setLanguage() {
    this.zoomService
      .setLocale(this.language)
      .then((success) => { })
      .catch((error) => {
        console.log(error);
        // this.presentToast(error);
      });
  }

  async presentToast(text) {
    const toast = await this.toastCtrl.create({
      message: text,
      duration: 3000,
      position: "top",
    });
    toast.present();
  }

  isAuthenticated() {
    this._auth.authenticationState.subscribe((status) => { this.authenticationState = status; });
  }

  getPreferredLanguage() {
    if (this._cs.isCordova()) {
      this.globalization
        .getPreferredLanguage()
        .then((res) => {
          console.log("res.value", res.value);
          const language = res.value.substring(0, 2);
          this._language.switchLanguage(language);
        })
        .catch((e) => {
          console.log("getPreferredLanguagee", e);
        });
    } else if (!this._cs.isCordova()) {
      const language = window.navigator.language;
      const res = language.substring(0, 2);
      if (res === "es") {
        this._language.switchLanguage("es");
      } else {
        this._language.switchLanguage("en");
      }
    } else {
      this._language.setDefaultLang();
      localStorage.setItem("translate", "es");
    }
  }
}
