import { Component, OnInit, ViewChild } from "@angular/core";
import { AllEventsComponent } from "../../../../components/all-events/all-events.component";
import { ModalController, IonRouterOutlet, IonSlides } from "@ionic/angular";
import { CommonService } from "../../../../services/services";

@Component({
  selector: "app-filter-by-continents",
  templateUrl: "./filter-by-continents.component.html",
  styleUrls: ["./filter-by-continents.component.scss"],
})
export class FilterByContinentsComponent implements OnInit {
  rows = [];
  temp = [];

  slideOpts: any;

  continents: any[] = [
    {
      name: "Norteamerica",
      title: "norteamerica.png",
      image: "/assets/continents/america.png",
      filter: "norteamerica",
    },
    {
      name: "Latinoamerica",
      title: "latinoamerica.png",
      image: "/assets/continents/africa.png",
      filter: "latinoamerica",
    },
    {
      name: "Europa",
      title: "europa.png",
      image: "/assets/continents/europa.png",
      filter: "europa",
    },
    {
      name: "Asia",
      title: "asia.png",
      image: "/assets/continents/asia.png",
      filter: "asia",
    },
  ];
  @ViewChild("slideWithNav") slideWithNav: IonSlides;

  col1 = 0;
  col2 = 12;
  col3 = 0;
  hidden_col = true;

  constructor(
    public modalController: ModalController,
    private routerOutlet: IonRouterOutlet,
    public _cs: CommonService
  ) {
    this.gridOptions();
  }

  ngOnInit() {}

  gridOptions() {
    this._cs.mediaBreakpoint$.subscribe((data) => {
      if (data === "xl" || data === "xxl" || data === "lg") {
        this.slideOpts = {
          slidesPerView: 4.2,
          speed: 400,
          grabCursor: true,
        };
        this.hidden_col = false;
      } else if (data == "md") {
        this.slideOpts = {
          slidesPerView: 3.2,
          speed: 400,
          grabCursor: true,
        };
        this.hidden_col = true;
      } else {
        this.slideOpts = {
          slidesPerView: 2.2,
          speed: 400,
          grabCursor: true,
        };
        this.hidden_col = true;
      }
    });
  }

  async presentModal(filter) {
    console.log("filter", filter);
    const modal = await this.modalController.create({
      component: AllEventsComponent,
      swipeToClose: true,
      componentProps: { type: 1, filter: filter },
      presentingElement: this.routerOutlet.nativeEl,
    });
    return await modal.present();
  }

  trackByEmpCode(index: number, data: any): string {
    return data.keydb;
  }
}
