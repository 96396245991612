import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AlertController, ModalController } from '@ionic/angular';
import { CommonService, CustomizationfileService } from 'src/app/services/services';
import { WalletService } from 'src/app/services/v2/wallet.service';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
})
export class RequestComponent implements OnInit {
  listpayment: any;
  selectionMethod
  @Input() wallet: any;
  constructor(
    public walletService: WalletService,
    public commonService: CommonService,
    public alertController: AlertController,
    public customizationfileService: CustomizationfileService,
    public afs: AngularFirestore,
    public modalController: ModalController,
  ) { }

  ngOnInit() {
    this.getPaymentOptions()
  }

  getPaymentOptions() {
    this.afs.collection('wallet')
      .doc(this.customizationfileService.getUid())
      .collection('list')
      .valueChanges({ idField: 'key' })
      .subscribe((res: any) => {
        if (res.length === 0) {
          return
        }
        this.listpayment = res
        console.log("wallet", res)
      })
  }

  async requestWithdrawal() {

    if (this.selectionMethod === undefined || this.wallet.totalToPay === 0) {
      return
    }

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: `Withdrawals: ${this.wallet.totalToPay}`,
      message: this.selectionMethod.type,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'SI',
          handler: async () => {
            console.log("selectionMethod", this.selectionMethod)
            console.log("wallet", this.wallet)
            let object = Object.assign(this.selectionMethod.data, { type: this.selectionMethod.type })
            await this.commonService.presentLoading()
            const res: any = await this.walletService.postWallet({
              route: "judges/requestWithdrawal",
              data: {
                "keydb": this.customizationfileService.getKeyDb(),
                "uid": this.customizationfileService.getUid(),
                "paymentDetails": object
              }
            }).catch(err => {
              console.log("err", err)
              this.commonService.presentAlert(JSON.stringify(err))
            })
            this.commonService.dismissLoading()
            this.commonService.presentAlert(JSON.stringify(res.results.status))
            this.dismiss()
            console.log("res", res)
          }
        }
      ]
    });

    await alert.present();

  }


  dismiss() {
    this.modalController.dismiss();
  }

}
