import { Component, OnInit } from "@angular/core";
import { AlertController, ModalController, NavParams } from "@ionic/angular";
import { AngularFireDatabase } from "angularfire2/database";
import {
  StorageService,
  CustomizationfileService,
  CommonService,
  ManageUsersService,
  RatingsService,
} from "src/app/services/services";

@Component({
  selector: "app-result-view-online",
  templateUrl: "./result-view-online.component.html",
  styleUrls: ["./result-view-online.component.scss"],
})
export class ResultViewOnlineComponent implements OnInit {
  data: any;
  round: any;
  items: any;
  keydb: any;
  name: any;
  screenResult = true;
  loading = false;
  day: any;
  constructor(
    public ratingsService: RatingsService,
    public storageService: StorageService,
    public db: AngularFireDatabase,
    public customizationfileService: CustomizationfileService,
    public commonService: CommonService,
    public alertController: AlertController,
    public modalController: ModalController,
    private navParams: NavParams,
    public manageUsersService: ManageUsersService
  ) {
    this.round = this.navParams.get("round");
    this.keydb = this.navParams.get("keydb");
    this.day = this.navParams.get("day");

    console.log("this.keydb", this.keydb);
    console.log("this.round", this.round);
  }

  ngOnInit() {
    console.log(`/livingRoom/${this.keydb}/${this.day}/${this.round}/livingRoomMain/0`)
    this.db
      .object(`/livingRoom/${this.keydb}/${this.day}/${this.round}/livingRoomMain/0`)
      .valueChanges()
      .subscribe((res: any) => {
        this.data = [];
        this.name = "";
        if (res === null) {
          this.screenResult = false;
          this.commonService.presentToast("No user to rate");
          return;
        }

        this.name = res.user;
        this.screenResult = true;
        this.loading = true;
        console.log("livingRoomMain", res);
        this.listenDivision({
          keyDivision: res.keyDivision,
          code: res.code,
          keydb: this.keydb,
          round: this.round,
        });
      });
  }

  listenDivision({ code, keydb, round, keyDivision }) {
    console.log("listenDivision", `/rate/${keydb}/${code}/${round}`);
    this.db
      .object(`/rate/${keydb}/${code}/${round}`)
      .valueChanges()
      .subscribe((res: any) => {
        if (!res) {
          return;
        }
        this.getResult({ code, keydb, round, keyDivision });
      });
  }

  async getResult({ code, keydb, round, keyDivision }) {
    console.log("getResult", { code, keydb, round, keyDivision });

    await this.manageUsersService
      .getResultDivisionsAdmin({
        keydb: keydb,
        code: code,
        round: Number(round),
      })
      .then((data: any) => {
        console.log("getResult", data);

        const result = data.results.filter((word) => word.key === keyDivision);
        if (!result) {
          return;
        }
        this.resultScreen(result[0]);
      })
      .catch((err) => {
        console.log("err", err);
        this.screenResult = false;
        this.commonService.presentAlert("NO RESULT");
        //  this.commonService.dismissLoading();
      });
  }

  async resultScreen(data) {
    console.log("data", data);
    if (data === undefined) {
      return;
    }
    const keydb = this.customizationfileService.getKeyDb();
    const code = this.storageService.localStorageGetItem("CODE_DIVISION");
    const round = this.storageService.localStorageGetItem("ROUND");
    const keyDivision = data.key;

    console.log(data);
    const result = {
      itemsJunior: this.ratingsService.getJudgesItemsDetails({
        data: data.items_junior,
        keydb,
        code,
        round,
        keyDivision,
      }),
      error: this.ratingsService.getJudgesErrosDetails(data.error_),
      milderrors: data.milderrors,
      seriousErrors: data.seriousErrors,
    };

    setTimeout(async () => {
      console.log("result", result.itemsJunior);
      console.log("error", result.error[0]);
      console.log("seriousErrors", result.milderrors + result.seriousErrors);

      let arr1 = [];
      arr1 = result.itemsJunior.map((x) => {
        return {
          name: x.name,
          average: x.average,
          err: false,
        };
      });

      let arr2 = [];
      if (result.error[0] !== undefined) {
        arr2 = [
          {
            name: result.error[0].name,
            average: `Errores: ${data.milderrors + data.seriousErrors}`,
            err: false,
          },
        ];
      }

      if (arr1.length > 0) {
        console.log("arr1.length", arr1.length);
        console.log("arr1", arr1);
        arr1[Math.floor(Math.random() * arr1.length)].average = "HIDDEN";
      }

      const arr3 = [...arr1, ...arr2];

      console.log("final", { details: arr3, name: data.users });

      this.data = arr3;
      // this.name = data.users;
      this.loading = false;
    }, 1500);
  }

  addKeyError(data) {
    if (data === undefined) return [];
    let result = [];
    Object.keys(data).forEach((x) => {
      result.push(
        Object.assign(
          { error: data[x] },
          {
            uid: x,
          }
        )
      );
    });
    return result;
  }

  addKey(data) {
    let result = [];
    if (data === undefined) return [];
    Object.keys(data).forEach((x) => {
      result.push(
        Object.assign(
          { category: data[x] },
          { audio: false },
          {
            uid: x,
          }
        )
      );
    });
    return result;
  }

  getAllcategory(data) {
    if (!data) return [];
    let result = [];
    Object.keys(data).forEach((x) => {
      data[x].forEach((c) => {
        result.push(c);
      });
    });
    return result;
  }

  dismiss(status, message?) {
    this.modalController.dismiss({
      dismissed: true,
      status,
      message,
    });
  }
}
