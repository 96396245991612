import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import {
  CommonService,
  CustomizationfileService,
  ManageUsersService,
  RatingsService,
  StorageService,
} from "src/app/services/services";

@Component({
  selector: "app-list-result-division",
  templateUrl: "./list-result-division.component.html",
  styleUrls: ["./list-result-division.component.scss"],
})
export class ListResultDivisionComponent implements OnInit {
  segment = 1;
  segmentFilter = "all";
  keydb: string;
  round: any;
  listDivision: any;
  listDivisionCopy: any;
  searchFilterList: any;
  constructor(
    public ratingsService: RatingsService,
    public storageService: StorageService,
    public commonService: CommonService,
    public customizationfileService: CustomizationfileService,
    public manageUsersService: ManageUsersService,
    public modalController: ModalController
  ) { }

  ngOnInit() {
    this.keydb = this.customizationfileService.getPROVISIONAKEY();
    this.segmentChanged(1);
  }

  segmentChanged(round) {
    this.round = round;

    this.keydb = this.customizationfileService.getPROVISIONAKEY();

    this.storageService.localStorageSetItem("ROUND", round);
    this.storageService.localStorageSetItem("keyDB", this.keydb);

    console.log("ROUND", round);
    console.log("keyDB", this.keydb);

    setTimeout(() => {
      this.getAllDivisionAdmin({ keydb: this.keydb, round });
    }, 300);
  }



  search(evt) {
    this.listDivision = this.listDivisionCopy;
    const searchTerm = evt.toLowerCase();
    if (searchTerm.length > 0) {
      console.log("searchTerm", searchTerm);
      this.listDivision = this.listDivision.filter((user) => {
        // tslint:disable-next-line: max-line-length
        return (
          user.divisionData.code.toLowerCase().indexOf(searchTerm) > -1 ||
          user.divisionData.name.toLowerCase().indexOf(searchTerm) > -1
        );
      });
    } else {
      this.listDivision = this.listDivisionCopy;
    }
  }

  filterTypeDivision(type) {
    const searchTerm = type.toLowerCase();
    this.listDivision = this.listDivisionCopy;
    if (searchTerm.length > 0) {
      console.log("searchTerm", searchTerm);
      this.listDivision = this.listDivision.filter((user) => {
        // tslint:disable-next-line: max-line-length
        return (
          user.divisionData.code.toLowerCase().indexOf(searchTerm) > -1 ||
          user.divisionData.name.toLowerCase().indexOf(searchTerm) > -1
        );
      });
    } else {
      this.listDivision = this.listDivisionCopy;
    }
  }

  async getAllDivisionAdmin({ keydb, round }) {
    console.log({ keydb, round });
    this.manageUsersService
      .global({
        url: "division/getAllDivisionAdminResult",
        data: {
          keydb: this.customizationfileService.getKeyDb(),
          round: Number(round),
        },
      })
      .then((res: any) => {
        const result = this.sortReview(res.results);
        this.listDivision = result;
        this.listDivisionCopy = result;
        this.segmentFilter = "all";
      })
      .catch((err) => {
        console.log(err)
        this.commonService.presentAlert(JSON.stringify(err));
      });
  }

  sortReview(array) {
    return array.sort((a, b) => b.check - a.check);
  }



}
