import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController, NavParams } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { CommonService, ManageUsersService, CustomizationfileService } from 'src/app/services/services';

@Component({
  selector: 'app-backstage-online',
  templateUrl: './backstage-online.component.html',
  styleUrls: ['./backstage-online.component.scss'],
})
export class BackstageOnlineComponent implements OnInit {
  calendarRoom: any
  keydb: any;
  round: any;
  day: any;
  constructor(
    private navParams: NavParams,
    public modalController: ModalController,
    public db: AngularFireDatabase,
    public commonService: CommonService,
    public manageUsersService: ManageUsersService,
    public customizationfileService: CustomizationfileService,
    public alertController: AlertController) {
    this.round = this.navParams.get("round");
    this.keydb = this.navParams.get("keydb");
    this.day = this.navParams.get("day");

    console.log("this.keydb", this.keydb);
    console.log("this.round", this.round);
    console.log("this.day", this.day);
  }

  ngOnInit() {
    this.db.object(`/livingRoom/${this.keydb}/${this.day}/${this.round}/calendarRoom`)
      .valueChanges().subscribe((res: any) => {
        console.log("res", res)
        this.calendarRoom = res
      })
  }

  async enabledLiveZoomAlertConfirm(item) {
    console.log("item", item);
    const alert = await this.alertController.create({
      header: "enabled Live Zoom",
      buttons: [
        {
          text: "NO",
          role: "cancel",
          cssClass: "secondary",
          handler: async (blah) => {
            this.updateZoomLive({
              code: item.code, keyDivision: item.keyDivision, value: false
            })

          },
        },
        {
          text: "SI",
          handler: async () => {
            this.updateZoomLive({
              code: item.code, keyDivision: item.keyDivision, value: true
            })
          },
        },
      ],
    });

    await alert.present();
  }

  updateZoomLive({
    code, keyDivision, value
  }) {
    console.log("value", value)
    console.log(`/competition/${this.keydb}/mainChoreography/${code}/${this.round}/${keyDivision}`)
    // await this.db
    //   .object(
    //     `/competition/${this.keydb}/mainChoreography/${item.code}/${this.round}/${item.keyDivision}`
    //   )
    //   .update({
    //     zoomlive: true,
    //   });
  }


  show1(index2, array) {
    console.log(array, this[array]);
    console.log("index2", index2);
    for (let index = 0; index < this[array].length; index++) {
      if (index === index2) {
        this[array][index].show = !this[array][index].show;
      } else {
        this[array][index].show = false;
      }
    }
  }

  dismiss(status, message?) {
    this.modalController.dismiss({
      dismissed: true,
      status,
      message,
    });
  }

}
