import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { ToastController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { fromEvent, merge, of, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class InternetSpeedService {
  disconnectSubscription: any;
  connectSubscription: any;
  private online$: Observable<boolean> = undefined;
  private speed$: Observable<any> = undefined;
  constructor(private network: Network, private toastCtrl: ToastController, public platform: Platform) {
    this.speedInternet()
    this.onConnect()
  }


  async speedInternet() {
    console.log('speedInternet')
    let imageAddr = "https://www.movilzona.es/app/uploads/2018/10/app-foto-movimiento.jpg";
    let downloadSize = 4995374; //bytes


    let startTime, endTime;
    let download = new Image();
    download.onload = () => {
      endTime = (new Date()).getTime();
      // return this.showResults(endTime, startTime, downloadSize);

      const duration: any = (endTime - startTime) / 1000;
      const bitsLoaded = downloadSize * 8;
      const speedBps: any = (bitsLoaded / duration).toFixed(2);
      const speedKbps: any = (speedBps / 1024).toFixed(2);
      const speedMbps: any = (speedKbps / 1024).toFixed(2);

      let data = [
        "Your connection speed is:",
        speedBps + " bps",
        speedKbps + " kbps",
        speedMbps + " Mbps"
      ]


      this.speed$ = Observable.create(observer => {
        observer.next(data);
      }).pipe(mapTo(data));

    }

    download.onerror = (err, msg) => {
      console.log("Invalid image, or error downloading")
      console.log("msg", msg)
      console.log("err", err)

      this.speed$ = merge([
        "Invalid image, or error downloading",
      ]);

    }

    startTime = (new Date()).getTime();
    let cacheBuster = "?nnn=" + startTime;
    download.src = imageAddr + cacheBuster;
  }

  onConnect() {
    this.online$ = Observable.create(observer => {
      observer.next(true);
    }).pipe(mapTo(true));

    if (this.platform.is('cordova')) {
      // on Device
      this.online$ = merge(
        this.network.onConnect().pipe(mapTo(true)),
        this.network.onDisconnect().pipe(mapTo(false))
      );
    } else {
      // on Browser
      this.online$ = merge(
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(mapTo(true)),
        fromEvent(window, 'offline').pipe(mapTo(false))
      );
    }
  }


  public getNetworkType(): string {
    return this.network.type;
  }

  public getNetworkStatus(): Observable<boolean> {
    return this.online$;
  }

  public getSpeedInternet(): Observable<any> {
    return this.speed$;
  }




  async presentToast(text) {
    const toast = await this.toastCtrl.create({
      message: text,
      duration: 3000,
      position: 'top'
    });
    toast.present();
  }

}
