import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-result-change-list',
  templateUrl: './result-change-list.component.html',
  styleUrls: ['./result-change-list.component.scss'],
})
export class ResultChangeListComponent implements OnInit {
  private itemsCollection: AngularFirestoreCollection<any>;
  items: Observable<any[]>;
  @Input() round: any;
  @Input() code: any;
  @Input() keydb: any;
  constructor(public afs: AngularFirestore) {

  }

  ngOnInit() {
    console.log("round", this.round)
    console.log("keydb", this.keydb)
    console.log("code", this.code)
    this.itemsCollection = this.afs.collection<any>('ratings')
      .doc(this.keydb).collection('list', ref => ref
        .where('public', '==', true)
        .where('round', '==', this.round)
        .where('code', '==', this.code)
        .orderBy("date_modified", "desc"));
    this.items = this.itemsCollection.valueChanges({ idField: 'customID' });


  }

}
