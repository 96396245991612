import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import { tap, map, switchMap, first } from 'rxjs/operators';
import { of } from 'rxjs';
import { CustomizationfileService } from "../customizationfile/customizationfile.service";
import { TouchSequence } from 'selenium-webdriver';

@Injectable({
  providedIn: 'root'
})
export class PresenceService {
  profile: any
  keydb: any
  meetingDay: any
  meetingStage: any

  constructor(private afAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    public _cf: CustomizationfileService) {

    this.keydb = this._cf.getKeyDb()
    this.meetingDay = this._cf.getMeetingDay()
    this.meetingStage = this._cf.getMeetingStage()
    this.profile = this._cf.getprofile()


    this.updateOnUser().subscribe();
    this.updateOnDisconnect().subscribe();
    this.updateOnAway();
  }


  // Updates status when logged-in connection to Firebase starts
  updateOnUser() {
    const connection = this.db.object('.info/connected').valueChanges().pipe(map(connected => connected ? 'online' : 'offline'));
    return this.afAuth.authState.pipe(
      switchMap(user => user ? connection : of('offline')),
      tap(status => this.setPresenceBackstage(status))
    );
  }


  updateOnDisconnect() {
    return this.afAuth.authState.pipe(
      tap(user => {
        if (user) {
          if (!this._cf.getUid()) return
          setTimeout(() => {
            this.db.object(`presence/${this._cf.getUid()}`).query.ref.onDisconnect()
              .update({
                status: 'offline',
                timestamp: this.timestamp
              });
          }, 1000);

        }
      })
    );
  }

  async setPresenceBackstage(status: string) {
    const user = await this.getUser();
    if (user) {
      if (!this._cf.getUid()) return
      setTimeout(() => {
        return this.db.object(`presence/${this._cf.getUid()}`)
          .update({ status, timestamp: this.timestamp });
      }, 1000);

    }
  }

  // User navigates to a new tab, case 3
  updateOnAway() {
    document.onvisibilitychange = (e) => {
      if (document.visibilityState === 'hidden') {
        this.setPresenceBackstage('away')
      } else {
        this.setPresenceBackstage('online')
      }
    };

    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        // console.log("document is hidden");
      } else {
        // console.log("document is showing");
      }
    }
    );

  }


  getUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  get timestamp() {
    return firebase.database.ServerValue.TIMESTAMP;
  }

  async signOut() {
    await this.setPresenceBackstage('offline');
    await this.afAuth.auth.signOut();
  }
}
