import { Component, Input, OnInit } from "@angular/core";
import { ActionSheetController } from "@ionic/angular";
import {
  CommonService,
  CustomizationfileService,
  OneSignalPushService,
} from "src/app/services/services";
import { ChatService } from "src/app/services/v2/chat.service";
import { MediaFilesService } from "src/app/services/v2/media-files.service";
import { Camera } from "@ionic-native/camera/ngx";

@Component({
  selector: "app-send-message",
  templateUrl: "./send-message.component.html",
  styleUrls: ["./send-message.component.scss"],
})
export class SendMessageComponent implements OnInit {
  newMsg: any;
  disabledButton = false;
  @Input() infoChat;
  files: any;
  constructor(
    private camera: Camera,
    public mediaFilesService: MediaFilesService,
    public commonService: CommonService,
    public chatService: ChatService,
    public customizationfileService: CustomizationfileService,
    public actionSheetController: ActionSheetController,
    public oneSignalPushService: OneSignalPushService
  ) {}

  ngOnInit() {}

  async showActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: "Actions",
      buttons: [
        {
          text: "Photo & Video Library",
          handler: () => {
            console.log("IMAGE PICKER CLICKED");
            this.getPhotoAndVideo();
          },
        },
        {
          text: "Document",
          handler: () => {
            console.log("DOCUMENT PICKER CLICKED");
            // this.DocumentPicker();
          },
        },
        {
          text: "Cancelar",
          role: "cancel",
          handler: () => {
            console.log("Cancel clicked");
          },
        },
      ],
    });
    await actionSheet.present();
  }

  async getPhotoAndVideo() {
    try {
      const captureUrl: any = await this.mediaFilesService.selectMedia({
        mediaType: this.camera.MediaType.ALLMEDIA,
      });

      const ext = captureUrl.substring(
        captureUrl.indexOf("/") + 1,
        captureUrl.indexOf(";base64")
      );

      console.log("ext", ext);

      const extFinal = this.commonService.isImage(ext) ? "png" : "mp4";
      const contentType = this.commonService.isImage(ext)
        ? "image/jpeg"
        : "video/mp4";

      console.log("extFinal", extFinal);
      console.log("contentType", contentType);

      const urlMedia = await this.mediaFilesService.upload({
        captureUrl,
        contentType,
        newName: `CHAT-${Date.now()}.${extFinal}`,
        codeDivision: this.customizationfileService.getUid(),
      });

      console.log("mediaFilesService", urlMedia);

      this.addChatMessage({
        msg: false,
        doc: true,
        msg_translate: false,
        isImage: this.commonService.isImage(ext),
        isVideo: this.commonService.isVideo(ext),
        media: urlMedia,
        from: this.customizationfileService.getUid(),
        to: this.infoChat.uuid,
        uuidAdmin: this.customizationfileService.getUid(),
        department: this.infoChat.department,
      });
    } catch (err) {
      console.log("err", err);
    }
  }

  async uploadFile($event) {
    try {
      this.disabledButton = true;
      console.log("$event", $event);
      this.files = $event.target.files;
      console.log("this.files ", this.files);
      const ext = this.files[0].name.substring(
        this.files[0].name.lastIndexOf(".") + 1
      );

      console.log("extension", ext);
      const captureUrl = await this.mediaFilesService.getBase64(this.files[0]);
      console.log("this.files[0]", this.files[0]);
      console.log("captureUrl", captureUrl);
      const urlMedia = await this.mediaFilesService.uploadFile(captureUrl);

      this.addChatMessage({
        msg: false,
        doc: true,
        msg_translate: false,
        isImage: this.commonService.isImage(ext),
        isVideo: this.commonService.isVideo(ext),
        media: urlMedia,
        from: this.customizationfileService.getUid(),
        to: this.infoChat.uuid,
        uuidAdmin: this.customizationfileService.getUid(),
        department: this.infoChat.department,
      });
      this.disabledButton = false;
    } catch (err) {
      console.log("videoUrl", err);
      this.disabledButton = false;
      this.commonService.presentAlert("ERROR");
    }
  }

  async sendMessage() {
    try {
      if (!this.newMsg) {
        return;
      }
      const mess = this.newMsg.replace(/(\r\n|\n|\r)/gm, "");
      const target = this.infoChat.translate === "es" ? "en" : "es";
      const translate: any = await this.chatService.translator({
        url: "translator/translate",
        data: {
          text: mess,
          target: target,
        },
      });

      console.log({
        msg: mess,
        msg_translate: translate.results[0],
      });
      this.addChatMessage({
        msg: mess,
        msg_translate: translate.results[0],
        doc: true,
        isImage: false,
        isVideo: false,
        media: false,
        from: this.customizationfileService.getUid(),
        to: this.infoChat.uuid,
        uuidAdmin: this.customizationfileService.getUid(),
        department: this.infoChat.department,
      });
    } catch (err) {
      this.commonService.presentAlert("ERROR");
    }
  }

  async addChatMessage({
    msg,
    doc,
    isImage,
    isVideo,
    media,
    from,
    to,
    department,
    uuidAdmin,
    msg_translate,
  }) {
    try {
      this.chatService.addChatMessage({
        msg,
        doc,
        department,
        isImage,
        isVideo,
        media,
        from,
        to,
        uuidAdmin,
        msg_translate,
        uidChat: this.infoChat.uidChat,
        tokenUser: this.infoChat.tokenUser,
        avatarUser: false,
        tokenAdmin: await this.oneSignalPushService.getUserIdOneSignal(),
        avatarAdmin: this.customizationfileService.getavatar(),
      });
      this.disabledButton = false;
      this.newMsg = "";
    } catch (err) {
      console.log("err", err);
      this.commonService.presentAlert("error");
      this.disabledButton = false;
    }
  }
}
