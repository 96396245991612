import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor(private storage: Storage) { }

  storageSet(key, value) {
    this.storage.set(key, value);
  }

  async storageGet(key) {
    return await this.storage.get(key);
  }

  localStorageSetItem(key, value) {
    return localStorage.setItem(key, value);
  }

  localStorageRemoveItem(key) {
    return localStorage.removeItem(key);
  }

  localStorageGetItem(key) {
    return localStorage.getItem(key);
  }
}
