import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { CommonService, CustomizationfileService, DivisionService } from 'src/app/services/services';

@Component({
  selector: 'app-move-division',
  templateUrl: './move-division.component.html',
  styleUrls: ['./move-division.component.scss'],
})
export class MoveDivisionComponent implements OnInit {
  divisionData: any;
  uid: string;
  keydb: string;
  dataBlock: any;
  division: any;

  submitted = false;
  public divisionForm: FormGroup;

  constructor(
    public customizationfileService: CustomizationfileService,
    public divisionService: DivisionService,
    public db: AngularFireDatabase,
    private afs: AngularFirestore,
    public commonService: CommonService,
    public modalController: ModalController,
    private navParams: NavParams,
    public fb: FormBuilder) {
    this.divisionForm = fb.group({
      block: ["", Validators.compose([Validators.required])],
      division: ["", Validators.compose([Validators.required])],
    });
  }

  get f() {
    return this.divisionForm.controls;
  }

  async ngOnInit() {
    this.uid = this.customizationfileService.getUid()
    this.keydb = this.customizationfileService.getKeyDb()
    this.divisionData = this.navParams.get("data");
    console.log("this.divisionData", this.divisionData)
    this.dataBlock = await this.getBlock()
  }



  async transferDivision() {
    this.submitted = true;

    // stop here if form is invalid
    console.log("invalid", this.divisionForm.invalid);
    if (this.divisionForm.invalid) {
      this.commonService.presentAlertTranslation("error en el formulario")
      return;
    }

    const divisionform = this.divisionForm.value;
    console.log("divisionform", divisionform)

    const category_detail = divisionform.division

    const oldKeyDivision = this.divisionData.keyDivision
    const oldCodeDivision = this.divisionData.code
    const round = this.divisionData.round
    const NEW_CODE_DIVISION = divisionform.division.id_sub_categoria

    console.log("old division", this.divisionData);
    console.log("new division", divisionform)
    console.warn("category_detail", category_detail)
    console.log("NEW_CODE_DIVISION", NEW_CODE_DIVISION);

    let rest = await this.commonService.presentAlertConfirm({ message: `you want to move this division from  ${oldCodeDivision} to ${NEW_CODE_DIVISION}` })
    if (!rest || oldCodeDivision === NEW_CODE_DIVISION) { return }


    await this.commonService.presentLoading()


    // Obtenemos Data vieja
    let division = await this.getData(`/competition/${this.keydb}/division/${oldKeyDivision}`)
    let testVideos = await this.getData(`/competition/${this.keydb}/testVideos/${oldCodeDivision}/${round}/${oldKeyDivision}`)
    let mainChoreography = await this.getData(`/competition/${this.keydb}/mainChoreography/${oldCodeDivision}/${round}/${oldKeyDivision}`)
    let multimediaContent = await this.getData(`/competition/${this.keydb}/multimediaContent/${oldCodeDivision}/${round}/${oldKeyDivision}`)
    let practiceVideoList = await this.getData(`/competition/${this.keydb}/practiceVideoList/${oldCodeDivision}/${round}/${oldKeyDivision}`)


    // actualizamos data nueva
    await this.setData(`/competition/${this.keydb}/division/${oldKeyDivision}/categories/0/category_detail`, category_detail)
    await this.setData(`/competition/${this.keydb}/division/${oldKeyDivision}/categories/0/category_detail/durationMusic`, { "max": 400, "min": 60 })
    await this.setData(`/competition/${this.keydb}/testVideos/${NEW_CODE_DIVISION}/${round}/${oldKeyDivision}`, testVideos)
    await this.setData(`/competition/${this.keydb}/mainChoreography/${NEW_CODE_DIVISION}/${round}/${oldKeyDivision}`, mainChoreography)
    await this.setData(`/competition/${this.keydb}/multimediaContent/${NEW_CODE_DIVISION}/${round}/${oldKeyDivision}`, multimediaContent)
    await this.setData(`/competition/${this.keydb}/practiceVideoList/${NEW_CODE_DIVISION}/${round}/${oldKeyDivision}`, practiceVideoList)


    //borramos data vieja
    await this.setRemove(`/competition/${this.keydb}/testVideos/${oldCodeDivision}/${round}/${oldKeyDivision}`, null)
    await this.setRemove(`/competition/${this.keydb}/mainChoreography/${oldCodeDivision}/${round}/${oldKeyDivision}`, null)
    await this.setRemove(`/competition/${this.keydb}/multimediaContent/${oldCodeDivision}/${round}/${oldKeyDivision}`, null)
    await this.setRemove(`/competition/${this.keydb}/practiceVideoList/${oldCodeDivision}/${round}/${oldKeyDivision}`, null)



    await this.setData(`/competition/${this.keydb}/transferDivision/${oldKeyDivision}-${Date.now()}`, {
      round,
      NEW_CODE_DIVISION,
      oldCodeDivision,
      division,
      testVideos,
      mainChoreography,
      multimediaContent,
      practiceVideoList,
      date: Date.now(),
      uid_admin: this.uid
    })


    this.commonService.presentAlert("successfully moved")
    this.commonService.dismissLoading()
    this.modalController.dismiss({
      dismissed: true,
      status: true,
      KeyDivision: oldKeyDivision,
      code: NEW_CODE_DIVISION,
      round: round,
    });


  }



  getData(url) {
    return new Promise(async (resolve,) => {
      this.db.object(url)
        .valueChanges()
        .subscribe(res => {
          console.log("url", url)
          if (!res) { resolve(null) }
          resolve(res)
        })
    })
  }

  setData(url, data) {
    return new Promise(async (resolve) => {
      await this.db.object(url).set(data).then(() => {
        console.log("url", url)
        resolve({})
      })
    })
  }

  setRemove(url, data) {
    return new Promise(async (resolve) => {
      await this.db.object(url)
        .remove().then(() => {
          console.log("url", url)
          resolve({})
        })
    })
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
      status: false
    });
  }


  getDivisionText(port: any, portIndex: number, ports: any[]) {
    if (
      portIndex === 0 ||
      port.id_sub_categoria !== ports[portIndex - 1].id_sub_categoria
    ) {
      return port.id_sub_categoria;
    }
    return null;
  }

  getBlockText(port: any, portIndex: number, ports: any[]) {
    if (
      portIndex === 0 ||
      port.id_categoria !== ports[portIndex - 1].id_categoria
    ) {
      return port.categoria;
    }
    return null;
  }

  async getBlock() {
    return new Promise(async (resolve, reject) => {
      let result: any = await this.divisionService
        .getBlock({ keydb: this.keydb })
        .catch(err => {
          console.log("err", err)
          this.commonService.presentAlertTranslation("Ocurrió un error, comuníquese con un administrador")
          reject(err)
        })

      if (!result) { resolve([]) }
      resolve(result.sort())

    })
  }

  async searchCategory(block) {
    if (block) {
      console.log("block", block)
      const id = block.value;
      this.afs
        .collection("divisions_list")
        .doc(this.keydb)
        .collection("list", (ref) => ref.where("id_categoria", "==", id).orderBy("sub_categoria"))
        .valueChanges({ idField: "id" })
        .subscribe((division) => {
          if (division.length > 0) {
            this.division = division.sort((a, b) => {
              if (a.id_sub_categoria > b.id_sub_categoria) {
                return 1;
              }
              if (a.id_sub_categoria < b.id_sub_categoria) {
                return -1;
              }
              return 0;
            });
          } else {
            this.commonService.presentAlert("There are no divisions enabled for this block");
          }
        });
    }
  }


}
