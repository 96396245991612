import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Pipe({
  name: 'getNameJubge'
})
export class GetNameJubgePipe implements PipeTransform {

  constructor(public db: AngularFireDatabase) { }
  async transform(value: any) {
    const name = await this.getProfileJudges(value)
    return name;
  }

  getProfileJudges(uid) {
    return new Promise((resolve) => {
      this.db
        .object(`/users/${uid}/profile`)
        .valueChanges()
        .subscribe((x: any) => {
          if (x) {
            resolve(`${x.name} ${x.surnames}`);
          } else {
            resolve("N/A");
          }
        });
    });
  }

}
