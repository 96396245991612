import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortLocalNotification'
})
export class SortLocalNotificationPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {

      console.log(value)

      return value.sort((a, b) => {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return a.trigger.at - b.trigger.at
      })
    }
    return null;
  }

}
