import {
  OneSignal,
  OSNotification,
  OSNotificationPayload,
} from "@ionic-native/onesignal/ngx";
import { Injectable, EventEmitter } from "@angular/core";
import { Storage } from "@ionic/storage";
import { ModalController, Platform } from "@ionic/angular";
import { NotificationViewComponent } from "../../../components/notification-view/notification-view.component";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class OneSignalPushService {
  public notifications = new BehaviorSubject(false);
  mensajes: any[] = [];

  userId: string;

  pushListener = new EventEmitter<OSNotificationPayload>();

  constructor(
    public router: Router,
    private oneSignal: OneSignal,
    private storage: Storage,
    public modalController: ModalController,
    public platform: Platform
  ) {
    this.cargarMensajes();
  }

  async configuracionInicial() {
    if (this.platform.is("cordova")) {
      this.oneSignal.startInit(
        "6ec8da52-63c1-4fad-9513-95d9ed48ad3f",
        "1046500872667"
      );

      this.oneSignal.inFocusDisplaying(
        this.oneSignal.OSInFocusDisplayOption.Notification
      );

      this.oneSignal.handleNotificationReceived().subscribe((noti) => {
        // do something when notification is received
        console.log("Notificación recibida", noti);
        this.notifications.next(true);
        this.notificacionRecibida(noti);
      });

      this.oneSignal.handleNotificationOpened().subscribe(async (noti) => {
        // do something when a notification is opened
        console.log("Notificación abierta", noti);
        await this.notificacionRecibida(noti.notification);
        this.actionNoticationPush(noti.notification);
      });

      this.oneSignal.endInit();
    }
  }

  getIdsOneSignal() {
    // Obtener ID del suscriptor
    this.oneSignal.getIds().then((info) => {
      this.userId = info.userId || "bb4c4088-3427-44ff-8380-570aa6c1ce1a";
      console.log("Obtener ID del suscriptor", this.userId);
    });
  }

  async getUserIdOneSignal() {
    if (this.platform.is("cordova")) {
      console.log("Cargando userId");
      // Obtener ID del suscriptor
      const info = await this.oneSignal.getIds();
      this.userId = info.userId;
      console.log("Cargando userId", this.userId);
      return info.userId;
    } else {
      return false;
    }
  }

  async notificacionRecibida(noti: OSNotification) {
    await this.cargarMensajes();

    const payload = noti.payload;

    const existePush = this.mensajes.find(
      (mensaje) => mensaje.notificationID === payload.notificationID
    );

    if (existePush) {
      return;
    }

    const notication = {
      type: payload.additionalData.type ? payload.additionalData.type : 1,
      notificationID: payload.notificationID,
      img: payload.additionalData.img ? payload.additionalData.img : false,
      title: payload.title,
      subtitle: payload.body,
      date: Date.now(),
      body: payload.additionalData.body,
      url: payload.additionalData.url ? payload.additionalData.url : false,
    };
    this.mensajes.unshift(notication);
    this.pushListener.emit(payload);

    this.guardarMensajes();
  }

  async actionNoticationPush(noti: OSNotification) {
    if (noti.payload.additionalData.type === 1) {
      // modal de notificaciones
      const modal = await this.modalController.create({
        component: NotificationViewComponent,
        componentProps: {
          details: {
            img: noti.payload.additionalData.img
              ? noti.payload.additionalData.img
              : false,
            title: noti.payload.title,
            subtitle: noti.payload.body,
            body: noti.payload.additionalData.body,
            url: noti.payload.additionalData.url
              ? noti.payload.additionalData.url
              : false,
          },
        },
      });
      return await modal.present();
    } else if (noti.payload.additionalData.type === 2) {
      // Chat
      this.router.navigate(["/help-desk/chats"]);
    }
  }

  guardarMensajes() {
    this.storage.set("mensajes", this.mensajes);
  }

  async cargarMensajes() {
    this.mensajes = (await this.storage.get("mensajes")) || [];

    return this.mensajes;
  }

  async borrarMensajes() {
    await this.storage.clear();
    this.mensajes = [];
    this.guardarMensajes();
  }

  async getMensajes() {
    await this.cargarMensajes();
    return [...this.mensajes];
  }
}
