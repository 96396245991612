import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ScrollEventService {
  public scrollEvent = new BehaviorSubject(0);
  public updateAdminEvent = new BehaviorSubject(true);
  constructor() {}
}
