import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, ModalController, NavParams } from "@ionic/angular";
import { AngularFireDatabase } from "angularfire2/database";
import {
  CommonService,
  CustomizationfileService,
} from "src/app/services/services";

@Component({
  selector: "app-rate-items",
  templateUrl: "./rate-items.component.html",
  styleUrls: ["./rate-items.component.scss"],
})
export class RateItemsComponent implements OnInit {
  session: any;
  items: any;
  code: any;
  data: any;
  keydb: any;
  averagetotalminuserror: number;
  round: any;
  constructor(
    public db: AngularFireDatabase,
    public customizationfileService: CustomizationfileService,
    public commonService: CommonService,
    public alertController: AlertController,
    public modalController: ModalController,
    public router: Router,
    private navParams: NavParams
  ) {}

  ngOnInit() {
    this.items = this.navParams.get("items");
    this.reset();
    this.code = this.navParams.get("code");
    this.data = this.navParams.get("data");
    this.keydb = this.navParams.get("keydb");
    this.round = this.navParams.get("round");

    console.log("this.items", this.items);
    console.log("this.code", this.code);
    console.log("this.data", this.data);
    console.log("this.keydb", this.keydb);
  }

  async presentAlertConfirm() {
    if (this.averagetotalminuserror) {
      const pts = this._toFixed(this.averagetotalminuserror);
      const alert = await this.alertController.create({
        header: "La puntuación total del competidor es",
        message: `${pts} pts`,
        backdropDismiss: false,
        buttons: [
          {
            text: "NO",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "SI",
            handler: async () => {
              this.saveRating();
            },
          },
        ],
      });

      await alert.present();
    }
  }

  async saveRating() {
    try {
      await this.commonService.presentLoading();
      console.log("items", this.items);
      console.log("this.averagetotalminuserror", this.averagetotalminuserror);
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i].score == undefined || this.items[i].score == 0) {
          let m = `Watch out! You missing ${this.items[i].name} for score 🤚`;
          this.commonService.dismissLoading();
          this.commonService.presentAlert(m);
          return;
        }
      }

      const uid = this.customizationfileService.getUid();
      console.log(
        `rate/${this.keydb}/${this.code}/${this.round}/${this.data.key}/items/${uid}`
      );
      await this.db
        .object(
          `rate/${this.keydb}/${this.code}/${this.round}/${this.data.key}/items/${uid}`
        )
        .set(this.items);
      this.commonService.dismissLoading();
      this.commonService.presentAlert("calificación guardada con éxito");
      this.averagetotalminuserror = 0;
      this.dismiss(1);
      this.reset();
    } catch (err) {
      this.commonService.dismissLoading();
      this.commonService.presentAlert(JSON.stringify(err));
    }
  }

  reset() {
    for (var i = 0; i < this.items.length; i++) {
      this.items[i].score = 0;
    }
  }

  onSearchChange() {
    this.averagetotalminuserror = 0;
    for (var i = 0; i < this.items.length; i++) {
      if (this.items[i].score) {
        this.averagetotalminuserror +=
          parseFloat(this.items[i].score) / this.items.length;
      }
    }
  }

  _toFixed(value) {
    if (!value) return;
    return value.toFixed(2);
  }

  dismiss(status, message?) {
    this.modalController.dismiss({
      dismissed: true,
      status,
      message,
    });
  }
}
