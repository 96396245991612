import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AssigmentService {

  public collection: string = 'auth__assigments';

  constructor(
    private afs: AngularFirestore
  ) { }

  async findUseByEmail(email: string){
    return this.afs
      .collection('users', (ref) => ref.where('email', '==', email))
      .valueChanges({ idField: "_id" })
      .pipe( take(1) )
      .toPromise();
  }

  async findUserAssigment(uid: string): Promise<any>{
    return this.afs
      .collection(this.collection)
      .doc(uid)
      .valueChanges()
      .pipe( take(1) )
      .toPromise();
  }


  async store(uid: string, data: any){
    return this.afs.collection(this.collection)
      .doc(uid)
      .set(Object.assign({}, data, { created_at: Date.now() }));
  }

  async update(uid: string, data: any){
    return this.afs.collection(this.collection)
      .doc(uid)
      .set(Object.assign({}, data, { updated_at: Date.now() }));
  }

  async destroy(uid: string){
    return this.afs.collection(this.collection)
      .doc(uid)
      .delete();
  }
}