import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { map } from "rxjs/operators";
import { CustomizationfileService } from "../v1/customizationfile/customizationfile.service";

@Injectable({
  providedIn: "root",
})
export class FirestoreService {
  constructor(
    public customizationfileService: CustomizationfileService,
    public firestore: AngularFirestore
  ) { }

  getListDivision({ keydb }) {
    console.log(1, keydb);
    return this.firestore
      .collection("divisions_list")
      .doc(keydb)
      .collection("list")
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  updateDivisionCode({ code, division, keydb }) {
    return this.firestore
      .collection("divisions_list")
      .doc(keydb)
      .collection("list")
      .doc(code)
      .update(division);
  }
}
