import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { CustomizationfileService } from 'src/app/services/services';

@Component({
  selector: 'app-pay-judge',
  templateUrl: './pay-judge.component.html',
  styleUrls: ['./pay-judge.component.scss'],
})
export class PayJudgeComponent implements OnInit {
  @Input() details: any;
  listData: any
  constructor(
    public modalController: ModalController,
    public customizationfileService: CustomizationfileService,
    public db: AngularFireDatabase,
    public afs: AngularFirestore) { }

  async ngOnInit() {
    let wallet = await this.getjudgesWallet(this.details.uid)
    let walletLog: any = await this.getpaymentRequest(this.details.uid)
    console.log("details", this.details)
    console.log("wallet", wallet)
    console.log("walletLog", walletLog)
    delete this.details.paymentDetails

    walletLog.judgesWallet

    let mergeJson = Object.assign(this.details, wallet, walletLog.judgesWallet)
    this.listData = Object.keys(mergeJson).map(x => {
      return { key: x, value: mergeJson[x] }
    })
  }


  getpaymentRequest(uid) {
    return new Promise(async (resolve, reject) => {
      this.afs.collection('Log-addPayment', ref =>
        ref.where('keydb', '==', this.customizationfileService.getKeyDb())
          .where('uid', '==', uid)
          .where('type', '==', 'payment request')
          .orderBy('date', 'desc'))
        .valueChanges({ idField: 'key' })
        .subscribe(res => {
          if (!res) {
            resolve([])
          }
          resolve(res[0])
        })
    })
  }


  getjudgesWallet(uid) {
    return new Promise((resolve, reject) => {
      this.db.object(`/judgesWallet/${uid}`)
        .valueChanges()
        .subscribe(res => {
          if (!res) {
            reject(res)
          }

          resolve(res)
        })
    })

  }



  dismiss() {
    this.modalController.dismiss();
  }

}
