// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  version: "SETTLE-1",
  eventDefault: "5O8DT7g9eJnMRSyRoRGJ",
  urlDB: "https://wldc-app.firebaseio.com/",
  urlrootFunctions: "https://wldc-app.uc.r.appspot.com",
  googleMapsAPIKey: "AIzaSyAbiBNskmI9DW3kK9N3Fgux9FbfqYtJY4g",
  bnfAcademy: "KCDSwbMtuRSBcrAwbkaH",
  appIdOneSignal: "34f7f606-13f0-4956-9509-371ee9e7ffe6",
  googleProjectNumberOneSignal: "580276769263",
  indicative: "+971",
  paypal: {
    environments_paypal: "PayPalEnvironmentProduction",
    paypal_mod_pwa: "production",
    payPalEnvironmentProduction:
      "AY3m2F6IWR-K9LDNuEYM8hBqt15yARFfiRVItDuvZyEcmLpHGBdKS9otkHiH_pb_qJB7TN8rh9mV6wtW",
    payPalEnvironmentSandbox:
      "ATqhoEhiL3_-En4pdU0mB-3hA6hE3-8RzgBaVX87nWlZzL4AGn2dzX71uK08s3jDSM19QY-z9aZ9r4Fz",
  },
  payu: {
    url_payu: "https://checkout.payulatam.com/ppp-web-gateway-payu",
    apiKey: "7nUq55H8xStf5pk6Qh0qCe2TE1",
    accountId: "652114",
    merchantId: "649637",
    responseUrl: "https://app.settlework.com/members/user/record",
    referenceCode: `SETTLE-${Math.round(
      Math.random() * (9000000 - 1000000) + 1000000
    )}`,
    test: "0",
  },
  adcb: {
    url: "https://NeO.network.ae/direcpay/secure/PaymentTxnServlet",
    url_adcb: "https://settle-php.uc.r.appspot.com",
    Network_Online_setting: {
      merchantKey: "OvbN0BEmjrJXpatEaSz7Jt88JdQikoDSM8S4QyDigH0=",
      merchantId: "201909081000001",
      collaboratorId: "ADCB",
      iv: "0123456789abcdef",
      url: true,
    },
    Block_Existence_Indicator: {
      transactionDataBlock: true,
      billingDataBlock: true,
      shippingDataBlock: true,
      paymentDataBlock: false,
      merchantDataBlock: true,
      otherDataBlock: false,
      DCCDataBlock: false,
    },
    Field_Existence_Indicator_Transaction: {
      merchantOrderNumber: Date.now(),
      transactionMode: "INTERNET",
      payModeType: "",
      transactionType: "01",
    },
  },
  bnf_adcb: {
    url: "https://NeO.network.ae/direcpay/secure/PaymentTxnServlet",
    url_adcb: "https://settle-php.uc.r.appspot.com",
    Network_Online_setting: {
      merchantKey: "OvbN0BEmjrJXpatEaSz7Jt88JdQikoDSM8S4QyDigH0=",
      merchantId: "201909081000001",
      collaboratorId: "ADCB",
      iv: "0123456789abcdef",
      url: true,
    },
    Block_Existence_Indicator: {
      transactionDataBlock: true,
      billingDataBlock: true,
      shippingDataBlock: true,
      paymentDataBlock: false,
      merchantDataBlock: true,
      otherDataBlock: false,
      DCCDataBlock: false,
    },
    Field_Existence_Indicator_Transaction: {
      merchantOrderNumber: Date.now(),
      transactionMode: "INTERNET",
      payModeType: "",
      transactionType: "01",
    },
  },
  zoom: {
    SDK_KEY_SDK: "GrwJ9r42vwhGUA8jMkEGGYvmHedDlWGdOLSY",
    SDK_SECRET_SDK: "a5G5YfFPslV4zm0E6az9MU3JmkVHVN4q94VN",
    JWT_API_KEY: "2vOK5GACQVGo8OXLVW0Fzw",
    userName: "bnfmeetings@gmail.com",
    password: "",
  },
  firebaseConfig: {
    apiKey: "AIzaSyC1MxrCfXicJ8gxAOMJut2fGouYH0QjMao",
    authDomain: "wldc-app.firebaseapp.com",
    databaseURL: "https://wldc-app.firebaseio.com",
    projectId: "wldc-app",
    storageBucket: "wldc-app.appspot.com",
    messagingSenderId: "1046500872667",
    appId: "1:1046500872667:web:39c435daa2f776166f17fe",
    measurementId: "G-8YQ92LN4ZK",
  },
  vimeo: {
    api: "https://api.vimeo.com/me/videos",
    client_id: "c39d4f179efb32d850b156f0bd7a33a56ab90fd2",
    client_secret:
      "36Xf5TiUHQU5Gu4Y7c5kFkDkMxPmE7sANBe56GcRrzYd5H7acPDI4iBmpRj0sXP5HJ6pqBE7Ejtrq1NWXxh2ZR4qRSMx0wfluCtDoCNhz0KGmj+9mTCpQazqiOxdTRFD",
    access_token: "9b24cbc7a903851184ad394d51ffea88",
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
