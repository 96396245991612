import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { map } from 'rxjs/operators';
import { CommonService, CustomizationfileService } from 'src/app/services/services';
import { SeeCouponComponent } from '../see-coupon/see-coupon.component';

@Component({
  selector: 'app-coupon-list',
  templateUrl: './coupon-list.component.html',
  styleUrls: ['./coupon-list.component.scss'],
})
export class CouponListComponent implements OnInit {


  couponList = []
  copyCouponList: any;

  constructor(
    public commonService: CommonService,
    public alertController: AlertController,
    public db: AngularFireDatabase,
    public customizationfileService: CustomizationfileService,
    public modalController: ModalController,) {

  }

  ngOnInit() {
    this.db.list(`coupon`, ref => ref.orderByChild('keydb')
      .equalTo(this.customizationfileService.getKeyDb()))
      .snapshotChanges()
      .pipe(map(changes =>
        changes.map((c: any) => ({ key: c.payload.key, ...c.payload.val() }))
      )).subscribe(res => {
        this.couponList = res.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        this.copyCouponList = this.couponList
        this.segmentChanged(null, true)
        console.log("this.couponList", this.couponList)
      })
  }

  // segmentChanged(event) {
  //   console.log(event.detail.value)
  // }

  segmentChanged(event, evet2?) {
    let searchTerm
    if (event) {
      searchTerm = event.detail.value === "true" ? true : false;
    } else {
      searchTerm = evet2
    }
    this.couponList = this.copyCouponList;
    this.couponList = this.copyCouponList.filter(word => word.enabled === searchTerm);
    console.log(this.couponList)
  }

  async removeCoupon(item) {
    const alert = await this.alertController.create({
      message: 'Message <strong>text</strong>!!!',
      buttons: [
        {
          text: this.commonService.getTranslation("no"),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: this.commonService.getTranslation("yes"),
          handler: () => {
            console.log('Confirm Okay', item.key);
            this.db.list('coupon').remove(item.key)
            this.commonService.presentAlertTranslation("eliminado con éxito")
          }
        }
      ]
    });

    await alert.present();
  }


  // copyInputMessage(inputElement) {
  //   this.commonService.copyInputMessage(inputElement)
  //   console.log("inputElement", inputElement)
  // }

  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.commonService.presentToast("copied to clipboard")
  }


  async seeCoupon(item) {
    const modal = await this.modalController.create({
      component: SeeCouponComponent,
      cssClass: "my-custom-modal-css",
      componentProps: { details: item }
    });
    return await modal.present();
  }


}
