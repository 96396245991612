import { Component, OnInit } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from "@angular/fire/firestore";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { Item } from "src/app/model/item";
import { Output, EventEmitter } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { CommonService } from "src/app/services/services";

@Component({
  selector: "app-list-chat",
  templateUrl: "./list-chat.component.html",
  styleUrls: ["./list-chat.component.scss"],
})
export class ListChatComponent implements OnInit {
  private chatListCollection: AngularFirestoreCollection<any>;
  department$: BehaviorSubject<string | null>;
  @Output() infoChat = new EventEmitter<string>();
  chatSegment = "chat";
  department = "";

  chatList: any;
  chatListCopy: any[];

  constructor(
    public commonService: CommonService,
    private readonly afs: AngularFirestore
  ) {}

  ngOnInit() {
    this.chatMessages();
  }

  chatMessages() {
    console.log("this.department", this.department);
    this.afs
      .collection<any>("chat-messages", (ref) => {
        let query:
          | firebase.firestore.CollectionReference
          | firebase.firestore.Query = ref;

        if (this.department) {
          console.log("this.department", this.department);
          query = query.where("department", "==", this.department);
        }
        // query.orderBy("createdAt");
        query.orderBy("read", "asc");

        return query;
      })
      .valueChanges({ idField: "uuid" })
      .subscribe((res) => {
        if (!res) {
          return;
        }
        this.chatList = res.sort((x, y) => {
          // true values first
          return x.read === y.read ? 0 : x ? -1 : 1;
          // false values first
          // return (x === y)? 0 : x? 1 : -1;
        });
        this.chatListCopy = this.chatList;
      });
  }

  search(type) {
    const searchTerm = type.toLowerCase();
    this.chatList = this.chatListCopy;

    if (searchTerm.length >= 3) {
      console.log("searchTerm", searchTerm);
      this.chatList = this.chatListCopy.filter((user) => {
        // tslint:disable-next-line: max-line-length
        return user.name.toLowerCase().indexOf(searchTerm) > -1;
      });
    } else {
      this.chatList = this.chatListCopy;
    }
  }

  viewChat(item) {
    this.readChat(item);
    this.infoChat.emit(item);
  }

  readChat(item) {
    this.afs
      .collection<Item>("chat-messages")
      .doc(item.uuid)
      .update({ read: true });
  }

  trackByFn(index, item) {
    return index; // or item.id
  }
}
