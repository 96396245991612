import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { ModalController, NavParams } from "@ionic/angular";
import {
  CommonService,
  CustomizationfileService,
  LanguageService,
  ManageUsersService,
  RatingsService,
} from "src/app/services/services";
import { MediaFilesService } from "src/app/services/v2/media-files.service";

@Component({
  selector: "app-open-case-amodal",
  templateUrl: "./open-case-amodal.component.html",
  styleUrls: ["./open-case-amodal.component.scss"],
})
export class OpenCaseAModalComponent implements OnInit {
  division: any;
  erros: any;
  items: any;
  itemsJunior: any[];
  value: any;
  seconds = new Array(60);

  claimObj = {
    times: {
      s: 0,
      m: 0,
    },
    description: "",
    files: false,
    videos: false,
    images: false,
  };
  constructor(
    public languageService: LanguageService,
    private afs: AngularFirestore,
    public mediaFilesService: MediaFilesService,
    public customizationfileService: CustomizationfileService,
    public manageUsersService: ManageUsersService,
    public modalController: ModalController,
    private navParams: NavParams,
    public ratingsService: RatingsService,
    public commonService: CommonService
  ) {}

  ngOnInit() {
    this.value = this.navParams.get("value");
    this.getData();
  }

  async getData() {
    await this.commonService.presentLoading();

    this.division = await this.manageUsersService.globalFirebase({
      url: `/competition/${this.value.keydb}/division/${this.value.keyDivision}`,
    });
    console.log("division", this.division);

    if (this.value.id === 1) {
      const erros = await this.manageUsersService.globalFirebase({
        url: `/rate/${this.value.keydb}/${this.value.code}/${this.value.round}/${this.value.keyDivision}/erros`,
      });
      const error2 = this.commonService.addKeyError(erros);
      this.erros = this.ratingsService.getJudgesErrosDetails(error2);
      console.log("erros", this.erros);
    }

    if (this.value.id === 2) {
      const items = await this.manageUsersService.globalFirebase({
        url: `/rate/${this.value.keydb}/${this.value.code}/${this.value.round}/${this.value.keyDivision}/items`,
      });
      const items2 = this.commonService.addKey(items);

      /**
       * TODO: de aca es la multimedia
       */
      this.items = this.ratingsService.getJudgesItemsDetails({
        data: items2,
        keydb: this.value.keydb,
        code: this.value.code,
        round: this.value.round,
        keyDivision: this.value.keyDivision,
      });
      console.log("items", this.items);
    }

    this.commonService.dismissLoading();
  }

  uploadFile($event, index) {
    if (index === 1) {
      this.claimObj.files = $event.target.files;
      console.log(Object.keys(this.claimObj.files).length);
    }
    if (index === 2) {
      this.claimObj.videos = $event.target.files;
    }
    if (index === 3) {
      this.claimObj.images = $event.target.files;
    }
  }

  async submitClaim() {
    if (!this.claimObj.description) {
      return;
    }

    let videos, images, files: any;
    await this.commonService.presentLoading();

    if (this.claimObj.files) {
      const fileList = this.getArrayFile(this.claimObj.files);
      files = await this.getUrlFile(fileList);
    }
    if (this.claimObj.videos) {
      const fileList = this.getArrayFile(this.claimObj.videos);
      videos = await this.getUrlFile(fileList);
    }
    if (this.claimObj.images) {
      const fileList = this.getArrayFile(this.claimObj.images);
      images = await this.getUrlFile(fileList);
    }

    let result = {
      type: this.value.id,
      files: {
        videos: videos ? videos : false,
        images: images ? images : false,
        files: files ? files : false,
      },
      timeDivision: this.claimObj.times,
      keyDivision: this.value.keyDivision,
      code: this.value.code,
      description: this.claimObj.description,
      round: Number(this.value.round),
      status: 0,
      resolution: false,
      from: this.customizationfileService.getprofile(),
      to: this.division,
      claimType: this.value.claimType,
      erros: this.erros ? this.erros : false,
      items: this.items ? this.items : false,
      division: this.value,
      time: Date.now(),
    };

    console.log("result", result);
    await this.afs
      .collection("complaints")
      .doc(this.value.keydb)
      .collection("list")
      .add(result);
    this.commonService.dismissLoading();
    this.commonService.presentAlert(
      this.languageService.getTranslation("claim submitted successfully")
    );
    this.dismiss(1);
  }

  async getUrlFile(array) {
    return new Promise(async (resolve, reject) => {
      let listBase64 = [];
      let listUrls = [];

      array.map((file) => {
        listBase64.push(this.mediaFilesService.getBase64(file));
      });

      const base64List = await Promise.all(listBase64);

      base64List.map((file) => {
        listUrls.push(this.mediaFilesService.uploadFile(file));
      });

      const r = await Promise.all(listUrls);

      resolve(r);
    }).catch((err) => {
      console.log(err);
    });
  }

  uploadFileFirebase(base) {
    return new Promise(async (resolve, reject) => {
      const url = await this.mediaFilesService.uploadFile(base);
      console.log("uploadFileFirebase", url);
      resolve(url);
    });
  }

  getArrayFile(obj) {
    return Object.keys(obj).map((x) => {
      return obj[x];
    });
  }

  isVisible(name) {
    if (!name) {
      return false;
    }
    return Object.keys(this.claimObj[name]).length > 0 ? true : false;
  }

  removeFile(name) {
    if (!name) {
      return false;
    }
    this.claimObj[name] = {};
    return;
  }

  dismiss(status, message?) {
    this.modalController.dismiss({
      dismissed: true,
      status,
      message,
    });
  }
}
