import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, ModalController, NavParams } from "@ionic/angular";
import { AngularFireDatabase } from "angularfire2/database";
import {
  CommonService,
  CustomizationfileService,
} from "src/app/services/services";
import { JudgeErrorsService } from "src/app/services/v2/judge-errors.service";
@Component({
  selector: "app-rate-errors",
  templateUrl: "./rate-errors.component.html",
  styleUrls: ["./rate-errors.component.scss"],
})
export class RateErrorsComponent implements OnInit {
  session: any;
  items: any;
  code: any;
  data: any;
  keydb: any;
  averagetotalminuserror: number;
  round: any;



  public milderrorsList = 0;
  public seriousErrorsList = 0;
  seriousErrors: any[];
  milderrors: any[];

  constructor(
    public db: AngularFireDatabase,
    public customizationfileService: CustomizationfileService,
    public commonService: CommonService,
    public alertController: AlertController,
    public modalController: ModalController,
    public router: Router,
    public judgeErrorsService: JudgeErrorsService,
    private navParams: NavParams
  ) {
    this.items = this.navParams.get("items");
    this.code = this.navParams.get("code");
    this.data = this.navParams.get("data");
    this.keydb = this.navParams.get("keydb");
    this.round = this.navParams.get("round");

    this.seriousErrors = this.judgeErrorsService.seriousErrors
    this.milderrors = this.judgeErrorsService.milderrors


    console.log("this.items", this.items);
    console.log("this.code", this.code);
    console.log("this.data", this.data);
    console.log("this.keydb", this.keydb);
  }

  ngOnInit() {
    this.session = this.navParams.get("value");
    console.log("this.session", this.session);
  }

  selectErrors(e, name, ptos) {
    console.log(e);
    console.log(name);
    console.log(ptos);
    if (e) {
      for (let i = 0; i < this.seriousErrors.length; i++) {
        if (this.seriousErrors[i].name === name) {
          let value = this.seriousErrors[i].value + ptos;
          if (value > 0) {
            this.seriousErrors[i].value = value;
            this.seriousErrors[i].selected = true;
          } else {
            this.seriousErrors[i].selected = false;
          }
        }
      }
    } else {
      for (let y = 0; y < this.milderrors.length; y++) {
        if (this.milderrors[y].name === name) {
          console.log(this.milderrors[y].name === name);
          let value = this.milderrors[y].value + ptos;
          if (value > 0) {
            this.milderrors[y].value = value;
            this.milderrors[y].selected = true;
          } else {
            this.milderrors[y].selected = false;
          }
        }
      }
    }

    this.getTotal();
  }

  getTotal() {
    this.seriousErrorsList = 0;
    this.milderrorsList = 0;
    for (let i = 0; i < this.seriousErrors.length; i++) {
      if (this.seriousErrors[i].selected) {
        this.seriousErrorsList += this.seriousErrors[i].value;
      }
    }

    for (let i = 0; i < this.milderrors.length; i++) {
      if (this.milderrors[i].selected) {
        this.milderrorsList += this.milderrors[i].value;
      }
    }

    console.log("milderrors", this.milderrors);
    console.log("seriousErrorsList", this.seriousErrors);
  }

  async presentAlertConfirm() {
    let r = this.milderrorsList + this.seriousErrorsList;
    const alert = await this.alertController.create({
      header: "¿Quieres guardar la calificación?",
      message: `${r} pts`,
      backdropDismiss: false,
      buttons: [
        {
          text: "NO",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "SI",
          handler: async () => {
            this.saveRating();
          },
        },
      ],
    });

    await alert.present();
  }

  async saveRating() {
    await this.commonService.presentLoading();
    const error = {
      milderrorsList: this.milderrorsList,
      seriousErrorsList: this.seriousErrorsList,
      milderrors: this.milderrors,
      seriousErrors: this.seriousErrors,
    };

    const uid = this.customizationfileService.getUid();
    console.log(
      `rate/${this.keydb}/${this.code}/${this.round}/${this.data.key}/erros/${uid}`
    );
    await this.db
      .object(
        `rate/${this.keydb}/${this.code}/${this.round}/${this.data.key}/erros/${uid}`
      )
      .set(error);
    this.commonService.dismissLoading();
    this.commonService.presentAlert("calificación guardada con éxito");
    this.dismiss(1);
    console.log("error", error);
  }

  dismiss(status, message?) {
    this.modalController.dismiss({
      dismissed: true,
      status,
      message,
    });
  }

  toFixed(value) {
    if (!value) return;
    return value.toFixed(2);
  }
}
