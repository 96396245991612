import { Injectable } from "@angular/core";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";

@Injectable({
  providedIn: "root",
})
export class SocialSharingService {
  constructor(private socialSharing: SocialSharing) { }


  shareWithOptions({ message, subject, files, url, chooserTitle }) {
    return this.socialSharing.shareWithOptions({
      message, subject, files, url, chooserTitle
    });
  }


  ShareGeneric({ message, subject, file }) {
    return this.socialSharing.share(message, subject, file, null);
  }

  ShareWhatsapp({ message, imgurl, url }) {
    return this.socialSharing.shareViaWhatsApp(message, imgurl, url);
  }

  ShareFacebook({ message, imgurl, url, pasteMessageHint }) {
    return this.socialSharing.shareViaFacebookWithPasteMessageHint(message, imgurl, url, pasteMessageHint);
  }

  SendEmail({ message, subject, arrayEmail }) {
    return this.socialSharing.shareViaEmail(message, subject, arrayEmail);
  }

  SendTwitter({ message, imgurl, url }) {
    return this.socialSharing.shareViaTwitter(message, imgurl, url);
  }

  SendInstagram({ message, imgurl }) {
    return this.socialSharing.shareViaInstagram(message, imgurl);
  }
}
