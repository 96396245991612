import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ActionSheetController, AlertController, ModalController } from "@ionic/angular";
import { AngularFireDatabase, AngularFireList } from "angularfire2/database";
import {
  CommonService,
  CustomizationfileService,
  LanguageService,
  StorageService,
} from "src/app/services/services";
import { MediaFilesService } from "src/app/services/v2/media-files.service";
import * as moment from "moment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RecordVideoComponent } from "src/app/components/record-video/record-video.component";

@Component({
  selector: "app-practice-my-choreography",
  templateUrl: "./practice-my-choreography.component.html",
  styleUrls: ["./practice-my-choreography.component.scss"],
})
export class PracticeMyChoreographyComponent implements OnInit {
  @Input() states: string;
  @Input() keyDb: string;
  @Input() keyDivision: string;
  @Input() dataDivision: any;
  @Input() round: any;
  data: any;
  startRecord: any;
  endRecord: any;
  url_root: string;
  timeExtra = 59;
  itemsRef: AngularFireList<any>;
  items: Observable<any[]>;
  duration: string;
  durationDivision: any;
  URL_URI: unknown;
  diff: moment.Duration;
  seconds: number;
  urlVideo: unknown;
  status: boolean;

  constructor(
    public modalController: ModalController,
    public languageService: LanguageService,
    public storageService: StorageService,
    public alertController: AlertController,
    public mediaFilesService: MediaFilesService,
    public commonService: CommonService,
    public customizationfileService: CustomizationfileService,
    private route: ActivatedRoute,
    public db: AngularFireDatabase,
    public actionSheetController: ActionSheetController
  ) { }

  ngOnInit() {
    console.log("this.keyDb", this.dataDivision);
    console.log("this.keyDb", this.keyDb);
    console.log("this.keyDivision", this.keyDivision);
    console.log("this.round", this.round);
    this.url_root = `competition/${this.keyDb}/practiceVideoList/${this.dataDivision.category_detail.id}/${this.round}/${this.keyDivision}`;


    const durationDivision = this.dataDivision.category_detail.durationMusic.max ?
      this.dataDivision.category_detail.durationMusic.max + this.timeExtra : 200
    this.duration = this.commonService.secondsToString(durationDivision);

    this.itemsRef = this.db.list(this.url_root, (ref) =>
      ref.orderByChild("date").limitToFirst(40)
    );
    this.items = this.itemsRef
      .snapshotChanges()
      .pipe(
        map((changes) =>
          changes.map((c) => ({ key: c.payload.key, ...c.payload.val() }))
        )
      )
      .map((arr) => {
        return arr.reverse();
      });
  }

  async recordWithCamareAlert1() {
    const alert = await this.alertController.create({
      header: `${this.languageService.getTranslation("prueba").toUpperCase()}`,
      subHeader: this.languageService.getTranslation(
        "Finalmente vas a mostrarnos tu coreografía"
      ),
      message: this.languageService.getTranslation(
        "Después de darle al botón SIGUIENTE ya no podrás volver a grabar nuevamente y abras perdido la oportunidad de enviar tu coreografía y quedaras automáticamente descalificado"
      ),
      buttons: [
        {
          text: this.languageService.getTranslation("cancelar"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: this.languageService.getTranslation("SIGUIENTE"),
          handler: () => {
            this.recordWithCamareAlert2();
          },
        },
      ],
    });

    await alert.present();
  }

  async recordWithCamareAlert2() {
    const alert = await this.alertController.create({
      header: `${this.languageService.getTranslation("prueba").toUpperCase()}`,
      subHeader: `${this.languageService.getTranslation(
        "Es importante que sepas que después de darle click al botón ESTOY LISTO"
      )}`,
      message: `${this.languageService.getTranslation(
        "comenzara a contar el cronometro y solo tendrás únicamente"
      )}  ${this.duration} ${this.languageService.getTranslation(
        "minutos para terminar el proceso de grabar tu coreografía darle click al botón USAR VIDEO"
      )} `,
      buttons: [
        {
          text: this.languageService.getTranslation("cancelar"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: this.languageService.getTranslation("SIGUIENTE"),
          handler: () => {
            this.recordWithCamareAlert3();
          },
        },
      ],
    });

    await alert.present();
  }

  async recordWithCamareAlert3() {
    const alert = await this.alertController.create({
      header: `${this.languageService.getTranslation("prueba").toUpperCase()} `,
      subHeader: `${this.languageService.getTranslation(
        "Estas seguro que estas listo para grabar tu coreografía?"
      )}`,
      message: `${this.languageService.getTranslation(
        "Recuerda que solo tienes "
      )} ${this.duration}  ${this.languageService.getTranslation(
        "minutos para terminar el proceso y darle click a USAR VIDEO"
      )}`,
      buttons: [
        {
          text: this.languageService.getTranslation("cancelar"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: this.languageService.getTranslation("ESTOY LISTO"),
          handler: () => {
            this.recordWithCamare();
          },
        },
      ],
    });

    await alert.present();
  }

  async recordWithCamare() {
    this.status = true;
    let type = this.commonService.isCordova() ? "movil" : "web"
    console.log("recordWithCamare");
    try {

      this.startRecord = Date.now();
      this.durationDivision =
        this.dataDivision.category_detail.durationMusic.max + this.timeExtra;



      if (this.commonService.isCordova()) {
        await this.commonService.presentLoading();

        // crear video
        this.URL_URI = await this.mediaFilesService.recordVideo({
          limit: 1,
          duration: this.durationDivision,
        });

        this.endRecord = this.mediaFilesService.endRecord;
        this.diff = moment.duration(
          moment(this.endRecord).diff(moment(this.startRecord))
        );

        this.seconds = this.diff.asSeconds();
        // colocar estatus aprobado
        this.urlVideo = await this.upload({
          captureVideoUrl: this.URL_URI,
          contentType: "video/mp4",
          newName: this.keyDivision,
          codeDivision: this.dataDivision.category_detail.id,
        });

      } else {
        this.urlVideo = await this.presentModalRecordVideo()
      }
      console.log('this.urlVideo', this.urlVideo)

      let comments = "";
      if (this.seconds >= this.durationDivision) {
        //colocar estatus rechazado
        const text = `${this.languageService.getTranslation(
          "prueba"
        )} ${this.languageService.getTranslation(
          "disqualified"
        )}, ${this.languageService.getTranslation(
          "Exceeded limited  time allowed to record"
        )} ${this.commonService.secondsToString(
          this.durationDivision
        )},${this.languageService.getTranslation(
          "seconds consumed"
        )}  ${this.commonService.secondsToString(this.seconds)}`;
        this.commonService.presentAlert(text);
        this.status = false;
        comments = "Se ha excedido el tiempo permitido para grabar";
      } else {
        comments = "aceptado";
        this.commonService.presentAlert("Cargado con éxito");
        this.updateStatusPractice(3);
      }

      await this.addDivision({
        step: 2,
        type,
        endRecord: this.endRecord,
        startRecord: this.startRecord,
        err: false,
        uid_user: this.customizationfileService.getUid(),
        last_update: Date.now(),
        comments,
        date: Date.now(),
        urlVideo: this.urlVideo,
        durationDivision: this.durationDivision,
        seconds: this.seconds,
        status: this.status,
      });

      this.commonService.dismissLoading();

    } catch (err) {
      console.warn('err', err)
      await this.addDivision({
        step: 2,
        type,
        last_update: Date.now(),
        endRecord: this.endRecord ? this.endRecord : false,
        startRecord: this.startRecord ? this.startRecord : false,
        seconds: false,
        urlVideo: false,
        comments: this.languageService.getTranslation("canceled the recording"),
        durationDivision: false,
        status: false,
      });

      this.commonService.dismissLoading();
      this.commonService.presentAlertTranslation("descalificado");
    }
  }


  async presentModalRecordVideo() {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalController.create({
        component: RecordVideoComponent,
        swipeToClose: false,
        backdropDismiss: false,
        componentProps: {
          divisionData: this.dataDivision,
        },
      });

      modal.onDidDismiss().then(async (dataReturned) => {
        if (dataReturned !== null) {
          if (dataReturned.data.status === 1) {
            await this.commonService.presentLoading()


            this.endRecord = Date.now();
            this.diff = moment.duration(
              moment(this.endRecord).diff(moment(this.startRecord))
            );
            this.seconds = this.diff.asSeconds();
            let urlBlodLocal = await this.mediaFilesService.getFileBlob(dataReturned.data.message)
            const urlVideo = await this.mediaFilesService
              .uploadFileBLod({ blod: urlBlodLocal, folder: "competitor", ext: "mp4" })
              .catch(err => {
                console.log("err", err)
                this.commonService.dismissLoading()
                this.commonService.presentAlert(JSON.stringify(err))
                reject(err)
              })
            this.commonService.dismissLoading()
            resolve(urlVideo)
          }
        }
      });

      return await modal.present();
    })


  }

  async updateStatusPractice(status) {
    await this.db
      .object(`competition/${this.keyDb}/division/${this.keyDivision}/states/`)
      .update({
        uid_user: this.customizationfileService.getUid(),
        last_update: Date.now(),
        step0: status,
      });
  }

  async upload({ captureVideoUrl, contentType, newName, codeDivision }) {
    return new Promise(async (resolve, reject) => {
      console.log("upload");
      this.mediaFilesService
        .upload({
          captureVideoUrl,
          contentType,
          newName,
          codeDivision,
        })
        .then(async (urlVideo) => {
          console.log("urlVideo", urlVideo);
          resolve(urlVideo);
        })
        .catch((err) => {
          console.log("upload", err);
          this.commonService.dismissLoading();
          this.commonService.presentAlert(JSON.stringify(err));
          reject(err);
        });
    });
  }

  async addDivision(data) {
    this.itemsRef.push(data);
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  openVideo(url) {
    console.log("url", url);
    this.mediaFilesService.playVideo(url);
  }
}
