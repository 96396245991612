import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateService {

  constructor(public appRef: ApplicationRef, public updates: SwUpdate) {

  }

  checkForUpdate() {
    console.log("checkForUpdate", this.updates.isEnabled)
    if (!this.updates.isEnabled) { return }
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
    everySixHoursOnceAppIsStable$.subscribe(() => this.updates.checkForUpdate());
  }


  forcingUpdate() {
    console.log("forcingUpdate", this.updates.isEnabled);
    if (!this.updates.isEnabled) { return }
    this.updates.available.subscribe(event => {
      this.updates.activateUpdate().then(() => document.location.reload());
    });
  }
}


