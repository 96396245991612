import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Mensaje } from '../../../model/mensaje';
import { AngularFireAuth } from '@angular/fire/auth';
import { CustomizationfileService } from '../customizationfile/customizationfile.service';
import { OneSignalPushService } from '../oneSignalPush/one-signal-push.service';


@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private itemsCollection: AngularFirestoreCollection<Mensaje>;
  public chats: Mensaje[] = [];

  private friendsCollection: AngularFirestoreCollection<any>;
  public friends: Observable<any[]>;

  private requestCollection: AngularFirestoreCollection<any>;
  public request: Observable<any[]>;


  private privateChatCollection: AngularFirestoreCollection<Mensaje>;
  public privateChats: Mensaje[] = [];
  public _loadsRequest: any[] = [];

  public _listRequest = 0;
  public usuario: any = {};
  constructor(private afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public _cf: CustomizationfileService,
    public oneSignal: OneSignalPushService
  ) {
    this.profile();
  }


  profile() {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        const profile = this._cf.getprofile();
        const name = `${profile.name} ${profile.surnames}`;
        this.usuario.nombre = name;
        this.usuario.avatar = this._cf.getavatar();
        this.usuario.uid = this._cf.getUid();
      }

    });
  }



  /**
   * Chat Global
   * @param uid1
   * @param uid2
   */

  cargarMensajes() {
    this.itemsCollection = this.afs.collection<Mensaje>('chats', ref => ref.orderBy('fecha', 'desc').limit(40));
    return this.itemsCollection.valueChanges()
      .pipe(map((mensajes: Mensaje[]) => {
        this.chats = [];
        for (const mensaje of mensajes) {
          this.chats.unshift(mensaje);
        }

        return this.chats;
      }));
  }

  agregarMensaje(texto: string) {
    const mensaje: Mensaje = {
      nombre: this.usuario.nombre,
      mensaje: texto,
      fecha: new Date().getTime(),
      avatar: this.usuario.avatar,
      uid: this.usuario.uid
    };

    return this.itemsCollection.add(mensaje);
  }


  /**
   * Chat One to One
   *
   */


  setOneToOneChat(uid1, uid2) {
    // Check if user1’s id is less than user2's
    if (uid1 < uid2) {
      return uid1 + uid2;
    } else {
      return uid2 + uid1;
    }
  }



  documentExists(idOneToOne) {
    return this.afs.collection('privateChats').doc(idOneToOne).collection('message').get();
  }


  documentExistsChat(idOneToOne) {
    return this.afs.collection('privateChats').doc(idOneToOne).collection('message').get();
  }

  /**
   * verifica si existe solicitudes de amistad
   */
  sizeRequest() {
    const uid = this._cf.getUid();
    const request = this.afs.collection('requests').doc(uid).collection('list');
    return request.valueChanges()
      .pipe(map((request: any) => {
        return request;
      }));
  }



  sendRequest(to_id, from_id) {
    const profile = Object.assign(this._cf.getprofile(), { avatar: this._cf.getavatar() }, { uid: from_id }, { date: Date.now() });
    return this.afs.collection('requests').doc(to_id).collection('list').add(profile);
  }


  removeRequest(key) {
    return this.afs.collection('requests').doc(this.usuario.uid).collection('list').doc(key).delete();
  }


  friendsList(user, friends) {
    if (friends) {
      console.log('friends ', user.uid);
      const profile = Object.assign(this._cf.getprofile(), { avatar: this._cf.getavatar() }, { uid: this.usuario.uid }, { date: Date.now() });
      return this.afs.collection('friendsList').doc(user.uid).collection('list').add(profile);
    } else {
      console.log('friends ', this.usuario.uid);
      return this.afs.collection('friendsList').doc(this.usuario.uid).collection('list').add(user);
    }

  }


  getfriendsValid(uid) {
    return this.afs.collection('friendsList').doc(uid).collection('list', ref => ref.where('uid', '==', uid)).valueChanges();

  }




  /**
   * carga los mensajes del chat
   * @param idOneToOne
   */
  cargarMensajesPrivados(idOneToOne) {
    return this.afs.collection('privateChats')
      .doc(idOneToOne)
      .collection('message', ref => ref.orderBy('fecha', 'desc').limit(40)).valueChanges()
      .pipe(map((mensajes: Mensaje[]) => {
        this.privateChats = [];
        // console.log('mensaje', mensajes)
        for (const mensaje of mensajes) {
          this.privateChats.unshift(mensaje);
        }

        return this.privateChats;
      }));
  }

  /**
   * agregar mensajes
   * @param texto
   * @param idOneToOne
   */
  privateChat(texto: string, idOneToOne) {
    const mensaje: Mensaje = {
      nombre: this.usuario.nombre,
      mensaje: texto,
      fecha: new Date().getTime(),
      avatar: this.usuario.avatar,
      uid: this.usuario.uid
    };
    this.setNotificationChat(mensaje);
    return this.afs.collection('privateChats').doc(idOneToOne).collection('message').add(mensaje);
  }

  setNotificationChat(data) {
    const global_notification = {
      'payload': {
        'activity': 2,
        'uid': this._cf.getUid(),
        'name': this.usuario.nombre,
        'tokenPush': localStorage.getItem('tokenpush'),
        'title': this.usuario.nombre,
      },
      'headings':
      {
        'en': this.usuario.nombre,
        'es': this.usuario.nombre
      },
      'subtitle':
      {
        'en': data.mensaje,
        'es': data.mensaje
      },
    };
    console.log('setNotificationChat', global_notification);

    // this.oneSignal.sendNotification(global_notification)
  }

}
