import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import {
  CommonService,
  LanguageService,
  ManageUsersService,
  ViewStatusService,
} from "src/app/services/services";
import { OpenCaseAModalComponent } from "../open-case-amodal/open-case-amodal.component";

@Component({
  selector: "app-open-acase",
  templateUrl: "./open-acase.component.html",
  styleUrls: ["./open-acase.component.scss"],
})
export class OpenACaseComponent implements OnInit {
  @Input() code: string;
  @Input() round: Number;
  @Input() keyDivision: string;
  @Input() keydb: string;

  listComplaints = [
    {
      id: 1,
      name: "Asuntos relacionados conmigo, mi pareja y yo o mi grupo.",
      type: "",
      selected: false,
      list: [
        {
          id: 1,
          claimType: "001",
          name: "Asunto relacionado con Errores o faltas.",
          type: "",
          selected: false,
        },
        {
          id: 2,
          claimType: "002",
          name: "Asuntos relacionados con puntajes de los jueces.",
          type: "",
          selected: false,
        },
        {
          id: 3,
          claimType: "003",
          name: "Asuntos relacionados con descalificación",
          type: "",
          selected: false,
        },
        {
          id: 3,
          claimType: "004",
          name: "Otros Asuntos",
          type: "",
          selected: false,
        },
      ],
    },
    {
      id: 2,
      name: "Asuntos relacionados con otro competidor de mi categoría.",
      type: "",
      selected: false,
      list: [
        {
          id: 1,
          claimType: "005",
          name: "Asunto relacionado con Errores o faltas.",
          type: "",
          selected: false,
        },
        {
          id: 2,
          claimType: "006",
          name: "Asuntos relacionados con puntajes de los jueces",
          type: "",
          selected: false,
        },
        {
          id: 3,
          claimType: "007",
          name: "Asuntos relacionados con descalificación",
          type: "",
          selected: false,
        },
        {
          id: 3,
          claimType: "008",
          name: "Otros asuntos",
          type: "",
          selected: false,
        },
      ],
    },
    {
      id: 3,
      name: "Asuntos relacionados con la app SETTLE.",
      type: "",
      claimType: "009",
      selected: false,
    },
    {
      id: 4,
      name: "Otros Asuntos",
      type: "",
      claimType: "010",
      selected: false,
    },
  ];
  listResult: any;
  complaints = false;
  constructor(
    public viewStatusService: ViewStatusService,
    public languageService: LanguageService,
    public manageUsersService: ManageUsersService,
    public modalController: ModalController,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    this.viewStatusService.complaints.subscribe((res) => {
      this.complaints = res;
      console.log("this.complaints", this.complaints);
    });
    this.getResult();
  }

  getResult() {
    console.log("open getResult");
    console.log({
      keydb: this.keydb,
      code: this.code,
      round: this.round,
    });
    this.manageUsersService
      .getResultDivisions({
        keydb: this.keydb,
        code: this.code,
        round: Number(this.round),
      })
      .then((data: any) => {
        console.log("data", data);
        this.listResult = data.results;
        this.listResult = this.listResult.sort((a, b) => {
          if (a.finalScore < b.finalScore) {
            return 1;
          }
          if (a.finalScore > b.finalScore) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        console.log("getResult", this.listResult);
      })
      .catch((err) => {
        this.listResult = [];
      });
  }

  async openModal(data, division) {
    const keyDivision = division ? division.key : this.keyDivision;

    // console.log("data", data);
    // console.log("division", division);
    // console.log("keyD", keyD);

    await this.commonService.presentLoading();
    const report = await this.commonService.isThisReport({
      keyDb: this.keydb,
      keyDivision: keyDivision,
      status: 0,
      code: this.code,
      claimType: data.claimType,
      round: Number(this.round),
    });
    this.commonService.dismissLoading();
    console.log("report", report);
    if (report) {
      this.commonService.presentAlert(
        this.languageService.getTranslation(
          "this report has already been created"
        )
      );
      return;
    }

    const modal = await this.modalController.create({
      component: OpenCaseAModalComponent,
      swipeToClose: false,
      cssClass: "my-custom-modal-css",
      backdropDismiss: false,
      componentProps: {
        value: Object.assign(data, {
          code: this.code,
          round: this.round,
          keyDivision: keyDivision,
          keydb: this.keydb,
        }),
      },
    });

    return await modal.present();
  }

  showMultimedia(index2, id, data) {
    if (id === 1 || id === 2) {
      for (let index = 0; index < this.listComplaints.length; index++) {
        if (index === index2) {
          this.listComplaints[index].selected = !this.listComplaints[index]
            .selected;
        } else {
          this.listComplaints[index].selected = false;
        }
      }
    } else if (id === 3 || id === 4) {
      this.openModal(data, null);
    }
  }

  showMultimedia2(index2) {
    console.log("index2", index2);
    console.log("index2", this.listComplaints);
    for (let index = 0; index < this.listComplaints.length; index++) {
      if (index === index2) {
        this.listComplaints[1].list[index].selected = !this.listComplaints[1]
          .list[index].selected;
      } else {
        this.listComplaints[1].list[index].selected = false;
      }
    }
  }
}
