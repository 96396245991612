import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Platform, AlertController } from "@ionic/angular";
import * as firebase from "firebase/app";
import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { Facebook, FacebookLoginResponse } from "@ionic-native/facebook/ngx";

import { Storage } from "@ionic/storage";

import AuthProvider = firebase.auth.AuthProvider;
import { AngularFireAuth } from "@angular/fire/auth";

import { AngularFireDatabase } from "@angular/fire/database";
import { Router } from "@angular/router";
import { CustomizationfileService } from "../customizationfile/customizationfile.service";
import { OneSignalPushService } from "../oneSignalPush/one-signal-push.service";
import { CartService } from "../cart/cart.service";
import { CommonService } from "../common/common.service";
import { AngularFirestore, Query } from "@angular/fire/firestore";
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { take, map, tap, catchError, startWith } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

const TOKEN_KEY = "auth-token";
const ROL_KEY = "rol-key";
const UID_KEY = 'uid';

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {

  public authenticationState = new BehaviorSubject(false);
  public incomplete_profile = new BehaviorSubject(false);
  public adminUser = new BehaviorSubject(false);
  public _isJubge = new BehaviorSubject(false);
  public profile = new BehaviorSubject({});
  public avatar = new BehaviorSubject("/assets/img/002-man.svg");
  public presence = new BehaviorSubject("offline");
  public emailVerified = new BehaviorSubject({
    email: "",
    emailVerified: true,
  });

  public user: firebase.User;
  // public token;
  public role: any;
  public authProfiles = new BehaviorSubject([]);

  constructor(
    public commonService: CommonService,
    private storage: Storage,
    public db: AngularFireDatabase,
    public alertController: AlertController,
    private platform: Platform,
    public afAuth: AngularFireAuth,
    public router: Router,
    public customizationfileService: CustomizationfileService,
    public _oneSignal: OneSignalPushService,
    private gplus: GooglePlus,
    private _ngZone: NgZone,
    private fb: Facebook,
    private zone: NgZone,
    public _cart: CartService,
    public afs: AngularFirestore,
    private http: HttpClient
  ) {
    this.platform.ready().then(() => {
      this.checkToken();
      this.login();
    });
  }

  login() {
    return new Promise<void>(async (resolve) => {
      this.afAuth.authState.subscribe(async (user) => {
        if (user) {
          this._oneSignal.getUserIdOneSignal();
          this.user = user;
          // console.log("user", user);
          this.storage.set(TOKEN_KEY, user.uid).then(async () => {
            window.localStorage.setItem("auth", "true");
            window.localStorage.setItem("uid", user.uid);

            this.saveTokenPushOneSignal(user.uid);
            this.getProfile(user.uid);
            this.incompleteProfile();
            this.getAvatar(user.uid);
            this.getPresence(user.uid);
            this.isAdmin(user.uid);
            this.getAuthProfiles(user.uid);

            this.emailVerified.next({ email: user.email, emailVerified: user.emailVerified, });
            const keydb = this.customizationfileService.getPROVISIONAKEY();

            this.isJudge({ keydb });
            this._cart.getCart();
            this.authenticationState.next(true);
            resolve();
          });
        } else {
          // this.logout()
          resolve();
        }
      });
    });
  }


  /**
   * Enviar email para verificar correo electronico
   * @returns
   */
  async setEmailVerified() {
    try {
      console.log("setemailVerified");
      await firebase.auth().currentUser.sendEmailVerification();
      return true;

    } catch (err) {
      console.log("err", err)
      this.commonService.presentAlertTranslation("se ha producido un error desconocido, al enviar la verificación de correo electrónico");
      return false;
    }
  }


  getRol(uid) {
    return new Promise(async (resolve) => {
      this.db
        .object(`/users/${uid}/rol`)
        .valueChanges()
        .subscribe((rol) => {
          let _rol = rol ? rol : 0
          resolve(_rol);
        }
        );
    });

  }


  async setRol(rol) {
    console.log(rol);
    return new Promise<void>(async (resolve) => {
      await this.storage.set(ROL_KEY, rol).then(async () => {
        resolve();
      });
    });
  }

  async setToken(token) {
    return new Promise<void>(async (resolve) => {
      await this.storage.set(TOKEN_KEY, token).then(async () => {
        resolve();
      });
    });
  }


  /**
   * Obtener UID deñ usuario autenticado
   * @returns
   */
  getUid() { return localStorage.getItem(UID_KEY); }


  checkToken() {
    return localStorage.getItem("tokenPush");
  }

  async checkRol() {
    await this.storage.get(ROL_KEY).then((res) => {
      console.log(res);
      return res;
    });
  }

  getProfile(uid) {
    return new Promise(async (resolve, reject) => {
      this.db
        .object(`/users/${uid}/profile`)
        .valueChanges()
        .subscribe(
          (profile) => {
            this.profile.next(profile);
            resolve(profile);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getAvatar(uid) {
    this.db
      .object(`/users/${uid}/avatar`)
      .valueChanges()
      .subscribe((img: any) => {
        if (img) {
          window.localStorage.setItem("avatar", img);
          this.avatar.next(img);
        } else {
          if (this.customizationfileService.getGender()) {
            const gender = this.customizationfileService
              .getGender()
              .substring(0, 1);
            gender === "m"
              ? this.avatar.next("/assets/img/002-man.svg")
              : this.avatar.next("/assets/img/001-woman.svg");

            gender === "m"
              ? window.localStorage.setItem("avatar", "/assets/img/002-man.svg")
              : window.localStorage.setItem(
                "avatar",
                "/assets/img/001-woman.svg"
              );
          } else {
            window.localStorage.setItem("avatar", "/assets/img/002-man.svg");
            this.avatar.next("/assets/img/002-man.svg");
          }
        }
      });
  }

  getPresence(uid) {
    this.db
      .object(`presence/${uid}`)
      .valueChanges()
      .subscribe((status: any) => {
        if (status) {
          this.presence.next(status);
        }
        return;
      });
  }

  createAnonymousUser(): Promise<any> {
    return this.afAuth.auth.signInAnonymously();
  }

  signInWithEmail(credentials) {
    return this.afAuth.auth.signInWithEmailAndPassword(
      credentials.email,
      credentials.password
    );
  }

  createUserWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
          resolve(user);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  sendPasswordResetEmail(emailAddress: string) {
    return this.afAuth.auth.sendPasswordResetEmail(emailAddress);
  }

  saveTokenPushOneSignal(uid) {
    return new Promise<void>(async (resolve) => {
      if (this.platform.is("cordova") && this._oneSignal.userId && uid) {
        const pushRef = this.db.object(`/users/${uid}/push`);
        await pushRef.set(this._oneSignal.userId);
        const keydb = this.customizationfileService.getKeyDb();
        if (keydb) {
          const allRef = this.db.list(`notificationsBySegment/${keydb}/all`);
          await allRef.set(uid, { userId: this._oneSignal.userId });
        }
      }
      resolve();
    });
  }

  checkProfile() {
    let profile;
    this.storage.get("profile").then((res) => {
      profile = res;
    });
    return profile;
  }

  isAdmin(uid) {
    return new Promise((resolve) => {
      const superAdmin = `/users/${uid}/super-admin`;
      this.db
        .object(superAdmin)
        .valueChanges()
        .subscribe((superAdmin) => {
          // console.log("superAdmin", superAdmin);
          if (!superAdmin) {
            const url = `users/${uid}/rol`;
            // console.log(url);
            // Verifamos su rol de usuarios
            this.db
              .object(url)
              .valueChanges()
              .subscribe((roluser) => {
                if (roluser) {
                  const urlEve = `eventRoles/${this.customizationfileService.getKeyDb()}/${roluser}/${uid}`;
                  // Verifamos su rol en el evento
                  this.db
                    .object(urlEve)
                    .valueChanges()
                    .subscribe((rolevent) => {
                      if (roluser === rolevent) {
                        resolve(false);
                      } else {
                        resolve(false);
                      }
                    });
                } else {
                  resolve(false);
                }
              });
          } else {
            this.adminUser.next(true);
            resolve(true);
          }
        });
    });
  }

  isJudge({ keydb }) {
    if (!keydb) { return }
    // console.log("isJudge", { keydb, });
    const uid = this.customizationfileService.getUid()
    return new Promise((resolve, reject) => {
      // console.log("isJudge", `judgesEnabled/${keydb}/users/${uid}`)
      this.db
        .object(`judgesEnabled/${keydb}/users/${uid}`)
        .valueChanges()
        .subscribe((res) => {
          if (res) {
            // console.warn("judgesEnabled", JSON.stringify(res))
            window.localStorage.setItem("DATE_JUDGE", JSON.stringify(res));
            this._isJubge.next(true);
            resolve(true);
            return
          }

          this._isJubge.next(false);
          resolve(false);
        });
    });
  }

  incompleteProfile() {
    const profile = this.customizationfileService.getprofile();
    if (profile) {
      if (profile.idType === "incomplete_profile") {
        this.incomplete_profile.next(false);
      } else {
        this.incomplete_profile.next(true);
      }
    }
  }

  async updateEventUserEmail(key_db: string, uid: any, newEmail: string) {
    return new Promise((resolve, reject) => {
      try {
        const url = `${environment.urlrootFunctions}/v3/admin/update-user-email`;
        this.http.post(url, {key_db, uid, email: newEmail})
          .subscribe((res: any) => {
            return resolve(res);
          }, (err) => reject(err))
      } catch (err) {
        console.log('Error on AuthenticationService.updateEventUserEmail', err);
        return reject(err)
      }
    });
  }

  async logout() {
    await this.afAuth.auth.signOut();
    await this.storage.clear();
    window.localStorage.clear();
    this.adminUser.next(false);
    this.authenticationState.next(false);
    this.avatar.next("/assets/img/002-man.svg");
    /**
     * TODO: event default
     */
    console.log('environment.eventDefault', environment.eventDefault)
    this.router.navigate([`/auth/login`]);
  }

  getAuthProfiles(uid: string) {
    return new Promise((resolve, reject) => {
      this.afs
        .collection('auth__assigments')
        .doc(uid)
        .valueChanges()
        .subscribe((res: any) => {

          /** If doesn't have assigment records */
          if (!res) { return resolve([]); }

          const { profiles = [] } = res;
          this.authProfiles.next(profiles);
          resolve(res);
        });
    });
  }


  /**
   * Crear documento y almacenar para la colección de usuarios
   * @param params
   */
  async buildAndStoreUserDoc(params: any) {
    const doc = {
      uid: params.uid,
      _language: "English",
      rol: 0,
      status: "incomplete_profile",
      stageName: false,
      avatar: params.avatar,
      name: params.name,
      prefijo: params.prefijo.phonecode,
      phone: params.phone,
      identificationType: params.identificationType || 'cedula',
      identification: params.identification,
      email: params.email
    };

    await this.afs.collection("users").doc(params.uid).set(doc);
    return doc;
  }


  /**
   * Crear documento para la colección de perfiles
   * @param params
   */
  async buildAndStoreProfileDoc(params: any) {
    const doc = {
      uid: params.uid,
      email: params.email,
      name: params.name.toLowerCase(),
      surnames: params.surnames.toLowerCase(),
      idType: "incomplete_profile",
      gender: null,
      birthdate: null,
      identificationType: params.identificationType || 'cedula',
      identificationNumber: params.identification,
      school: null,
      tShirtSize: null,
      bio: null,
      celular: null,
      cityOfBirth: null,
      countryOfBirth: null,
      countryOfResidence: null,
      facebook: null,
      instagram: null,
      prefijo: params.prefijo.phonecode,
      phone: params.phone,
      stageName: null,
      stateOfBirth: null,
      stateOfResidence: null,
    };

    await this.afs.collection("profile").doc(params.uid).set(doc);
    return doc;
  }


  /**
   * Obtener listado dinamico
   * @param where
   * @param where.field
   * @param where.condition
   * @param where.value
   * @param opts
   * @param opts.idField
   * @param opts.orderBy
   * @param opts.orderBy.field
   * @param opts.orderBy.order
   *
   * @returns
   */
  getDynamic(collection: string, where: any[] = [], opts: any = {}): Observable<any[]> {
    const { idField = "_id", orderBy = [] } = opts;

    return this.afs.collection(collection,
      (ref) => {
        let query: Query = ref;
        for (const row of where) { query = ref.where(row.field, row.condition, row.value); }

        for (const order of orderBy) { query = ref.orderBy(order.field, order.order); }
        return query;
      }
    ).valueChanges({ idField });

    this.afs.collection('users', (ref) => ref.where('email', '==', '')).get()
  }
}


/**
 * Validar si usuario registrado a través de un identificador
 *
 * @param service
 * @returns
 */
export function checkIdentificationForExists(service: AuthenticationService): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors> => {
    return service.afs.collection('users', (ref) => ref.where('identification', '==', `${control.value}`.trim())).get()
      .pipe(
        // tap((result) => console.log(result) ),
        map((data) => {
          // console.log({data});
          return (data.empty) ? null : { existingIdentification: true };
        })
      );
  }
}
// export function checkIdentificationForExists(service: any): AsyncValidatorFn{
//   return (control: AbstractControl) => {
//     return service.getDynamic('users', [
//       {field: 'identification', condition: '==', value: control.value}
//     ])
//     .pipe(
//       // tap((result) => console.log(result) ),
//       take(1),
//       map((result: any) => (result.length > 0) ? {existingIdentification: true} : null )
//     );
//   };
// }


/**
 * Validat si usuario registrado a través de un email
 *
 * @param service
 * @returns
 */
export function checkEmailForExists(service: AuthenticationService): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors> => {
    return service.afs.collection('users', (ref) => ref.where('email', '==', `${control.value}`.trim())).get()
      .pipe(
        // tap((result) => console.log(result) ),
        map((data) => {
          // console.log({data});
          return (data.empty) ? null : { existingEmail: true };
        })
      );
  }
}
// export function checkEmailForExists(service: AuthenticationService): AsyncValidatorFn{
//   return (control: AbstractControl): Observable<ValidationErrors> => {
//     return service.getDynamic('users', [
//       {field: 'email', condition: '==', value: control.value}
//     ])
//     .pipe(
//       // tap((result) => console.log(result) ),
//       // take(3),
//       map( (result: any[]) =>{
//         console.log('result', result);
//         console.log('result.length', result.length);

//         const status = (result.length  === 0) ? null : {existingEmail: true};
//         console.log('status', status);
//         return status;
//         // return (result.length  === 0) ? null : {existingEmail: true};
//       })
//     );
//   };
// }
