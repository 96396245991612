import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cartSort'
})
export class CartSortPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    // console.log("value", value)
    // console.log(value.length)
    // return value
    const duplicates = this.removeDups(value)
    // console.log("duplicates", duplicates)
    // console.log(duplicates.length)

    return value.sort((a, b) => {
      if (a.type > b.type) {
        return 1;
      }
      if (a.type < b.type) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  }

  sortName(value) {
    return value.sort(function (a, b) {
      if (a.type > b.type) {
        return 1;
      }
      if (a.type < b.type) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  }

  removeDups(data) {
    let result = []
    data.forEach(x => {
      if (x.type == 1) {
        result.push(x)
      }
    });
    // console.log('result', result)
    return result.filter((thing, index, self) => index === self.findIndex(t => t.uid === thing.uid));

  }

}
