import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { CommonService, CustomizationfileService } from 'src/app/services/services';
import { ClaimTrophyComponent } from './components/claim-trophy/claim-trophy.component';
import { SendTrophyComponent } from './components/send-trophy/send-trophy.component';

@Component({
  selector: 'app-trophy-claim',
  templateUrl: './trophy-claim.component.html',
  styleUrls: ['./trophy-claim.component.scss'],
})
export class TrophyClaimComponent implements OnInit {
  @Input() myRating: any;
  @Input() avatar: any;
  @Input() code: any;
  @Input() round: any;
  @Input() profile: any;
  @Input() keydb: any;
  @Input() key_division: any;

  _isClaimTrophy: any
  _isTwoRound: any
  optionList = [
    { id: 1, name: "It will be claimed at WLDC 2022 in Medellin Colombia" },
    { id: 2, name: "You will pay for shipping to arrive by mail to your address" },
    { id: 3, name: "It will be claimed at the BNF office in Medellin during the year 2021" },
  ]

  enablodOption = false
  constructor(
    public afs: AngularFirestore,
    public modalController: ModalController,
    public db: AngularFireDatabase,
    public commonService: CommonService,
    public customizationfileService: CustomizationfileService
  ) { }

  async ngOnInit() {
    this.validInfo()
  }


  async validInfo() {
    await this.commonService.presentLoading()
    this._isClaimTrophy = await this.isClaimTrophy({ keydb: this.keydb, keydivision: this.myRating.key })
    if (this.round == 1) {
      this._isTwoRound = await this.isTwoRound({ keydb: this.keydb, code: this.code })
    }
    this.commonService.dismissLoading()
  }



  isClaimTrophy({ keydb, keydivision }) {
    return new Promise((resolve) => {
      try {
        this.afs.collection('claim-trophy', ref =>
          ref.where('keyRequest', '==', keydivision)
            .where('keydb', '==', keydb))
          .valueChanges()
          .subscribe(res => {
            console.warn("isClaimTrophy", res)
            if (res.length === 0) { resolve(false) }
            resolve(true)
          })
      } catch (error) {
        resolve(false)
      }

    })
  }

  isTwoRound({ keydb, code }) {
    // console.log("isTwoRound", { keydb, code })
    return new Promise((resolve) => {
      try {
        this.db.object(`/competition/${keydb}/mainChoreography/${code}/2`)
          .valueChanges()
          .subscribe(res => {
            // console.log("res", res)
            if (res === null) { return resolve(false) }
            if (Object.keys(res).length <= 4) { return resolve(false) }
            return resolve(true)
          })
      } catch (error) {
        resolve(false)
      }
    })
  }


  async openModal(item) {
    let data = {
      myRating: this.myRating,
      item: item,
      key_division: this.key_division,
      code: this.code,
      profile: this.profile
    }

    let comModal: any
    if (item.id === 1) {
      comModal = ClaimTrophyComponent
    } else if (item.id === 2) {
      comModal = SendTrophyComponent
    } else if (item.id === 3) {
      comModal = ClaimTrophyComponent
    }


    // let comModal = item.id === 1 ? ClaimTrophyComponent : SendTrophyComponent
    const modal = await this.modalController.create({
      component: comModal,
      cssClass: 'my-custom-class',
      componentProps: { details: data }
    });

    modal.onDidDismiss()
      .then(async (dataReturned) => {
        // console.log("dataReturned", dataReturned)
        this._isClaimTrophy = false
        this._isTwoRound = false
        this.validInfo()
      });


    return await modal.present();


  }



}
