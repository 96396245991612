import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { CustomizationfileService, OneSignalPushService } from "../services";
import { BehaviorSubject, Observable } from "rxjs";
import { switchMap, map } from "rxjs/operators";
import * as firebase from "firebase";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
const url_root: any = environment.urlrootFunctions;
const prod = environment.production;

@Injectable({
  providedIn: "root",
})
export class ChatService {
  uid: any;
  profile: any;
  translate: string;
  private _data: any;
  latestEntry: any;

  //Models for Input fields
  nameValue: string;
  placeValue: string;

  //Data object for listing items
  tableData: any[] = [];

  //Save first document in snapshot of items received
  firstInResponse: any = [];

  //Save last document in snapshot of items received
  lastInResponse: any = [];

  //Keep the array of first document of previous pages
  prev_strt_at: any = [];

  //Maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;

  //Disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = false;

  constructor(
    private http: HttpClient,
    private afs: AngularFirestore,
    public customizationfileService: CustomizationfileService
  ) {
    this.uid = this.customizationfileService.getUid();
    this.profile = this.customizationfileService.getProfile();
    this.translate = this.customizationfileService.getTranslate();
  }

  getChatMessages({ uidChat, limit }) {
    let users = [];
    console.log("limit", limit);
    return this.getUsers().pipe(
      switchMap((res) => {
        users = res;
        return this.afs
          .collection("messages")
          .doc(uidChat)
          .collection("list", (ref) =>
            ref.orderBy("createdAt", "desc").limit(limit)
          )
          .snapshotChanges()
          .pipe(
            map((actions) =>
              actions.map((a) => {
                const data = a.payload.doc.data() as any;
                const id = a.payload.doc.id;
                return { id, ...data };
              })
            )
          );
      }),
      map((messages) => {
        // Get the real name for each user
        for (let m of messages) {
          m.fromName = this.getUserForMsg(m.from, users);
          m.myMsg = this.uid === m.from;
        }
        return messages.reverse();
      })
    );
  }

  addChatMessage({
    msg,
    doc,
    media,
    isImage,
    isVideo,
    from,
    to,
    department,
    uuidAdmin,
    tokenUser,
    tokenAdmin,
    avatarUser,
    avatarAdmin,
    uidChat,
    msg_translate,
  }) {
    const token = tokenUser ? tokenUser : tokenAdmin;
    const fromNotification = uuidAdmin ? `settle ${department}` : name;

    console.log("msg", msg);
    console.log("msg_translate", msg_translate);

    this.addMenssage({
      msg,
      msg_translate,
      uidChat,
      doc,
      media,
      isImage,
      isVideo,
      from,
      uuidAdmin,
      tokenUser,
      tokenAdmin,
      avatarUser,
      avatarAdmin,
    });

    if (uuidAdmin) {
      this.sendNotification({ token, msg, from: fromNotification });

      return;
    }
    this.updateChat({
      from,
      uidChat,
      department,
      uuidAdmin,
      msg,
      avatarUser,
      tokenUser,
      tokenAdmin,
    });
  }

  addMenssage({
    msg,
    doc,
    media,
    isImage,
    isVideo,
    from,
    uuidAdmin,
    tokenUser,
    tokenAdmin,
    avatarUser,
    avatarAdmin,
    uidChat,
    msg_translate,
  }) {
    return this.afs
      .collection("messages")
      .doc(uidChat)
      .collection("list")
      .add({
        msg,
        msg_translate,
        doc,
        media,
        isImage,
        isVideo,
        from,
        uuidAdmin,
        tokenUser,
        tokenAdmin,
        avatarUser,
        avatarAdmin,
        name: `${this.profile.name} ${this.profile.surnames}`,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
  }

  sendNotification({ token, msg, from }) {
    return new Promise(async (resolve, reject) => {
      this.http
        .post(`${url_root}/onesignal/createNotification`, {
          include_player_ids: [token],
          data: { type: 2 },
          contents: { en: msg ? msg : "media" },
          headings: { en: from },
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  updateChat({
    uidChat,
    from,
    department,
    uuidAdmin,
    msg,
    avatarUser,
    tokenUser,
    tokenAdmin,
  }) {
    const name = `${this.profile.name} ${this.profile.surnames}`;
    return this.afs.collection("chat-messages").doc(uidChat).set({
      department,
      uuidAdmin,
      avatarUser,
      name,
      tokenUser,
      tokenAdmin,
      uidChat,
      chat: from,
      msg: msg,
      read: false,
      translate: this.customizationfileService.getTranslate(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  translator({ url, data }) {
    return new Promise(async (resolve, reject) => {
      console.log("translator", `${url_root}/v2/${url}`);
      console.log("data", data);
      this.http.post(`${url_root}/v2/${url}`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  private getUsers() {
    return this.afs
      .collection("users")
      .valueChanges({ idField: "uid" }) as Observable<any[]>;
  }

  private getUserForMsg(msgFromId, users: any[]): string {
    for (let usr of users) {
      if (usr.uid == msgFromId) {
        return usr.email;
      }
    }
    return "admin";
  }
}
