import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JudgeErrorsService {


  public milderrors: any = [
    {
      selected: false,
      max: 1,
      value: 0,
      name: "more than 7 tricks lifts or acrobatics",
    }, {
      selected: false,
      max: 1,
      value: 0,
      name: "overtime between 00:00 - 00:05 seconds",
    }, {
      selected: false,
      max: 1,
      value: 0,
      name: "every 5 seconds more",
    }, {
      selected: false,
      max: 1,
      value: 0,
      name: "music 80 / 20 % Music & Dance rule",
    }, {
      selected: false,
      max: 1,
      value: 0,
      name: "Missing Mandatory meetings",
    }, {
      selected: false,
      max: 1,
      value: 0,
      name: "violating Costume rules",
    }, {
      selected: false,
      max: 1,
      value: 0,
      name: "Dancers walk in in the beginning of your performance",
    }, {
      selected: false,
      max: 1,
      value: 0,
      name: "Dancers do not stand 3 seconds in their place",
    }, {
      selected: false,
      max: 1,
      value: 0,
      name: "recording with moving camera",
    }, {
      selected: false,
      max: 1,
      value: 0,
      name: "Recording with mirror in the back",
    }, {
      selected: false,
      max: 1,
      value: 0,
      name: "Recording with shoes or unnecessary materials  to mark your stage",
    }, {
      selected: false,
      max: 1,
      value: 0,
      name: "Dancing outside the view camera",
    }
  ]

  // public milderrors: any = [
  //   {
  //     selected: false,
  //     max: 1,
  //     value: 0,
  //     name: "Saludos y caminadas",
  //   },
  //   {
  //     selected: false,
  //     max: 1,
  //     value: 0,
  //     name: "Caída de un elemento personal en el escenario",
  //   },
  //   {
  //     selected: false,
  //     max: 1,
  //     value: 0,
  //     name: "Presentarse descalzo",
  //   },
  //   {
  //     selected: false,
  //     max: 1,
  //     value: 0,
  //     name: "Utilización de medias (mujer)",
  //   },
  //   {
  //     selected: false,
  //     max: 3,
  //     value: 0,
  //     name: "desconexión en figura o movimiento",
  //   },
  //   {
  //     selected: false,
  //     max: 3,
  //     value: 0,
  //     name: "Perdida de tiempo",
  //   },
  //   {
  //     selected: false,
  //     max: 3,
  //     value: 0,
  //     name: "Efectos musicales que no pertenecen a la canción original",
  //   },
  //   {
  //     selected: false,
  //     max: 3,
  //     value: 0,
  //     name: "Elemento escenográfico",
  //   },
  //   {
  //     selected: false,
  //     max: 3,
  //     value: 0,
  //     name: "Fusión de géneros musicales",
  //   },
  //   {
  //     selected: false,
  //     max: 3,
  //     value: 0,
  //     name: "Exceder el tiempo permitido",
  //   },
  // ];


  public seriousErrors = []

  // public seriousErrors: any = [
  //   { selected: false, max: 5, value: 0, name: "Acrobacias adicionales" },
  //   { selected: false, max: 5, value: 0, name: "Caídas" },
  //   {
  //     selected: false,
  //     max: 5,
  //     value: 0,
  //     name: "Acrobacias adicionalesDesconexión con el piso en trucos",
  //   },
  // ];



  constructor() { }
}
