import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-play-video",
  templateUrl: "./play-video.component.html",
  styleUrls: ["./play-video.component.scss"],
})
export class PlayVideoComponent implements OnInit {
  @Input() video: any;
  @Input() showHeader: any;
  @Input() name: any;
  constructor(public modalController: ModalController) { }

  ngOnInit() {
  
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
