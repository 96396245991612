import { Component, OnInit } from '@angular/core';
import { NavController, ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-participants',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.scss']
})
export class ParticipantsComponent implements OnInit {
  public participants = [];
  constructor(
    private modalCtrl: ModalController,
    private params: NavParams
  ) {
    this.participants = this.params.get('participants');
    console.log(this.participants);
  }

  ngOnInit() { }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
