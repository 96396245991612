import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonService, CustomizationfileService } from 'src/app/services/services';
import { JudgesService } from 'src/app/services/v2/judges.service';

@Component({
  selector: 'app-global-report-of-judges-result',
  templateUrl: './global-report-of-judges-result.component.html',
  styleUrls: ['./global-report-of-judges-result.component.scss'],
})
export class GlobalReportOfJudgesResultComponent implements OnInit {
  judgesReport: any
  totalGlobal: any;
  totalQualifications: any;
  constructor(
    public commonService: CommonService,
    public customizationfileService: CustomizationfileService,
    public modalController: ModalController,
    public judgesService: JudgesService) { }

  ngOnInit() {
    this.getjudgesReport()
  }


  async getjudgesReport() {
    this.judgesReport = await this.judgesService.judgesReport({
      route: `judges/judgesReport?keydb=${this.customizationfileService.getKeyDb()}`,
      data: {}
    }).catch(err => {
      this.commonService.presentAlert(JSON.stringify(err))
      console.log("err", err)
    })

    console.log("this.judgesReport", this.judgesReport)
    this.judgesReport.results.ratingByJudges = this.judgesReport.results.list.sort((a, b) => {
      if (a.totalQualifications < b.totalQualifications) {
        return 1;
      }
      if (a.totalQualifications > b.totalQualifications) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

    this.totalGlobal = 
    this.judgesReport.results.ratingByJudges.reduce((sum, { total }) => sum +Number(total) , 0)
    console.log('sumTotal', this.totalGlobal)

    this.totalQualifications = 
    this.judgesReport.results.ratingByJudges.reduce((sum, { totalQualifications }) => sum +Number(totalQualifications) , 0)
    console.log('sumTotal', this.totalQualifications)

  }

  downloadReport(){
    console.log('downloadReport')
  }


  dismiss() {
    this.modalController.dismiss();
  }

}
