import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Pipe({
  name: 'judgeUploadedVideo'
})
export class JudgeUploadedVideoPipe implements PipeTransform {


  constructor(public db: AngularFireDatabase) { }


  async transform(value: any, keydb: any, code: any, round: any, keydivision: any, uidJubge: any): Promise<any> {
    const result = await this.getJudgesVideo({ keydb, code, round, keydivision, uidJubge })
    return result;
  }


  getJudgesVideo({ keydb, code, round, keydivision, uidJubge }) {
    return new Promise((resolve) => {
      this.db
        .object(`/competition/${keydb}/mainChoreography/${code}/${round}/${keydivision}/audio/${uidJubge}/urlVideo`)
        .valueChanges()
        .subscribe((x: any) => {
          if (x) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  }


}
