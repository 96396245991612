import { Component, OnInit } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { CommonService } from "src/app/services/services";
import { Onesignalv2Service } from "src/app/services/v2/onesignalv2.service";
import { ViewNotificationAdminComponent } from "../view-notification-admin/view-notification-admin.component";

@Component({
  selector: "app-list-admin-notification",
  templateUrl: "./list-admin-notification.component.html",
  styleUrls: ["./list-admin-notification.component.scss"],
})
export class ListAdminNotificationComponent implements OnInit {
  listResult: any;
  constructor(
    public alertController: AlertController,
    public modalController: ModalController,
    public onesignalv2Service: Onesignalv2Service,
    public commonService: CommonService
  ) {}

  ngOnInit() {
    this.getListNotification();
  }

  async getListNotification() {
    try {
      const res: any = await this.onesignalv2Service.onesignalV1({
        route: "viewNotifications",
        data: null,
      });
      this.listResult = res.results.notifications;
    } catch (err) {
      console.log("result", err);
      this.commonService.presentAlert(err.error.message);
    }
  }

  async cancelNotification(notificationId) {
    try {
      const alert = await this.alertController.create({
        message: "¿Cancelar notificación?",
        buttons: [
          {
            text: "Cancelar",
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              console.log("Confirm Cancel: blah");
            },
          },
          {
            text: "Ok",
            handler: async () => {
              const res: any = await this.onesignalv2Service
                .onesignalV1({
                  route: "cancelNotification",
                  data: {
                    notificationId,
                  },
                })
                .catch((err) => {
                  this.commonService.presentAlert(err.error.message);
                });

              this.listResult = res.results.notifications;
              console.log("this.listResult", this.listResult);
            },
          },
        ],
      });

      await alert.present();
    } catch (err) {
      console.log("result", err);
      this.commonService.presentAlert(err.error.message);
    }
  }

  async viewNotification(notificationId) {
    const modal = await this.modalController.create({
      component: ViewNotificationAdminComponent,
      componentProps: {
        notificationId,
      },
    });
    return await modal.present();
  }

  trackByFn(index, item) {
    return index; // or item.id
  }
}
