import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CommonService } from 'src/app/services/services';
import { VimeoService } from 'src/app/services/v2/vimeo.service';
import * as tus from 'tus-js-client';

@Component({
  selector: 'app-input-single-vimeo-video',
  templateUrl: './input-single-vimeo-video.component.html',
  styleUrls: ['./input-single-vimeo-video.component.scss'],
})
export class InputSingleVimeoVideoComponent implements OnInit {


  @ViewChild('fileUploadInputVideo') fileUploadInputVideo: ElementRef<HTMLInputElement>;

  public file: File;

  public upload: tus.Upload;
  public progress = 0;

  public btnStart = true;
  public btnCancel = false;

  @Output() upVideo = new Subject<Object>();

  constructor(
    private vimeoService: VimeoService,
    private alertController: AlertController,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
  }


  /**
   * Seleccionar archivo desde el navegador
   * @param e
   */
  selectFromBrowser(e: Event) {
    const files = this.fileUploadInputVideo.nativeElement.files;
    const file = files[0];

    this.file = file;

    console.log({ file });
  }


  /**
   * Preguntar antes de iniciar la carga
   * @returns
   */
  async askBeforeStart() {

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Settle',
      message: '¿Desea subir este video?',
      backdropDismiss: false,
      buttons: [
        { role: 'cancel', text: 'Cancelar' },
        { role: 'next', text: 'Si' },
      ]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();

    if (role === 'cancel') { return; }

    await this.start();
  }


  /**
   * Iniciar carga del video en vimeo
   */
  async start() {
    this.btnCancel = true;
    this.btnStart = false;

    try {

      /** Crear video en servidor */
      const create = await this.vimeoService.createVideo(this.file)
        .pipe(
          take(1),
          map((response) => {
            console.log('response: ', { response });

            return {
              link: response.body.link,
              upload_link: response.body.upload.upload_link,
            };
          })
        ).toPromise();

      /** Crear peticion para subir */
      const upload = new tus.Upload(
        this.file,
        {
          uploadUrl: create.upload_link,
          endpoint: create.upload_link,
          retryDelays: this.vimeoService.retryDelays,
          chunkSize: this.vimeoService.chunkSize,
          parallelUploads: this.vimeoService.parallelUploads,
          onError: (err) => {
            console.log('Failed: ' + this.file.name + err);
          },
          onProgress: (bytesUploaded, bytesTotal) => {
            const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
            console.log('file: ', bytesUploaded, bytesTotal, percentage + '%');

            /** Actualizar valor del porcentaje */
            this.progress = Number(percentage);
          },
          onSuccess: () => {
            // console.log('Download ' + this.file.name + ' from ' + upload.url,
            console.log('Download ' + this.file.name + ' from ' + upload.url,
              {
                url: upload.url,
                link: create.link,
              }
            );

            console.log('Videos uploaded successfully');

            this.btnStart = true;
            this.btnCancel = false;

            this.completeAlert(this.file, { url: upload.url, link: create.link });
            this.file = undefined;
          }
        }
      );

      this.upload = upload;
      this.startOrResumeUpload();
      // this.startOrResumeUpload(upload);

    } catch (err) {
      console.log('Error on try to upload video on Vimeo', err);
      this.commonService.dismissLoading();
    }

  }


  /**
   * Preguntar antes de iniciar la carga
   * @returns
   */
  async askBeforeCancel() {

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Settle',
      message: '¿Desea cancelar la carga del video?',
      backdropDismiss: false,
      buttons: [
        { role: 'cancel', text: 'Cancelar' },
        { role: 'next', text: 'si' },
      ]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();

    if (role === 'cancel') { return; }

    await this.pause();
  }


  /**
   * Cancelar carga del video
   */
  async pause() {
    const upload = this.upload;
    upload.abort();
  }


  /**
   * Iniciar carga o restablecer alguna pendiente
   */
  // startOrResumeUpload(toUpload){
  startOrResumeUpload() {
    const toUpload = this.upload;

    /** Buscar peticiones que esten pendientes por ser ejecutadas */
    toUpload.findPreviousUploads()
      .then(function (previousUploads) {

        /** Si existen peticiones pendientes */
        if (previousUploads.length) {
          toUpload.resumeFromPreviousUpload(previousUploads[0]);
        }

        toUpload.start();
      })
      .catch((err) => console.log('Error on start', { err }));
  }

  /**
   * Alerta al completar la carga del video
   * @param file
   */
  async completeAlert(file: File, data: any) {


    this.upVideo.next({ data, file });


    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Settle',
      message: `El  archivo <strong>${file.name}</strong> fue cargado con exito.<br> url: <strong>${data.url}</strong>.<br> link: <strong>${data.link}</strong>`,
      // message: 'El archivo '+ file.name + ' fue cargado con exito, url: ' + data.url + ', link: ' + data.link,
      backdropDismiss: false,
      buttons: ['Ok']
    });

    await alert.present();
  }

}
