import { Component, OnInit } from "@angular/core";
import {
  UploadFileService,
  CustomizationfileService,
  AuthenticationService,
} from "../../../../services/services";
import { File } from "@ionic-native/file/ngx";
import { CommonService } from "../../../../services/v1/common/common.service";
import { Platform, ModalController } from "@ionic/angular";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireDatabase } from "@angular/fire/database";
import { MediaFilesService } from "src/app/services/v2/media-files.service";
import { ImageCropperComponent } from "src/app/components/image-cropper/image-cropper.component";

@Component({
  selector: "avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent implements OnInit {
  public fileUrl: any = null;
  isCordova: any;
  files: any;
  avatarFace: any;
  yourImageDataURLBase64: any;
  constructor(
    public mediaFilesService: MediaFilesService,
    public _uploadFile: UploadFileService,
    public _auth: AuthenticationService,
    public _cf: CustomizationfileService,
    public file: File,
    public _cs: CommonService,
    public platform: Platform,
    private afs: AngularFirestore,
    private db: AngularFireDatabase,
    public modalController: ModalController,
  ) {
    this.isCordova = this._cs.isCordova();
    this._auth.avatar.subscribe((avatar) => {
      console.log("avatarFace", avatar)
      this.avatarFace = avatar;
      window.localStorage.setItem("avatar", avatar);
    });
  }

  ngOnInit() { }

  async updatePhoto(event) {

    try {
      console.log("event", event)
      console.log("this.isCordova", this.isCordova)
      if (this.isCordova) {

        const captureImagenUrl: any = await this.mediaFilesService.pickImages({
          message: "CILIK",
          outputType: 0,
          quality: 100,
          maximumImagesCount: 1,
          allow_video: false,
          disable_popover: true,
          enabledCopyFileToLocalDir: false
        })

        let pathToImage = captureImagenUrl[0]
        console.warn("pathToImage", pathToImage);

        const cropImagen: any = await this.mediaFilesService
          .cropImagen({ pathToImage, quality: 75, targetWidth: -1, targetHeight: -1, base64: true })

        await this._cs.presentLoading()
        this.fileUrl = await this.mediaFilesService.showCroppedImage(cropImagen.split('?')[0])
        const urlAvatar = await this.mediaFilesService.uploadFile(this.fileUrl)
        this.updateProfile(urlAvatar)
        return
      } else {
        console.log("event", event)
        const modal = await this.modalController.create({
          component: ImageCropperComponent,
          swipeToClose: false,
          backdropDismiss: false,
          componentProps: {
            imageChangedEvent: event,
          },
        });

        modal.onDidDismiss().then(async (dataReturned) => {
          if (dataReturned !== null) {
            if (dataReturned.data.status === 1) {
              await this._cs.presentLoading()
              const urlAvatar = await this.mediaFilesService.uploadFile(dataReturned.data.message)
              this.updateProfile(urlAvatar)
              return
            }
          }
        });

        return await modal.present();
      }
    } catch (err) {
      console.log("error", err)
      this._cs.presentAlertTranslation("ha ocurrido un error desconocido")
    }


  }




  async updateProfile(url) {
    console.log(url)
    if (!url) {
      this._cs.dismissLoading()
      return
    }
    const uid = this._cf.getUid();
    this._cf.setAvatar(url);
    this._auth.avatar.next(url)
    await this.db.object(`users/${uid}`).update({ avatar: url });
    await this.afs.collection("users").doc(uid).update({ avatar: url });
    await this._cs.presentAlertTranslation("avatar actualizado")
    this._cs.dismissLoading()

  }
}
