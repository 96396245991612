import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalController } from "@ionic/angular";
import {
  CommonService,
  CustomizationfileService,
} from "src/app/services/services";
import { MediaFilesService } from "src/app/services/v2/media-files.service";
import { OptionComponent } from "../option/option.component";

@Component({
  selector: "app-info-chat",
  templateUrl: "./info-chat.component.html",
  styleUrls: ["./info-chat.component.scss"],
})
export class InfoChatComponent implements OnInit {
  @Input() infoChat;
  @Output() closeButtonChat = new EventEmitter<string>();
  @Output() open: EventEmitter<any> = new EventEmitter();

  translateAdmin = "";
  screenChat: boolean;
  constructor(
    public commonService: CommonService,
    public mediaFilesService: MediaFilesService,
    public modalController: ModalController,
    public customizationfileService: CustomizationfileService
  ) {}

  ngOnInit() {
    this.translateAdmin = this.customizationfileService.getTranslate();
  }

  closeModal() {
    console.log("closeModal");
    this.closeButtonChat.emit(null);
  }

  gridOptions() {
    this.commonService.mediaBreakpoint$.subscribe((data) => {
      if (data == "xl" || data == "xxl" || data == "lg") {
        // pantalla grande
        console.log("pantalla grande");
        this.screenChat = true;
      } else {
        // pantalla ipad
        console.log(" pantalla ipad and movil");
        this.screenChat = false;
      }
    });
  }

  async presentOptionModal() {
    const modal = await this.modalController.create({
      component: OptionComponent,
      cssClass: "my-custom-class",
      componentProps: { infoChat: this.infoChat },
    });
    return await modal.present();
  }
}
