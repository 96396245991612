import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
const url_root: any = environment.urlrootFunctions;

@Injectable({
  providedIn: "root",
})
export class Onesignalv2Service {
  constructor(private http: HttpClient) {}

  onesignalV1({ route, data }) {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${url_root}/onesignal/${route}`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  onesignalV2({ url, data }) {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${url_root}/v2/${url}`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
