import { Injectable } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
import * as geohash from 'ngeohash';
import { BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  public geohash = new BehaviorSubject({});

  geoLatitude: number;
  geoLongitude: number;
  geoAccuracy: number;
  geoAddress: string;

  watchLocationUpdates: any;
  loading: any;
  isWatching: boolean;

  // Geocoder configuration
  geoencoderOptions: NativeGeocoderOptions = {
    useLocale: true,
    maxResults: 5
  };

  constructor(private geolocation: Geolocation,
    private nativeGeocoder: NativeGeocoder,
    public platform: Platform) {
    this.getGeolocation();
  }


  // Get current coordinates of device
  getGeolocation() {

    if (this.platform.is('cordova')) {

      this.geolocation.getCurrentPosition().then((resp) => {
        this.geoLatitude = resp.coords.latitude;
        this.geoLongitude = resp.coords.longitude;
        this.geoAccuracy = resp.coords.accuracy;

        this.getGeoencoder(this.geoLatitude, this.geoLongitude);

        const result_geahash = this.getGeohashRange(this.geoLatitude, this.geoLongitude, 1000);
        this.geohash.next(result_geahash);

        return;
      }).catch((error) => {
        console.log(1, error);
      });

    } else {
      navigator.geolocation.getCurrentPosition((data: any) => {

        this.geoLatitude = data.coords.latitude;
        this.geoLongitude = data.coords.longitude;
        const result_geohash = this.getGeohashRange(this.geoLatitude, this.geoLongitude, 1000);
        this.geohash.next(result_geohash);
      }, function () {
      }, { timeout: 10000 });
    }

  }


  // geocoder method to fetch address from coordinates passed as arguments
  getGeoencoder(latitude, longitude) {
    this.nativeGeocoder.reverseGeocode(latitude, longitude, this.geoencoderOptions)
      .then((result: any[]) => {
        this.geoAddress = this.generateAddress(result[0]);
      }).catch((error: any) => {
        //  alert('Error getting location' + JSON.stringify(error));
      });
  }

  // Return Comma saperated address
  generateAddress(addressObj) {
    if (addressObj) {
      const obj = [];
      let address = '';
      for (const key in addressObj) {
        if (addressObj.hasOwnProperty(key)) {
          obj.push(addressObj[key]);
        }
      }
      obj.reverse();
      for (const val in obj) {
        if (obj.hasOwnProperty(val)) {
          address += obj[val] + ', ';
        }
      }
      return address.slice(0, -2);
    } else {
      return '';
    }

  }


  // Start location update watch
  watchLocation() {
    this.isWatching = true;
    this.watchLocationUpdates = this.geolocation.watchPosition();
    this.watchLocationUpdates.subscribe((resp) => {
      this.geoLatitude = resp.coords.latitude;
      this.geoLongitude = resp.coords.longitude;
      this.getGeoencoder(this.geoLatitude, this.geoLongitude);
    });
  }

  // Stop location update watch
  stopLocationWatch() {
    this.isWatching = false;
    this.watchLocationUpdates.unsubscribe();
  }


  getGeohashRange(
    latitude: number,
    longitude: number,
    distance: number, // miles
  ) {
    const lat = 0.0144927536231884; // degrees latitude per mile
    const lon = 0.0181818181818182; // degrees longitude per mile

    const lowerLat = latitude - lat * distance;
    const lowerLon = longitude - lon * distance;

    const upperLat = latitude + lat * distance;
    const upperLon = longitude + lon * distance;

    const lower = geohash.encode(lowerLat, lowerLon);
    const upper = geohash.encode(upperLat, upperLon);

    return {
      lower,
      upper
    };
  }
}
