import { Component, Input, OnInit } from "@angular/core";
import { AngularFireDatabase } from "angularfire2/database";
import {
  CommonService,
  CustomizationfileService,
  LanguageService,
  ManageUsersService,
} from "src/app/services/services";
import { Globalization } from "@ionic-native/globalization/ngx";
import { MediaFilesService } from "src/app/services/v2/media-files.service";
import { ScrollEventService } from "src/app/services/v2/scroll-event.service";
import { YoutubeServicesService } from "src/app/services/v2/youtube-services.service";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-test-videos",
  templateUrl: "./test-videos.component.html",
  styleUrls: ["./test-videos.component.scss"],
})
export class TestVideosComponent implements OnInit {
  @Input() states: string;
  @Input() keyDb: string;
  @Input() keyDivision: string;
  @Input() dataDivision: any;
  @Input() round: any;

  url_root: string;
  data: any;
  videoRef = "";
  type = ""
  constructor(
    public modalController: ModalController,
    public languageService: LanguageService,
    public scrollEventService: ScrollEventService,
    public mediaFilesService: MediaFilesService,
    public youtubeServicesService: YoutubeServicesService,
    public db: AngularFireDatabase,
    public commonService: CommonService,
    public customizationfileService: CustomizationfileService,
    public manageUsersService: ManageUsersService,
    private globalization: Globalization
  ) { }

  async ngOnInit() {
    console.log("this.keyDb", this.keyDb);
    console.log("this.keyDivision", this.keyDivision);
    console.log("this.round", this.round);

    let language: any = "en";
    if (this.commonService.isCordova()) {
      language = await this.globalization.getPreferredLanguage();
    } else {
      language = this.languageService.navigatorLanguage();
    }
    console.log("createStates language", language);
    const res = language.value
      ? language.value.substring(0, 2)
      : language.substring(0, 2);
    console.log("createStates language", res);

    if (res === "en") {
      this.videoRef =
        "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/videos-round%2FStep%202%20English%20location%20check%20.mp4?alt=media&token=8375d1a7-5d80-4e90-ba83-ca6b6a89cfc7";
    } else {
      this.videoRef =
        "https://firebasestorage.googleapis.com/v0/b/wldc-app.appspot.com/o/videos-round%2FStep%202%20spanish%20location%20check.mp4?alt=media&token=f2060a8d-4630-4abc-bc82-10bd13463e1d";
    }

    this.url_root = `competition/${this.keyDb}/testVideos/${this.dataDivision.category_detail.id}/${this.round}/${this.keyDivision}`;
    this.db
      .object(this.url_root)
      .valueChanges()
      .subscribe(async (res: any) => {
        if (res !== null) {
          this.data = res;
          this.data.show = false;
        } else {
          await this.commonService.presentLoading();
          await this.createStates();
          this.commonService.dismissLoading();
        }
      });
  }

  showCommentshow() {
    this.data.show = !this.data.show;
    this.scrollEventService.scrollEvent.next(2);
  }

  showComment(text) {
    this.commonService.presentAlert(text);
  }

  async createStates() {
    await this.commonService.presentLoading();
    await this.manageUsersService
      .createStatesTestVideo({
        keydb: this.keyDb,
        round: this.round,
        code: this.dataDivision.category_detail.id,
        keyDivision: this.keyDivision,
        uid: this.customizationfileService.getUid(),
      }).catch((err) => {
        this.commonService.dismissLoading();
        this.commonService.presentAlert(JSON.stringify(err));
      });

    this.commonService.dismissLoading();
  }

  async recordVideo() {
    console.log("start recordVideo");
    if (this.commonService.isCordova()) {
      this.type = "movil"
      await this.commonService.presentLoading();
      const res = await this.mediaFilesService
        .recordVideo({ limit: 1, duration: 60 })
        .catch((err) => {
          this.saveError({
            comment: "error",
            video: false,
            step: 2,
          })
          this.commonService.dismissLoading();
          this.commonService.presentAlert(JSON.stringify(err));
        });

      console.log("recordVideo", res);


      await this.upload({
        captureVideoUrl: res,
        contentType: "video/mp4",
        newName: this.keyDivision,
        codeDivision: this.dataDivision.category_detail.id,
      });


    } else {
      this.type = "web"
      const urlVideo = await this.mediaFilesService.presentModalRecordVideo()
      this.saveData({ urlVideo })
      this.commonService.presentAlertTranslation("cargado con éxito");
      this.commonService.dismissLoading();
    }
  }





  async upload({ captureVideoUrl, contentType, newName, codeDivision }) {
    let urlVideo = await this.mediaFilesService
      .upload({
        captureVideoUrl,
        contentType,
        newName,
        codeDivision,
      })
      .catch((err) => {
        this.commonService.dismissLoading();
        this.commonService.presentAlert(JSON.stringify(err));
      });


    this.saveData({ urlVideo })
    this.commonService.presentAlertTranslation("cargado con éxito");
    this.commonService.dismissLoading();
  }

  async saveData({ urlVideo }) {
    await this.db.object(this.url_root).update({
      comment: "pending to review",
      video: urlVideo,
      last_update: Date.now(),
      type: this.type,
      step: 3,
    });

    await this.db
      .object(
        `competition/${this.keyDb}/division/${this.keyDivision}/states/`
      )
      .update({
        uid_user: this.customizationfileService.getUid(),
        last_update: Date.now(),
        step1: 1,
      });
  }

  saveError(data) {
    this.db.object(this.url_root).update(data);
  }

  openVideo(url) {
    this.mediaFilesService.playVideo(url);
  }
}
