import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalReportOfJudgesResultComponent } from '../global-report-of-judges-result/global-report-of-judges-result.component';
import { WinnersReportComponent } from '../winners-report/winners-report.component';

@Component({
  selector: 'app-result-popover',
  templateUrl: './result-popover.component.html',
  styleUrls: ['./result-popover.component.scss'],
})
export class ResultPopoverComponent implements OnInit {



  constructor(public modalController: ModalController) { }

  ngOnInit() { }



  globalReportOfJudges() {
    this.openMondal(GlobalReportOfJudgesResultComponent)
  }

  winnersReportComponent() {
    this.openMondal(WinnersReportComponent)
  }

  async openMondal(Component) {
    const modal = await this.modalController.create({
      component: Component,
      cssClass: "my-custom-modal-css",
      swipeToClose: false,
      backdropDismiss: false,
      componentProps: {},
    });

    return await modal.present()
  }

}
