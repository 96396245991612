import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CommonService, CustomizationfileService } from 'src/app/services/services';

// import { normalizeCommonJSImport } from "../../../../../helpers/normalizeCommonJSImport";
// const importChart = normalizeCommonJSImport(
//   import(/* webpackChunkName: "chart" */ 'chart.js'),
// );
// import { Chart } from 'chart.js';



@Component({
  selector: 'app-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.scss'],
})
export class SurveyListComponent implements OnInit, AfterViewInit {

  keydb: string;
  dataSurvey: any;


  segmentGlobal = "barChart"
  bars: any;
  colorArray: any;
  listChart: any[];
  commentList: any[];
  segment2 = "chart"
  constructor(
    public afs: AngularFirestore,
    public commonService: CommonService,
    public customizationfileService: CustomizationfileService) {
    this.keydb = this.customizationfileService.getKeyDb()

  }

  async ngOnInit() {
    this.dataSurvey = await this.getSurvey()
    let question = this.dataSurvey[0]
    this.listChart = []
    this.commentList = []
    Object.keys(question).forEach(x => {
      if (x === "uid") { return }
      let chart = this.getDataChart(this.dataSurvey, x)
      if (x === "in-what-areas-do-you-think-we-should-improve"
        || x === "what-did-you-like-most-about-this-virtual-version-of-the-wldc") {
        this.commentList.push({ name: x, mess: chart })
        return
      }

      this.listChart.push({ name: x, chart: chart })
    })

    console.log("commentList", this.commentList)
    console.log("listChart", this.listChart)
  }



  getDataComment(arrayData, value) {

  }

  getDataChart(arrayData, value) {

    // console.log("value", value)
    let filteDataValue = arrayData.map(res => { return res[value] })

    let countDataValue = this.commonService.countdDuplicate(filteDataValue)

    let labels = Object.keys(countDataValue).map(x => {
      return x
    })

    let data = Object.keys(countDataValue).map(x => {
      return countDataValue[x]
    })


    return { labels, data }
  }



  ngAfterViewInit() {
    console.log("show chat")
  }

  getSurvey() {
    return new Promise((resolve, reject) => {
      this.afs.collection('survey')
        .doc(this.keydb)
        .collection('list')
        .valueChanges()
        .subscribe((res: any) => {
          if (!res) { reject([]) }
          resolve(res)
        })
    });
  }


}


