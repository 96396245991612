
import { Injectable } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import * as moment from 'moment';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadFileService } from "../uploadFile/upload-file.service";
import * as firebase from 'firebase/app';
interface RecordedAudioOutput {
    blob: Blob;
    title: string;
}

@Injectable({
    providedIn: "root"
})
export class RecordRTCService {
    private navigator: any
    private stream;
    private recorder;
    private interval;
    private startTime;
    private _recorded = new Subject<RecordedAudioOutput>();
    private _recordingTime = new Subject<string>();
    private _recordingFailed = new Subject<string>();
    public video_url_db = new BehaviorSubject('false');
    video: any
    video_url: any
   
    constructor(private sanitizer: DomSanitizer,
        public _up: UploadFileService) {
        this.navigator = navigator as any;
        if (!this.navigator.getDisplayMedia && !this.navigator.mediaDevices.getDisplayMedia) {
            const error = 'Your browser does NOT support the getDisplayMedia API.';
            throw new Error(error);
        }
    }


    startRecording() {
        if (this.recorder) {
            // It means recording is already started or it is already recording something
            return;
        }

        this._recordingTime.next('00:00');
        var displaymediastreamconstraints = {
            video: {
                displaySurface: 'monitor', // monitor, window, application, browser
                logicalSurface: true,
                cursor: 'always' // never, always, motion
            },
            enableMicrophone: true,
        };

       

        if (this.navigator.mediaDevices.getDisplayMedia) {
            this.navigator.mediaDevices.getDisplayMedia(displaymediastreamconstraints)
                .then(success => {
                    console.log('1 success', success)
                    this.stream = success;
                    this.record();
                }).catch(error => {
                    console.log('2 error', error)
                    this._recordingFailed.next();
                });
        } else {
            this.navigator.getDisplayMedia(displaymediastreamconstraints)
                .then(success => {
                    this.stream = success;
                    this.record();
                    console.log('3 success', success)
                }).catch(error => {
                    console.log('4 error', error)
                    this._recordingFailed.next();
                });
        }

    }



    private record() {

        this.video = document.querySelector('video');

        this.video.srcObject = this.stream;

        this.recorder = RecordRTC(this.stream, {
            type: 'video'
        });

        this.recorder.startRecording();

        this.recorder.screen = this.stream


        this.startTime = moment();
        this.interval = setInterval(
            () => {
                const currentTime = moment();
                const diffTime = moment.duration(currentTime.diff(this.startTime));
                const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
                this._recordingTime.next(time);
            },
            1000
        );
    }


    stopRecording() {
        if (this.recorder) {
            this.recorder.stopRecording(async (blob) => {
                console.log("blob", blob)
                this.video.src = this.video.srcObject = null;
                this.video_url = this.sanitizer.bypassSecurityTrustUrl(blob);
                console.log('this.video_url', this.video_url)
                this.recorder.screen.stop();
                this.recorder.destroy();
                this.recorder = null;
                this.stopMedia();

                const urldb = await this.uploadVideo(blob)
                console.log('urldb', urldb)

            }, () => {
                this.stopMedia();
                this._recordingFailed.next();
            });
        }

    }


    async uploadVideo(blob) {

        const result = await this.blobFromURL(blob)
        console.log('result', result)
        console.log('blob', blob)

        var metadata = {
            contentType: 'video/mp4',
        };



        var storageRef = firebase.storage().ref();
        var uploadTask = storageRef.child(`video_prueba/${Date.now()}.mp4`).put(result, metadata)


        uploadTask.on('state_changed', (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
            }
        }, (error) => {
            // Handle unsuccessful uploads
            console.log('error', error)
        }, () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log('File available at', downloadURL);
                // this.video_url_db = downloadURL
                // this.video_url_db.next(downloadURL)
            });
        });


    }



    async blobFromURL(url) {
        const blob = await fetch(url).then(r => r.blob());
        return blob
    }




    private toString(value) {
        let val = value;
        if (!value) {
            val = '00';
        }
        if (value < 10) {
            val = '0' + value;
        }
        return val;
    }



    private stopMedia() {
        if (this.recorder) {
            this.recorder = null;
            clearInterval(this.interval);
            this.startTime = null;
            if (this.stream) {
                this.stream.getAudioTracks().forEach(track => track.stop());
                this.stream = null;
            }
        }
    }


    abortRecording() {
        this.stopMedia();
    }

    getRecordedBlob(): Observable<RecordedAudioOutput> {
        return this._recorded.asObservable();
    }

    getRecordedTime(): Observable<string> {
        return this._recordingTime.asObservable();
    }

    recordingFailed(): Observable<string> {
        return this._recordingFailed.asObservable();
    }


}
