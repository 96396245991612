import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortCalendar'
})
export class SortCalendarPipe implements PipeTransform {

  /**
   * 
   * @param value 
   * @param args 
   * @returns 
   */
  transform(value: any, args?: any): any {
    if (value) { return value }

    return value.sort(function (a, b) {
      if (a.members > b.members) {
        return 1;
      }
      if (a.members < b.members) {
        return -1;
      }
      // a must be equal to b
      return 0;
    })
  }


}
