import { Pipe, PipeTransform } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { CommonService } from 'src/app/services/services';

@Pipe({
  name: 'getDivisionInformation'
})
export class GetDivisionInformationPipe implements PipeTransform {

  constructor(
    public db: AngularFireDatabase,
    public commonService: CommonService) { }
  async transform(value,keydb: any, keydivision: any, type: any) {

    console.log("value", value)
    console.log("keydb", keydb)
    console.log("keydivision", keydivision)
    console.log("type", type)

    let result
    switch (type) {
      case '1':
        result = await this.getData(`/competition/${keydb}/division/${keydivision}`)
        break;
      case '2':
        result = await this.getData(`/competition/${keydb}/division/${keydivision}/categories/0`)
        break;
      case '3':
        result = await this.getData(`/competition/${keydb}/division/${keydivision}/school`)
        break;

      case '4':
        console.log(`/competition/${keydb}/division/${keydivision}/categories/0`)
        let res: any = await this.getData(`/competition/${keydb}/division/${keydivision}`)
        console.log('res',res)
        result = this.commonService.getName2(res)
        break;

      default:
        break;
    }

    return result;
  }

  getData(url) {
    return new Promise((resolve) => {
      this.db
        .object(url)
        .valueChanges()
        .subscribe((x: any) => {
          if (x) {
            resolve(x);
          } else {
            resolve("N/A");
          }
        });
    });
  }



}
