import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CommonService } from 'src/app/services/services';

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent2 implements OnInit {
  // Data passed in by componentProps
  @Input() users: any[];
  @Input() gender: any[];
  users_list: any[];
  constructor(
    public _cs: CommonService,
    private navParams: NavParams,
    private modalController: ModalController) { }

  async ngOnInit() {
    this.users_list = this.sortName(this.navParams.get('users'));
    console.log('this.users_list', this.users_list);
    this.gender = this.navParams.get('gender');
  }

  sortName(items) {
    if (!items) { return; }
    return items.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  }




  closeModal() {
    this.modalController.dismiss(null, 'cancel');
  }





  addCartUser(user) {
    this.modalController.dismiss(
      { user: user },
      'confirm'
    );

  }

}
