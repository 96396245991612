import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomizationfileService } from '../services';
const url_root: any = environment.urlrootFunctions;

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(private http: HttpClient,
    public customizationfileService: CustomizationfileService) { }

  getWallet({ route, data }) {
    return new Promise(async (resolve, reject) => {
      console.log(`${url_root}/v2/${route}`)
      this.http.get(`${url_root}/v2/${route}`).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  postWallet({ route, data }) {
    return new Promise(async (resolve, reject) => {
      console.log(`${url_root}/v2/${route}`)
      this.http.post(`${url_root}/v2/${route}`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
