import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/services';

@Component({
  selector: 'app-iframe-vimeo',
  templateUrl: './iframe-vimeo.component.html',
  styleUrls: ['./iframe-vimeo.component.scss'],
})
export class IframeVimeoComponent implements OnInit {
  @Input() video: any;
  @Input() backButton: any;
  screenChat: boolean;
  constructor(public commonService: CommonService) { }

  ngOnInit() {
    console.log("app-iframe-vimeo", this.video)
    this.gridOptions()
  }


  gridOptions() {
    this.commonService.mediaBreakpoint$.subscribe((data) => {
      if (data == "xl" || data == "xxl" || data == "lg") {
        // pantalla grande
        console.log("pantalla grande");
        this.screenChat = true;
      } else {
        // pantalla ipad
        console.log("pantalla ipad and movil");
        this.screenChat = false;
      }
    });
  }

}
