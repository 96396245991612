import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { CustomizationfileService, LanguageService, RatingsService, CommonService } from 'src/app/services/services';
import { MediaFilesService } from 'src/app/services/v2/media-files.service';

@Component({
  selector: 'app-results-view',
  templateUrl: './results-view.component.html',
  styleUrls: ['./results-view.component.scss'],
})
export class ResultsViewComponent {

  public details: any = [];
  isUser = false;
  keyDivision: any;
  name: any;
  round: any;
  code: any;
  keydb: any;
  divisionOld: any;
  ratingsOld: any;
  profile: any;

  userOwner: boolean = false;
  isFeedback = false;
  feedbackList = [];
  constructor(
    private customService: CustomizationfileService,
    public db: AngularFireDatabase,
    private modalCtrl: ModalController,
    public _language: LanguageService,
    private params: NavParams,
    public _rs: RatingsService,
    public _cs: CommonService,
    public mediaFilesService: MediaFilesService,
  ) {


    this.details = this.params.get("details");
    this.name = this.params.get("name");
    this.round = this.params.get("round");
    this.code = this.params.get("code");
    this.keydb = this.params.get("keydb");
    this.keyDivision = this.params.get("keyDivision");
    this.profile = this.customService.getprofile()

    console.log("this.details", this.details.participants);
    /**
     * TODO: revisar la validation de los usuarios
     */
    // this.userOwner = this.isUserOwner(this.details.participants);
    this.userOwner = true;
    this.getFeedback()

    console.log("this.userOwner", this.userOwner);
  }

  /**
 *
 * @param users
 */
  isUserOwner(users) {
    const uidToFind = this.customService.getUid();
    console.warn("uidToFind", uidToFind);
    for (let i = 0; i < users.length; i++) {
      console.warn("users[i].uid", users[i].uid);
      console.warn("uidToFind", uidToFind);
      if (users[i].uid == uidToFind) {

        return true;
      }
    }

    return false;

  }

  /**
   *
   * @returns
   */
  getFeedback() {
    const keydb = this.customService.getKeyDb();
    // console.log("feedbacks", `/feedbacks/${keydb}/${this.details.infoCategory.day}/${this.details.infoCategory.name}/${this.details.infoCategory.key2}`);
    if (!this.userOwner) return;
    this.db
      .object(`/feedbacks/${keydb}/${this.details.infoCategory.day}/${this.details.infoCategory.name}/${this.details.infoCategory.key2}`)
      .snapshotChanges()
      .subscribe((action) => {
        console.log("feedbacks final", action.payload.val());
        console.log("feedbacks final", action.payload.exists());
        if (!action.payload.exists()) {
          return;
        }

        this.isFeedback = true;
        Object.keys(action.payload.val()).forEach((key) => {
          let feedbackList = [];
          console.log("action.payload.val()[key]", action.payload.val()[key]);

          /**
           * TODO: solo audio
           */
          if (!action.payload.val()[key]['audio']) { return }

          Object.keys(action.payload.val()[key]['audio']).forEach((key2) => {
            feedbackList.push({
              audio: action.payload.val()[key]['audio'][key2]['url'],
              name: action.payload.val()[key]['audio'][key2]['name'],
            });
          });

          this.feedbackList.push(feedbackList);

        });

        console.log("this.feedbackList", this.feedbackList);
      });
  }



  videoFeedback(url) {
    if (!url) {
      this._cs.presentAlertTranslation("no hay video")
      return
    }
    this.mediaFilesService.playVideo(url);
  }


  /**
   *
   * @param index2
   */
  expandItem(index2): void {
    for (let index = 0; index < this.details.itemsJunior.length; index++) {
      if (index === index2) {
        this.details.itemsJunior[index].expanded = !this.details.itemsJunior[
          index
        ].expanded;
      } else {
        this.details.itemsJunior[index].expanded = false;
      }
    }
  }

  // /**
  //  *
  //  */
  // validateUid() {
  //   if (this.details.participarte === undefined) {
  //     this.isUser = false;
  //     return
  //   }
  //   const userUid = this.customService.getUid();
  //   this.details.participarte.forEach((uid) => {
  //     if (uid === userUid) {
  //       this.isUser = true;
  //     }
  //   });
  // }


  /**
   *
   * @param num
   * @returns
   */
  expandHeight(num) {
    if (num) {
      return num * 150;
    }
  }

  /**
   *
   * @param update
   */
  closeModal(update) {
    this.modalCtrl.dismiss({
      update
    });
  }

}
