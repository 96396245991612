import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { CommonService, LanguageService } from 'src/app/services/services';

@Component({
  selector: 'app-list-language',
  templateUrl: './list-language.component.html',
  styleUrls: ['./list-language.component.scss'],
})
export class ListLanguageComponent implements OnInit {

  listLanguages = []
  filteredData = []
  constructor(
    public languageService: LanguageService,
    public commonService: CommonService,
    public modalController: ModalController,
    public db: AngularFireDatabase) { }

  ngOnInit() {
    this.segmentChanged("en")
  }

  segmentChanged(ev: any) {
    let lang = ev.detail ? ev.detail.value : ev
    this.languageService.getJsonLanguage(lang)
      .subscribe(res => {
        console.log("segmentChanged", res);
        if (!res) { return }
        this.listLanguages = Object.keys(res).map((x) => {
          return { key: x, value: res[x] }
        });
        this.filteredData = this.listLanguages
      })
  }

  filterType(type) {
    const searchTerm = type.toLowerCase();
    if (searchTerm.length > 0) {
      // console.log("searchTerm", searchTerm);
      this.listLanguages = this.filteredData.filter((user) => {
        // tslint:disable-next-line: max-line-length
        return (
          user.key.toLowerCase().indexOf(searchTerm) > -1 ||
          user.value.toLowerCase().indexOf(searchTerm) > -1
        );
      });
    } else {
      this.listLanguages = this.filteredData;
    }
  }

}
