import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
declare var require: any
const FileSaver = require('file-saver');
const url_root: any = environment.urlrootFunctions;
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File, IWriteOptions } from '@ionic-native/file/ngx';
import { CommonService } from '../v1/common/common.service';
import { Base64ToGallery, Base64ToGalleryOptions } from '@ionic-native/base64-to-gallery/ngx';
import { DocumentViewer, DocumentViewerOptions } from '@ionic-native/document-viewer/ngx';
import { Platform } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { SocialSharingService } from './social-sharing.service';

@Injectable({
  providedIn: 'root'
})
export class SocialSharedService {

  constructor(private http: HttpClient,
    private file: File,
    private document: DocumentViewer,
    private _base64ToGallery: Base64ToGallery,
    public commonService: CommonService,
    private fileTransfer: FileTransfer,
    private platform: Platform,
    public socialSharingService: SocialSharingService,
    private androidPermissions: AndroidPermissions) { }

  getFile1({ route, data }) {
    return new Promise(async (resolve, reject) => {
      this.http.get(`${url_root}/v2/${route}`, { responseType: 'arraybuffer' }).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /**
   * Obtener archivo para compartir
   * @param {string} route 
   * @param {object} data 
   * @returns 
   */
  getFile({ route, data }) {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${url_root}/v2/${route}`, data, { responseType: 'arraybuffer' }).subscribe(
        (res) => { resolve(res); },
        (err) => { reject(err); }
      );
    });
  }

  /**
   * Convertir buffer a base64
   * @param buffer 
   * @param contentType 
   * @returns 
   */
  bufferToBlob(buffer: any, contentType = 'image/jpeg') {
    const blob: any = new Blob([buffer], { type: contentType });
    return blob;
  }

  /**
   * Convertir blob a base64
   * @param blob 
   * @returns 
   */
  blobTob64(blob: any) {
    return new Promise(async (resolve, reject) => {
      let reader = new FileReader();

      reader.onloadend = function () {
        const base64data = reader.result;
        // console.log("base64data", base64data);
        resolve(base64data);
      }

      reader.onerror = function (event) {
        alert("Failed to read file!\n\n" + reader.error);
        reject(reader.error);
        reader.abort();
      }

      reader.readAsDataURL(blob);
    });
  }

  saveCanvasImage(base64Data) {

    return new Promise((resolve, reject) => {
      let base64option: Base64ToGalleryOptions = {
        prefix: '_SETTLE',
        mediaScanner: true
      };

      this._base64ToGallery.base64ToGallery(base64Data, base64option).then(
        res => {
          console.log('Saved image to gallery ', res)
          resolve(res)
        },
        err => {
          console.log('Error saving image to gallery ', err)
          reject(err)
        }
      );
    })
  }



  async downloadBlodWeb({ response, MIME_types, ext, name }) {

    return new Promise(async (resolve, reject) => {

      let blob: any = new Blob([response], {
        type: MIME_types,
      });

      if (this.commonService.isCordova()) {

        let path = await this.getDownloadPath()
        let options: IWriteOptions = { replace: true };
        this.file.writeFile(path, name, blob, options).then(res => {
          this.socialSharingService.ShareGeneric({ message: null, subject: null, file: `${this.file.cacheDirectory}${name}` })
          resolve({})
        }, err => {
          console.log('writeFile: ', err);
          reject(err)
        });

        return
      }


      FileSaver.saveAs(blob, `${name}.${ext}`)
      resolve({})
    })

  }






  public async getDownloadPath() {

    if (!this.platform.is('cordova')) {
      return
    }


    // https://stackoverflow.com/questions/43749535/share-a-pdf-in-ionic-app
    // https://2amigos.us/blog/how-to-download-files-to-your-device-from-your-api-in-ionic-framework-v3
    if (this.platform.is('ios')) {
      return this.file.cacheDirectory;
    }

    // To be able to save files on Android, we first need to ask the user for permission. 
    // We do not let the download proceed until they grant access
    await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
      result => {
        if (!result.hasPermission) {
          return this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
        }
      }
    );

    return this.file.externalRootDirectory + "/Download/";
  }


}
