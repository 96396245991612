import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { RequestComponent } from 'src/app/components/request/request.component';
import { CustomizationfileService } from 'src/app/services/services';
import { WalletService } from 'src/app/services/v2/wallet.service';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss'],
})
export class BalanceComponent implements OnInit {
  result: any
  judgesWalletObject: any;
  constructor(
    public modalController: ModalController,
    public db: AngularFireDatabase,
    public walletService: WalletService,
    public customizationfileService: CustomizationfileService) { }

  async ngOnInit() {
    this.gettotalQualificationsByJudge()
  }

  async gettotalQualificationsByJudge() {
    this.db.object(`/judgesWallet/${this.customizationfileService.getUid()}`)
      .valueChanges()
      .subscribe(res => {
        this.judgesWalletObject = res
        console.log("this.judgesWalletObject", this.judgesWalletObject)
      })
  }



  request() {
    this.modalController
      .create({
        cssClass: "my-custom-modal-css",
        component: RequestComponent,
        componentProps: {
          wallet: this.judgesWalletObject
        },
      })
      .then((modal) => {
        modal.present();
      });
  }



}
