import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { CustomizationfileService } from 'src/app/services/services';

@Component({
  selector: 'app-movements-wallet',
  templateUrl: './movements-wallet.component.html',
  styleUrls: ['./movements-wallet.component.scss'],
})
export class MovementsWalletComponent implements OnInit {
  ListLogAddPayment: unknown[];

  constructor(
    public customizationfileService: CustomizationfileService,
    public afs: AngularFirestore,

  ) { }

  ngOnInit() { 
    this.logAddPayment() 
  }

  logAddPayment() {
    this.afs.collection('Log-addPayment', ref =>
      ref.where('keydb', '==', this.customizationfileService.getKeyDb())
        .where('uid', '==', this.customizationfileService.getUid())
        .orderBy('date', 'desc'))
      .valueChanges()
      .subscribe(res => {
        this.ListLogAddPayment = res
        console.log("logAddPayment", res)
      })
  }

}
