import { Component, OnInit } from '@angular/core';
declare var google;
interface Marker {
  position: {
    lat: number,
    lng: number,
  };
  title: string;
}
@Component({
  selector: 'app-map-welcome',
  templateUrl: './map-welcome.component.html',
  styleUrls: ['./map-welcome.component.scss'],
})
export class MapWelcomeComponent implements OnInit {
  map = null;
  markers: Marker[] = [
    {
      position: {
        lat: 4.658383846282959,
        lng: -74.09394073486328,
      },
      title: 'Parque Simón Bolivar'
    }
  ];

  constructor() { }

  ngOnInit() {
    
  }


 

 

}
