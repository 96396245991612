import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { PlayVideoComponent } from 'src/app/components/play-video/play-video.component';

@Component({
  selector: 'app-view-multimedia-result',
  templateUrl: './view-multimedia-result.component.html',
  styleUrls: ['./view-multimedia-result.component.scss'],
})
export class ViewMultimediaResultComponent implements OnInit {

  listResult: any
  name: any;
  details: any;
  constructor(
    public modalController: ModalController,
    private navParams: NavParams,
    public db: AngularFireDatabase,
  ) {
    this.details = this.navParams.get("details");
    console.log("details", this.details);
  }

  ngOnInit() {
    this.db
      .object(`/competition/${this.details.keydb}/mainChoreography/${this.details.code}/${this.details.round}/${this.details.keyDivision}`)
      .valueChanges()
      .subscribe((res: any) => {
        if (!res.audio) {
          return;
        }
        this.listResult = res
        this.listResult.audio = Object.keys(res.audio).map(x => {
          return Object.assign(res.audio[x], { uid: x })
        })
        console.log("mainChoreography", this.listResult)
      })
  }



  async viewVideo(url) {
    console.log("url", url)
    if (!url) {
      return
    }
    const modal = await this.modalController.create({
      cssClass: "my-custom-modal-css",
      component: PlayVideoComponent,
      componentProps: {
        video: url,
        showHeader: true,
        name: ""

      },
    });
    modal.present();
  }

  dismiss(status, message?) {
    this.modalController.dismiss({
      dismissed: true,
      status,
      message,
    });
  }

}
