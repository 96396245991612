import { Injectable, NgZone } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { HttpClient } from "@angular/common/http";
import { CustomizationfileService } from "../customizationfile/customizationfile.service";
import { Storage } from "@ionic/storage";
import { BehaviorSubject, Subject, fromEvent } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../../environments/environment";
import { NgforPipe } from "../../../pipes/Ngfor/ngfor.pipe";
import { PersistenceDayAndStageService } from "../persistenceDayAndStage/persistence-day-and-stage.service";
import { InformationComponent } from "../../../components/information/information.component";
import * as moment from "moment";
import * as momentTimezone from "moment-timezone";


import {
  ModalController,
  LoadingController,
  ToastController,
  AlertController,
  Platform,
} from "@ionic/angular";
import { debounceTime, map, takeUntil } from "rxjs/operators";
import { AngularFirestore } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { LanguageService } from "../language/language.service";
import { findArrayIntoArray } from "src/app/helpers/findArrayIntoArray";
import { handlerArrayResult } from "src/app/helpers/model.helper";

@Injectable({
  providedIn: "root",
})
export class CommonService {

  public timeZone = "America/Bogota"
  public key = "";
  public activeUser = new BehaviorSubject(null);
  public darkthema = new BehaviorSubject(false);
  public loading;
  public version = environment.version;
  public urlDB = environment.firebaseConfig.databaseURL;
  public functions = environment.urlrootFunctions;
  public _production = environment.production;
  public imageLoading = "/assets/img/200.gif";
  public urlRootShared = "https://settlework.com/";
  public urlImgRoot =
    "https://settlework.com/assets/img/logo_settle_fondo_blanco.svg";
  public appPackageName = "com.bfn.onecup.jr";

  departmentChat = [
    { name: "all", value: "" },
    { name: "rules", value: "rules" },
    { name: "payments", value: "payments" },
    { name: "technique", value: "technique" },
    { name: "web-or-social-networks", value: "web-or-social-networks" },
  ];

  private _unsubscriber$: Subject<any> = new Subject();
  public screenWidth$: BehaviorSubject<number> = new BehaviorSubject(null);
  public mediaBreakpoint$: BehaviorSubject<string> = new BehaviorSubject(null);

  // tslint:disable-next-line: member-ordering
  public slideOptsMainSlide = {
    initialSlide: 1,
    slidesPerView: 2,
    spaceBetween: 20,
    speed: 600,
  };

  // tslint:disable-next-line: member-ordering
  public slideOptsResult = {
    loop: true,
    initialSlide: 1,
    slidesPerView: this.checkScreen(),
    speed: 600,
    slideShadows: true,

    spaceBetween: 10,
  };

  // tslint:disable-next-line: member-ordering
  public slideOptsFilterByContinents = {
    initialSlide: 1,
    slidesPerView: this.checkScreen(),
    spaceBetween: 10,
    speed: 600,
    loop: true,
  };

  public listMonth = [
    { name: "January", id: "1" },
    { name: "February", id: "2" },
    { name: "March", id: "3" },
    { name: "April", id: "4" },
    { name: "May", id: "5" },
    { name: "June", id: "6" },
    { name: "July", id: "7" },
    { name: "August", id: "8" },
    { name: "September", id: "9" },
    { name: "October", id: "10" },
    { name: "November", id: "11" },
    { name: "December", id: "12" },
  ];

  sliderOne: any;
  loader: any;
  uid: string;
  hourNow: any;
  constructor(
    public languageService: LanguageService,
    public alertController: AlertController,
    public loadingController: LoadingController,
    public db: AngularFireDatabase,
    private http: HttpClient,
    public _cf: CustomizationfileService,
    public _persistence: PersistenceDayAndStageService,
    private storage: Storage,
    private toastController: ToastController,
    public modalController: ModalController,
    public platform: Platform,
    public translate: TranslateService,
    private zone: NgZone,
    private router: Router,
    public readonly afs: AngularFirestore
  ) {
    this.key = this._cf.getKeyDb();
    this.uid = this._cf.getUid();
    this._setScreenWidth(window.innerWidth);
    this._setMediaBreakpoint(window.innerWidth);
    this.getHourNow();
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this._unsubscriber$))
      .subscribe((evt: any) => {
        this._setScreenWidth(evt.target.innerWidth);
        this._setMediaBreakpoint(evt.target.innerWidth);
      });
  }


  /**
  * @dev ocultar boton de ver resultado de las categoris sin limite
  * @param code 
  * @returns 
  */
  isDivisionLimit(code) {
    if (code == "tslg" || code == "tslp" || code == "tsls") {
      return false
    }
    return true;
  }


  /**
 * @dev eliminar duplicados de un array || object remove duplicates
 * @param array 
 * @param propiedad 
 * @returns 
 */
  removeDuplicates(array, propiedad) {
    return array.filter((elemento, indice, self) =>
      indice === self.findIndex((e) => e[propiedad] === elemento[propiedad])
    )
  }

  /**
   * @dev ordenar array por nombre
   * @param array 
   * @param key 
   * @returns 
   */
  sortFornName(array, key) {
    return array.sort((x, y) => {
      if (x[key] < y[key]) {
        return -1;
      }
      if (x[key] > y[key]) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * @dev informacion  de la hora actual
   * @description
   */
  getUserAgent() {
    // Obtener el User-Agent del navegador
    const userAgent = navigator.userAgent;

    // Verificar si es un dispositivo móvil
    const isMobile = /Mobi/.test(userAgent);

    // Verificar si es un dispositivo iOS
    const isIOS = /iPad|iPhone|iPod/.test(userAgent);

    // Verificar si es un dispositivo Android
    const isAndroid = /Android/.test(userAgent);

    // Crear un objeto con la información del dispositivo
    const dispositivo = {
      userAgent: userAgent,
      esMobile: isMobile,
      esIOS: isIOS,
      esAndroid: isAndroid
    };

    // Imprimir el objeto en la consola
    console.log(dispositivo);

    return dispositivo;

  }

  isRoles(rol, userProfiles) {
    return findArrayIntoArray(rol, userProfiles) ? true : false;;
  }


  // * To copy Text from Textbox */
  copyInputMessage(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  /* To copy any Text */
  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }


  goToRouter(route) {
    this.router.navigate([route]);
  }

  countdDuplicate(listArray) {
    return listArray.reduce((prev, cur) => {
      prev[cur] = (prev[cur] || 0) + 1;
      return prev;
    }, {})
  }


  getComplaints({ keyDb, code, round, status }) {
    console.log({ keyDb, code, round, status });
    return new Promise((resolve, reject) => {
      this.afs
        .collection("complaints")
        .doc(keyDb)
        .collection("list", (ref) =>
          ref
            .where("status", "==", status)
            .where("code", "==", code)
            .where("round", "==", round)
            .orderBy("time", "desc")
        )
        .valueChanges()
        .subscribe((data) => {
          resolve(data);
        });
    });
  }

  getHourNow() {
    setInterval(() => {
      this.hourNow = Date.now();
    }, 3000);
  }

  getHoursDifference({ endTime, startTime }) {
    if (!endTime || !startTime) {
      return;
    }

    console.log("endTime", endTime)
    console.log("startTime", startTime)


    // var a = moment(startTime); //now
    // var b = moment(endTime);

    let a = momentTimezone.tz(startTime, this.timeZone)
    let b = momentTimezone.tz(endTime, this.timeZone)



    return {
      minutes: a.diff(b, "minutes"),
      hours: a.diff(b, "hours"),
      days: a.diff(b, "days"),
      weeks: a.diff(b, "weeks"),
    };
  }

  addseconds(seconds) {
    if (!seconds) {
      return;
    }
    return moment().add(seconds, "seconds").format("hh:mm A");
  }

  addMinutes(minutes, startTime) {
    if (minutes === undefined || startTime === undefined) {
      return Date.now();
    }


    const startTimeMoment = momentTimezone.tz(startTime, this.timeZone).add(minutes, 'minutes').format()

    return startTimeMoment;
  }


  setDateLocale(startTime) {
    if (startTime === undefined) {
      return Date.now();
    }

    const startTimeMoment = momentTimezone.tz(startTime, this.timeZone).format()

    return startTimeMoment;
  }

  addHours(hours) {
    if (!hours) {
      return;
    }
    return moment().add(hours, "hours").format("hh:mm A");
  }

  isImage(ext) {
    switch (ext.toLowerCase()) {
      case "jpg":
      case "jpeg":
      case "gif":
      case "bmp":
      case "png":
        //etc
        return true;
    }
    return false;
  }

  isVideo(ext) {
    switch (ext.toLowerCase()) {
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
      case "mov":
      case "quicktime":
        // etc
        return true;
    }
    return false;
  }

  typeDivision(code, participarte) {

    console.log("typeDivision code", code)
    console.log("participarte", participarte)

    // let nameDivision = this.getTranslation(code).toLowerCase()
    // let isSoloist, isCouple, isTeam
    // console.log('nameDivision', nameDivision)

    // if (this._cf.getTranslate() === "en") {
    //   isSoloist = nameDivision.toLowerCase().indexOf("soloist")
    //   isCouple = nameDivision.toLowerCase().indexOf("couple")
    //   isTeam = nameDivision.toLowerCase().indexOf("team")
    // } else {
    //   isSoloist = nameDivision.toLowerCase().indexOf("solista")
    //   isCouple = nameDivision.toLowerCase().indexOf("pareja")
    //   isTeam = nameDivision.toLowerCase().indexOf("grupo")
    // }
    // console.warn("getTranslate", this._cf.getTranslate())
    // console.warn("isSoloist", isSoloist)
    // console.warn("isCouple", isCouple)
    // console.warn("isTeam", isTeam)

    if (participarte === 1) {
      return { type: "soloists", medals: participarte, trophy: 1 }
    } else if (participarte === 2) {
      return { type: "couples", medals: participarte, trophy: 1 }
    } else if (participarte > 2) {
      return { type: "teams", medals: participarte, trophy: 1 }
    }
  }

  getComplaintsAdmin({ keyDb, status, round }) {
    console.log({ keyDb, status, round });
    return new Promise((resolve, reject) => {
      this.afs
        .collection("complaints")
        .doc(keyDb)
        .collection("list", (ref) =>
          ref
            .where("status", "==", status)
            .where("round", "==", round)
            .orderBy("time", "asc")
        )
        .valueChanges({ idField: "docId" })
        .subscribe((data) => {
          resolve(data);
        });
    });
  }

  isThisReport({ keyDb, keyDivision, code, round, status, claimType }) {
    console.log({ keyDb, keyDivision, code, round, status, claimType });
    return new Promise((resolve, reject) => {
      this.afs
        .collection("complaints")
        .doc(keyDb)
        .collection("list", (ref) =>
          ref
            .where("keyDivision", "==", keyDivision)
            .where("code", "==", code)
            .where("round", "==", round)
            .where("status", "==", status)
            .where("claimType", "==", claimType)
        )
        .valueChanges()
        .subscribe((data) => {
          console.log("data", data.length);
          if (data.length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  }

  getName2(data) {
    if (!data) {
      return;
    }

    let name;
    if (data.categories[0].dataGroups) {
      name = data.categories[0].dataGroups.nameGroups;
    } else {
      name = this.getNames({
        participarte: data.categories[0].participarte,
      });
    }
    return name;
  }

  getNames({ participarte }) {
    if (!participarte) {
      return [];
    }
    let u = [];
    let value = this.getNameParse(participarte);
    value.forEach((x) => {
      let name = `${x.name} ${x.surnames}`;
      u.push(name);
    });
    // eslint-disable-next-line consistent-return
    return u.toString().replace(/,/g, " & ").toLowerCase();
  }

  getNameParse(value) {
    let _category = [];
    if (Array.isArray(value)) {
      value.forEach((elem) => {
        _category.push(elem);
      });
    } else {
      Object.keys(value).forEach((i) => {
        _category.push(value[i]);
      });
    }
    return _category;
  }

  fromListObjectToArray(ListObject) {
    return Object.keys(ListObject).map((key) => {
      return Object.assign(ListObject[key], { key: key, uid: key });
    });
  }

  addKeyError(data) {
    if (data === undefined) return [];
    let result = [];
    Object.keys(data).forEach((x) => {
      result.push(
        Object.assign(
          { error: data[x] },
          {
            uid: x,
          }
        )
      );
    });
    return result;
  }

  addKey(data) {
    let result = [];
    if (data === undefined) return [];
    Object.keys(data).forEach((x) => {
      result.push(
        Object.assign(
          { category: data[x] },
          { audio: false },
          {
            uid: x,
          }
        )
      );
    });
    return result;
  }

  isAdmin() {
    console.log(`users/${this.uid}/rol`);
    return new Promise((resolve) => {
      this.db
        .object(`users/${this.uid}/rol`)
        .valueChanges()
        .subscribe((rol: any) => {
          console.log("rol", rol);
          if (rol) {
            localStorage.setItem("rol", rol);
            resolve(rol);
          } else {
            localStorage.setItem("rol", "0");
            resolve(0);
          }
        });
    });
  }

  downloadFile({ url }): any {
    return this.http.get(url, {
      responseType: "blob",
    });
  }

  secondsToString(seconds) {
    if (!seconds) {
      return;
    }
    let hour: any = Math.floor(seconds / 3600);
    hour = hour < 10 ? "0" + hour : hour;
    let minute: any = Math.floor((seconds / 60) % 60);
    minute = minute < 10 ? "0" + minute : minute;
    let second: any = seconds % 60;
    second = second < 10 ? "0" + second : second;
    // return hour + ":" + minute + ":" + second;

    return Number(minute) + ":" + Number(second).toFixed(2);
  }

  secondsToTime(secs) {
    var hours = Math.floor(secs / (60 * 60));

    var divisor_for_minutes = secs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);

    var obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  reload() {
    this.zone.runOutsideAngular(() => {
      window.location.reload();
    });
  }

  getPassCompetitor({ keyDb, uid }) {
    return new Promise((resolve) => {
      console.log(`competition/${keyDb}/panticipants/${uid}`);
      this.db
        .object(`competition/${keyDb}/panticipants/${uid}`)
        .valueChanges()
        .subscribe((data) => {
          if (data) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  }


  /**
   * Validar si existe un usuario a través del identificador
   *
   * @param identification        Identificador del usuario a buscar
   * @param opts                  Valores de configuración en objeto de respuesta
   * @returns
   */
  async checkIdentificationForExists(identification: string, opts: any = {}) {
    const snapshot = await this.afs.collection('users',
      (ref) => ref.where("identification", "==", identification)
    ).get().toPromise();

    const result = await handlerArrayResult(snapshot, opts);
    return (result.length > 0) ? true : false;
  }


  /**
   * TODO: validar antes de eliminar
   * @param identification
   * @returns
   */
  _checkIdentificationForExists(identification) {
    return new Promise((resolve) => {
      this.afs
        .collection("users", (ref) =>
          ref.where("identification", "==", identification)
        )
        .snapshotChanges()
        .subscribe((res) => {
          console.log("checkIdentificationForExists", res);
          if (res.length >= 1) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  }

  checkEmailForExists(email) {
    return new Promise((resolve) => {
      this.afs
        .collection("users", (ref) => ref.where("email", "==", email))
        .valueChanges()
        .subscribe((res) => {
          console.log("checkEmailForExists", res);
          if (res.length >= 1) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  }

  async presentLoading(message?) {
    if (this.loader) {
      this.loader.dismiss();
    }
    this.loader = await this.loadingController.create({
      spinner: "crescent",
    });

    this.loader.present();
    this.loader.onDidDismiss(() => {
      this.loader = null;
    });
  }

  dismissLoading() {
    if (this.loader) {
      return this.loader.dismiss();
    }

    return Promise.resolve(true);
  }

  private _setScreenWidth(width: number): void {
    this.screenWidth$.next(width);
  }

  private _setMediaBreakpoint(width: number): void {
    if (width < 576) {
      this.mediaBreakpoint$.next("xs");
    } else if (width >= 576 && width < 768) {
      this.mediaBreakpoint$.next("sm");
    } else if (width >= 768 && width < 992) {
      this.mediaBreakpoint$.next("md");
    } else if (width >= 992 && width < 1200) {
      this.mediaBreakpoint$.next("lg");
    } else if (width >= 1200 && width < 1600) {
      this.mediaBreakpoint$.next("xl");
    } else {
      this.mediaBreakpoint$.next("xxl");
    }
  }

  changeTheme(value) {
    this.darkthema.next(value);
  }

  isCordova() {
    return this.platform.is("cordova");
  }

  isCordovaParam(option) {
    return this.platform.is(option);
  }

  isCordovaIOS() {
    return this.platform.is("ios");
  }

  getDay() {
    return new Promise(async (resolve) => {
      this.db
        .object(`/competitionday/${this._cf.getKeyDb()}`)
        .valueChanges()
        .subscribe((data: any) => {
          if (data) {
            resolve(new NgforPipe().transform(data));
          }
        });
    });
  }

  getDayEvent2() {
    return new Promise((resolve) => {
      this.db
        .object(`competitionday/${this._cf.getKeyDb()}`)
        .snapshotChanges()
        .subscribe((action: any) => {
          const data = action.payload.val();
          const daystage = [];
          Object.keys(data).forEach((i) => {
            daystage.push({ value: i, name: data[i].day });
          });
          resolve(daystage);
        });
    });
  }

  getStage2() {
    return new Promise((resolve) => {
      this.db
        .object(`/stage_enabled/${this._cf.getKeyDb()}`)
        .snapshotChanges()
        .subscribe((action: any) => {
          const array_stage = [];
          console.log(1, action.payload.val().stage);
          const stage = Number(action.payload.val().stage) + 1;
          for (let index = 1; index < stage; index++) {
            array_stage.push({ id: index, name: `Stage ${index}` });
          }

          resolve(array_stage);
        });
    });
  }

  getTranslate(value) {
    return new Promise((resolve) => {
      this.translate.get([value]).subscribe((translations) => {
        resolve(translations);
      });
    });
  }

  strip(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  setSidebarMenu(value) {
    this.activeUser.next(value);
  }

  getImg(array, size) {
    if (array && size) {
      for (let index = 0; index < array.length; index++) {
        if (array[index].name == size) {
          return array[index].img;
        }
      }
    }
  }

  getDate(): string {
    const dt = new Date();
    const month = dt.getMonth() + 1;
    const day = dt.getDate();
    const year = dt.getFullYear();
    return month + "-" + day + "-" + year;
  }


  getDateMonthName(dateString) {
    // https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
    let leng = this._cf.getTranslate() === "en" ? "en-us" : "es"
    return new Date(dateString).toLocaleString(leng, { month: 'long', year: 'numeric' })

  }

  getDateOnlyMonthName(dateString) {
    // https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
    let leng = this._cf.getTranslate() === "en" ? "en-us" : "es"
    return new Date(dateString).toLocaleString(leng, { month: 'long' })
  }

  getDateOnlyYearName(dateString) {
    // https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
    let leng = this._cf.getTranslate() === "en" ? "en-us" : "es"
    return new Date(dateString).toLocaleString(leng, { year: 'numeric' })
  }



  /**
   * 
   * @param data 
   * @returns 
   */
  sortHour(data) {
    if (!data) { return }
    const value = data.sort((a, b) => {
      if (
        a.info != undefined
        && b.info != undefined
        && a.info.startTime
        && b.info.startTime) {
        const x = a.info.startTime;
        const y = b.info.startTime;
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      }
    });

    return value;

  }

  sortArrayObjectBoolean(array, key, first) {
    return array.sort((x, y) => {
      // true values first
      if (first) {
        return (x[key] === y[key]) ? 0 : x[key] ? -1 : 1;
      } else {
        // false values first
        return (x[key] === y[key]) ? 0 : x[key] ? 1 : -1;
      }
    });
  }

  getStarted(mins) {
    if (!mins) {
      return;
    }
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}:${m}`;
  }

  getFinalHour(mins) {
    if (!mins) {
      return;
    }
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}:${m}`;
  }

  getCountry() {
    return this.http.get("../../assets/country-state-city/country.json");
  }

  getCity() {
    return this.http.get("../../assets/country-state-city/city.json");
  }

  getBlock() {
    return this.http.get(`${this.functions}/video_purchase/block`);
  }

  getDivision(code) {
    return this.http.get(
      `${this.functions}/video_purchase/division?code=${code}`
    );
  }

  getStage(code) {
    return this.http.get(`${this.functions}/video_purchase/stage?code=${code}`);
  }

  checkInAutomatic(data) {
    return new Promise((resolve, reject) => {
      console.log("data", data);
      this.http
        .post(`${this.functions}/check-in-automatic/user`, data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  officialListOfDepartures(data) {
    return new Promise((resolve, reject) => {
      console.log("data", data);
      this.http
        .post(`${this.functions}/official_list_of_departures/check`, data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  checkIn(data) {
    console.log("checkIn", data);
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.functions}/check-in-automatic/checkIn`, data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  clearScanQR(data) {
    if (data) {
      const n = data.search("onecup");
      if (n >= 1) {
        const vf = data.slice(33);
        return vf;
      }
      return data;
    }
  }

  /**
   * Calendar
   */

  almostdisqualified(i, tp, status, isEnabled) {
    const horaactual = isEnabled;
    const horapresentacion = i * 4 + tp;
    if (status == 7) {
      return 7;
    }
    if (status !== 0) {
      return status;
    } else {
      const hEliminacion = horapresentacion - 30;
      const hPenalizacion = horapresentacion - 45;
      if (horaactual > hEliminacion) {
        return 6;
      } else if (horaactual > hPenalizacion && hPenalizacion < hEliminacion) {
        return 5;
      } else {
        return 0;
      }
    }
  }

  trackByFn(index, item) {
    return item.info.code; // or item.id
  }

  trackByFn2(index, item) {
    return item.music_name; // or item.id
  }

  async getInfo(user, category, hour) {
    if (user) {
      const result = {
        user: user,
        category: category,
        hour: hour,
      };
      this.modalController
        .create({
          component: InformationComponent,
          swipeToClose: true,
          componentProps: { details: result },
        })
        .then((modal) => {
          modal.present();
        });
    }
  }

  // getDayName(day) {
  //   for (let i = 0; i < this._persistence.day.length; i++) {
  //     if (day == this._persistence.day[i].value) {
  //       // this.day_ = this._persistence.day[i].name;
  //       return this._persistence.day[i].name;
  //     }
  //   }
  // }

  expandHeight(num) {
    if (num) {
      return num * 320;
    }
  }


  /**
   *  @dev no tocar
   * @param data 
   * @returns 
   */
  async transformArray(data) {
    return new Promise(async (resolve) => {
      if (!data) { resolve([]) }
      const listParticipants = [];
      await data.map(async (x) => {
        const participants = this.getKey(x.categories);
        const result = {
          break: x.categoryStages != undefined ? false : true,
          info: x.info,
          categoryStages: x.categoryStages,
          participants: participants,
        };
        listParticipants.push(result);
      });
      console.log("listParticipants", listParticipants);
      resolve(listParticipants);
    });
  }

  /**
   * @dev no tocar
   * @param data 
   * @returns 
   */
  getKey(data) {
    if (data) {

      /// @dev le asigno la key a cada participante
      const result = [];
      Object.keys(data).forEach((i) => {
        if (data[i].nameEvent && data[i].participants) {
          const rf = Object.assign(data[i], { key_: i, currentPosition: data[i]['currentPosition'] || 0 });
          result.push(rf);
        }
      });

      /// @dev filtrar por status 13 que estan eliminados
      const result2 = result.filter((x) => {
        return x.statusScreen !== 13;
      }).sort((a, b) => {
        if (a.currentPosition !== 0 && !a.currentPosition) {
          // Si currentPosition no existe o no es igual a 0 en 'a'
          return a.members.localeCompare(b.members); // Ordenar por 'members'
        } else if (b.currentPosition !== 0 && !b.currentPosition) {
          // Si currentPosition no existe o no es igual a 0 en 'b'
          return b.members.localeCompare(a.members); // Ordenar por 'members' en orden inverso
        } else {
          return b.currentPosition - a.currentPosition; // Ordenar por 'currentPosition'
        }
      });

      return result2;
    }
  }


  /**
   * 
   * @param data 
   * @returns 
   */
  sortPosition(data) {
    if (!data) { return; }
    const result = data.sort((a, b) => {
      return a.currentPosition - b.currentPosition;
    });
    return result;
  }

  getHora(i, s) {
    const suma = i * 3 + s;
    return this.convertMinsToHrsMins(suma);
    // return suma;
  }

  convertMinsToHrsMins(mins) {
    if (!mins) {
      return;
    }
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}:${m}`;
  }

  getDayCode(name: string) {
    for (let i = 0; i < this._persistence.day.length; i++) {
      if (this._persistence.day[i].name == name) {
        return this._persistence.day[i].value;
      }
    }
  }

  sortCountry(data) {
    if (!data) {
      return;
    }
    return data.sort((x, y) => {
      return x.nameEvent.countryRating === y.nameEvent.countryRating
        ? 0
        : x.nameEvent.countryRating
          ? 1
          : -1;
    });
  }

  sortWldc(data) {
    if (!data) {
      return;
    }
    return data.sort((x, y) => {
      return x.nameEvent.youWonInTheWldc === y.nameEvent.youWonInTheWldc
        ? 0
        : x.nameEvent.youWonInTheWldc
          ? 1
          : -1;
    });
  }

  getName(data) {
    if (!data) {
      return;
    }
    const u = [];
    data.forEach((elem) => {
      const name = `${elem.name} ${elem.surnames}`;
      u.push(name);
    });
    return u.toString().replace(/,/g, " & ");
  }

  penalty(data, isEnabled) {
    return isEnabled >= data.info.startTime - 60;
  }

  now() {
    const d = new Date();
    const h = this.addZero(d.getHours());
    const m = this.addZero(d.getMinutes());
    return this.convertHoursAndMinutes(h, m);
  }

  convertHoursAndMinutes(hours: number, minutes: number) {
    return hours * 60 + minutes;
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  setCalendar(key, data) {
    // set a key/value
    this.storage.set(key, data);
  }

  // setlastTime(data, day) {
  //   this.storage.set(day, data);
  // }

  getlastTime(day) {
    return new Promise(async (resolve) => {
      this.storage.get(day).then((val) => {
        if (val) {
          resolve(JSON.parse(val));
        } else {
          resolve(false);
        }
      });
    });
  }

  getCalendar(day) {
    return new Promise(async (resolve) => {
      this.storage.get(day).then((val) => {
        if (val) {
          resolve(JSON.parse(val));
        } else {
          resolve(false);
        }
      });
    });
  }

  removeCalendar(day) {
    if (day) {
      return this.storage.remove(day);
    }
  }

  removeCommas(data) {
    if (data) {
      return data.replace(/,/g, " & ");
    }
  }

  removeGuion(data) {
    if (data) {
      return data.replace(/-/g, " ");
    }
  }

  clearCode(data) {
    if (data) {
      return data.toLowerCase().replace(/ /g, "");
    }
  }

  async presentModal(ModalPage, param?) {
    const modal = await this.modalController.create({
      component: ModalPage,
    });
    return await modal.present();
  }

  public async presentToast(text) {
    const mess = this.languageService.getTranslation(text)
    const toast = await this.toastController.create({
      message: mess,
      position: "top",
      duration: 3000,
    });
    toast.present();
  }




  async presentAlert(text, message?) {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      animated: true,
      cssClass: "alertLogCss",
      header: "CILIK",
      subHeader: text,
      message: message ? message : null,
      buttons: ["OK"],
    });

    await alert.present();
  }



  getTranslation(lang: string) {
    let result;
    this.translate.get(lang).subscribe((data: any) => {
      result = data;
    });
    return result
  }

  async presentAlertTranslation(text) {
    let mens = this.languageService.getTranslation(text)
    const alert = await this.alertController.create({
      backdropDismiss: false,
      animated: true,
      cssClass: "alertLogCss",
      header: "CILIK",
      message: mens ? mens : null,
      buttons: ["OK"],
    });

    await alert.present();
  }

  async presentAlertConfirm({ message }) {
    return new Promise(async (resolve, reject) => {
      let mess = this.getTranslation(message)
      const alert = await this.alertController.create({
        message: mess,
        buttons: [
          {
            text: this.getTranslation("NO"),
            role: "cancel",
            cssClass: "secondary",
            handler: (blah) => {
              resolve(false)
            },
          },
          {
            text: this.getTranslation("YES"),
            handler: () => {
              resolve(true)
            },
          },
        ],
      });

      return await alert.present();
    })

  }

  getParameterByName(name, url) {
    // if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return "";
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  intials(fullName) {
    if (fullName === undefined) {
      return "";
    }
    return fullName
      .split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();
  }

  reloadApp() {
    this.zone.runOutsideAngular(() => {
      location.reload();
    });
  }

  checkScreen() {
    const innerWidth = window.innerWidth;
    switch (true) {
      case 340 <= innerWidth && innerWidth <= 400:
        return 1.3;
      case 401 <= innerWidth && innerWidth <= 700:
        return 1.3;
      case 701 <= innerWidth && innerWidth <= 900:
        return 2;
      case 901 <= innerWidth:
        return 2;
    }
  }

  // Move to Next slide
  slideNext(object, slideView) {
    slideView.slideNext(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });
  }

  // Move to previous slide
  slidePrev(object, slideView) {
    slideView.slidePrev(500).then(() => {
      this.checkIfNavDisabled(object, slideView);
    });
  }

  // Call methods to check if slide is first or last to enable disbale navigation
  checkIfNavDisabled(object, slideView) {
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }

  checkisBeginning(object, slideView) {
    slideView.isBeginning().then((istrue) => {
      // object.isBeginningSlide = istrue;
    });
  }

  checkisEnd(object, slideView) {
    slideView.isEnd().then((istrue) => {
      // object.isEndSlide = istrue;
    });
  }

  slugify(text) {
    return text
      .toString()
      .normalize("NFD") // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-");
  }
}
