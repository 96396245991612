import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CustomizationfileService {
  public existKeyDb = new BehaviorSubject(true);
  constructor() { }

  getUrlRoot() {
    return `eventDays/${this.getKeyDb()}`;
  }

  getPROVISIONAKEY() {
    return this.getKeyDb()
  }

  getKeyInfo() {
    return window.localStorage.getItem("keyDB");
  }

  getTranslate() {
    return window.localStorage.getItem("translate");
  }

  getBanner() {
    return JSON.parse(window.localStorage.getItem("banner"));
  }

  getDatabaseURL() {
    return environment.firebaseConfig.databaseURL;
  }

  getKeyDb() {
    return window.localStorage.getItem("keyDB");
  }

  getMeetingDay() {
    return window.localStorage.getItem("meetingDay");
  }
  getMeetingStage() {
    return window.localStorage.getItem("meetingStage");
  }

  getParticipants() {
    return JSON.parse(window.localStorage.getItem("_participants"));
  }

  getEventInfo() {
    return JSON.parse(window.localStorage.getItem("event_info"));
  }

  getProfile() {
    return JSON.parse(window.localStorage.getItem("profile"));
  }


  getIsTangoSalon() {
    return JSON.parse(window.localStorage.getItem("event_info")).isTango;
  }

  getEventName() {
    return window.localStorage.getItem("eventName")
  }

  getName() {
    return window.localStorage.getItem("nameDB") ? window.localStorage.getItem("nameDB") : "CILIK";
  }

  getGender() {
    const profile = this.getProfile();
    console.log("profile", profile);
    if (profile) {
      return profile.gender;
    }
    return false;
  }

  getAddress() {
    return window.localStorage.getItem("address");
  }

  getUid() {
    return window.localStorage.getItem("uid");
  }

  getUTC() {
    return window.localStorage.getItem("utc");
  }

  getrol() {
    return JSON.parse(window.localStorage.getItem("rol"));
  }

  getavatar() {
    return window.localStorage.getItem("avatar");
  }

  setAvatar(url) {
    window.localStorage.setItem("avatar", url);
  }

  gettokenPush() {
    return window.localStorage.getItem("tokenPush");
  }

  getprofile() {
    const profile = JSON.parse(window.localStorage.getItem("profile"));
    const uid = this.getUid();
    return Object.assign({ uid: uid }, profile);
  }

  getCurrency() {
    return window.localStorage.getItem("currency");
  }

  getCompetitorPass() {
    return window.localStorage.getItem("competitor_pass");
  }

  getDivisionPass() {
    return window.localStorage.getItem("division_pass");
  }
  getTicketPass() {
    return window.localStorage.getItem("ticket_pass");
  }

  getIsParticipant() {
    const isParticipant = window.localStorage.getItem("isParticipant");
    if(!isParticipant){ return true; }
    return isParticipant === "true";
  }



  transformarString(string) {
    if (!string) {
      return null;
    }
    const number = string.toString();
    return number.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "").toLowerCase()
  }
}
