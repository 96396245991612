// https://levelup.gitconnected.com/nearby-location-queries-with-cloud-firestore-e7f4a2f18f9d

import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import {
  WelcolmeService,
  LocationService,
  CommonService,
  AuthenticationService,
} from "../../../../services/services";
import { AngularFirestore } from "@angular/fire/firestore";
import { AllEventsComponent } from "../../../../components/all-events/all-events.component";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-main-events",
  templateUrl: "./main-events.component.html",
  styleUrls: ["./main-events.component.scss"],
})
export class MainEventsComponent implements OnInit {
  slideOpts = {
    slidesPerView: 1.3,
    freeMode: true,
  };

  city$: Observable<any[]>;
  country$: Observable<any[]>;
  country_events = false;
  city_events = false;
  constructor(
    public _cs: CommonService,
    public _welcome: WelcolmeService,
    public _location: LocationService,
    public modalController: ModalController,
    public afs: AngularFirestore
  ) { }

  ngOnInit() {
    this._location.geohash.subscribe((geo: any) => {
      if (geo.lower && geo.upper) {
        this.getCountry(geo);
        this.getCity(geo);
      }
    });
  }


  /**
   * 
   * @param geo 
   */
  getCountry(geo) {

    const isProduction = environment.production

    this.afs
      .collection<any>("events", (ref) =>
        ref
          .where("qualifiers_stage", "==", "country")
          .where("geohash", ">=", geo.lower)
          .where("geohash", "<=", geo.upper)
          .where("public", "==", true)
          .orderBy("geohash")
          .limit(1)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      )
      .subscribe((events_id) => {
        if (events_id) {
          this.country_events = true;
          this.country$ = combineLatest(
            events_id.map((authorId) =>
              this.afs
                .collection("event_images")
                .doc(authorId.id)
                .collection<any>("imagen", (ref) =>
                  ref.where("name", "==", "img1200x1200MV")
                )
                .valueChanges()
                .pipe(
                  map((authors) =>
                    Object.assign(authorId, { img: authors[0].img })
                  )
                )
            )
          );
        } else {
          this.country_events = false;
        }
      });
  }




  /**
   * 
   * @param geo 
   */
  getCity(geo) {
    this.afs
      .collection<any>("events", (ref) =>
        ref
          .where("qualifiers_stage", "==", "city")
          .where("geohash", ">=", geo.lower)
          .where("geohash", "<=", geo.upper)
          .where("public", "==", true)
          .orderBy("geohash")
          .limit(1)
      )
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      )
      .subscribe((events_id) => {
        if (events_id) {
          this.city_events = true;
          this.city$ = combineLatest(
            events_id.map((authorId) =>
              this.afs
                .collection("event_images")
                .doc(authorId.id)
                .collection<any>("imagen", (ref) =>
                  ref.where("name", "==", "img1200x1200MV")
                )
                .valueChanges()
                .pipe(
                  map((authors) =>
                    Object.assign(authorId, { img: authors[0].img })
                  )
                )
            )
          );
        } else {
          this.city_events = false;
        }
      });
  }

  /**
   * 
   * @param item 
   */
  goEvent(item) {
    console.log("goEvent", item);
    localStorage.setItem("event_info", JSON.stringify(item));
    this._welcome.goEvent(item);
  }


  /**
   * 
   * @returns 
   */
  async presentModal() {
    const modal = await this.modalController.create({
      component: AllEventsComponent,
      componentProps: { type: 1, filter: "" },
    });
    return await modal.present();
  }
}
