import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonicSelectableComponent } from 'ionic-selectable';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthenticationService, CommonService, CustomizationfileService, DataService, DivisionService } from 'src/app/services/services';
import { LoadingService } from 'src/app/services/v2/loading.service';

import * as moment from 'moment'
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from 'angularfire2/database';
import { AlertService } from 'src/app/services/v2/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent implements OnInit {

  public profile: any;
  public uid: string;
  public rol: any;

  public form: FormGroup;
  public validationMessages = {
    name: [{ type: 'required', message: 'es requerido.' }],
    surnames: [{ type: 'required', message: 'es requerido' }],
    identificationNumber: [{ type: 'required', message: 'es requerido.' }],
    email: [{ type: 'required', message: 'es requerido.' }],
    prefijo: [{ type: 'required', message: 'es requerido.' }],
    phone: [{ type: 'required', message: 'es requerido.' }],
    birthdate: [{ type: 'required', message: 'es requerido.' }],
    academy: [{ type: 'required', message: 'es requerido.' }],
    gender: [{ type: 'required', message: 'es requerido.' }],
    bio: [{ type: 'required', message: 'es requerido.' }],
  };

  public monthShortNames: string[];
  public datePicker$: Observable<boolean>;
  public schoolRecord$: Observable<any[]>;
  public emailVerified$: Observable<boolean>;

  public genderList: any[];
  public countriesList: any[];

  constructor(
    private fb: FormBuilder,
    private dataSrv: DataService,
    public _cs: CommonService,
    private divisionSrv: DivisionService,
    private authSrv: AuthenticationService,
    private loadingSrv: LoadingService,
    public _cf: CustomizationfileService,
    private afs: AngularFirestore,
    private db: AngularFireDatabase,
    private alertSrv: AlertService,
    public router: Router,
  ) {
    this.profile = this._cf.getprofile();
    this.uid = this._cf.getUid();

    this.monthShortNames = this.dataSrv.listMonth.map((doc) => doc.name);
    this.genderList = this.dataSrv.genderList;
    this.countriesList = this.dataSrv.getCountry();

    this.getUserRol();
  }


  ngOnInit() {
    this.buildForm();

    this.datePicker$ = this._cs.mediaBreakpoint$
      .pipe( map((size) => (['md', 'lg', 'xl', 'xxl'].includes(size)) ? true : false ) );

    this.schoolRecord$ = this.divisionSrv.getSchoolRecord()
      .pipe( map((data) => data.sort() ) );

    this.emailVerified$ = this.authSrv.emailVerified
      .pipe( map((status) => status.emailVerified || false ) );
  }


  /**
   * Obtener rol del usuario
   */
  async getUserRol(){
    this.rol = await this.authSrv.getRol(this._cf.getUid())
    console.warn('rol', this.rol);
  }


  /**
   * Crear formulario
   */
  buildForm(){
    this.form = this.fb.group({
      name: [this.profile.name || null, [Validators.required]],
      surnames: [this.profile.surnames || null, [Validators.required]],
      identificationNumber: [{
        value: this.profile.identificationNumber || null, disabled: true
      }, [Validators.required]],
      email: [{
        value: this.profile.email || null, disabled: true
      }, [Validators.required]],
      prefijo: [this.profile.prefijo || null, [Validators.required]],
      phone: [this.profile.phone || null, [Validators.required]],
      birthdate: [this.profile.birthdate || null, [Validators.required]],
      academy: [this.profile.school || null, [Validators.required]],
      gender: [this.profile.gender || null, [Validators.required]],
      bio: [this.profile.bio || null, [Validators.required]],
    });
  }

  get f(){ return this.form.controls; }


  /**
   * Enviar formulario
   * @param formData
   * @returns
   */
  async onSubmit(formData: any){

    formData.birthdate = moment(formData.birthdate).format('YYYY-MM-DD');

    if (this.form.invalid) { return this.form.markAllAsTouched(); }

    try {
      // console.log('Try to submit', formData);
      await this.loadingSrv.presentLoading();

      /** Si el formulario fue manipulado */
      if(!this.form.pristine){
        const name = `${formData.name} ${formData.surnames}`.toLowerCase();
        const avatar = this._cf.getavatar();

        const userDoc = {
          uid: this.uid,
          _language: this._cf.getTranslate(),
          rol: this.rol,
          status: true,
          stageName: null,
          avatar,
          name,
          birthdate: formData.birthdate,
          email: this.profile.email,
        };

        const profileDoc = {
          uid: this.uid,
          email: this.profile.email,
          name: formData.name.toLowerCase(),
          surnames: formData.surnames.toLowerCase(),
          gender: formData.gender,
          birthdate: formData.birthdate,
          identificationNumber: this.profile.identificationNumber,
          school: formData.academy,
          tShirtSize: null,
          bio: formData.bio,
          celular: false,
          cityOfBirth: false,
          countryOfBirth: false,
          countryOfResidence: false,
          facebook: false,
          idType: true,
          rol: this.rol,
          instagram: false,
          prefijo: formData.prefijo,
          phone: formData.phone,
          stageName: null,
          stateOfBirth: false,
          stateOfResidence: false,
          avatar,
        };

        const profileDocRealTime = Object.assign({}, userDoc, { profile: profileDoc });


        /**
         * - Actualizar documento usuario
         * - Actualizar documento perfil de usuario
         * - Crear nuevo documento de escuela asociado al usuario
         * - Actualizar documento perfil de usuario en Realtime
         */
        await Promise.all([
          this.afs.collection("users").doc(this.uid).set(userDoc, {merge: true}),
          this.afs.collection("profile").doc(this.uid).set(profileDoc, {merge: true}),
          this.afs.collection("school").doc(this.uid).set({ idSchool: formData.academy.key, uidUser: this.uid }, {merge: true}),
          this.db.object(`users/${this.uid}`).update(profileDocRealTime)
        ]);

        /**
         * TODO: validar el crear un nuevo documento de escuela asociado al usuario cada vez que se actualizan los
         * datos del mismo
         */

        const oldProfile = JSON.parse(window.localStorage.getItem('profile'));
        window.localStorage.setItem("profile", JSON.stringify( Object.assign({}, oldProfile, profileDoc) ) );

        this.authSrv.incomplete_profile.next(true);
      }

      await this.alertSrv.presentAlert('Guardado exitosamente');
      return await this.router.navigate(["/members/welcome"]);

    } catch (err) {
      await this.alertSrv.presentAlert('Se ha producido un error desconocido, CODE: 003');
      console.log('Error on ProfileFormComponent@onSubmit', err);
    }finally{
      this.loadingSrv.dismissLoading();
    }
  }


  /**
   * Asignar valor al formulario al realizar cambios input de prefijo
   * @param event
   */
  prefixChange(event: { component: IonicSelectableComponent, value: any }) {
    this.form.get("prefijo").setValue(event.value.phonecode);
  }


  /**
   * Asignar valor al formulario al realizar cambios en el input de academia
   * @param event
   */
  academyChange(event: { component: IonicSelectableComponent, value: any }) {
    const { value } = event;
    this.form.get("academy").setValue({ key: value.key || null, name_institution: value.name_institution || null });
  }


  /**
   * Realizar filtro de text en input de prefijo
   * @param port
   * @param portIndex
   * @param ports
   * @returns
   */
  getCountryText(port: any, portIndex: number, ports: any[]) {
    if (portIndex === 0 || port.id !== ports[portIndex - 1].id) {
      return port.phonecode;
    }
    return null;
  }


  /**
   * Realizar filtro de texto en input de representantes de academia
   * @param port
   * @param portIndex
   * @param ports
   * @returns
   */
  getSchoolRecordText(port: any, portIndex: number, ports: any[]) {
    if (portIndex === 0 || port.key !== ports[portIndex - 1].key) {
      return port.name_institution;
    }
    return null;
  }


  /**
   * Enviar email de verificación de correo
   */
  async setEmailVerified() {
    try {
      await this.loadingSrv.presentLoading();
      const send = await this.authSrv.setEmailVerified()
      console.log({send});
    } catch (err) {
      console.log('Error on ProfileFormComponent@setEmailVerified', err);
    }finally{
      this.loadingSrv.dismissLoading();
    }
  }

}
