import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-form-update-email',
  templateUrl: './modal-form-update-email.component.html',
  styleUrls: ['./modal-form-update-email.component.scss'],
})
export class ModalFormUpdateEmailComponent implements OnInit {

  @Input() email: string;
  @Input() uid: string;

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    // console.log({email: this.email, uid: this.uid});
  }

  onSubmit(data: any){
    return this.closeModal(data, 'next');
  }

  closeModal(data = {}, role = 'cancel') {
    this.modalCtrl.dismiss(data, role);
  }

}
