import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AlertController, ModalController } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { CommonService, CustomizationfileService } from 'src/app/services/services';


@Component({
  selector: 'app-shipping-price-list',
  templateUrl: './shipping-price-list.component.html',
  styleUrls: ['./shipping-price-list.component.scss'],
})
export class ShippingPriceListComponent implements OnInit {
  dataList: any;

  constructor(
    public alertController: AlertController,
    private readonly afs: AngularFirestore,
    public db: AngularFireDatabase,
    public commonService: CommonService,
    private modalCtrl: ModalController,
    public customizationfileService: CustomizationfileService) {
  }

  async ngOnInit() {
    this.dataList = await this.getShippingPriceList()
    console.log('this.dataList', this.dataList)
  }

  getShippingPriceList() {
    return new Promise((resolve) => {
      this.afs.collection('shipping-price-list')
        .valueChanges()
        .subscribe(res => {
          if (res.length === 0) { resolve([]) }
          resolve(res)
        })
    })
  }

  async remove(item) {

    const alert = await this.alertController.create({
      header: `retirar ${item.name}`,
      buttons: [
        {
          text: this.commonService.getTranslation("NO"),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: this.commonService.getTranslation("YES"),
          handler: async () => {
            await this.commonService.presentLoading()
            console.log('item', item)
            await this.afs.collection('shipping-price-list').doc(item.id).delete()
            this.dataList = await this.getShippingPriceList()
            this.commonService.dismissLoading()
            this.commonService.presentAlertTranslation("eliminado con éxito")
          }
        }
      ]
    });

    await alert.present();


  }

  closeModal() {
    this.modalCtrl.dismiss();
  }


}
