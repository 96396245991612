// https://pumpingco.de/blog/automatic-scrolling-only-if-a-user-already-scrolled-the-bottom-of-a-page-in-angular/

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Observable } from "rxjs";
import { CommonService } from "src/app/services/services";
import { ChatService } from "src/app/services/v2/chat.service";

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit, AfterViewInit {
  messages: Observable<any[]>;
  @HostListener("window:scroll", ["$event"])
  @Input()
  infoChat;
  limit = 5;
  private scrollContainer: any;
  private isNearBottom = true;
  firtTimeChat = true;
  @ViewChild("scrollframe", { static: false }) scrollFrame: ElementRef;
  @ViewChildren("item") itemElements: QueryList<any>;
  screenChat: any;

  constructor(
    public commonService: CommonService,
    public chatService: ChatService
  ) {}

  async ngOnInit() {
    this.screenChat = await this.gridOptions();
    this.getChatMessages();
  }

  getChatMessages(paginto?) {
    const pag = paginto ? this.limit + paginto : 5;
    this.messages = this.chatService.getChatMessages({
      uidChat: this.infoChat.uidChat,
      limit: pag,
    });
  }

  gridOptions() {
    return new Promise((resolve, reject) => {
      this.commonService.mediaBreakpoint$.subscribe(async (data) => {
        const result =
          data == "xl" || data == "xxl" || data == "lg" ? true : false;
        resolve(result);
      });
    });
  }

  ngAfterViewInit() {
    this.scrollContainer = this.scrollFrame.nativeElement;
    this.itemElements.changes.subscribe((_) => {
      console.log("_", _);
      if (this.firtTimeChat) {
        setTimeout(() => {
          this.scrollToBottom();
        }, 200);
        this.firtTimeChat = false;
      }
      this.onItemElementsChanged();
    });
  }

  private onItemElementsChanged(): void {
    if (this.isNearBottom) {
      this.scrollToBottom();
    }
  }

  public scrollToBottom(): void {
    setTimeout(() => {
      this.scrollContainer.scroll({
        top: this.scrollContainer.scrollHeight + 300,
        left: 0,
        behavior: "smooth",
      });
    }, 300);
  }

  private isUserNearBottom(): boolean {
    const threshold = 150;
    const position =
      this.scrollContainer.scrollTop + this.scrollContainer.offsetHeight;
    const height = this.scrollContainer.scrollHeight;
    return position > height - threshold;
  }

  scrolled(event: any): void {
    this.isNearBottom = this.isUserNearBottom();
  }

  trackByFn(index, item) {
    return index; // or item.id
  }
}
