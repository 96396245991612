import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, LoadingController, AlertController } from "@ionic/angular";
import { CustomizationfileService, CommonService } from "../../services/services";
import { AngularFireStorage } from "@angular/fire/storage";
import { DomSanitizer } from '@angular/platform-browser';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-user-info-video',
  templateUrl: './user-info-video.component.html',
  styleUrls: ['./user-info-video.component.scss'],
})
export class UserInfoVideoComponent implements OnInit {
  details: any;
  fileUrl: any;
  video: any;
  file: any;
  loading: HTMLIonLoadingElement;
  type_video: any;
  day
  stage
  user_key
  constructor(private modalCtrl: ModalController,
    private params: NavParams,
    public loadingController: LoadingController,
    public _cf: CustomizationfileService,
    private storage: AngularFireStorage,
    public domSanitizer: DomSanitizer,
    public _cs: CommonService,
    public db: AngularFireDatabase,
    public alertController: AlertController
  ) {
    this.details = this.params.get("details");
  }

  ngOnInit() {
    if (this.details) {
      this.day = this.details.day
      this.stage = this.details.stage
      this.user_key = this.details.key
      this.db.object(`official_list_of_departures/${this._cf.getKeyDb()}/${this.details.day}/${this.details.stage}/${this.details.key}/`)
        .snapshotChanges().subscribe(action => {
          this.details = action.payload.val()
          console.log('this.details', this.details)
        });
    }
  }


  previewVideo(event, type) {
    this.type_video = type
    this.file = event.target.files[0];
    this.video = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(this.file));
  }



  async alertUploadVideo(type) {
    const alert = await this.alertController.create({
      header: `Subir un video ${type}?`,
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'SI',
          handler: async () => {
            console.log('Confirm Okay');
            this.loading = await this.loadingController.create({
              message: 'Espere por favor...'
            });
            await this.loading.present();
            const reader = new FileReader();
            reader.readAsDataURL(this.file);
            reader.onloadend = () => {
              this.upload(reader.result)
                .then((data: any) => {
                  this.fileUrl = data
                  this.videoUpload(this.fileUrl, type);
                }, err => {
                  this._cs.presentToast("Error :(")
                })
            }
          }
        }
      ]
    });

    await alert.present();
  }



  async upload(imageURI) {
    return new Promise<any>(async (resolve, reject) => {
      const ramdom = Math.floor(Math.random() * 1000000 * 5);
      const filePath = `multimedia/${this._cf.getKeyDb()}/users/${this.details.info.code}-${this.details.participants.members}-${ramdom}.mp4`;
      console.log("filePath", filePath)
      const fileRef = this.storage.ref(filePath);
      await fileRef.putString(imageURI, "data_url").then(snapshot => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      });
      fileRef.getDownloadURL().subscribe(url => {
        resolve(url);
      })
    });
  }

  async videoUpload(url, type_video) {
    console.log(1, `official_list_of_departures/${this._cf.getKeyDb()}/${this.day}/${this.stage}/${this.user_key}/video_${type_video}`)
    const itemRef = this.db.object(`official_list_of_departures/${this._cf.getKeyDb()}/${this.day}/${this.stage}/${this.user_key}/video_${type_video}`);
    await itemRef.set(url);

    this.type_video = 0
    this.file = null
    this.loading.dismiss();

  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
