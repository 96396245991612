import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { AngularFireDatabase } from "@angular/fire/database";
import { CustomizationfileService, CommonService } from "../../../services/services";
@Component({
  selector: "app-schedule",
  templateUrl: "./schedule.component.html",
  styleUrls: ["./schedule.component.scss"]
})
export class ScheduleComponent {
  public calendar: any = [];
  public h: any;
  public m: any;
  skeleton = false
  competition_day: any
  constructor(
    private params: NavParams,
    private modalCtrl: ModalController,
    public db: AngularFireDatabase,
    public _cf: CustomizationfileService,
    public _cs: CommonService
  ) {
    const code = this._cs.clearCode(this.params.get("code"))
    this.seeSchedules(code);
  }

  async seeSchedules(code) {
    this.skeleton = false
    this.calendar = [];
    const url = `categoryCalendars/${this._cf.getKeyDb()}`;
    for (let index = 1; index < 8; index++) {
      await this.db
        .object(`${url}/day${index}/${code}`)
        .valueChanges()
        .subscribe(async data => {
          if (data) {
            await this.getDay(index).then(day => {
              this.calendar.push(Object.assign({ day: day }, data));
            })

          }
        });
    }
    this.skeleton = true
  }


  getDay(index) {
    return new Promise(async resolve => {
      this.db
        .object(`/competitionday/${this._cf.getKeyDb()}/day${index}`)
        .valueChanges()
        .subscribe((data: any) => {
          resolve(data.day)
        });
    })
  }


  getStarted(mins) {
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}: ${m} `;
  }

  getFinalHour(mins) {
    let h: any = Math.floor(mins / 60);
    let m: any = mins % 60;
    h = h < 10 ? "0" + h : h;
    m = m < 10 ? "0" + m : m;
    return `${h}: ${m} `;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
