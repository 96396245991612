import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { CustomizationfileService } from 'src/app/services/services';
import { WalletService } from 'src/app/services/v2/wallet.service';

@Component({
  selector: 'app-see-paid-division',
  templateUrl: './see-paid-division.component.html',
  styleUrls: ['./see-paid-division.component.scss'],
})
export class SeePaidDivisionComponent implements OnInit {
  @Input() code: any;
  round = []
  listDivision = []
  roundSelect = 1
  keydb: string;
  constructor(
    public modalController: ModalController,
    public db: AngularFireDatabase,
    public walletService: WalletService,
    public customizationfileService: CustomizationfileService) { }

  ngOnInit() {
    console.log("code", this.code)
    this.keydb = this.customizationfileService.getKeyDb()
    this.getData(1)

  }

  getData(round: any) {
    console.log('Segment changed', round);
    this.db.object(`/rate/${this.keydb}/${this.code}/${round}`)
      .valueChanges()
      .subscribe((res: any) => {
        // console.log("res", res)
        if (!res) {
          this.listDivision.length = 0
          return
        }

        this.round = new Array(res.length);
        this.listDivision = Object.keys(res).map(x => {
          return Object.assign(res[x], { key: x })
        })
        // console.log("this.round", this.round)
        // console.log("this.listDivision", this.listDivision)

      })
  }

  dismiss() {
    this.modalController.dismiss();
  }

}
