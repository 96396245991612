import { Injectable } from '@angular/core';
// import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';
import { NotificationViewComponent } from "../../../components/notification-view/notification-view.component";
import { ModalController } from '@ionic/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class LocalNotificationService {
  public isAndroid = false
  public date1: any
  public date2: any
  public date3: any
  public date4: any
  public date5: any
  public date6: any
  public date7: any
  public schedule = []
  enabled = false
  constructor(
    //  private localNotifications: LocalNotifications,
    public translate: TranslateService,
    public platform: Platform,
    public modalController: ModalController) {
    this.enabled = true
  }


  // async actionNoticationPush(data) {
  //   console.log("actionNoticationPush", data)
  //   setTimeout(async () => {
  //     const modal = await this.modalController.create({
  //       component: NotificationViewComponent,
  //       componentProps: { 'details': Object.assign(data, { type: 2 }) }
  //     });
  //     return await modal.present();
  //   }, 900);
  // }


  // dateEmulator() {
  //   return new Date(new Date().getTime())
  // }

  // async localNotification(day, startTime, code, name) {
  //   console.warn("inicio de categoria", code)
  //   console.warn("inicio de categoria", name)
  //   let category_name = ""
  //   await this.translate.get(code).subscribe((res: string) => { category_name = res });


  //   this.date1 = this.getDate(day, startTime, 1400, code, day)
  //   this.date2 = this.getDate(day, startTime, 240, code, day)
  //   this.date3 = this.getDate(day, startTime, 120, code, day)
  //   this.date4 = this.getDate(day, startTime, 30, code, day)
  //   this.date5 = this.getDate(day, startTime, 15, code, day)

  //   // this.date1 = this.getDate(day, startTime, 20, code, day)
  //   // this.date2 = this.getDate(day, startTime, 15, code, day)
  //   // this.date3 = this.getDate(day, startTime, 10, code, day)
  //   // this.date4 = this.getDate(day, startTime, 5, code, day)
  //   // this.date5 = this.getDate(day, startTime, 2, code, day)

  //   for (let index = 1; index < 7; index++) {
  //     if (this['date' + index] && !isNaN(this['date' + index])) {
  //       console.log(this['date' + index], '-', isNaN(this['date' + index]))
  //       console.log({
  //         id: Math.floor(Math.random() * 100) + Math.random(),
  //         title: category_name,
  //         text: this.getData(index, category_name).title ? this.getData(index, category_name).title : "N/A",
  //         trigger: { at: this['date' + index] },
  //         icon: 'https://settlework.com/assets/wldc/BG-min.png',
  //         sound: this.isAndroid ? 'file://sound.mp3' : 'file://beep.caf',
  //         data: this.getData(index, category_name)
  //       })
  //       this.schedule.push({
  //         id: Math.floor(Math.random() * 100) + Math.random(),
  //         title: category_name,
  //         text: this.getData(index, category_name).title ? this.getData(index, category_name).title : "N/A",
  //         trigger: { at: this['date' + index] },
  //         icon: 'https://settlework.com/assets/wldc/BG-min.png',
  //         sound: this.isAndroid ? 'file://sound.mp3' : 'file://beep.caf',
  //         data: this.getData(index, category_name)
  //       })
  //     } else {
  //       console.log("NO PASO")
  //     }
  //   }
  //   await this.localNotifications.schedule(this.schedule)
  // }

  // getData(value, category) {

  //   const translate = localStorage.getItem("translate");
  //   if (translate == "es") {
  //     if (value == 1) {
  //       return {
  //         category: category,
  //         img: '/assets/notifications/bell-animation.gif',
  //         title: "¡En 24 horas puedes ser el próximo campeón del mundo!",
  //         body: `Estimado competidor,
  //         ¡En 24 horas puedes ser el próximo campeón del mundo!
  //         Recuerda llegar con al menos 2 horas de anticipación al lugar de la competencia, y no olvides realizar el check-in 60 minutos antes de que compitas.
  //         Te invitamos a verificar periódicamente el estado de la competencia en tu teléfono, utilizando nuestra aplicación móvil, SETTLE, para que no te pierdas ningún detalle del Evento
  //         ¡Nos vemos mañana!`,
  //         url: "https://drive.google.com/file/d/1GkCCx_htunS7Jbme2XAZOVkg2B05pmG-/view"
  //       }
  //     } else if (value == 2) {
  //       return {
  //         img: '/assets/notifications/bell-animation.gif',
  //         title: "Hoy es tu gran día. Tu categoría empieza en 4 horas.",
  //         body: `Estimado competidor
  //         Hoy es tu gran día. Tu categoría empieza en 4 horas.
  //         ¡Importante! Debes realizar el check-in 1 hora antes de que empiece tu categoría.
  //         En el check-in los bailarines confirman que van a competir en la categoría. Como competidor, debes realizar el check-in dentro del período de tiempo señalado; de lo contrario, puedes tener problemas o incluso perder tu oportunidad de mostrar tu talento al mundo.
  //         Con nuestra aplicación móvil, estarás siempre informado de todo lo que sucede en el Evento. Mantente informado.
  //         ¡Nos vemos pronto!`,
  //         url: "https://drive.google.com/file/d/1GkCCx_htunS7Jbme2XAZOVkg2B05pmG-/view"
  //       }
  //     } else if (value == 3) {
  //       return {
  //         img: '/assets/notifications/bell-animation.gif',
  //         title: "¡Se acerca tu hora de brillar!",
  //         body: `Estimado competidor
  //         ¡Se acerca tu hora de brillar!
  //         En 1 hora debes realizar tu check-in.
  //         Es importante que cumplas con estas indicaciones de tiempo para que puedas estar preparado y evitar demoras o preocupaciones.
  //         ¡Buena suerte!`,
  //         url: "https://drive.google.com/file/d/1GkCCx_htunS7Jbme2XAZOVkg2B05pmG-/view"
  //       }
  //     } else if (value == 4) {
  //       return {
  //         img: '/assets/notifications/bell-animation.gif',
  //         title: "Te estamos esperando",
  //         body: `Estimado competidor
  //         Te estamos esperando, si no realizas tu check-in en 30 minutos, el sistema te deshabilitará de la competencia.
  //         No pierdas la oportunidad de convertirte en el próximo campeón mundial.`,
  //         url: "https://drive.google.com/file/d/1GkCCx_htunS7Jbme2XAZOVkg2B05pmG-/view"
  //       }
  //     } else if (value == 5) {
  //       return {
  //         img: '/assets/notifications/bell-animation.gif',
  //         title: "Si no te registras en 15 minutos",
  //         body: `Estimado competidor
  //         Si no te registras en 15 minutos, el sistema te deshabilitará de la competencia. ¡Date prisa!
  //         World Latin Dance Cup`,
  //         url: "https://drive.google.com/file/d/1GkCCx_htunS7Jbme2XAZOVkg2B05pmG-/view"
  //       }
  //     }
  //   } else if (localStorage.getItem("translate") == "en") {
  //     if (value == 1) {
  //       return {
  //         img: '/assets/notifications/bell-animation.gif',
  //         title: "In 24 hours you can be the next world champion.",
  //         body: `
  //         Dear competitor,
  //         In 24 hours you can be the next world champion.
  //         Remember to arrive at least 2 hours in advance to the venue before you dance, since you have to check in 60 minutes before your competition.
  //         We invite you to periodically check the status of the competition on your smartphone using our mobile app, SETTLE, so you don’t miss any information regarding to the event
  //         See you tomorrow!`,
  //         url: "https://drive.google.com/file/d/1GkCCx_htunS7Jbme2XAZOVkg2B05pmG-/view"
  //       }
  //     } else if (value == 2) {
  //       return {
  //         img: '/assets/notifications/bell-animation.gif',
  //         title: "Today is your big day. Your division starts in 4 hours.",
  //         body: `
  //         Dear competitor,
  //         Today is your big day. Your division starts in 4 hours.
  //         Important! You have to check-in 1 hour before your division competes
  //         Check-in is when dancers confirm they’re going to compete on their division. As competitor you must check-in within the allotted time frame, otherwise, you may have problems or may even miss
  //         your opportunity to show your talent.
  //         With our app, you will always be informed of what happens at the event. Stay informed.
  //         See you soon!`,
  //         url: "https://drive.google.com/file/d/1GkCCx_htunS7Jbme2XAZOVkg2B05pmG-/view"
  //       }
  //     } else if (value == 3) {
  //       return {
  //         img: '/assets/notifications/bell-animation.gif',
  //         title: "Your time to shine is coming up! In 1 hour you MUST chek-in.",
  //         body: `Dear Competitor,
  //         Your time to shine is coming up! In 1 hour you MUST chek-in.
  //         It’s important that you comply with these time indications so you can be prepared and avoid any delays or worries.
  //         Good luck`,
  //         url: "https://drive.google.com/file/d/1GkCCx_htunS7Jbme2XAZOVkg2B05pmG-/view"
  //       }
  //     } else if (value == 4) {
  //       return {
  //         img: '/assets/notifications/bell-animation.gif',
  //         title: "We are waiting for you",
  //         body: `Dear Competitor,
  //         We are waiting for you, if you don’t check-in in 30 minutes the system will disable your participation.
  //         Don't miss your chance to become the next World Champion.`,
  //         url: "https://drive.google.com/file/d/1GkCCx_htunS7Jbme2XAZOVkg2B05pmG-/view"
  //       }
  //     } else if (value == 5) {
  //       return {
  //         img: '/assets/notifications/bell-animation.gif',
  //         title: "If you don’t check-in in 15 minutes",
  //         body: `
  //         Dear Competitor,
  //         If you don’t check-in in 15 minutes the system will disable you from the competition.
  //         Hurry up!`,
  //         url: "https://drive.google.com/file/d/1GkCCx_htunS7Jbme2XAZOVkg2B05pmG-/view"
  //       }
  //     }
  //   }
  // }

  // simpleNotif() {
  //   this.localNotifications.schedule({
  //     text: 'Delayed ILocalNotification',
  //     trigger: { at: new Date(new Date().getTime() + 3600) },
  //     led: 'FF0000',
  //     sound: null,
  //     data: {
  //       img: '/assets/notifications/bell-animation.gif',
  //       title: "In 24 hours you can be the next world champion.",
  //       body: `Dear Competitor,
  //       Your time to shine is coming up! In 1 hour you MUST chek-in.
  //       It’s important that you comply with these time indications so you can be prepared and avoid any delays or worries.
  //       Good luck`,
  //       url: "https://drive.google.com/file/d/1GkCCx_htunS7Jbme2XAZOVkg2B05pmG-/view"
  //     }
  //   });
  // }

  // notifications(title, mess) {
  //   this.localNotifications.schedule({
  //     id: Date.now() + 1,
  //     text: mess,
  //     title: title,
  //     data: { secret: '' }
  //   });
  // }



  // getDate(day, startTime, min, code?, dayi?) {

  //   let delay = startTime - min;
  //   let df = `${day} ${this.convertMinsToHrsMins(delay)}`;
  //   let fecha_de_notificacion = moment(df).format()
  //   let fecha_de_telefono = moment().format()
  //   let fecha_final_notificacion: any = startTime < 1440 ? fecha_de_notificacion : false
  //   if (fecha_final_notificacion && (fecha_de_notificacion > fecha_de_telefono)) {
  //     return new Date(fecha_final_notificacion).getTime()
  //   }
  //   return false
  // }

  // getTimezoneOffset(data_utc) {

  //   // const data_utc: any = localStorage.getItem('currentEventTime')
  //   let d = new Date();
  //   let utc = d.getTime() + d.getTimezoneOffset() * 60000;
  //   let nd = new Date(utc + 3600000 * data_utc)
  //   return nd;
  // }

  // getTimezoneOffset2(data_utc, date) {

  //   // const data_utc: any = localStorage.getItem('currentEventTime')
  //   let d = new Date(date);
  //   let utc = d.getTime() + d.getTimezoneOffset() * 60000;
  //   let nd = new Date(utc + 3600000 * data_utc)
  //   return nd;
  // }


  // getAllNotifications() {
  //   return this.localNotifications.getAll()
  // }

  // async cancelAll() {
  //   await this.localNotifications.clearAll()
  //   await this.localNotifications.cancelAll()
  // }


  // convertMinsToHrsMins(mins) {
  //   if (!mins) return;
  //   let h: any = Math.floor(mins / 60);
  //   let m: any = mins % 60;
  //   h = h < 10 ? "0" + h : h;
  //   m = m < 10 ? "0" + m : m;
  //   return `${h}:${m}`;
  // }

}
