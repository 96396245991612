import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { CommonService, CustomizationfileService, DataService } from 'src/app/services/services';

@Component({
  selector: 'app-add-shipping-price',
  templateUrl: './add-shipping-price.component.html',
  styleUrls: ['./add-shipping-price.component.scss'],
})
export class AddShippingPriceComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  country: { id: string; sortname: string; name: string; phonecode: string; }[];

  constructor(
    private readonly afs: AngularFirestore,
    public db: AngularFireDatabase,
    public commonService: CommonService,
    private modalCtrl: ModalController,
    public customizationfileService: CustomizationfileService,
    private formBuilder: FormBuilder,
    public dataService: DataService) {
    this.getCountry()
    this.registerForm = this.formBuilder.group({
      zone: ["", Validators.required],
      country: ["", Validators.required],
      shipping_value: ["", Validators.required],
      weight_range_a: [2.6, Validators.required],
      weight_range_b: [3, Validators.required],
      enabled: [true, Validators.required],
    });
  }

  ngOnInit() { }


  get f() {
    return this.registerForm.controls;
  }

  getCountry() {
    this.country = this.dataService.country.sort();
  }

  getCountryText(port: any, portIndex: number, ports: any[]) {
    if (portIndex === 0 || port.id !== ports[portIndex - 1].id) {
      return port.name;
    }
    return null;
  }

  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    console.log(this.registerForm.invalid);
    if (this.registerForm.invalid) {
      this.commonService.presentAlertTranslation("formulario incompleto")
      return;
    }

    await this.commonService.presentLoading();
    const data = this.registerForm.value;

    let existCountry = await this.existCountry(data.country.sortname)
    console.log("existCountry", existCountry)

    if (existCountry) {
      this.commonService.dismissLoading()
      this.commonService.presentAlertTranslation("este pais ya esta registrado")
      return;
    }




    let country = data.country
    delete data.country
    let uid = this.customizationfileService.getUid()
    const id = this.afs.createId();

    let res = Object.assign(country, data, { uid: uid, date: Date.now(), id: id })

    console.log("res", res)

    await this.afs.collection('shipping-price-list').doc(id).set(res)
    this.registerForm.reset()
    this.submitted = false
    this.commonService.dismissLoading()
    this.commonService.presentAlertTranslation("guardado con éxito")

  }


  existCountry(code) {
    return new Promise((resolve) => {
      this.afs.collection('shipping-price-list', ref => ref.where('sortname', '==', code))
        .valueChanges()
        .subscribe(res => {
          if (res.length === 0) { resolve(false) }
          resolve(true)
        })
    })
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
