import { Component, OnInit, ViewChild } from "@angular/core";
import { WelcolmeService, CommonService } from "../../../../services/services";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { IonSlides } from "@ionic/angular";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-main-slides",
  templateUrl: "./main-slides.component.html",
  styleUrls: ["./main-slides.component.scss"],
})
export class MainSlidesComponent implements OnInit {
  @ViewChild("slideWithNav") slideWithNav: IonSlides;
  public events$: Observable<any[]>;
  public hidden_col = true;
  public slideOpts: any;

  constructor(
    public afs: AngularFirestore,
    public _welcome: WelcolmeService,
    public _cs: CommonService
  ) {
    this.gridOptions();
  }

  ngOnInit() {
    const isProduction = environment.production;
    console.log(isProduction)
    if (isProduction) {
      this.getEvents()
    } else {
      this.getEventDev()
    }
  }


  /**
   * @dev get event
   */
  getEventDev() {
    this.afs
      .collection<any>("events", (ref) => ref.where("public", "==", false).orderBy("date", "desc"))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      )
      .subscribe((events_id) => {
        this.events$ = combineLatest(
          events_id.map((authorId) =>
            this.afs
              .collection("event_images")
              .doc(authorId.id)
              .collection<any>("imagen", (ref) =>
                ref.where("name", "==", "img1200x1200MV")
              )
              .valueChanges()
              .pipe(
                map((authors) =>
                  Object.assign(authorId, { img: authors[0].img })
                )
              )
          )
        );
      });
  }


  /**
   * @dev Get events
   */
  getEvents() {
    this.afs
      .collection<any>("events", (ref) => ref.where("public", "==", true).orderBy("date", "desc"))
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      )
      .subscribe((events_id) => {
        this.events$ = combineLatest(
          events_id.map((authorId) =>
            this.afs
              .collection("event_images")
              .doc(authorId.id)
              .collection<any>("imagen", (ref) =>
                ref.where("name", "==", "img1200x1200MV")
              )
              .valueChanges()
              .pipe(
                map((authors) =>
                  Object.assign(authorId, { img: authors[0].img })
                )
              )
          )
        );
      });
  }

  async goToEvent(item: any){
    return this._welcome.goEvent(item);
  }


  /**
   * @dev Grid options
   */
  gridOptions() {
    this._cs.mediaBreakpoint$.subscribe((data) => {
      if (data == "xl" || data == "xxl" || data == "lg") {
        this.slideOpts = {
          slidesPerView: 2,
          speed: 400,
          grabCursor: true,
        };
        this.hidden_col = false;
      } else if (data == "md") {
        this.slideOpts = {
          slidesPerView: 1.2,
          speed: 400,
          grabCursor: true,
        };
        this.hidden_col = true;
      } else {
        this.slideOpts = {
          slidesPerView: 1.2,
          speed: 400,
          grabCursor: true,
        };
        this.hidden_col = true;
      }
    });
  }
}
