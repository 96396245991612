import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guard/auth.guard";
import { AuthGuardService } from "./guard/auth-guard.service";

const routes: Routes = [
  {
    path: "auth",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/auth/auth-routing.module").then(
        (m) => m.AuthRoutingModule
      ),
  },
  {
    path: "admin-event",
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import("./admin-event/admin-event-routing.module").then(
        (m) => m.AdminEventRoutingModule
      ),
  },
  {
    path: "survey",
    loadChildren: () =>
      import("./pages/survey/survey-routing.module").then(
        (m) => m.SurveyRoutingModule
      ),
  },
  {
    path: "members",
    loadChildren: () =>
      import("./pages/members/member-routing.module").then(
        (m) => m.MemberRoutingModule
      ),
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./admin/admin-routing.module").then((m) => m.AdminRoutingModule),
  },
  {
    path: "tv",
    loadChildren: () =>
      import("./pages/settle-tv/settle-tv-routing.module").then(
        (m) => m.BrowseRoutingModule
      ),
  },
  {
    path: "help-desk",
    loadChildren: () =>
      import("./pages/help-desk/help-desk-routing.module").then(
        (m) => m.HelpDeskRoutingModule
      ),
  },
  {
    path: "rejection",
    loadChildren: () =>
      import("./rejection/rejection.module").then((m) => m.RejectionPageModule),
  },
  {
    path: "handlers",
    loadChildren: () =>
      import("./handlers/handlers.module").then((m) => m.HandlersPageModule),
  },
  {
    path: "academy",
    loadChildren: () =>
      import("./pages/academy/academy.module").then((m) => m.AcademyPageModule),
  },
  {
    path: "admin-tutorial",
    loadChildren: () =>
      import("./pages/admin-tutorial/admin-tutorial.module").then(
        (m) => m.AdminTutorialPageModule
      ),
  },
  {
    path: "tutorials",
    loadChildren: () =>
      import("./pages/tutorials/tutorials.module").then(
        (m) => m.TutorialsPageModule
      ),
  },

  {
    path: "document-verification",
    canActivate: [AuthGuardService],
    loadChildren: () =>
      import("./shared/documents/documents.module").then(
        (m) => m.DocumentsPageModule
      ),
  },
  {
    path: "",
    redirectTo: "/members/welcome",
    pathMatch: "full",
  },
  {
    path: "**",
    loadChildren: () =>
      import("./pages/not-found/not-found.module").then(
        (m) => m.NotFoundPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
