import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { CommonService } from "src/app/services/services";
import { SeeComplaintsComponent } from "../see-complaints/see-complaints.component";

@Component({
  selector: "app-resolved-cases",
  templateUrl: "./resolved-cases.component.html",
  styleUrls: ["./resolved-cases.component.scss"],
})
export class ResolvedCasesComponent implements OnInit {
  @Input() code: string;
  @Input() round: Number;
  @Input() keyDivision: string;
  @Input() keydb: string;
  listComplaints;
  constructor(
    public commonService: CommonService,
    public modalController: ModalController
  ) {}

  async ngOnInit() {
    this.listComplaints = [];
    console.log("ResolvedCasesComponent");
    this.listComplaints = await this.commonService.getComplaints({
      keyDb: this.keydb,
      code: this.code,
      round: this.round,
      status: 3,
    });
  }

  async presentModal(data) {
    const modal = await this.modalController.create({
      component: SeeComplaintsComponent,
      cssClass: "my-custom-modal-css",
      componentProps: { data: data },
    });
    return await modal.present();
  }

  getName(data) {
    if (!data) {
      return;
    }
    return this.commonService.getName2(data);
  }
}
