// unir colecciones
// https://medium.com/@joaqcid/how-to-inner-join-data-from-multiple-collections-on-angular-firebase-bfd04f6b36b7

import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ModalController, NavParams } from '@ionic/angular';
import { WelcolmeService } from '../../services/services';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, combineLatest, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';


@Component({
  selector: 'app-all-events',
  templateUrl: './all-events.component.html',
  styleUrls: ['./all-events.component.scss'],
})
export class AllEventsComponent implements OnInit {
  interval;
  events: any;
  data_events: any;
  loading;

  items$: Observable<any[]>;
  geohashList: any[]

  searchByName$: BehaviorSubject<string | null>;
  geohash$: BehaviorSubject<string | null>;
  continents$: BehaviorSubject<string | null>;
  qualifiers_stage$: BehaviorSubject<string | null>;
  date$: BehaviorSubject<string | null>;
  currency$: BehaviorSubject<string | null>;
  price$: BehaviorSubject<string | null>;


  items: Observable<any[]>;


  constructor(
    private modalCtrl: ModalController,
    public _welcome: WelcolmeService,
    private router: Router,
    public navParams: NavParams,
    public afs: AngularFirestore) {


    this.searchByName$ = new BehaviorSubject(null);
    this.geohash$ = new BehaviorSubject(null);
    this.continents$ = new BehaviorSubject(null);
    this.qualifiers_stage$ = new BehaviorSubject(null);
    this.date$ = new BehaviorSubject(null);
    this.currency$ = new BehaviorSubject(null);
    this.price$ = new BehaviorSubject(null);

    combineLatest(
      this.searchByName$,
      this.geohash$,
      this.continents$,
      this.qualifiers_stage$,
      this.date$,
      this.currency$,
      this.price$
    ).
      pipe(switchMap(([
        searchByName,
        geohash,
        continents,
        qualifiers_stage,
        date, currency,
        price,
        remove]) =>

        // Haga la consulta
        afs.collection('events', ref => {
          let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
          if (searchByName) { query = query.where('public', '==', true).orderBy("name").startAt(searchByName).endAt(searchByName + "\uf8ff"); };
          if (geohash) { query = query.where('public', '==', true).where('geohash', '==', geohash) };
          if (continents) { query = query.where('public', '==', true).where('continents', '==', continents) };
          if (qualifiers_stage) { query = query.where('public', '==', true).where('qualifiers_stage', '==', qualifiers_stage) };
          if (date) { query = query.where('public', '==', true).where('date', '==', date) };
          if (currency) { query = query.where('public', '==', true).where('currency', '==', currency) };
          if (price) { query = query.where('public', '==', true).where('price', '==', price) };
          if (remove) { query = query.where('public', '==', true).where('price', '==', price) };

          return query
        })
          .auditTrail()
          .pipe(map(actions => actions.map(a => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })))
      )).subscribe(events_id => {
        this.items$ = combineLatest(
          events_id.map(authorId =>
            this.afs
              .collection('event_images')
              .doc(authorId.id)
              .collection<any>('imagen', ref => ref.where('name', '==', 'img1200x1200MV'))
              .valueChanges()
              .pipe(map(authors => Object.assign(authorId, { img: authors[0].img }))))
        )
      })
  }



  ngOnInit() {
    console.log('type', this.navParams.get('type'));
    console.log('filter', this.navParams.get('filter'));
    let type = this.navParams.get('type')
    let filter = this.navParams.get('filter')
    switch (type) {
      case 1:
        console.log('continents', filter)
        this.continents(filter)
        break;

      default:
        break;
    }
  }




  searchByName(event: string | null) {
    this.searchByName$.next(event);
  }

  continents(event: any) {
    this.continents$.next(event);
  }


  geohash(event: any) {
    this.geohash$.next(event);
  }


  qualifiersStage(event: any) {
    this.qualifiers_stage$.next(event);
  }

  date(event: any) {
    this.date$.next(event);
  }
  currency(event: any) {
    this.qualifiers_stage$.next(event);
  }
  price(event: any) {
    this.price$.next(event);
  }


  goEvent(data) {
    this.closeModal()
    this._welcome.goEvent(data)
  }


  closeModal() {
    this.modalCtrl.dismiss();
  }

}
