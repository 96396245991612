import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class YoutubeServicesService {
  constructor() {}

  openVideo(myvideoid) {
    console.log("myvideoid", myvideoid);
  }
}
