import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class SettingDivisionService {

  public classifiedSetting = new BehaviorSubject(false);
  public daySetting = new BehaviorSubject(false);
  public showCalendarSetting = new BehaviorSubject(false);
  public liveViewSetting = new BehaviorSubject(false);
  public videoUploadFromWeb = new BehaviorSubject(false);

  public complaintSetting = new BehaviorSubject({
    complaint: false, complaint_time: false
  });
  public socialSharedSetting = new BehaviorSubject(false);


  constructor(private afs: AngularFirestore, public storageService: StorageService) { }


  divisionSetting({ keyDb, code }) {
    console.log({ keyDb, code })
    this.afs
      .collection("divisions_list")
      .doc(keyDb)
      .collection("list")
      .doc(code)
      .valueChanges()
      .subscribe((res: any) => {
        // console.log("divisionSetting", res);
        if (!res) { return; }


        let classified = res.classified ? res.classified : 0;
        let day = res.day ? res.day : 0;
        let showCalendar = res.showCalendar ? res.showCalendar : false;

        let complaint = res.complaint ? res.complaint : false;
        let complaint_time = res.complaint_time ? res.complaint_time : false;

        let live_view = res.live_view ? res.live_view : false;
        let socialShared = res.socialShared ? res.socialShared : false;
        let videoUploadFromWeb = res.videoUploadFromWeb ? res.videoUploadFromWeb : false;

        this.videoUploadFromWeb.next(videoUploadFromWeb)
        this.classifiedSetting.next(classified)
        this.daySetting.next(day)
        this.showCalendarSetting.next(showCalendar)
        this.complaintSetting.next({ complaint, complaint_time })
        this.liveViewSetting.next(live_view)
        this.socialSharedSetting.next(socialShared)

        this.storageService.localStorageSetItem("classified", classified);
        this.storageService.localStorageSetItem("day", day);
        this.storageService.localStorageSetItem("showCalendar", showCalendar);
      });
  }
}
