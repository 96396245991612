import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  AlertController,
  ActionSheetController,
  MenuController,
} from "@ionic/angular";
import {
  AuthenticationService,
  LanguageService,
  CommonService,
  CustomizationfileService,
  ViewStatusService,
} from "../../services/services";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { Router } from "@angular/router";
import { Storage } from "@ionic/storage";
import { AppRateService } from "src/app/services/v2/app-rate.service";

import { findArrayIntoArray } from "../../helpers/findArrayIntoArray";
import { Subscription } from "rxjs";
import { CheckForUpdateService } from "src/app/services/v2/check-for-update.service";
import { map } from "underscore";

@Component({
  selector: "app-sidebar-menu",
  templateUrl: "./sidebar-menu.component.html",
  styleUrls: ["./sidebar-menu.component.scss"],
})
export class SidebarMenuComponent implements OnInit, OnDestroy {
  public lang;
  public sidebarMenu = false;
  public menuSidebar = [];
  public copymenuSidebar = [];
  public profile: any;
  public avatar: any;
  public active: boolean;
  currentUser = false;
  darkMode = false;
  presence: any;
  version = "";
  isAdmin = false;
  isJubge = false;
  existKeyDb = false;
  keydb = "";
  eventName = "";
  chatSupport: boolean;
  settleTV: boolean;
  public userProfiles: Array<any> = [];
  public isParticipant = false;

  private stn$: Subscription;

  constructor(
    public languageService: LanguageService,
    public alertController: AlertController,
    public actionSheetController: ActionSheetController,
    public _auth: AuthenticationService,
    public _language: LanguageService,
    public _cs: CommonService,
    public _cf: CustomizationfileService,
    private iab: InAppBrowser,
    private router: Router,
    private menu: MenuController,
    private storage: Storage,
    private socialSharing: SocialSharing,
    public viewStatusService: ViewStatusService,
    public appRateService: AppRateService,
    public checkForUpdateService: CheckForUpdateService
  ) {
    this.isParticipant = this._cf.getIsParticipant();
  }

  ngOnInit() {
    this.active = false;
    this.active = true;

    this._auth.profile.subscribe((profile) => {
      this.profile = profile;
    });
    this._auth.avatar.subscribe((avatar) => {
      this.avatar = avatar;
    });
    this._auth.presence.subscribe((presence) => {
      this.presence = presence;
    });

    this.stn$ = this._auth.authProfiles.subscribe((res) => {
      this.userProfiles = res;
      this.setMENU();
    });

    this._cf.existKeyDb.subscribe((res) => {
      this.keydb = this._cf.getKeyDb();
      this.eventName = this._cf.getEventName();
      this.setMENU();
    });
  }

  setMENU() {
    this.menuSidebar = [
      {
        name: "home",
        img: "home",
        router: "/members/welcome",
        type: "navigate",
        _kedb: false,
        public: true,
        isNew: false,
        description: "",
      },
      // {
      //   name: "announcement",
      //   img: "call",
      //   _kedb: false,
      //   router: "/survey/admin-survey",
      //   type: "navigate",
      //   isNew: true,
      //   public: false,
      //   profiles: ["call-for-dancers"],
      //   description: "",
      // },
      // {
      //   name: "organize-event",
      //   img: "calendar",
      //   _kedb: true,
      //   router: "/admin/organize-event",
      //   type: "navigate",
      //   isNew: false,
      //   public: false,
      //   profiles: ["organize-event"],
      //   description: "",
      // },
      {
        name: "translate",
        img: "language",
        _kedb: false,
        router: "/admin/translate",
        type: "navigate",
        isNew: true,
        public: false,
        profiles: ["translate"],
        description: "",
      },
      // {
      //   name: "admin-chat",
      //   img: "chatbubbles",
      //   router: "/admin/chat",
      //   type: "navigate",
      //   isNew: false,
      //   _kedb: true,
      //   public: false,
      //   profiles: ["admin-chat"],
      //   description: "",
      // },
      // {
      //   name: "admin-notifications",
      //   img: "notifications",
      //   _kedb: true,
      //   router: "/admin/admin-notifications",
      //   type: "navigate",
      //   isNew: true,
      //   public: false,
      //   profiles: ["admin-notifications"],
      //   description: "",
      // },
      // {
      //   name: "admin-settle-tv",
      //   img: "tv",
      //   _kedb: false,
      //   router: "/admin/admin-settle-tv",
      //   type: "navigate",
      //   isNew: true,
      //   public: false,
      //   profiles: ["admin-settle-tv"],
      //   description: "",
      // },
      // {
      //   name: "admin-survey",
      //   img: "reader",
      //   _kedb: true,
      //   router: "/admin/admin-survey",
      //   type: "navigate",
      //   isNew: true,
      //   public: false,
      //   profiles: ["admin-survey"],
      //   description: "",
      // },
      // {
      //   name: "admin-trophy",
      //   img: "trophy",
      //   router: "/admin/admin-trophy",
      //   type: "navigate",
      //   isNew: true,
      //   _kedb: true,
      //   public: false,
      //   profiles: ["admin-trophy"],
      //   description: "",
      // },
      // {
      //   name: "judge",
      //   img: "document-lock",
      //   router: "/admin/judge",
      //   type: "navigate",
      //   isNew: false,
      //   _kedb: true,
      //   public: false,
      //   profiles: ["judge"],
      //   description: "",
      // },
      // {
      //   name: "admin-wallet",
      //   img: "pricetag",
      //   _kedb: true,
      //   router: "/admin/admin-wallet",
      //   type: "navigate",
      //   isNew: true,
      //   public: false,
      //   profiles: ["admin-wallet"],
      //   description: "",
      // },
      // {
      //   name: "BNF-academy",
      //   img: "school",
      //   _kedb: false,
      //   router: "/academy",
      //   type: "navigate",
      //   isNew: true,
      //   public: true,
      //   profiles: [],
      //   description: "",
      // },
      // {
      //   name: "complaints",
      //   img: "alert-circle",
      //   _kedb: true,
      //   router: "/admin/complaints",
      //   type: "navigate",
      //   isNew: false,
      //   public: false,
      //   profiles: ["complaints"],
      //   description: "",
      // },
      // {
      //   name: "result-admin",
      //   img: "trophy",
      //   _kedb: true,
      //   router: "/admin/result",
      //   type: "navigate",
      //   isNew: false,
      //   public: false,
      //   profiles: ["result-admin"],
      //   description: "",
      // },
      // {
      //   name: "videos-divisions",
      //   img: "videocam",
      //   _kedb: true,
      //   router: "/admin/videos-divisions",
      //   type: "navigate",
      //   isNew: false,
      //   public: false,
      //   profiles: ["videos-divisions"],
      //   description: "",
      // },
      // {
      //   name: "setting-division",
      //   img: "hammer",
      //   _kedb: true,
      //   router: "/admin/setting-division",
      //   type: "navigate",
      //   isNew: false,
      //   public: false,
      //   profiles: ["setting-division"],
      //   description: "",
      // },
      // {
      //   name: "permissions",
      //   img: "key",
      //   _kedb: false,
      //   router: "/admin/permission",
      //   type: "navigate",
      //   isNew: false,
      //   public: false,
      //   profiles: ["admin"],
      //   description: "",
      // },
      // {
      //   name: "admin-tutorials",
      //   img: "construct",
      //   _kedb: false,
      //   router: "/admin-tutorial",
      //   type: "navigate",
      //   public: false,
      //   profiles: ["admin-tutorial"],
      //   isNew: true,
      //   description: "Módulo de tutoriales",
      // },
      // {
      //   name: "tutorials",
      //   _kedb: false,
      //   img: "desktop",
      //   router: "/tutorials",
      //   type: "navigate",
      //   public: false,
      //   isNew: true,
      //   description: "Módulo de tutoriales",
      // },
      // {
      //   name: "help-desk",
      //   img: "chatbubbles",
      //   router: "/help-desk",
      //   type: "navigate",
      //   public: false,
      //   _kedb: false,
      //   profiles: ["help-desk"],
      //   isNew: true,
      //   description: "",
      // },
      // {
      //   name: "SETTLE TV",
      //   img: "tv",
      //   _kedb: false,
      //   router: "/settle-tv",
      //   type: "navigate",
      //   public: false,
      //   profiles: ["settletv"],
      //   isNew: true,
      //   description: "",
      // },
      // {
      //   name: "notifications",
      //   img: "notifications",
      //   _kedb: false,
      //   router: "/members/notifications",
      //   type: "navigate",
      //   public: true,
      //   isNew: false,
      //   description: "",
      // },
      {
        name: "profile",
        img: "trophy",
        _kedb: false,
        router: "/members/user/profile",
        type: "navigate",
        public: true,
        isNew: false,
        description: "",
      },
      {
        name: "my-divisions",
        img: "archive",
        _kedb: false,
        router: "/members/user/record",
        type: "navigate",
        public: true,
        isNew: false,
        description: "",
      },
      {
        name: "enjoying-settle",
        img: "star",
        _kedb: false,
        router: "#",
        type: "method",
        action: () => {
          this.appRateService.showRatePrompt(true);
        },
        public: this._cs.isCordova(),
        isNew: false,
        description: "",
      },
      {
        name: "forcing-update",
        img: "reload",
        _kedb: false,
        router: "#",
        type: "method",
        action: () => {
          this.checkForUpdateService.forcingUpdate();
        },
        public: !this._cs.isCordova(),
        isNew: false,
        description: "",
      },
      {
        name: "choose-language",
        img: "pin",
        _kedb: false,
        router: "#",
        type: "method",
        action: () => {
          this.presentActionChooselanguage();
        },
        public: true,
        isNew: false,
        description: "",
      },
      {
        name: "logout",
        img: "exit",
        _kedb: false,
        router: "notificaciones",
        type: "method",
        action: () => {
          this.logout();
        },
        isNew: false,
        public: true,
        description: "",
      },
    ];
    this.copymenuSidebar = this.menuSidebar;
    if (this.userProfiles.length > 0) {
      this.updateMenuOpts();
    }
  }

  /**
   * Actualizar opciones visibles del menu tomando
   * en cuenta los perfiles de permisos del usuario
   */
  updateMenuOpts() {
    this.menuSidebar = this.menuSidebar.map((row) => {
      if (!row.public && row.profiles) {
        const { profiles } = row;
        if (row._kedb) {
          row.public =
            findArrayIntoArray(profiles, this.userProfiles) && this.keydb
              ? true
              : false;
        } else {
          row.public = findArrayIntoArray(profiles, this.userProfiles)
            ? true
            : false;
        }
      }
      return row;
    })
    .filter((row) => (!this.isParticipant) ? row.name !== "my-divisions" : true)
    this.copymenuSidebar = this.menuSidebar;
    // console.log('call to update menu items', { userProfiles: this.userProfiles });
  }

  /**
   *
   * @param {Object} item
   */
  async _method(item: any) {
    const { router, action, type } = item;
    console.log("call to method", { item });
    switch (type) {
      case "navigate":
        if (router === "/members/welcome") {
          this.clearLocalStorage();
        }

        this.router.navigate([router]);
        break;
      case "method":
        if (action) {
          action();
        }
        break;

      default:
        console.log("No type handler on sidebar");
        break;
    }

    // this.menuSidebar = this.copymenuSidebar;
    this.menu.close();
  }

  searchbar(type) {
    const searchTerm = type.toLowerCase();
    this.menuSidebar = this.copymenuSidebar;

    if (searchTerm.length > 1) {
      // console.log("searchTerm", searchTerm);
      this.menuSidebar = this.copymenuSidebar.filter((user) => {
        return user.name.toLowerCase().indexOf(searchTerm) > -1;
      });
    } else {
      this.menuSidebar = this.copymenuSidebar;
    }
  }

  clearLocalStorage() {
    window.localStorage.removeItem("nameDB");
    window.localStorage.removeItem("address");
    window.localStorage.removeItem("keyInfo");
    window.localStorage.removeItem("keyDB");

    window.localStorage.removeItem("initialSlide");
    window.localStorage.removeItem("currentEventTime");
  }

  changeTheme() {
    this.darkMode = !this.darkMode;
    this._cs.changeTheme(this.darkMode);
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set("ion_did_tutorial", false);
    this.router.navigateByUrl("/tutorial");
  }

  async presentActionChooselanguage() {
    const actionSheet = await this.actionSheetController.create({
      header: `${this.languageService.getTranslation("Choose language")} ?`,
      buttons: [
        {
          text: "English",
          handler: () => {
            this._language.switchLanguage("en");
          },
        },
        {
          text: "Español",
          handler: () => {
            this._language.switchLanguage("es");
          },
        },
      ],
    });
    await actionSheet.present();
  }

  openBrowser(url) {
    const browser = this.iab.create(url, "_self", { location: "no" });
  }

  openWhatsapp() {
    this.socialSharing
      .shareViaWhatsApp("+573147722450", "Message via WhatsApp", null)
      .then(() => {
        this._cs.presentToast("+573147722450");
      })
      .catch(() => {
        this._cs.presentToast("Error!");
      });
  }

  async logout() {
    const alert = await this.alertController.create({
      header: `${this.languageService.getTranslation("Cerrar sesión")}`,
      buttons: [
        {
          text: this.languageService.getTranslation("NO"),
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: this.languageService.getTranslation("SI"),
          handler: () => {
            this._auth.authProfiles.next([]);
            this._cf.existKeyDb.next(false);
            this._auth.logout();
          },
        },
      ],
    });

    await alert.present();
  }

  ngOnDestroy() {
    this.stn$.unsubscribe();
  }
}
