import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-admin-form-update-user-email',
  templateUrl: './admin-form-update-user-email.component.html',
  styleUrls: ['./admin-form-update-user-email.component.scss'],
})
export class AdminFormUpdateUserEmailComponent implements OnInit {

  @Input() uid: string;
  @Input() email: string = '';

  @Output() onUpdated = new Subject<any>();

  public form!: FormGroup;
  public vm = {
    email: [
      { type: 'required', message: 'El correo es requerido' },
      { type: 'pattern', message: 'El correo no es válido' }
    ]
  };
  public submitted = false;

  constructor(
    private fb: FormBuilder,
    private alertCtrl: AlertController,
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm(){
    this.form = this.fb.group({
      uid: [this.uid],
      email: [this.email, [
        Validators.required,
        Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ]],
    });
  }

  async onSubmit(){
    try {

      this.submitted = true;
      if (this.form.invalid) { return; }

      const ask = await this.alertCtrl.create({
        header: 'Confirmación',
        message: '¿Está seguro de que desea cambiar el correo electrónico de este usuario?',
        buttons: [
          {text: 'Cancelar', role: 'cancel'},
          {text: 'Aceptar', role: 'next'}
        ]
      });

      await ask.present();

      const { role } = await ask.onDidDismiss();
      console.log('role', role);
      if(role == 'cancel'){ return; }

      const formData = this.form.value;
      // console.log('Try to update email', formData);

      this.onUpdated.next(formData);
      return;
      
    } catch (err) {
      console.log('Error on AdminFormUpdateUserEmailComponent.onSubmit', err);
      return;
    }
  }

}
