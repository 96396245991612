import { Component, Input, OnInit } from "@angular/core";
import { AngularFireDatabase } from "angularfire2/database";
import {
  CommonService,
  ConferencingService,
  CustomizationfileService,
  StorageService,
  ViewStatusService,
} from "src/app/services/services";

@Component({
  selector: "app-connect-zoom",
  templateUrl: "./connect-zoom.component.html",
  styleUrls: ["./connect-zoom.component.scss"],
})
export class ConnectZoomComponent implements OnInit {
  @Input() round: string;
  @Input() code: string;
  @Input() key_division: string;
  @Input() keydb: string;
  @Input() day: string;
  enabled_button = false;
  enabled_alert = true;
  setting: any;
  indexCodeDivision: number;
  divisionInfo = {};
  checkinEnabled: boolean;
  myDivision: any;
  indexCalendarRoom: any;
  indexDivisionCalendarRoom: any;
  zoom_enabled = false;
  updateProgress: boolean;
  constructor(
    public viewStatusService: ViewStatusService,
    public db: AngularFireDatabase,
    public commonService: CommonService,
    public conferencingService: ConferencingService,
    public storageService: StorageService,
    public customizationfileService: CustomizationfileService
  ) { }

  async ngOnInit() {


    console.warn("code", this.code)
    console.warn("round", this.round)
    console.warn("key_division", this.key_division)
    console.warn("keydb", this.keydb)

    this.getLiveZoom();
    this.getData()

  }

  getData() {
    try {
      return this.db
        .object(`/livingRoom/${this.keydb}/${this.day}/${this.round}`)
        .valueChanges()
        .subscribe((res: any) => {
          this.updateProgress = true;
          if (!res) {
            return;
          }

          const setting = res.hasOwnProperty("setting") ? res.setting : [];
          this.getSetting(setting);

          const calendarRoom = res.hasOwnProperty("calendarRoom")
            ? res.calendarRoom
            : [];

          let result = calendarRoom.filter((res, index) => {
            // console.log("res.code ", res.code);
            if (res.code == this.code) {
              return Object.assign(res, { indexCalendarRoom: index });
            }
          });

          if (result.length === 0) {
            this.checkinEnabled = false;
            return;
          }

          console.log("result[0]", result[0]);
          console.log("setting", setting);

          this.youCanConnectNow(result[0]);

          setTimeout(() => {
            this.updateProgress = false;
          }, 1500);
        });
    } catch (err) {
      console.log("err", err);
    }
  }

  getLiveZoom() {
    console.log(1,
      `/competition/${this.keydb}/mainChoreography/${this.code}/${this.round}/${this.key_division}/zoomlive`
    );
    return this.db
      .object(
        `/competition/${this.keydb}/mainChoreography/${this.code}/${this.round}/${this.key_division}/zoomlive`
      )
      .valueChanges()
      .subscribe((res: any) => {
        console.log("getLiveZoom", res);
        if (!res) {
          this.zoom_enabled = false;
          return;
        }
        this.zoom_enabled = res;
      });
  }

  getSetting(setting) {
    console.log("setting", setting);
    if (setting) {
      this.setting = setting;
    }
  }

  youCanConnectNow(res) {
    try {
      if (!res) {
        return;
      }

      console.log("indexCalendarRoom", this.setting);
      let calendarRoom = res.division_list;
      let connectionTime = this.setting.connectionTime;
      let checkInMin = this.setting.checkInMin;
      this.indexCalendarRoom = res.indexCalendarRoom;

      console.log("indexCalendarRoom", this.indexCalendarRoom);
      console.log("calendarRoom", calendarRoom);
      console.log("connectionTime", connectionTime);

      for (let index = 0; index < calendarRoom.length; index++) {
        const division: any = calendarRoom[index];
        if (division.key === this.key_division) {
          console.log("division", division);

          this.myDivision = division;
          this.storageService.localStorageSetItem(
            "app-connect-zoom",
            JSON.stringify(this.myDivision)
          );
          this.indexDivisionCalendarRoom = index;
          console.log(
            "this.indexDivisionCalendarRoom",
            this.indexDivisionCalendarRoom
          );


          // console.log("division.hour", new Date(division.hour))
          // console.log("this.setting.startTime", new Date(this.setting.startTime))
          // console.log("this.setting", this.setting)

          const hourEnabled = this.commonService.getHoursDifference({
            endTime: division.hour,
            startTime: this.setting.startTime,
          });

          console.log("hourEnabled", hourEnabled)

          let time_min = Math.abs(Number(connectionTime.time_min));
          let time_max = Math.abs(Number(connectionTime.time_max));
          let checkin = Math.abs(Number(checkInMin));

          let timeCurrent = Math.abs(hourEnabled.minutes);

          console.log("tiempo actual", timeCurrent);
          console.log("tiempo mínimo para poder conectarse", time_min);
          console.log("tiempo maximo para poder conectarse", time_max);
          console.log("tiempo para habilitar la conexión de check", checkin);

          // // check in - enabled button
          // console.log("this.myDivision.checkIn", this.myDivision.checkIn);
          if (checkin > timeCurrent) {
            this.checkinEnabled = true;
          } else {
            this.checkinEnabled = false;
          }

          console.log("this.checkinEnabled", this.checkinEnabled);

          // Habilitamos boton de conexion
          this.enabled_button =
            timeCurrent >= time_min && timeCurrent <= time_max ? true : false;

          console.log("this.enabled_button", this.enabled_button);
          // this.enabled_button =
          //   timeCurrent >= -time_min && timeCurrent <= time_max ? true : false;



          if (
            this.enabled_alert &&
            this.enabled_button &&
            this.checkinEnabled &&
            this.myDivision.checkIn !== undefined
          ) {
            this.commonService.presentAlert("you can connect now");
            this.enabled_alert = false;
          }

          return;
        }
      }
    } catch (err) {
      console.log("err", err);
      // this.commonService.presentAlert("Erro");
    }
  }

  async joinMeeting() {
    try {
      console.log("this.setting", this.setting.meeting);
      if (!this.setting.meeting) {
        return;
      }

      const userData = JSON.parse(
        this.storageService.localStorageGetItem("app-connect-zoom")
      )

      const user_conection = userData === null ? "NO NAME" : `${this.code} ${userData.user.toLowerCase()}`

      let zoomOption = {
        meetingNumber: this.setting.meeting.meetingNumber
          .replace(/\s/g, "")
          .toString(),
        meetingPassword: this.setting.meeting.meetingPassword
          .replace(/\s/g, "")
          .toString(),
        displayName: user_conection,
      };

      console.log("user_conection", user_conection);
      await this.commonService.presentLoading();

      if (this.commonService.isCordova()) {
        this.conferencingService
          .joinMeetingNative(zoomOption)
          .then((success) => {
            console.log("success", success);
            this.commonService.dismissLoading();
            this.commonService.presentToast(success);
            this.commonService.presentAlert("espera un momento");
          });
      } else {
        this.conferencingService.joinMeetingWeb(zoomOption);
      }
    } catch (err) {
      console.log("err", err)
      this.commonService.dismissLoading();
      this.commonService.presentAlert(JSON.stringify(err));
    }
  }

  checkIn() {
    console.log("checkIn");
    if (this.updateProgress) {
      this.commonService.presentToast("try again");
      return;
    }
    this.db
      .object(
        `/livingRoom/${this.keydb}/${this.day}/${this.round}/calendarRoom/${this.indexCalendarRoom}/division_list/${this.indexDivisionCalendarRoom}`
      )
      .update({
        checkIn: true,
      });
  }

  trackByFn(index, item) {
    return index; // or item.id
  }
}
