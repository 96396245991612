import { Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { AuthenticationService, CartService, CommonService, CustomizationfileService, LanguageService } from 'src/app/services/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  authenticationState: boolean;
  avatar: string;
  cartState = [];

  lengSelected = "es";
  constructor(
    public _language: LanguageService,
    public _cart: CartService,
    public _cs: CommonService,
    public _cf: CustomizationfileService,
    public auth: AuthenticationService,
    private menu: MenuController) { }


  ngOnInit() {
    this.auth.authenticationState.subscribe((status) => {
      this.authenticationState = status;
      this._cs.setSidebarMenu(status);
    });


    this._cart.cartState.subscribe((data) => {
      this.cartState = data;
    });

    this.auth.avatar.subscribe((avatar) => { this.avatar = avatar; });
  }


  /**
   * @dev set  lenguege
   */
  switchLanguage(language: string) {
    this.lengSelected = language;
    this._language.switchLanguage(language);
  }


  /**
   * @dev get lenguege
   */
  getLanguage() {
    this.lengSelected = this._language.getLanguage();
  }



  /**
   * @dev  open menu
   */
  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }
}
