import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { CommonService, CustomizationfileService } from 'src/app/services/services';

@Component({
  selector: 'app-add-coupon',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.scss'],
})
export class AddCouponComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;

  constructor(
    public commonService: CommonService,
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    public db: AngularFireDatabase,
    public customizationfileService: CustomizationfileService) {

    this.registerForm = this.formBuilder.group(
      {
        _type: ["", Validators.required],
        ref_coupon: ["", Validators.required],
        typeofDiscount: ["", Validators.required],
        code_coupon: [`CILIK-${Date.now()}`, Validators.required],
        discount: ["", Validators.required],
      });
  }


  ngOnInit() { }

  async onSubmit() {

    try {
      this.submitted = true;

      // stop here if form is invalid
      console.log(this.registerForm.invalid);
      if (this.registerForm.invalid) {
        this.commonService.presentAlertTranslation("perfil incompleto")
        return;
      }

      await this.commonService.presentLoading();
      let coupon = this.registerForm.value;
      console.warn("coupon", coupon)
      const profile = this.customizationfileService.getprofile()
      const res = Object.assign(coupon, profile, {
        percentage: coupon.discount,
        keydb: this.customizationfileService.getKeyDb(),
        enabled: true,
        uid: this.customizationfileService.getUid(),
        date: Date.now()
      });

      console.log("res", res)
      this.registerForm.reset()
      this.closeModal()
      await this.db.list(`coupon`).push(res)
      this.commonService.dismissLoading()

    } catch (error) {
      this.commonService.presentAlertTranslation("error")
      this.commonService.dismissLoading()
    }


  }

  get f() {
    return this.registerForm.controls;
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
