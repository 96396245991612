import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IonReorderGroup, ModalController, NavParams } from "@ionic/angular";
import { CommonService } from "src/app/services/services";
import { MediaFilesService } from "src/app/services/v2/media-files.service";
import { ItemReorderEventDetail } from "@ionic/core";
import { UpdateChaptersComponent } from "../update-chapters/update-chapters.component";
import { VimeoService } from "src/app/services/v2/vimeo.service";

@Component({
  selector: "app-add-chapters",
  templateUrl: "./add-chapters.component.html",
  styleUrls: ["./add-chapters.component.scss"],
})
export class AddChaptersComponent implements OnInit {
  registerForm: FormGroup;
  @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup;
  nameUpload = ""
  submitted = false;
  data: any;
  listVideo = [];
  statusCarga = ""
  @ViewChild("myInput")
  myInputVariable: ElementRef;
  bytes: number;
  offset: number;
  size: any;
  fileSize: any;
  progressLoanding
  constructor(
    public vimeoService: VimeoService,
    public mediaFilesService: MediaFilesService,
    public commonService: CommonService,
    public modalController: ModalController,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private navParams: NavParams,
    private readonly afs: AngularFirestore
  ) {
    this.data = this.navParams.get("value");
    console.log("this.data ", this.data);
  }


  ngOnInit() {
    this.getListVideo();
    this.registerForm = this.formBuilder.group({
      title: ["", Validators.required],
      placeholder: ["/assets/img/settle-tv/settle-tv-banner.jpeg", Validators.required],
      src: ["", Validators.required],
      description: ["", Validators.required],
      public: [true],
      date: [Date.now()],
      pos: [Date.now()],
      type: ["video/mp4"],
    });
  }

  upVideo(e: any) {
    console.warn("onUpIngredients", e);
    this.registerForm.patchValue({ src: e.data.link });
  }


  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  async onSubmit() {
    try {

      this.submitted = true;
      // stop here if form is invalid
      if (this.registerForm.invalid) {
        return;
      }


      await this.commonService.presentLoading();

      await this.afs
        .collection("chapters")
        .doc(this.data.id)
        .collection("list")
        .add(this.registerForm.value);

      this.commonService.presentAlert("SUCCESS!! :-)");
      this.onReset();

    } catch (error) {
      console.log(error);
    } finally {
      this.commonService.dismissLoading();
    }
  }


  getListVideo() {
    this.afs
      .collection("chapters")
      .doc(this.data.id)
      .collection("list")
      .valueChanges({ idField: "idDoc" })
      .subscribe((res) => {
        this.listVideo = res.sort((a, b) => {
          if (a.pos > b.pos) {
            return 1;
          }
          if (a.pos < b.pos) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      });
  }

  trackByFn(index, item) {
    return item.idDoc;
  }

  doReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    // Before complete is called with the items they will remain in the
    // order before the drag
    console.log("Before complete", this.listVideo);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. Update the items variable to the
    // new order of items
    this.listVideo = ev.detail.complete(this.listVideo);

    // After complete is called the items will be in the new order
    console.log("After complete", this.listVideo);
    this.update();
  }

  async update() {
    await this.commonService.presentLoading();
    this.listVideo.map(async (element, index) => {
      console.log("index", index);
      console.log("index", element.idDoc);

      return await this.afs
        .collection("chapters")
        .doc(this.data.id)
        .collection("list")
        .doc(element.idDoc)
        .update({ pos: index });
    });
    this.commonService.dismissLoading();
  }

  async presentModalUpdateChapters(data) {
    const modal = await this.modalController.create({
      component: UpdateChaptersComponent,
      cssClass: "my-custom-modal-css",
      componentProps: {
        value: Object.assign({ idChapters: this.data.id }, data),
      },
    });
    return await modal.present();
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}

