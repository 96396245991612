import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CommonService, CustomizationfileService } from 'src/app/services/services';
import { JudgesService } from 'src/app/services/v2/judges.service';

@Component({
  selector: 'app-winners-report',
  templateUrl: './winners-report.component.html',
  styleUrls: ['./winners-report.component.scss'],
})
export class WinnersReportComponent implements OnInit {

  listResult: any
  listResultCopy: any
  constructor(public commonService: CommonService,
    public customizationfileService: CustomizationfileService,
    public modalController: ModalController,
    public judgesService: JudgesService) { }

  async ngOnInit() {
    const res: any = await this.judgesService
      .resultReport(
        {
          route: "result/winnersReport",
          data: { keydb: this.customizationfileService.getKeyDb() }
        })
      .catch(err => {
        this.commonService.presentAlert(JSON.stringify(err))
      })

    console.log("this.listResult", this.listResult)
    this.listResult = res.results
    this.listResultCopy = res.results
  }

  async downloadWinnersReport() {
    await this.commonService.presentLoading()
    const response: any = await this.judgesService
      .downloadReport(
        {
          route: "result/downloadWinnersReport",
          data: { keydb: this.customizationfileService.getKeyDb() }
        })
      .catch(err => {
        this.commonService.dismissLoading()
        this.commonService.presentAlert(JSON.stringify(err))
      })

    console.log("result", response)
    this.judgesService.downLoadFile(response, "application/ms-excel");
    this.commonService.dismissLoading()
  }


  filter(type) {
    this.listResult = this.listResultCopy;
    const searchTerm = type.toLowerCase();
    if (searchTerm.length >= 1) {
      this.listResult = this.listResult.filter((user) => {
        return user.code.toLowerCase().indexOf(searchTerm) > -1;
      });
    } else {
      this.listResult = this.listResultCopy;
    }
  }

  trackByFn(index, item) {
    return item.code
  }


  dismiss() {
    this.modalController.dismiss();
  }

}
