import { Component, OnInit } from "@angular/core";
import { AlertController, ModalController, NavParams } from "@ionic/angular";
import { AngularFireDatabase } from "angularfire2/database";
import {
  CustomizationfileService,
  CommonService,
  StorageService,
} from "src/app/services/services";

@Component({
  selector: "app-rate-items-online",
  templateUrl: "./rate-items-online.component.html",
  styleUrls: ["./rate-items-online.component.scss"],
})
export class RateItemsOnlineComponent implements OnInit {
  session: any;
  items: any;
  code: any;
  data: any;
  keydb: any;
  averagetotalminuserror: number;
  round: any;

  iAlreadyQualify = false;
  day: any;
  constructor(
    public storageService: StorageService,
    public db: AngularFireDatabase,
    public customizationfileService: CustomizationfileService,
    public commonService: CommonService,
    public alertController: AlertController,
    public modalController: ModalController,
    private navParams: NavParams
  ) {
    this.keydb = this.customizationfileService.getPROVISIONAKEY();
    this.round = this.navParams.get("round");
    this.items = this.navParams.get("items");
    this.day = this.navParams.get("day");
    console.log("this.keydb", this.keydb);
    console.log("this.round", this.round);
  }

  ngOnInit() {
    this.db
      .object(`/livingRoom/${this.keydb}/${this.day}/${this.round}/setting`)
      .valueChanges()
      .subscribe((res) => {
        if (res === null) {
          return;
        }
        console.log("setting", res);
      });

    this.db
      .object(`/livingRoom/${this.keydb}/${this.day}/${this.round}/livingRoomMain/0`)
      .valueChanges()
      .subscribe((res: any) => {
        this.iAlreadyQualify = false;
        if (res === null) {
          this.commonService.presentToast("No user to rate");
          return;
        }

        const last = this.storageService.localStorageGetItem(
          "last_qualified_online"
        );

        console.log("last", last);
        console.log("keyDivision", res.keyDivision);
        // if (last === res.keyDivision) {
        //   this.commonService.presentToast("you already rated this user");
        //   return;
        // }

        this.data = res;
        this.iAlreadyQualify = true;
        console.log("livingRoomMain", this.data);
      });
  }

  async presentAlertConfirm() {
    if (this.averagetotalminuserror) {
      const pts = this._toFixed(this.averagetotalminuserror);
      const alert = await this.alertController.create({
        header: "La puntuación total del competidor es",
        message: `${pts} pts`,
        backdropDismiss: false,
        buttons: [
          {
            text: "NO",
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: "SI",
            handler: async () => {
              this.saveRating();
            },
          },
        ],
      });

      await alert.present();
    }
  }

  async saveRating() {
    try {
      await this.commonService.presentLoading();
      console.log("items", this.items);
      console.log("this.averagetotalminuserror", this.averagetotalminuserror);
      for (var i = 0; i < this.items.length; i++) {
        if (this.items[i].score == undefined || this.items[i].score == 0) {
          let m = `Watch out! You missing ${this.items[i].name} for score 🤚`;
          this.commonService.dismissLoading();
          this.commonService.presentAlert(m);
          return;
        }
      }

      const uid = this.customizationfileService.getUid();
      console.log("data", this.data);

      const url = `rate/${this.keydb}/${this.data.code}/${this.round}/${this.data.keyDivision}/items/${uid}`;
      await this.db.object(url).set(this.items);

      const urlOnline = `rate_online/${this.keydb}/${this.data.keyDivision}/items/${uid}`;
      await this.db.object(urlOnline).set(this.items);

      const urlname = `rate_online/${this.keydb}/${this.data.keyDivision}/name`;
      await this.db.object(urlname).set(this.data.user);

      this.storageService.localStorageSetItem(
        "last_qualified_online",
        this.data.keyDivision
      );

      this.commonService.dismissLoading();
      this.commonService.presentAlert("calificación guardada con éxito");
      this.averagetotalminuserror = 0;
      // this.dismiss(1);
      this.reset();
      this.iAlreadyQualify = false;
    } catch (err) {
      this.commonService.dismissLoading();
      this.commonService.presentAlert(JSON.stringify(err));
    }
  }

  reset() {
    for (var i = 0; i < this.items.length; i++) {
      this.items[i].score = 0;
    }
  }

  onSearchChange() {
    this.averagetotalminuserror = 0;
    for (var i = 0; i < this.items.length; i++) {
      if (this.items[i].score) {
        this.averagetotalminuserror +=
          parseFloat(this.items[i].score) / this.items.length;
      }
    }
  }

  _toFixed(value) {
    if (!value) return;
    return value.toFixed(2);
  }

  dismiss(status, message?) {
    this.modalController.dismiss({
      dismissed: true,
      status,
      message,
    });
  }
}
