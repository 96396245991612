// fallo de compilacion android


import { Injectable, OnInit } from "@angular/core";
import { ImagePicker } from "@ionic-native/image-picker/ngx";
import { ModalController, Platform } from "@ionic/angular";
import {
  MediaCapture,
  CaptureError,
  MediaFile,
  CaptureVideoOptions,
  CaptureAudioOptions,
} from "@ionic-native/media-capture/ngx";
import { Camera, CameraOptions } from "@ionic-native/camera/ngx";
import { File, IWriteOptions } from "@ionic-native/file/ngx";
import { Media, MediaObject } from "@ionic-native/media/ngx";
import { StreamingMedia } from "@ionic-native/streaming-media/ngx";
import { PhotoViewer } from "@ionic-native/photo-viewer/ngx";
import { AngularFireDatabase } from "angularfire2/database";
import { CommonService, CustomizationfileService } from "../services";
import { AngularFireStorage } from "@angular/fire/storage";
import { SeeVideoComponent } from "src/app/admin/components/see-video/see-video.component";
import { finalize } from "rxjs/operators";
import { Crop } from "@ionic-native/crop/ngx";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { RecordVideoComponent } from "src/app/components/record-video/record-video.component";

const MEDIA_FOLDER_NAME = "my_media";
const MAX_FILE_SIZE = 5 * 1024 * 1024;
const ALLOWED_MIME_TYPE = "video/mp4";

import * as FileSaver from 'file-saver'
// const FileSaver = require('file-saver');

@Injectable({
  providedIn: "root",
})
export class MediaFilesService implements OnInit {

  files = [];
  downloadURL: any;
  endRecord: any;

  constructor(
    private androidPermissions: AndroidPermissions,
    private crop: Crop,
    public platform: Platform,
    private camera: Camera,
    private storage: AngularFireStorage,
    public db: AngularFireDatabase,
    private imagePicker: ImagePicker,
    private mediaCapture: MediaCapture,
    private file: File,
    private media: Media,
    public modalController: ModalController,
    private streamingMedia: StreamingMedia,
    private photoViewer: PhotoViewer,
    public commonService: CommonService,
    public customizationfileService: CustomizationfileService
  ) { }

  ngOnInit(): void {
    let path = this.file.dataDirectory;
    this.file.checkDir(path, MEDIA_FOLDER_NAME).then(
      () => {
        this.loadFiles();
      },
      (err) => {
        this.file.createDir(path, MEDIA_FOLDER_NAME, false);
      }
    );
  }

  loadFiles() {
    this.file.listDir(this.file.dataDirectory, MEDIA_FOLDER_NAME).then(
      (res) => {
        this.files = res;
      },
      (err) => console.log("error loading files: ", err)
    );
  }

  captureImage() {
    this.mediaCapture.captureImage().then(
      (data: MediaFile[]) => {
        if (data.length > 0) {
          this.copyFileToLocalDir(data[0].fullPath);
        }
      },
      (err: CaptureError) => console.error(err)
    );
  }

  recordAudio() {
    return new Promise((resolve, reject) => {
      let option: CaptureAudioOptions = {
        limit: 1,
        duration: 320,
      };
      this.mediaCapture.captureAudio(option).then(
        async (data: MediaFile[]) => {
          console.log("captureAudio", data);
          if (data.length > 0) {
            const url = await this.copyFileToLocalDir(data[0].fullPath);
            resolve(url);
          }
        },
        (err: CaptureError) => {
          this.commonService.dismissLoading();
          this.commonService.presentAlert(JSON.stringify(err));
          console.error(err);
          reject(err);
        }
      );
    });
  }

  recordVideo({ limit, duration }) {
    return new Promise((resolve, reject) => {
      let option = {
        limit,
        quality: 1,
        duration,
        ios_quality: "high",
      };

      // calidad de video corregida, revisar este enlace y crear propio plugins
      //https://stackoverflow.com/questions/31966709/why-low-resolution-video-480x360-recording-on-ios-with-ionic-cordovacapture-a

      this.mediaCapture.captureVideo(option).then(
        async (data: MediaFile[]) => {
          if (data.length > 0) {
            // contador aca
            this.endRecord = Date.now();
            const url = await this.copyFileToLocalDir(data[0].fullPath);
            resolve(url);
          }
        },
        (err: CaptureError) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }

  recordWithCamare() {
    return new Promise(async (resolve, reject) => {
      try {
        const options: CameraOptions = {
          quality: 100,
          mediaType: this.camera.MediaType.VIDEO,
          sourceType: this.camera.PictureSourceType.CAMERA,
          destinationType: this.camera.DestinationType.FILE_URI,
          allowEdit: false,
          saveToPhotoAlbum: false,
          correctOrientation: true,
        };

        // ERROR _ GRAVE
        // https://github.com/apache/cordova-plugin-camera/issues/506#issuecomment-534070130
        const fileURI = await this.camera.getPicture(options);
        const imageData2 =
          fileURI.indexOf("file://") === -1 ? `file://${fileURI}` : fileURI;

        const urlFinal = await this.copyFileToLocalDir(imageData2);

        resolve(urlFinal);
      } catch (err) {
        reject(err);
      }
    });
  }

  pickImages({
    message,
    outputType,
    quality,
    maximumImagesCount,
    allow_video,
    disable_popover,
    enabledCopyFileToLocalDir
  }) {
    return new Promise(async (resolve, reject) => {
      const permission = await this.imagePicker.hasReadPermission();
      if (!permission) {
        await this.imagePicker.requestReadPermission();
      }

      // ERROR GRAVE : https://github.com/Telerik-Verified-Plugins/ImagePicker/issues/165#issuecomment-737118970
      this.imagePicker
        .getPictures({
          message,
          outputType,
          quality,
          maximumImagesCount,
          allow_video,
          disable_popover,
        })
        .then(async (results) => {
          console.log("imagePicker", results.length);
          let minimumPhoto = maximumImagesCount - 1
          let listPromise = [];
          if (results.length > minimumPhoto) {

            if (enabledCopyFileToLocalDir) {
              await results.map((element) => {
                listPromise.push(this.copyFileToLocalDir(element));
              });
              const images = await Promise.all(listPromise);
              resolve(images);
            } else {
              resolve(results);
            }

          } else {
            reject(`minimum ${minimumPhoto} photos`);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  cropImagen({ pathToImage, quality, targetHeight, targetWidth, base64 }) {
    return new Promise(async (resolve, reject) => {
      this.crop.crop(pathToImage, { quality, targetHeight, targetWidth })
        .then((ImagePath: any) => {
          resolve(ImagePath)
        },
          (err) => {
            console.error("Error cropping image", err)
            reject(err)
          }
        );
    })
  }

  showCroppedImage(ImagePath) {
    return new Promise(async (resolve, reject) => {
      var copyPath = ImagePath;
      var splitPath = copyPath.split('/');
      var imageName = splitPath[splitPath.length - 1];
      var filePath = ImagePath.split(imageName)[0];
      this.file.readAsDataURL(filePath, imageName).then(base64 => {
        resolve(base64)
      }, error => {
        resolve(error)
      });
    })

  }



  validBase64(base64) {
    return base64.replace(/^data:(image|video)\/(png|jpeg|jpg|avi|mov|mp4|wmv);base64,/, '')
  }


  /**
   * Obtener extension de archivo en base64
   * @param url
   * @returns
   */
  getBase64Ext(url: string){
    const type = url.split(';')[0].split('/')[1];
    return type;
  }




  public savePicture(base64) {
    return new Promise(async (resolve, reject) => {
      // Convert photo to base64 format, required by Filesystem API to save
      const base64Data = await this.base64toBlob(base64, 'image/jpeg');
      console.log("base64Data", base64Data)

      // Write the file to the data directory
      const fileName = new Date().getTime() + '.jpeg';
      let path = await this.getDownloadPath()
      const result = await this.file.writeFile(path, fileName, base64Data)
        .catch(err => {
          reject(err)
        })
      resolve(result)
    })

  }



  public async getDownloadPath() {
    // https://stackoverflow.com/questions/43749535/share-a-pdf-in-ionic-app
    // https://2amigos.us/blog/how-to-download-files-to-your-device-from-your-api-in-ionic-framework-v3
    if (this.platform.is('ios')) {
      return this.file.cacheDirectory;
    }

    // To be able to save files on Android, we first need to ask the user for permission.
    // We do not let the download proceed until they grant access
    await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
      result => {
        if (!result.hasPermission) {
          return this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE);
        }
      }
    );

    return this.file.externalRootDirectory + "/Download/";
  }

  async selectMedia({ mediaType }) {
    return new Promise(async (resolve, reject) => {
      try {
        const options: CameraOptions = {
          quality: 100,
          mediaType,
          sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
          destinationType: this.camera.DestinationType.FILE_URI,
          allowEdit: true,
          saveToPhotoAlbum: false,
          correctOrientation: true,
        };

        // ERROR _ GRAVE
        // https://github.com/apache/cordova-plugin-camera/issues/506#issuecomment-534070130
        const fileURI = await this.camera.getPicture(options);
        const imageData2 =
          fileURI.indexOf("file://") === -1 ? `file://${fileURI}` : fileURI;

        const urlFinal = await this.copyFileToLocalDir(imageData2);

        resolve(urlFinal);
      } catch (err) {
        reject(err);
      }
    });
  }

  copyFileToLocalDir(fullPath) {
    // console.log("copyFileToLocalDir", fullPath);
    try {
      return new Promise(async (resolve, reject) => {
        let myPath = fullPath;
        // Make sure we copy from the right location
        if (fullPath.indexOf("file://") < 0) {
          myPath = "file://" + fullPath;
        }

        const ext = myPath.split(".").pop();
        const d = Date.now();
        const newName = `${d}.${ext}`;

        const name = myPath.substr(myPath.lastIndexOf("/") + 1);
        const copyFrom = myPath.substr(0, myPath.lastIndexOf("/") + 1);
        const copyTo = this.file.dataDirectory + MEDIA_FOLDER_NAME;

        console.log("readAsDataURL", name);
        await this.file
          .readAsDataURL(copyFrom, name)
          .then(async (dataText) => {
            resolve(dataText);
          })
          .catch((err) => {
            console.log("readAsDataURL", err);
            reject(err);
          });
      });
    } catch (err) {
      this.commonService.dismissLoading();
      this.commonService.presentAlert(JSON.stringify(err));
      console.log("error", err);
    }
  }

  playVideo(url) {
    if (!url) {
      this.commonService.presentAlertTranslation("NO HAY VIDEO");
      return;
    }
    if (this.commonService.isCordova()) {
      return this.streamingMedia.playVideo(url);
    } else {
      return this.watchVideos(url)
    }
  }



  async presentModalRecordVideo() {
    return new Promise(async (resolve, reject) => {
      const modal = await this.modalController.create({
        component: RecordVideoComponent,
        swipeToClose: false,
        backdropDismiss: false,
        componentProps: {
          divisionData: "",
        },
      });

      modal.onDidDismiss().then(async (dataReturned) => {
        if (dataReturned !== null) {
          if (dataReturned.data.status === 1) {
            this.endRecord = Date.now();
            await this.commonService.presentLoading()
            let urlBlodLocal = await this.getFileBlob(dataReturned.data.message)
            const urlVideo = await this
              .uploadFileBLod({ blod: urlBlodLocal, folder: "competitor", ext: "mp4" })
              .catch(err => {
                console.log("err", err)
                this.commonService.dismissLoading()
                this.commonService.presentAlert(JSON.stringify(err))
                reject(err)
              })
            this.commonService.dismissLoading()
            resolve(urlVideo)
          }
        }
      });

      return await modal.present();
    })


  }


  async watchVideos(url) {
    const modal = await this.modalController.create({
      component: SeeVideoComponent,
      cssClass: "my-custom-modal-css",
      componentProps: {
        value: {
          name: "",
          nameVideo: false,
          url: url,
          code: "",
          key: "",
        },
      },
    });

    return await modal.present();
  }

  async presentVideo(data) {
    const modal = await this.modalController.create({
      component: SeeVideoComponent,
      cssClass: "my-custom-modal-css",
      componentProps: {
        value: {
          name: data.question ? data.question : "",
          nameVideo: `video`,
          url: data.state ? data.state.video : data,
          code: "",
          key: false,
        },
      },
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        if (dataReturned.data.status === 1) {
        }
      }
    });

    return await modal.present();
  }

  public uploadProgress = 0;
  async upload(data) {
    const { captureVideoUrl, contentType, newName, codeDivision } = data;
    return new Promise<any>(async (resolve, reject) => {
      try {
        const ramdom = Math.floor(Math.random() * 1000000 * 5);
        const filePath = `multimedia/${codeDivision}/${ramdom}-${ramdom}-${newName}`;
        const fileRef = this.storage.ref(filePath);
        const task = fileRef.putString(captureVideoUrl, "data_url", {
          contentType,
        });

        task.percentageChanges().subscribe((res) => {
          this.uploadProgress = this.setPercentBar(res);
        });

        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe((url) => {
                this.uploadProgress = 0;
                console.log("url", url);
                resolve(url);
              });
            })
          )
          .subscribe();
      } catch (err) {
        reject(err);
      }
    });
  }

  // sube file base64
  uploadFile(imageURI) {
    return new Promise(async (resolve, reject) => {
      try {
        const ramdom = Math.floor(Math.random() * 1000000 * 5);
        const filePath = `multimedia/${new Date().getTime()}/${ramdom}`;
        const fileRef = this.storage.ref(filePath);

        const task = fileRef.putString(imageURI, "data_url");

        task.percentageChanges().subscribe((res) => {
          this.uploadProgress = this.setPercentBar(res);
        });

        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe((url) => {
                this.uploadProgress = 0;
                resolve(url);
              });
            })
          )
          .subscribe();
      } catch (err) {
        reject(err);
      }
    });
  }


  /**
   * Cargar archivo en directorio especifico
   * @param imageURI
   * @param folder
   * @param ext
   * @returns
   */
  uploadFileWithCustomFoler(imageURI, folder, ext) {
    return new Promise(async (resolve, reject) => {
      try {
        const ramdom = Math.floor(Math.random() * 1000000 * 5);
        const filePath = `${folder}/${new Date().getTime()}/${ramdom}.${ext}`;
        const fileRef = this.storage.ref(filePath);

        const task = fileRef.putString(imageURI, "data_url");

        task.percentageChanges().subscribe((res) => {
          this.uploadProgress = this.setPercentBar(res);
        });

        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe((url) => {
                this.uploadProgress = 0;
                resolve(url);
              });
            })
          )
          .subscribe();
      } catch (err) {
        reject(err);
      }
    });
  }


  uploadFileBLod({ blod, folder, ext }) {
    return new Promise(async (resolve, reject) => {
      try {
        const ramdom = Math.floor(Math.random() * 1000000 * 5);
        const filePath = `${folder}/${new Date().getTime()}/${ramdom}.${ext}`;
        const fileRef = this.storage.ref(filePath);

        const task = fileRef.put(blod);
        task.percentageChanges().subscribe((res) => {
          this.uploadProgress = this.setPercentBar(res);
        });

        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe((url) => {
                this.uploadProgress = 0;
                resolve(url);
              });
            })
          )
          .subscribe();
      } catch (err) {
        reject(err);
      }
    });
  }

  // // Raw string is the default if no format is provided
  uploadFilePutString({ string, folder, contentType, ext }) {
    return new Promise(async (resolve, reject) => {
      try {
        const ramdom = Math.floor(Math.random() * 1000000 * 5);
        const filePath = `${folder}/${new Date().getTime()}/${ramdom}.${ext}`;
        const fileRef = this.storage.ref(filePath);


        const task = fileRef.putString(string, null, {
          contentType: contentType,
        });
        task.percentageChanges().subscribe((res) => {
          this.uploadProgress = this.setPercentBar(res);
        });

        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe((url) => {
                this.uploadProgress = 0;
                console.log("url", url);
                resolve(url);
              });
            })
          )
          .subscribe();
      } catch (err) {
        reject(err);
      }
    });
  }

  // https://github.com/josefrichter/resize/blob/master/public/preprocess.js
  resizeImage(file, maxWidth: number, maxHeight: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        let width = image.width;
        let height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }

        let newWidth;
        let newHeight;

        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        let canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        let context = canvas.getContext('2d');

        context.drawImage(image, 0, 0, newWidth, newHeight);

        canvas.toBlob(resolve, file.type, 0.7);  // get the data from canvas as 70% JPG (can be also PNG, etc.)
      };
      image.onerror = reject;
    });
  }


  // https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


  // convierte el url de blod para poder subirlo a firebase
  getFileBlob(url) {
    return new Promise(async (resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.addEventListener('load', () => {
        resolve(xhr.response);
      });
      xhr.send();
    })

  }



  getBase64(event) {
    if (!event) { return }
    return new Promise(async (resolve, reject) => {
      const file = event;
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };

      reader.onprogress = (pross) => {
        console.log(pross);
      };

      reader.readAsDataURL(file);
    });
  }



  // de base64 a blod
  base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(this.validBase64(base64Data));
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }


  // conviente archivos de blod a base64
  getBlodToBase64(blob) {
    return new Promise(async (resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        var base64data = reader.result;
        resolve(base64data)
      }
    })
  }



  setPercentBar(i) {
    let apc = i / 100;
    console.warn("setPercentBar", apc);
    return apc;
  }

  openFile(type, media) {
    if (type === 1) {
      const audioFile: MediaObject = this.media.create(media);
      audioFile.play();
      return;
    }

    if (type === 2) {
      this.streamingMedia.playVideo(media);
      return;
    }

    if (type === 3) {
      this.photoViewer.show(media, "MY awesome image");
      return;
    }
  }



  /**
   * Convertir buffer a base64
   * @param buffer
   * @param contentType
   * @returns
   */
  bufferToBlob(buffer: any, contentType = 'image/jpeg') {
    const blob: any = new Blob([buffer], { type: contentType });
    return blob;
  }


  /**
   * Convertir blob a base64
   * @param blob
   * @returns
   */
  blobTob64(blob: any) {
    return new Promise(async (resolve, reject) => {
      let reader = new FileReader();

      reader.onloadend = function () {
        const base64data = reader.result;
        // console.log("base64data", base64data);
        resolve(base64data);
      }

      reader.onerror = function (event) {
        alert("Failed to read file!\n\n" + reader.error);
        reject(reader.error);
        reader.abort();
      }

      reader.readAsDataURL(blob);
    });
  }


  /**
   * Descargar archivo
   * @param params
   * @param params.response           Buffer del sarchivo
   * @param params.MIME_types         MIME_TYPE del archivo
   * @param params.ext                Extension del archivo
   * @param params.name               Nombre del archivo
   * @returns
   */
  async downloadBlodWeb(params: any = {}) {
    const { response, MIME_types, ext, name } = params;

    return new Promise(async (resolve, reject) => {

      const blob: any = new Blob([response], { type: MIME_types, });

      FileSaver.saveAs(blob, `${name}.${ext}`);
      resolve({});
    })
  }


  /**
   * Eliminar archivo a través de su URL
   * @param url           URL del archivo a eliminar
   * @returns
   */
   async removeFileByUrl(url: string){
    return this.storage.ref(url).delete();
    // return this.storage.refFromURL(url).delete();
  }

}
