import { CommonService, CustomizationfileService } from 'src/app/services/services';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AlertController, ModalController } from '@ionic/angular';
import { SeeRequestComponent } from '../see-request/see-request.component';

@Component({
  selector: 'app-trophy-request-list',
  templateUrl: './trophy-request-list.component.html',
  styleUrls: ['./trophy-request-list.component.scss'],
})
export class TrophyRequestListComponent implements OnInit {
  claimTrophyList = []
  copyClaimTrophyList: any[];
  segment = "2"
  constructor(
    public alertController: AlertController,
    public customizationfileService: CustomizationfileService,
    public commonService: CommonService,
    private readonly afs: AngularFirestore,
    public modalController: ModalController) {
    this.afs.collection('claim-trophy')
      .valueChanges({ idField: 'docId' })
      .subscribe((res: any) => {
        this.claimTrophyList = res.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
        this.copyClaimTrophyList = this.claimTrophyList
        this.segment = "2"
        this.filterChanged(null, 2)
        console.log('claimTrophyLis', res)
      })
  }

  ngOnInit() { }

  filterChanged(event, event2?) {
    let filter = event ? event.detail.value : event2
    console.log("event", filter)

    if (filter != 4) {
      console.log("no delivered")
      this.claimTrophyList = this.copyClaimTrophyList
        .filter((word: any) => word.item.id == filter && !word.delivered);
    } else {
      console.log("delivered")
      this.claimTrophyList = this.copyClaimTrophyList.filter((word: any) => word.delivered);
    }


  }


  searchbar(type) {
    const searchTerm = type.toLowerCase();
    this.claimTrophyList = this.copyClaimTrophyList;

    if (searchTerm.length > 1) {
      console.log("searchTerm", searchTerm);
      this.claimTrophyList = this.copyClaimTrophyList.filter((user) => {
        return (
          user.email.toLowerCase().indexOf(searchTerm) > -1 ||
          user.name.toLowerCase().indexOf(searchTerm) > -1 ||
          user.identification.toLowerCase().indexOf(searchTerm) > -1
          //  ||
          // user.city.toLowerCase().indexOf(searchTerm) > -1 ||
          // user.state.toLowerCase().indexOf(searchTerm) > -1 ||
          // user.postalCode.toLowerCase().indexOf(searchTerm) > -1 ||
          // user.address.toLowerCase().indexOf(searchTerm) > -1 ||
          // user.address2.toLowerCase().indexOf(searchTerm) > -1
        );
      });
    } else {
      this.claimTrophyList = this.copyClaimTrophyList;
    }
  }

  async delivered(item) {

    console.log("item", item)
    console.log("item", item.docId)

    let res = await this.commonService.presentAlertConfirm({ message: "marcar como entregado" })
    if (!res) { return }


    const alert = await this.alertController.create({
      inputs: [
        {
          name: 'note',
          type: 'text',
          placeholder: 'note'
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: async (data) => {
            let note = data.note ? data.note : null
            await this.afs.collection('claim-trophy').doc(item.docId)
              .update({
                delivered: true,
                date_delivered: Date.now(),
                note: note,
                uid_admin: this.customizationfileService.getUid()
              })

            this.commonService.presentAlertConfirm({ message: "marcado como entregado" })
          }
        }
      ]
    });

    await alert.present();



  }


  async openModal(item) {
    console.log("item", item)
    const modal = await this.modalController.create({
      component: SeeRequestComponent,
      cssClass: 'my-custom-class',
      componentProps: { details: item }
    });

    modal.onDidDismiss()
      .then(async (dataReturned) => {
        console.log("dataReturned", dataReturned)

      });
    return await modal.present();
  }

}
