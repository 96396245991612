import { Component, Input, OnInit } from "@angular/core";
import { CustomizationfileService } from "src/app/services/services";
import { LikesService } from "src/app/services/v2/likes.service";

@Component({
  selector: "app-likes",
  templateUrl: "./likes.component.html",
  styleUrls: ["./likes.component.scss"],
})
export class LikesComponent implements OnInit {
  @Input() idVideo: any;
  uid: string;
  countlike = 0;
  _alreadyLiked = true;
  constructor(
    public likesService: LikesService,
    public customizationfileService: CustomizationfileService
  ) {
    this.uid = this.customizationfileService.getUid();
  }

  ngOnInit() {
    console.log("idVideo", this.idVideo);
    console.log("uid", this.uid);
    this.alreadyLiked();
    this.getCountlike();
  }

  alreadyLiked() {
    this.likesService
      .alreadyLiked(this.idVideo, this.uid)
      .subscribe((count) => {
        console.log("alreadyLiked", count);
        if (count > 0) {
          this._alreadyLiked = true;
        } else {
          this._alreadyLiked = false;
        }
      });
  }

  getCountlike() {
    this.likesService.getLikesCount(this.idVideo).subscribe((count) => {
      this.countlike = count;
    });
  }

  like() {
    this._alreadyLiked = false;
    this.likesService.like(this.idVideo, this.uid);
    this.getCountlike();
    this.alreadyLiked();
  }

  unlike() {
    this._alreadyLiked = false;
    this.likesService.unlike(this.idVideo, this.uid);
    this.getCountlike();
    this.alreadyLiked();
  }
}
