import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertController, ModalController, NavParams } from "@ionic/angular";
import { CommonService } from "src/app/services/services";
import { MediaFilesService } from "src/app/services/v2/media-files.service";

@Component({
  selector: "app-add-serie",
  templateUrl: "./add-serie.component.html",
  styleUrls: ["./add-serie.component.scss"],
})
export class AddSerieComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  data: any;

  constructor(
    public mediaFilesService: MediaFilesService,
    public commonService: CommonService,
    public modalController: ModalController,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private navParams: NavParams,
    public alertController: AlertController,
    private readonly afs: AngularFirestore
  ) {
    this.data = this.navParams.get("value");
    console.log("this.data ", this.data);
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      title: ["", Validators.required],
      slugify: [""],
      id: [""],
      pos: [Date.now()],
      can_see: [false],
      date: [""],
      banner: ["", Validators.required],
      cast: ["", Validators.required],
      genre: ["", Validators.required],
      subtitle: ["", Validators.required],
      description: ["", Validators.required],
      paymentType: ["", Validators.required],
      public: [false, Validators.required],
      tag: ["", Validators.required],
    });

    if (this.data) {
      this.registerForm.patchValue({
        pos: this.data.pos,
        can_see: this.data.can_see,
        tag: this.data.tag.toString(),
        cast: this.data.cast.toString(),
        genre: this.data.genre.toString(),
      });

      this.registerForm.setValue(this.data);
    }
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.registerForm.patchValue({
          banner: reader.result,
        });

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  async onSubmit() {
    try {
      this.submitted = true;
      // stop here if form is invalid
      if (this.registerForm.invalid) {
        return;
      }

      await this.commonService.presentLoading();

      if (this.data) {
        const n = this.registerForm.value.banner
          .substring(1, 50)
          .search("firebasestorage");

        if (n === -1) {
          const urlBanner = await this.mediaFilesService.upload({
            captureVideoUrl: this.registerForm.value.banner,
            contentType: "",
            newName: `series-${Date.now()}.png`,
            codeDivision: "chapters",
          });

          this.registerForm.patchValue({
            banner: urlBanner,
          });
        }

        console.log(
          "this.registerForm.tag",
          this.registerForm.value.tag.toString().toLowerCase().split(",")
        );
        this.registerForm.patchValue({
          date: Date.now(),
          slugify: this.commonService.slugify(this.registerForm.value.title),
          tag: this.registerForm.value.tag.toString().toLowerCase().split(","),
          cast: this.registerForm.value.cast
            .toString()
            .toLowerCase()
            .split(","),
          genre: this.registerForm.value.genre
            .toString()
            .toLowerCase()
            .split(","),
        });

        await this.afs
          .collection("series")
          .doc(this.data.id)
          .update(this.registerForm.value);

        this.onReset();
        this.dismiss();

        this.commonService.dismissLoading();
        // display form values on success
        this.commonService.presentAlert("SUCCESS!! :-)");

        return;
      }

      const urlBanner = await this.mediaFilesService.upload({
        captureVideoUrl: this.registerForm.value.banner,
        contentType: "",
        newName: `series-${Date.now()}.png`,
        codeDivision: "series",
      });

      this.registerForm.patchValue({
        banner: urlBanner,
        date: Date.now(),
        slugify: this.commonService.slugify(this.registerForm.value.title),
        tag: this.registerForm.value.tag.toString().toLowerCase().split(","),
        cast: this.registerForm.value.cast.toString().toLowerCase().split(","),
        genre: this.registerForm.value.genre
          .toString()
          .toLowerCase()
          .split(","),
      });

      console.log("this.registerForm.value", this.registerForm.value);

      await this.afs.collection("series").add(this.registerForm.value);

      this.onReset();
      this.dismiss();

      this.commonService.dismissLoading();
      // display form values on success
      this.commonService.presentAlert("SUCCESS!! :-)");
    } catch (error) {
      console.log("error", error);
      this.commonService.dismissLoading();
    }
  }

  async remove() {
    console.log("this.data ", this.data);
    const alert = await this.alertController.create({
      header: "remove!",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Ok",
          handler: async () => {
            const result = await this.afs
              .collection("series")
              .doc(this.data.id)
              .delete();
            this.dismiss();
            console.log("Confirm Okay", result);
          },
        },
      ],
    });

    await alert.present();
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
