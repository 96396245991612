import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
const url_root: any = environment.urlrootFunctions;

@Injectable({
  providedIn: 'root'
})
export class MultimediaContentService {

  public url = [url_root, 'v2', 'videos', 'multimediaContentOfEvent'].join('/');

  constructor(
    private http: HttpClient,
  ) { }


  async getDataList(keydb: string, endpoint: string){
    const url = [this.url, endpoint].join('/');

    return new Promise((resolve, reject) => {
      this.http.post(url, { keydb }, { responseType: 'json'})
        .subscribe((res: any) => { resolve(res.results); }, err => { reject(err); });
    });
  }

}
