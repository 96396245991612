import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CommonService } from 'src/app/services/services';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoRecordingService } from 'src/app/services/v2/video-recording.service';

@Component({
  selector: 'app-record-video',
  templateUrl: './record-video.component.html',
  styleUrls: ['./record-video.component.scss'],
})
export class RecordVideoComponent implements OnInit, AfterViewInit {

  @ViewChild('videoElement') videoElement: any;
  video: any;
  isPlaying = false;
  displayControls = true;
  isAudioRecording = false;
  isVideoRecording = false;
  // audioRecordedTime;
  videoRecordedTime;
  // audioBlobUrl;
  videoBlobUrl;
  // audioBlob;
  videoBlob;
  // audioName;
  videoName;
  // audioStream;
  videoStream: MediaStream;
  audioConf = { audio: true }
  videoConf = {
    video: {
      mandatory: {
        maxHeight: 720,
        maxWidth: 1280
      },
      optional: [
        { minWidth: 320 },
        { minWidth: 640 },
        { minWidth: 960 },
        { minWidth: 1024 },
        { minWidth: 1280 }
      ]
    }, audio: true
  }
  divisionData: any;

  constructor(
    public commonService: CommonService,
    public modalController: ModalController,
    private navParams: NavParams,
    private ref: ChangeDetectorRef,
    private videoRecordingService: VideoRecordingService,
    private sanitizer: DomSanitizer
  ) {

    this.divisionData = this.navParams.get("divisionData");
    console.log(this.divisionData);

    this.videoRecordingService.recordingFailed().subscribe(() => {
      this.isVideoRecording = false;
      this.ref.detectChanges();
    });

    this.videoRecordingService.getRecordedTime().subscribe((time) => {
      this.videoRecordedTime = time;
      this.ref.detectChanges();
    });

    this.videoRecordingService.getStream().subscribe((stream) => {
      this.videoStream = stream;
      this.ref.detectChanges();
    });

    this.videoRecordingService.getRecordedBlob().subscribe((data) => {
      this.videoBlob = data.blob;
      this.videoName = data.title;
      this.videoBlobUrl = this.sanitizer.bypassSecurityTrustUrl(data.url);
      this.ref.detectChanges();
    });


  }


  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.video = this.videoElement.nativeElement;

  }

  startVideoRecording() {
    if (!this.isVideoRecording) {
      this.video.controls = true;
      this.video.muted = true;
      this.video.volume = 1;
      this.isVideoRecording = true;

      console.log("this.videoConf", this.videoConf)
      this.videoRecordingService.startRecording(this.videoConf)
        .then(stream => {
          // this.video.src = window.URL.createObjectURL(stream);
          this.video.srcObject = stream;
          this.video.play();
        })
        .catch(function (err) {
          console.log(err.name + ": " + err.message);
        });
    }
  }

  abortVideoRecording() {
    if (this.isVideoRecording) {
      this.isVideoRecording = false;
      this.videoRecordingService.abortRecording();
      this.video.controls = false;
    }
  }

  stopVideoRecording() {
    if (this.isVideoRecording) {
      this.videoRecordingService.stopRecording();
      this.video.srcObject = this.videoBlobUrl;
      this.isVideoRecording = false;
      this.video.controls = true;
      this.video.muted = false;
    }
  }

  clearVideoRecordedData() {
    this.videoBlobUrl = null;
    this.video.srcObject = null;
    this.video.controls = false;
    this.ref.detectChanges();
  }

  downloadVideoRecordedData() {
    this._downloadFile(this.videoBlob, 'video/mp4', this.videoName);
  }


  async _downloadFile(data: any, type: string, filename: string): Promise<any> {
    console.log("data", data)
    console.log("type", type)
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    console.log("url", url)
    console.log("urlVideo", url)
    this.dismiss(1, url);
  }



  dismiss(status, message?) {
    this.modalController.dismiss({
      dismissed: true,
      status,
      message,
    });
  }

}
