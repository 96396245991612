import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { CommonService, CustomizationfileService } from 'src/app/services/services';

@Component({
  selector: 'app-add-payment-method',
  templateUrl: './add-payment-method.component.html',
  styleUrls: ['./add-payment-method.component.scss'],
})
export class AddPaymentMethodComponent implements OnInit {

  payment = ""
  segmentpaymentMethod = "add-payment-method"
  public paypalForm: FormGroup;
  public bankForm: FormGroup;
  public cryptocurrencyForm: FormGroup;
  paymentOptions = [
    {
      name: "Paypal",
      enabled: false
    }, {
      name: "Bank",
      enabled: false
    }, {
      name: "cryptocurrency",
      enabled: false
    },
  ]
  listpayment = [];
  submitted: boolean;


  constructor(
    public commonService: CommonService,
    public alertController: AlertController,
    public customizationfileService: CustomizationfileService,
    public afs: AngularFirestore,
    public modalController: ModalController,
    public fb: FormBuilder) {
    this.paypalForm = fb.group({
      email: ["", Validators.compose([Validators.required, Validators.email])]
    });

    this.bankForm = fb.group({
      bankName: ["", Validators.compose([Validators.required])],
      accountInTheNameOf: ["", Validators.compose([Validators.required])],
      accountNumber: ["", Validators.compose([Validators.required])],
      ibanNumber: ["", Validators.compose([Validators.required])],
      swift: ["", Validators.compose([Validators.required])],
      bankPhone: ["", Validators.compose([Validators.required])],
      bankBranchAddress: ["", Validators.compose([Validators.required])],
    });

    this.cryptocurrencyForm = fb.group({
      address_bitcoin: [""],
      address_ethereum: [""]
    });
  }

  get p() {
    return this.paypalForm.controls;
  }

  get b() {
    return this.bankForm.controls;
  }

  get c() {
    return this.cryptocurrencyForm.controls;
  }

  ngOnInit() {
    this.getPaymentOptions()
  }

  showPaymentOptions() {
    this.payment = ''
  }

  getPaymentOptions() {
    this.afs.collection('wallet')
      .doc(this.customizationfileService.getUid())
      .collection('list')
      .valueChanges({ idField: 'key' })
      .subscribe((res: any) => {
        if (res.length === 0) {
          this.segmentpaymentMethod = "add-payment-method"
          return
        }
        this.segmentpaymentMethod = "list-payment-method"
        this.listpayment = res
        console.log("wallet", res)
      })
  }


  showMethod(method) {
    console.log("method", method)
    this.payment = method.name
  }

  async saveMethodPaypal() {
    this.submitted = true;
    if (this.paypalForm.invalid) { return }
    const alert = await this.alertController.create({
      header: '¿guardar?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'SI',
          handler: async () => {
            console.log(this.paypalForm.invalid);
            const paypal = this.paypalForm.value;
            console.log("paypal", paypal)
            this.saveMethod({
              data: paypal, type: "paypal",
            })
            this.payment = ''
            this.submitted = false;
          }
        }
      ]
    });
    await alert.present();

  }

  async saveMethodCryptoCurrency() {
    this.submitted = true;
    if (this.cryptocurrencyForm.invalid) { return }
    const alert = await this.alertController.create({
      header: '¿guardar?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'SI',
          handler: async () => {
            console.log(this.cryptocurrencyForm.invalid);
            const cryptocurrency = this.cryptocurrencyForm.value;
            console.log("cryptocurrencyForm", cryptocurrency)
            this.saveMethod({
              data: cryptocurrency, type: "cryptocurrency",
            })
            this.payment = ''
            this.submitted = false;
          }
        }
      ]
    });
    await alert.present();



  }

  async saveMethodbank() {
    this.submitted = true;
    if (this.bankForm.invalid) { return }
    const alert = await this.alertController.create({
      header: '¿guardar?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'SI',
          handler: async () => {

            console.log(this.bankForm.invalid);
            const bank = this.bankForm.value;
            console.log("bankForm", bank)
            this.saveMethod({
              data: bank, type: "bank",
            })
            this.payment = ''
            this.submitted = false;
          }
        }
      ]
    });
    await alert.present();
  }


  async saveMethod({
    data, type,
  }) {
    const id = this.afs.createId();
    await this.commonService.presentLoading()
    await this.afs.collection('wallet')
      .doc(this.customizationfileService.getUid())
      .collection('list')
      .doc(id)
      .set({
        date: Date.now(),
        data,
        type
      });
    this.commonService.dismissLoading()
    this.commonService.presentAlertTranslation("guardar con éxito")
  }

  async removeMethod(id) {
    console.log("Prints", id)
    const alert = await this.alertController.create({
      header: '¿guardar?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'SI',
          handler: async () => {
            this.afs.collection('wallet')
              .doc(this.customizationfileService.getUid())
              .collection('list').doc(id).delete()
          }
        }
      ]
    });
    await alert.present();

  }

  dismiss() {
    this.modalController.dismiss();
  }
}
