// https://stackoverflow.com/questions/51809241/how-to-compress-a-base64-image-to-custom-size
import { Injectable, OnInit } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { Router } from "@angular/router";
import { CommonService } from "../common/common.service";
import { StorageService } from "../storage/storage.service";
import { AuthenticationService } from "../../v1/auth/authentication.service";
import { CustomizationfileService } from "../customizationfile/customizationfile.service";

@Injectable({
  providedIn: "root",
})
export class WelcolmeService {
  update = true;
  locations: any[] = [];
  events: any[];
  authenticationState: any
  constructor(
    public authenticationService: AuthenticationService,
    public db: AngularFireDatabase,
    public _cs: CommonService,
    private router: Router,
    public _cf: CustomizationfileService,
    private _storage: StorageService
  ) {

    this.authenticationService.authenticationState.subscribe((res => {
      this.authenticationState = res
    }))
  }

  async getEvents() {
    return new Promise((resolve) => {

      const itemsRef = this.db.list("_competitionListCreated", (ref) =>
        ref.orderByChild("public").equalTo(true)
      );
      itemsRef.snapshotChanges().subscribe((parameters) => {
        this.events = [];
        parameters.forEach((param: any) => {
          // console.log("param", param.payload.val())
          const imagenUrl = this._cs.getImg(
            param.payload.val().imagen,
            "img1200x1200MV"
          );

          

          this.events.push({
            keyinfo: param.payload.key,
            name: param.payload.val().name.toLowerCase(),
            distance: param.payload.val().placeOfEvent,
            coverPhotoUrl: imagenUrl,
            keydb: param.payload.val().keydb,
            rating: 5,
            utc: param.payload.val().utc,
            banner_competitor_pass: this._cs.getImg(
              param.payload.val().imagen,
              "img1980x400PA"
            ),
            banner_division_pass: this._cs.getImg(
              param.payload.val().imagen,
              "img1980x400CA"
            ),
            banner_ticket_pass: this._cs.getImg(
              param.payload.val().imagen,
              "img1980x400TI"
            ),
            description: param.payload.val().description,
            competitionDate: param.payload.val().competitionDate,
            city: param.payload.val().city,
          });
        });
        resolve(this.events);
      });
    });
  }

  getPopularevents() {
    return this.sortDate(this.events);
  }

  getAllevents() {
    return this.events;
  }

  sortDate(array) {
    if (!array) {
      return;
    }
    return array
      .sort((a, b) => {
        const c: any = new Date(a.competitionDate);
        const d: any = new Date(b.competitionDate);
        return d - c;
      })
      .slice(1, 5);
  }

  goEvent(data) {
    console.log("data", data)
    console.log("this.authenticationState", this.authenticationState)
    console.log("dat", data)
    if (!this.authenticationState || !data) {
      this.router.navigate(["/auth/login"]);
      return
    }


    window.localStorage.removeItem("competitor_pass");
    window.localStorage.removeItem("division_pass");
    window.localStorage.removeItem("ticket_pass");
    window.localStorage.removeItem("nameDB");
    window.localStorage.removeItem("currency");
    window.localStorage.removeItem("keyDB");
    window.localStorage.removeItem("event_info");
    window.localStorage.removeItem("competitor_pass");


    localStorage.setItem("event_info", JSON.stringify(data));
    this._storage.localStorageSetItem("eventName", data.name);
    this._storage.localStorageSetItem("keyDB", data.event_id);
    this.router.navigate(["/members/tabs-event/tabs/home", data.event_id]);
    this._cf.existKeyDb.next(data.event_id)

  }
}
