import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertController, ModalController, NavParams } from "@ionic/angular";
import { CommonService } from "src/app/services/services";
import { MediaFilesService } from "src/app/services/v2/media-files.service";

@Component({
  selector: "app-update-chapters",
  templateUrl: "./update-chapters.component.html",
  styleUrls: ["./update-chapters.component.scss"],
})
export class UpdateChaptersComponent implements OnInit {
  data: any;
  registerForm: FormGroup;

  submitted = false;
  _editVideo = false
  constructor(
    public mediaFilesService: MediaFilesService,
    public commonService: CommonService,
    public modalController: ModalController,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private navParams: NavParams,
    private readonly afs: AngularFirestore,
    public alertController: AlertController
  ) {


    this.registerForm = this.formBuilder.group({
      title: ["", Validators.required],
      placeholder: ["/assets/img/settle-tv/settle-tv-banner.jpeg", Validators.required],
      src: ["", Validators.required],
      description: ["", Validators.required],
      public: [true],
      date: [Date.now()],
      pos: [Date.now()],
      type: ["video/mp4"],
      idDoc: [""],
      idChapters: [""],
    });

  }

  ngOnInit() {
    this.data = this.navParams.get("value");
    console.log('this.data', this.data)
    this.registerForm.setValue(this.data);
  }

  editVideo() {
    this._editVideo = !this._editVideo;
    this.registerForm.patchValue({ src: "" });
  }


  upVideo(e: any) {
    console.warn("onUpIngredients", e);
    this.registerForm.patchValue({ src: e.data.link });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  async onSubmit() {
    try {
      this.submitted = true;
      // stop here if form is invalid
      if (this.registerForm.invalid) { return; }

      await this.commonService.presentLoading();

      console.log("this.registerForm.value", this.registerForm.value);

      await this.afs
        .collection("chapters")
        .doc(this.registerForm.value.idChapters)
        .collection("list")
        .doc(this.registerForm.value.idDoc)
        .update(this.registerForm.value);

      // display form values on success
      this.onReset();
      this.dismiss();
      this.commonService.presentAlert("¡¡ÉXITO!! :-)");
    } catch (error) {
      console.log(error);
    } finally {
      this.commonService.dismissLoading();
    }
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

  async remove() {
    console.log("this.data ", this.data);
    const alert = await this.alertController.create({
      header: "remove!",
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: "Ok",
          handler: async () => {
            await this.afs
              .collection("chapters")
              .doc(this.data.idChapters)
              .collection("list")
              .doc(this.data.idDoc)
              .delete();
            console.log("Confirm Okay");
            this.dismiss();
          },
        },
      ],
    });

    await alert.present();
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
