import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { AngularFireDatabase } from "angularfire2/database";

const url_root: any = environment.urlrootFunctions;
const prod = environment.production;

@Injectable({
  providedIn: "root",
})
export class ManageUsersService {
  constructor(private http: HttpClient, public db: AngularFireDatabase) { }
  // https://stackoverflow.com/questions/47345282/how-to-add-cors-request-in-header-in-angular-5
  downloadAllVideos({ url, data }) {
    return new Promise(async (resolve, reject) => {
      console.log("downloadAllVideos", `${url_root}/v2/${url}`);
      console.log("data", data);
      this.http.post(`${url_root}/v2/${url}`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  downloadFile({ url }): any {
    return this.http.get(url, {
      responseType: "blob",
    });
  }

  globalFirebase({ url }) {
    return new Promise(async (resolve, reject) => {
      this.db
        .object(url)
        .valueChanges()
        .subscribe((data) => {
          if (!data) {
            resolve([]);
            return;
          }
          resolve(data);
        });
    });
  }

  global({ url, data }) {
    // console.log({ url, data });
    return new Promise(async (resolve, reject) => {
      this.http.post(`${url_root}/v2/${url}`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  globalOld({ url, data }) {
    console.log(`${url_root}/${url}`);
    return new Promise(async (resolve, reject) => {
      this.http.post(`${url_root}/${url}`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getResultDivisions(data) {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${url_root}/v2/result/resultsDivision`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getResultDivisionsAdmin(data) {
    return new Promise(async (resolve, reject) => {
      console.log(`${url_root}/v2/result/resultsDivisionAdmin`, data)
      this.http
        .post(`${url_root}/v2/result/resultsDivisionAdmin`, data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }


  /**
   * 
   * @param keydb 
   * @param uid 
   */
  getDivision(keydb, uid) {
    return new Promise(async (resolve, reject) => {
      this.http
        .get(`${url_root}/division/look_for_divisions?keydb=${keydb}&uid=${uid}`)
        .subscribe((res) => { resolve(res); }, (err) => { reject(err); }
        );

    });
  }

  getConsultDivisions(data) {
    return new Promise(async (resolve, reject) => {
      this.http
        .post(`${url_root}/v2/division/consultDivisions`, data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getDivisionDancer(data) {
    return new Promise(async (resolve, reject) => {
      this.http
        .post(`${url_root}/v2/division/getDivisionDancer`, data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getCustomVideos(data) {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${url_root}/v2/division/customVideos`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  createStatesTestVideo(data) {
    return new Promise(async (resolve, reject) => {
      this.http
        .post(`${url_root}/v2/division/createStatesTestVideo`, data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getMainVideos(data) {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${url_root}/v2/videos/mainVideos`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getMainVideosjudges(data) {
    return new Promise(async (resolve, reject) => {
      this.http.post(`${url_root}/v2/videos/mainVideosjudges`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  mainChoreography(data) {
    return new Promise(async (resolve, reject) => {
      this.http
        .post(`${url_root}/v2/division/mainChoreography`, data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getUserByEmail(email) {
    return new Promise(async (resolve, reject) => {
      this.http
        .post(`${url_root}/manage_users/getUserByEmail`, { email: email })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  sendEmail(email) {
    if (prod) {
      return new Promise(async (resolve, reject) => {
        this.http.post(`${url_root}/mail/send-email`, email).subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
      });
    }
  }

  validateDivision(data) {
    return new Promise((resolve, reject) => {
      console.log(data);
      this.http.post(`${url_root}/division/user_validation`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  divisionRules(data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      this.http.post(`${url_root}/division/rules`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  fullPass(data) {
    return new Promise((resolve, reject) => {
      console.log("url_root", url_root);
      console.log(`${url_root}/full_pass/pass`);
      console.log("data", data);
      this.http.post(`${url_root}/full_pass/pass`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  fullPassValidate(data) {
    return new Promise((resolve, reject) => {
      console.log(`${url_root}/full_pass/fullPassValidate`);
      this.http.post(`${url_root}/full_pass/fullPassValidate`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  exchangeRate(code) {
    // https://www.exchangerate-api.com/#per_audience
    // https://fixer.io/product
    return new Promise((resolve, reject) => {
      console.log(1, `https://api.exchangerate-api.com/v4/latest/${code}`);
      this.http
        .get(`https://api.exchangerate-api.com/v4/latest/${code}`)
        .subscribe(
          (res: any) => {
            if (res.rates.AED) {
              resolve(res.rates.AED);
            } else {
              reject("moneda error");
            }
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  vote(data) {
    return new Promise((resolve, reject) => {
      console.log("data", data);
      console.log("url", `${url_root}/vote`);
      this.http.post(`${url_root}/vote`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /**
   * 
   * @param data 
   * @param route 
   * @returns 
   */
  executePurchase(data, route) {
    return new Promise((resolve, reject) => {
      console.log("data", data);
      console.log("url", `${url_root}/payment_gateways/${route}`);
      this.http.post(`${url_root}/payment_gateways/${route}`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  aFullPassPurchase(data) {
    return new Promise((resolve, reject) => {
      console.log("aFullPassPurchase", data);
      console.log("url", `${url_root}/payment_gateways/aFullPass`);
      this.http.post(`${url_root}/payment_gateways/aFullPass`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  aDivisionPassPurchase(data) {
    return new Promise((resolve, reject) => {
      console.log("aFullPassPurchase", data);
      console.log("url", `${url_root}/payment_gateways/aDivisionPass`);
      this.http
        .post(`${url_root}/payment_gateways/aDivisionPass`, data)
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  encryption(data) {
    return new Promise((resolve, reject) => {
      console.log(`${environment.adcb.url_adcb}/encryption`);
      this.http
        .post(`${environment.adcb.url_adcb}/encryption`, JSON.stringify(data), {
          responseType: "text",
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  // Acreditaciones

  accreditationsDivision(data) {
    return new Promise((resolve, reject) => {
      this.http.post(`${url_root}/accreditations/division`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  accreditationsFullPass(data) {
    return new Promise((resolve, reject) => {
      this.http.post(`${url_root}/accreditations/full_pass`, data).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
}
