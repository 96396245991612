import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AngularFireDatabase } from 'angularfire2/database';
import { CommonService } from 'src/app/services/services';

@Component({
  selector: 'app-create-language',
  templateUrl: './create-language.component.html',
  styleUrls: ['./create-language.component.scss'],
})
export class CreateLanguageComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  constructor(
    public commonService: CommonService,
    public modalController: ModalController,
    public db: AngularFireDatabase,
    private formBuilder: FormBuilder) {
    this.registerForm = this.formBuilder.group(
      {
        key: ["", Validators.required],
        es: ["", Validators.required],
        en: ["", Validators.required],
      }
    );
  }

  ngOnInit() {

  }

  async onSubmit() {

    try {
      this.submitted = true;

      // stop here if form is invalid
      console.log(this.registerForm.invalid);
      if (this.registerForm.invalid) {
        return;
      }


      const data = this.registerForm.value;
      console.log('data', data)

      let es = {}
      es[data.key] = data.es;

      let en = {}
      en[data.key] = data.en;



      const itemRefen = this.db.object(`languages/en`);
      await itemRefen.update(en);

      const itemRefes = this.db.object(`languages/es`);
      await itemRefes.update(es);


      console.log("en", en)
      console.log("es", es)

      this.commonService.presentAlertTranslation("guardar")
      this.registerForm.reset()
      this.submitted = false
    } catch (error) {
      console.log("error", error)
      this.commonService.presentAlert("error")
    }
  }


  get f() {
    return this.registerForm.controls;
  }

  dismiss() {
    this.modalController.dismiss();
  }



}
